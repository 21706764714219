import {useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {throttle} from 'lodash';

import {getDrawer, getFiltersVisibility, getMap} from '@/selectors';
import {setViewportBoundingBox} from '@/store/modules/filters/actions';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useRouter} from '@/hooks/useRouter';

export const useMapResize = () => {
  const dispatch = useDispatch();
  const {getBoundingBox, resizeMap, recenterMap} = useMapboxGL();
  const {isReportRoute} = useRouter();

  const {analyticsOpen} = useSelector(getDrawer);
  const filtersOpen = useSelector(getFiltersVisibility);
  const {center, isFlying} = useSelector(getMap);

  const handleViewport = useCallback(
    throttle((boundingBox) => {
      dispatch(setViewportBoundingBox(boundingBox));
    }, 200),
    [dispatch],
  );

  const handleResize = useCallback(
    ({target: map}) => {
      map.resize();
      if (!isReportRoute) {
        handleViewport(getBoundingBox());
      }
    },
    [dispatch, isReportRoute, handleViewport],
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (!isFlying) {
        resizeMap();
        recenterMap({longitude: center.longitude, latitude: center.latitude});
      }
      if (!isReportRoute) {
        handleViewport(getBoundingBox());
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [
    dispatch,
    getBoundingBox,
    resizeMap,
    recenterMap,
    filtersOpen,
    analyticsOpen,
    isReportRoute,
    handleViewport,
    isFlying,
  ]);

  return {handleResize};
};
