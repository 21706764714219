import {Environment, OrganizationTier, UserRole} from '@/types/shared';

export type FeatureAction = 'disable' | 'hide' | 'label' | 'tease' | 'modal';

export interface ControlledFeature {
  id: string;
  name: string;
  mixpanel?: string;
  roles?: Array<UserRole>; // If roles are undefined = no restrictions, if empty array = no access
  tiers?: Array<OrganizationTier>; // If tiers are undefined = no restrictions, if empty array = no access
  actions: FeatureAction[];
  environments?: Environment[]; // If environments are undefined = no restrictions, if empty array = no access
}

export const CONTROLLED_FEATURES: ControlledFeature[] = [
  {
    id: 'PD-4810',
    name: 'shared-saved-locations',
    mixpanel: 'Additional Saved Locations',
    roles: ['owner', 'admin'],
    tiers: ['premium', 'enterprise'],
    actions: ['disable', 'hide'],
  },
  {
    id: 'PD-5405',
    name: 'shared-locations-visibility',
    mixpanel: 'Additional Team Downloads',
    tiers: ['premium', 'enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-5182',
    name: 'api-key-management',
    mixpanel: 'User API Keys',
    roles: ['owner'],
    tiers: ['premium', 'enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-7777',
    name: 'saved-locations-tags',
    mixpanel: 'Location Types Tabs',
    tiers: ['premium', 'enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-7778',
    name: 'saved-locations-priority',
    mixpanel: 'Priority Rating',
    tiers: ['premium', 'enterprise'],
    actions: ['disable', 'label', 'hide'],
  },
  {
    id: 'PD-5319',
    name: 'threat-analytics-change',
    mixpanel: 'Percentage Change Threat Analytics',
    tiers: ['premium', 'enterprise'],
    actions: ['tease', 'hide'],
  },
  {
    id: 'PD-5425',
    name: 'saved-locations-change',
    mixpanel: 'Percentage Change Saved Locations',
    tiers: ['enterprise'],
    actions: ['disable', 'hide', 'label'],
  },
  {
    id: 'PD-4791',
    name: 'saved-locations-downloads',
    mixpanel: 'Additional Team Downloads',
    tiers: ['premium', 'enterprise'],
    actions: ['disable'],
  },
  {
    id: 'PD-4790',
    name: 'report-downloads',
    mixpanel: 'Report Downloads',
    tiers: ['premium', 'enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-5754',
    name: 'custom-report',
    mixpanel: 'Custom Report',
    tiers: ['premium', 'enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-5607',
    name: 'saved-locations-analysis',
    mixpanel: 'Enterprise Dashboard Charts',
    tiers: ['enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-6281',
    name: 'my-locations-analysis',
    mixpanel: 'My Locations Analysis',
    tiers: ['enterprise'],
    actions: ['hide'],
  },
  {
    id: 'PD-6130',
    name: 'map-layers',
    mixpanel: 'Map Layers',
    roles: [],
    tiers: [],
    actions: ['hide'],
  },
  {
    id: 'PD-6512',
    name: 'country-analysis',
    mixpanel: 'Country Analysis Settings',
    roles: [],
    tiers: [],
    actions: ['hide'],
  },
  {
    id: 'PD-6512',
    name: 'gtm',
    mixpanel: 'Global Threats Model',
    actions: ['hide'],
  },
  {
    id: 'PD-5756',
    name: 'forecast',
    mixpanel: 'Forecast',
    tiers: ['enterprise'],
    actions: ['modal'],
  },
  {
    id: 'PD-6714',
    name: 'mld-threat-profile',
    mixpanel: 'My Location Analysis - Threat Profile Tab',
    actions: ['hide'],
    environments: ['local', 'development'],
  },
];
