export const CITY_MARKERS_LAYER = 'cities';
export const THREAT_POINTS_LAYER = 'threats';
export const TIMEZONE_LAYER = 'timezones';
export const COUNTRY_LAYER = 'world-maps';
export const NEIGHBORHOOD_LAYER = 'boundary-maps';
export const SAVED_LOCATIONS_LAYER = 'saved_locations';
export const COUNTRY_SOURCE = 'country-boundaries-source';
// change layer
export const SAVED_LOCATIONS_ANALYSIS_LAYER =
  'change_and_score_saved_locations';
export const SAVED_LOCATIONS_ANALYSIS_SOURCE = 'change-points-source';
// radius layer
export const RADIUS_LINE_LAYER = 'threat-radius-line-layer';
export const RADIUS_FILL_LAYER = 'threat-radius-fill-layer';
export const THREAT_RADIUS_SOURCE = 'threat-radius-source';
export const JSON = '.json';
// threat score
export const THREAT_SCORE = 'h3_threats_score';
export const THREAT_SCORE_SOURCE = 'threat-score-source';
export const THREAT_SCORE_FILL_LAYER = 'threat-score-fill-layer';
export const THREAT_SCORE_LINE_LAYER = 'threat-score-line-layer';
// population
export const POPULATION = 'h3_kontur_population';
export const POPULATION_SOURCE = 'population-source';
export const POPULATION_FILL_LAYER = 'population-fill-layer';
export const POPULATION_LINE_LAYER = 'population-line-layer';
