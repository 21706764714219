import {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Source, Layer, Marker} from 'react-map-gl';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash/debounce';

import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import {Plus, Minus} from '@phosphor-icons/react';

import {
  getBearing,
  getCircleGeoJSON,
  getDestinationPoint,
  getDistance,
} from '@/utils/turfUtils';
import {convertDistance} from '@/utils/utils';
import {
  setClickedLocation,
  setThreatAnalyticsArea,
} from '@/store/modules/user/actions';
import {
  getClickedLocation,
  getThreatAnalyticsArea,
  getUser,
  getUserSavingLocationStatus,
} from '@/selectors';
import {
  RADIUS_FILL_LAYER,
  RADIUS_LINE_LAYER,
  THREAT_RADIUS_SOURCE,
} from '@/components/map/layers/constants';
import {THREAT_RADIUS_LIMITS} from '@/components/map/constants';
import {styles} from '@/components/map/layers/LayerThreatRadius.styles';
import BasePopup from '@/components/common/map/BasePopup';
import {useApi} from '@/hooks/api/useApi';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useTranslation} from '@/hooks/useTranslation';
import {BaseIconButton} from '@/components/common/buttons';
import {useMapHandlers} from '@/hooks/map/useMapHandlers';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

const snackbarId = 'save-location-confirmation';

function LayerThreatRadius() {
  const {track} = useSegmentAnalytics();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {putSavedLocation} = useApi();
  const {triggerMapRepaint} = useMapboxGL();
  const {showSnackbar, hideSnackbarById} = useSnackbar();
  const {getI18N} = useTranslation();
  const {clickSavedLocation} = useMapHandlers();

  const analyticsArea = useSelector(getThreatAnalyticsArea);
  const savedLocation = useSelector(getClickedLocation);
  const isSavingLocation = useSelector(getUserSavingLocationStatus);
  const {units} = useSelector(getUser);

  const [clickSize, setClickSize] = useState([]);

  const [area, setArea] = useState({
    latitude: 0,
    longitude: 0,
    radius: 0,
  });

  const [sizeControl, setSizeControl] = useState({
    latitude: 0,
    longitude: 0,
    bearing: 45,
  });

  const {yesLabel, noLabel, positionUpdated, updatedLocationLabel} =
    getI18N('savedLocations');

  const clickSizeRef = useRef(clickSize);

  const isVisible = useMemo(
    () => analyticsArea.display && analyticsArea.shape === 'circle',
    [analyticsArea],
  );

  const validateRadius = useCallback(
    (radius) => {
      const {min, max, main} = THREAT_RADIUS_LIMITS[units];

      const minRadius = convertDistance(min, DISTANCE_UNIT.meters, units);
      const maxRadius = convertDistance(max, DISTANCE_UNIT.meters, units);

      if (radius >= minRadius && radius <= maxRadius) {
        return radius;
      }

      if (radius < minRadius) {
        return minRadius;
      }

      if (radius > maxRadius) {
        return maxRadius;
      }

      return convertDistance(main, DISTANCE_UNIT.meters, units);
    },
    [units],
  );

  const updateSavedLocation = useCallback(
    debounce(({latitude, longitude, radius}) => {
      if (isSavingLocation) return;
      if (
        latitude !== savedLocation.latitude ||
        longitude !== savedLocation.longitude
      ) {
        showSnackbar({
          id: snackbarId,
          message: positionUpdated,
          actions: [
            {
              label: noLabel,
              props: {
                variant: 'outlined',
                onClick: () => {
                  hideSnackbarById(snackbarId);
                  dispatch(
                    setThreatAnalyticsArea({
                      latitude: savedLocation.latitude,
                      longitude: savedLocation.longitude,
                      radius: savedLocation.radiusMeters,
                    }),
                  );
                },
              },
            },
            {
              label: yesLabel,
              props: {
                onClick: () => {
                  hideSnackbarById(snackbarId);
                  putSavedLocation.mutate(
                    {
                      query: savedLocation.id,
                      body: {
                        ...savedLocation,
                        tags: savedLocation.tags?.map(({name}) => name),
                        latitude,
                        longitude,
                      },
                    },
                    {
                      onSuccess: (location) => {
                        dispatch(setClickedLocation(location));
                        triggerMapRepaint('saved-locations-source');
                        showSnackbar({
                          iconColor: 'success',
                          icon: 'check',
                          message: updatedLocationLabel,
                        });
                      },
                    },
                  );
                },
              },
            },
          ],
          duration: 0,
        });
      } else {
        putSavedLocation.mutate(
          {
            query: savedLocation.id,
            body: {
              ...savedLocation,
              tags: savedLocation.tags?.map(({name}) => name),
              radiusMeters: radius,
            },
          },
          {
            onSuccess: (location) => {
              dispatch(setClickedLocation(location));
            },
          },
        );
      }
    }, 1000),
    [dispatch, triggerMapRepaint, savedLocation, isSavingLocation],
  );

  const handleDragEnd = useCallback(() => {
    dispatch(
      setThreatAnalyticsArea({
        latitude: area.latitude,
        longitude: area.longitude,
        radius: area.radius,
      }),
    );
    updateSavedLocation({
      latitude: area.latitude,
      longitude: area.longitude,
      radius: area.radius,
    });
    track('Threat Radius Dragged', {
      radius: area.radius,
      latitude: area.latitude,
      longitude: area.longitude,
    });
  }, [dispatch, updateSavedLocation, area]);

  const handleDragSize = useCallback(
    ({lngLat: {lat: latitude, lng: longitude}}) => {
      const radius = validateRadius(getDistance(area, sizeControl));
      setArea({
        latitude: area.latitude,
        longitude: area.longitude,
        radius,
      });
      const bearing = getBearing(area, sizeControl);
      setSizeControl({
        latitude,
        longitude,
        bearing,
      });
    },
    [sizeControl, area],
  );

  const handleDragPosition = useCallback(
    ({lngLat: {lat: latitude, lng: longitude}}) => {
      setArea(({radius}) => ({
        latitude,
        longitude,
        radius,
      }));
      const {latitude: lat, longitude: lon} = getDestinationPoint({
        latitude,
        longitude,
        radius: area.radius,
        bearing: sizeControl.bearing,
      });
      setSizeControl(({bearing}) => ({
        latitude: lat,
        longitude: lon,
        bearing,
      }));
    },
    [area, sizeControl],
  );

  const debouncedClickSize = useCallback(
    debounce(() => {
      const radius = validateRadius(
        area.radius +
          convertDistance(
            clickSizeRef.current.reduce(
              (acc, action) => acc + (action === 'increase' ? 0.1 : -0.1),
              0,
            ),
            DISTANCE_UNIT.meters,
            units,
          ),
      );
      setArea(({latitude, longitude}) => ({
        latitude,
        longitude,
        radius,
      }));
      const {latitude: lat, longitude: lon} = getDestinationPoint({
        latitude: area.latitude,
        longitude: area.longitude,
        radius,
        bearing: sizeControl.bearing,
      });
      setSizeControl(({bearing}) => ({
        latitude: lat,
        longitude: lon,
        bearing,
      }));
      updateSavedLocation({
        latitude: area.latitude,
        longitude: area.longitude,
        radius,
      });
      dispatch(
        setThreatAnalyticsArea({
          latitude: area.latitude,
          longitude: area.longitude,
          radius,
        }),
      );
      setClickSize([]);
      track('Threat Radius Changed via Click', {
        radius,
        latitude: area.latitude,
        longitude: area.longitude,
      });
    }, 1000),
    [updateSavedLocation, units, area, sizeControl],
  );

  const handleClickSize = (action) => {
    setClickSize((state) => [...state, action]);
    debouncedClickSize();
  };

  const handleClickPosition = useCallback(
    ({originalEvent}) => {
      originalEvent.stopPropagation();
      clickSavedLocation(savedLocation);
    },
    [clickSavedLocation, savedLocation],
  );

  useEffect(() => {
    if (isVisible) {
      setArea({
        latitude: analyticsArea.latitude,
        longitude: analyticsArea.longitude,
        radius: analyticsArea.radius,
      });
      const {latitude, longitude} = getDestinationPoint({
        latitude: analyticsArea.latitude,
        longitude: analyticsArea.longitude,
        radius: analyticsArea.radius,
        bearing: sizeControl.bearing,
      });
      setSizeControl(({bearing}) => ({
        latitude,
        longitude,
        bearing,
      }));
    }
  }, [isVisible, analyticsArea]);

  const isControlRight = useMemo(
    () => sizeControl.bearing > 0 && sizeControl.bearing < 161,
    [sizeControl.bearing],
  );

  useEffect(() => {
    clickSizeRef.current = clickSize;
  }, [clickSize]);

  if (!isVisible) return null;

  return (
    <>
      <Source
        id={THREAT_RADIUS_SOURCE}
        type="geojson"
        tolerance={0}
        promoteId="id"
        data={getCircleGeoJSON(area, savedLocation.id)}>
        <Layer
          id={RADIUS_LINE_LAYER}
          source={THREAT_RADIUS_SOURCE}
          type="line"
          paint={{
            'line-color': theme.palette.primary.main,
            'line-width': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              2,
              1.5,
            ],
          }}
        />
        <Layer
          id={RADIUS_FILL_LAYER}
          source={THREAT_RADIUS_SOURCE}
          type="fill"
          paint={{
            'fill-color': theme.palette.primary.main,
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'hover'], false],
              0.2,
              0.1,
            ],
          }}
        />
      </Source>
      <Marker
        latitude={area.latitude}
        longitude={area.longitude}
        draggable
        onClick={handleClickPosition}
        onDrag={handleDragPosition}
        onDragEnd={handleDragEnd}>
        <Box sx={styles.center} />
      </Marker>
      <Marker
        latitude={sizeControl.latitude}
        longitude={sizeControl.longitude}
        rotation={sizeControl.bearing}
        offset={[0, -7]}
        draggable
        onDrag={handleDragSize}
        onDragEnd={handleDragEnd}>
        <Box sx={styles.resizeHandle} />
      </Marker>
      <BasePopup
        latitude={sizeControl.latitude}
        longitude={sizeControl.longitude}
        closeButton={false}
        closeOnClick={false}
        offset={isControlRight ? [20, -10] : [-20, -10]}
        anchor={isControlRight ? 'bottom-left' : 'bottom-right'}
        tipColor="transparent"
        color="transparent">
        <Box sx={styles.magnitude}>
          <BaseIconButton
            icon={Minus}
            size={21}
            onClick={() => handleClickSize('decrease')}
          />
          <Typography variant="caption">
            {convertDistance(area.radius, units).toFixed(2)} {units}
          </Typography>
          <BaseIconButton
            icon={Plus}
            size={21}
            onClick={() => handleClickSize('increase')}
          />
        </Box>
      </BasePopup>
    </>
  );
}

export default memo(LayerThreatRadius);
