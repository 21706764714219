import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

import {Box, useTheme} from '@mui/material';

import {styles} from '@/components/analytics/sections/ForecastTrendSection.styles';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseThresholdChart from '@/components/common/charts/BaseThresholdChart';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import BaseLegend from '@/components/common/charts/BaseLegend';

import {useForecastTrend} from '@/hooks/charts/useForecastTrend';
import {useTranslation} from '@/hooks/useTranslation';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import FeatureAccessForecastModal from '@/components/feature-access/FeatureAccessForecastModal';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function ForecastTrendSection({id, sx, granularity, settings, isGTM}) {
  const title = 'Monthly Crime Trend Forecast';
  const [forecastMonths, setForecastMonths] = useState(6);
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {getFeatureAccess} = useFeatureAccess();

  const {
    eventCountLegend,
    trendLineLegend,
    forecastLegend,
    confidence75Legend,
    confidence90Legend,
    forecastTooltip,
    trendTooltip,
    countTooltip,
    confUb90Tooltip,
    confUb75Tooltip,
    confLb75Tooltip,
    confLb90Tooltip,
  } = getI18N('threatAnalytics.forecastTrendSection');
  const {forecast3Months, forecast6Months, forecast1Year} =
    getI18N('chartSelection');
  const disableFeature = getFeatureAccess('forecast', 'modal');

  const {
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useForecastTrend({
    id,
    title,
    granularity,
    forecastMonths,
    disabled: disableFeature,
  });

  const periodOptions = [
    {
      label: forecast3Months,
      value: 3,
    },
    {
      label: forecast6Months,
      value: 6,
    },
    {
      label: forecast1Year,
      value: 12,
    },
  ];

  const legend = [
    {
      label: eventCountLegend,
      value: 'timeSeries',
      color: theme.palette.chart[3],
    },
    {
      label: trendLineLegend,
      value: 'trendLine',
      color: theme.palette.primary.main,
      type: 'line',
    },
    {
      label: forecastLegend,
      value: 'forecastTrend',
      color: theme.palette.primary.main,
      type: 'line',
      dashed: true,
    },
    {
      label: confidence75Legend,
      value: '75confidence',
      color: `${theme.palette.primary.main}80`,
    },
    {
      label: confidence90Legend,
      value: '90confidence',
      color: `${theme.palette.primary.dark}80`,
    },
  ];

  const tooltip = [
    {
      value: 'forecastedValue',
      label: forecastTooltip,
      color: theme.palette.primary.main,
    },
    {
      value: 'trendValue',
      label: trendTooltip,
      color: theme.palette.primary.main,
    },
    {
      value: 'estimatedValue',
      label: countTooltip,
      formatValue: (value) => (value >= 1 ? Math.round(value) : value),
      color: theme.palette.chart[3],
    },
    {
      value: 'ciUpper90',
      label: confUb90Tooltip,
      color: `${theme.palette.primary.dark}80`,
    },
    {
      value: 'ciUpper75',
      label: confUb75Tooltip,
      color: `${theme.palette.primary.main}80`,
    },
    {
      value: 'ciLower75',
      label: confLb75Tooltip,
      color: `${theme.palette.primary.main}80`,
    },
    {
      value: 'ciLower90',
      label: confLb90Tooltip,
      color: `${theme.palette.primary.dark}80`,
    },
  ];

  const handleSelect = (event) => {
    setForecastMonths(event.target.value);
  };

  useEffect(() => {
    setForecastMonths(settings.forecastMonths);
  }, [settings]);

  if ((error || (data.length === 0 && !isFetching)) && !disableFeature)
    return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        downloadImage={downloadImage}
        downloadCSV={downloadCSV}
        copyCSV={copyCSV}
        copyImage={copyImage}
        title={title}
        tooltip="forecastTrend"
        isGTM={isGTM}
        chart={
          <>
            <FeatureAccessWrapper
              feature="forecast"
              action="modal"
              modal={{
                content: FeatureAccessForecastModal,
                sx: styles.forecastTeaser,
                header: {hide: true},
              }}>
              <BaseThresholdChart
                data={data}
                isLoading={isFetching}
                tooltip={tooltip}
              />
            </FeatureAccessWrapper>
            <BaseLegend
              labels={legend}
              isLoading={isFetching}
              sx={styles.legend}
            />
          </>
        }
        controls={
          <Box sx={styles.selectContainer}>
            <BaseSelect
              variant="filled"
              size="small"
              value={forecastMonths}
              onChange={handleSelect}
              options={periodOptions}
              sx={styles.select}
            />
          </Box>
        }
        settings={{id, forecastMonths}}
      />
    </Box>
  );
}

export default ForecastTrendSection;

ForecastTrendSection.propTypes = {
  sx: PropTypes.object,
  id: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  isGTM: PropTypes.bool,
  settings: PropTypes.object,
};
