import {useSelector} from 'react-redux';

import {getSavedLocations} from '@/selectors';
import {CHANGE_CHART_METRICS} from '@/components/saved-locations/constants';
import {API_SAVED_LOCATIONS_SUMMARY} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';

export const useSavedLocationsSummary = ({
  enabled = false,
  aggregation,
  period,
}) => {
  const {useGetQuery} = useApi();
  const {
    analysisFilters: {
      addressTypes,
      tags,
      visibility,
      degreesOfChange,
      threatScores,
    },
  } = useSelector(getSavedLocations);

  const {data, isFetching, isError} = useGetQuery({
    path: API_SAVED_LOCATIONS_SUMMARY,
    params: {
      threatScoreQuintiles:
        threatScores?.length > 0 ? JSON.stringify(threatScores) : undefined,
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : undefined,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled,
    },
  });

  return {
    data: data || {},
    isFetching,
    isError,
  };
};
