export const styles = {
  root: (theme) => ({
    color: theme.palette.text.primary,
    fontSize: '10px',
    paddingBlock: 1,
    backgroundColor: theme.palette.background.light,
    borderRadius: '8px',
    overflow: 'hidden',
  }),
  innerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 1,
    justifyContent: 'center',
  },
  legendItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    margin: '0px',
    paddingRight: '0px',
    marginInline: '8px',
    height: '16px',
  },
  expandItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    justifyContent: 'center',
    margin: '0px',
    paddingRight: '0px',
    marginInline: '8px',
  },
  legendItemBox: {
    width: '16px',
    height: '16px',
  },
  legendLabel: {
    align: 'left',
    padding: '0 0 0 8px',
    overflowWrap: 'break-word',
  },
  container: (expanded) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    height: 'inherit',
    justifyContent: expanded ? 'start' : 'center',
  }),
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 1,
  },
  headerTitle: {
    fontWeight: 'bold',
  },
};
