import {useSelector} from 'react-redux';

import {getSavedLocations, getUserSettings} from '@/selectors';
import {
  CHANGE,
  CHANGE_CHART_METRICS,
  THREAT,
} from '@/components/saved-locations/constants';
import {
  API_CHANGE_DISTRIBUTION,
  API_THREAT_SCORE_DISTRIBUTION,
} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';

const PATHS = {
  [CHANGE]: API_CHANGE_DISTRIBUTION,
  [THREAT]: API_THREAT_SCORE_DISTRIBUTION,
};

export const useSavedLocationsDistribution = ({metric, enabled = true}) => {
  const {useGetQuery} = useApi();
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);
  const {
    analysisFilters: {
      addressTypes,
      tags,
      visibility,
      degreesOfChange,
      threatScores,
    },
  } = useSelector(getSavedLocations);

  const {data, isFetching, isError} = useGetQuery({
    path: PATHS[metric],
    params: {
      threatScoreQuintiles:
        threatScores?.length > 0 ? JSON.stringify(threatScores) : undefined,
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : undefined,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled,
    },
  });

  return {
    data: data?.chartData || [],
    isFetching,
    isError,
  };
};
