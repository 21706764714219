import React, {useState} from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import {useTheme} from '@mui/material';

import {DownloadSimple} from '@phosphor-icons/react';

function ChartOptionsDropdown({
  options = [],
  icon: Icon = DownloadSimple,
  size = 20,
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        data-html2canvas-ignore="true"
        onClick={handleClick}
        disabled={!(options.length > 0)}
        size="small"
        color="primary">
        <Icon size={size} color={theme.palette.secondary.light} />
      </IconButton>
      <Menu
        data-html2canvas-ignore="true"
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {options.map((op, index) => (
          <MenuItem key={index} onClick={op.action}>
            {op.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ChartOptionsDropdown.propTypes = {
  icon: PropTypes.elementType,
  size: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
};

export default ChartOptionsDropdown;
