import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {SAVED_LOCATIONS_LAYER} from '@/components/map/layers/constants';
import {
  getClickedLocation,
  getDefaultThemeStatus,
  getIsFlying,
  getMap,
  getViewTypeWorld,
} from '@/selectors';

function LayerSavedLocations() {
  const theme = useTheme();
  const isWorldView = useSelector(getViewTypeWorld);
  const {id} = useSelector(getClickedLocation);
  const isFlying = useSelector(getIsFlying);
  const isDefaultTheme = useSelector(getDefaultThemeStatus);
  const {markers} = useSelector(getMap);

  const {dark: darkText, light: lightText} = theme.palette.map.text;

  return (
    <Source
      id="saved-locations-source"
      type="vector"
      url={MARTIN_SERVICE_URL + SAVED_LOCATIONS_LAYER}>
      <Layer
        id="saved-locations-markers-layer"
        source="saved-locations-source"
        source-layer="public.saved_locations_fn"
        type="symbol"
        filter={id ? ['!=', ['get', 'id'], id] : ['all']}
        layout={{
          'icon-image': [
            'case',
            ['==', ['get', 't'], 'office'],
            'marker_office',
            ['==', ['get', 't'], 'hotel'],
            'marker_hotel',
            ['==', ['get', 't'], 'event'],
            'marker_event',
            ['==', ['get', 't'], 'restaurant'],
            'marker_restaurant',
            ['==', ['get', 't'], 'travel'],
            'marker_travel',
            ['==', ['get', 't'], 'supply_chain'],
            'marker_supply_chain',
            ['==', ['get', 't'], 'facility'],
            'marker_facility',
            ['==', ['get', 't'], 'parking'],
            'marker_parking',
            ['==', ['get', 't'], 'service_area'],
            'marker_service_area',
            ['==', ['get', 't'], 'warehouse'],
            'marker_warehouse',
            ['==', ['get', 't'], 'retail'],
            'marker_retail',
            ['==', ['get', 't'], 'point_of_interest'],
            'marker_point_of_interest',
            'marker_other',
          ],
          'icon-size': 0.7,
          'icon-allow-overlap': !isWorldView,
          'icon-ignore-placement': !isWorldView,
          visibility: !isFlying && markers.savedLocations ? 'visible' : 'none',
        }}
      />
      <Layer
        id="saved-locations-text-layer"
        source="saved-locations-source"
        source-layer="public.saved_locations_fn"
        type="symbol"
        filter={id ? ['!=', ['get', 'id'], id] : ['all']}
        layout={{
          'text-field': ['get', 'n'],
          'text-size': 10,
          'text-max-width': 20,
          'text-radial-offset': 2,
          'text-justify': 'center',
          'text-anchor': 'bottom',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-optional': true,
          visibility:
            !isFlying && !isWorldView && markers.savedLocations
              ? 'visible'
              : 'none',
        }}
        paint={{
          'text-opacity': isWorldView ? 0 : 1,
          'text-color': isDefaultTheme ? lightText : darkText,
          'text-halo-color': isDefaultTheme ? darkText : lightText,
          'text-halo-width': 1,
        }}
      />
    </Source>
  );
}

export default LayerSavedLocations;
