import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

import {setTags} from '@/store/modules/saved-locations/actions';

import {API_SAVED_LOCATIONS_TAGS} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

export const useTags = ({enabled}) => {
  const dispatch = useDispatch();
  const {useGetQuery} = useApi();
  const {getFeatureAccess} = useFeatureAccess();

  const {data, isFetching} = useGetQuery({
    path: API_SAVED_LOCATIONS_TAGS,
    config: {
      enabled: enabled && !getFeatureAccess('saved-locations-tags', 'hide'),
    },
  });

  useEffect(() => {
    if (data?.length > 0 && !isFetching) {
      dispatch(setTags(data));
    }
  }, [dispatch, data, isFetching]);
};
