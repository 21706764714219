import {useSelector} from 'react-redux';
import {useMemo} from 'react';

import {
  API_CHANGE_DISTRIBUTION_BY_TAG,
  API_CHANGE_DISTRIBUTION_BY_TYPE,
  API_THREAT_SCORE_DISTRIBUTION_BY_TYPE,
  API_THREAT_SCORE_DISTRIBUTION_BY_TAG,
} from '@hooks/api/constants';
import {
  CHANGE,
  CHANGE_CHART_METRICS,
  DEGREE_OF_CHANGE,
  TAG,
  THREAT,
  THREAT_SCORES,
  TYPE,
} from '@/components/saved-locations/constants';

import {useApi} from '@/hooks/api/useApi';
import {getSavedLocations} from '@/selectors';
import {useChartTranslation} from '@/hooks/charts/useChartTranslation';

export const useSavedLocationDistributionByKey = ({
  mode,
  view,
  aggregation,
  period,
}) => {
  const {useGetQuery} = useApi();
  const {
    analysisFilters: {
      addressTypes,
      tags,
      visibility,
      degreesOfChange,
      threatScores,
    },
  } = useSelector(getSavedLocations);

  const {translateLocationTag, translateLocationType, translateDegreeOfChange} =
    useChartTranslation();

  const path = useMemo(() => {
    if (view === CHANGE) {
      if (mode === TYPE) return API_CHANGE_DISTRIBUTION_BY_TYPE;
      if (mode === TAG) return API_CHANGE_DISTRIBUTION_BY_TAG;
    }
    if (view === THREAT) {
      if (mode === TYPE) return API_THREAT_SCORE_DISTRIBUTION_BY_TYPE;
      if (mode === TAG) return API_THREAT_SCORE_DISTRIBUTION_BY_TAG;
    }
  }, [mode, view]);

  const {data, isFetching, isError} = useGetQuery({
    path,
    params: {
      threatScoreQuintiles:
        threatScores?.length > 0 ? JSON.stringify(threatScores) : undefined,
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : undefined,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled: view === CHANGE || view === THREAT,
    },
  });

  const formattedData = useMemo(() => {
    const chartData = data?.chartData;
    if (!chartData) return {};
    return chartData.reduce((acc, item) => {
      const yValue = view === CHANGE ? 'value' : 'count';
      const xValue = mode === TYPE ? 'addressType' : 'tag';
      const stackValue = view === CHANGE ? 'range' : 'threatScoreQuintile';
      const getLabel =
        mode === TYPE ? translateLocationType : translateLocationTag;

      return {
        ...acc,
        [item[stackValue]]: chartData
          .filter((stack) => stack[stackValue] === item[stackValue])
          .map((item) => ({
            y: item[yValue],
            x: String(getLabel(item[xValue])), // Convert to string here
            stack: item[stackValue],
          })),
      };
    }, {});
  }, [data, view]);

  const sortedDomain = useMemo(() => {
    if (!formattedData || Object.keys(formattedData).length === 0) return [];

    const totals = {};
    Object.values(formattedData).forEach((series) => {
      series.forEach((point) => {
        const xKey = String(point.x); // Convert to string for consistent keys
        totals[xKey] = (totals[xKey] || 0) + point.y;
      });
    });

    return Object.entries(totals)
      .sort(([, a], [, b]) => b - a)
      .map(([x]) => String(x)); // Ensure domain values are strings
  }, [formattedData]);

  // Normalize data to ensure all series have the same x values and missing values are filled with 0
  const normalizedData = useMemo(() => {
    if (!formattedData || Object.keys(formattedData).length === 0) return {};

    const allXValues = sortedDomain;

    const normalized = {};
    Object.keys(formattedData).forEach((seriesKey) => {
      normalized[seriesKey] = allXValues.map((x) => {
        const existingPoint = formattedData[seriesKey].find(
          (p) => String(p.x) === String(x), // String comparison
        );
        return {
          x: String(x), // Ensure x is a string
          y: existingPoint ? existingPoint.y : 0,
          stack: seriesKey,
        };
      });
    });

    return normalized;
  }, [formattedData, sortedDomain]);

  const translatedData = useMemo(() => {
    if (!normalizedData || Object.keys(normalizedData).length === 0) return {};

    const getStack =
      view === CHANGE ? translateDegreeOfChange : translateDegreeOfChange;
    const keys = view === CHANGE ? DEGREE_OF_CHANGE : THREAT_SCORES;

    return keys.reduce((acc, key) => {
      if (normalizedData[key] !== undefined) {
        const transformedKey = getStack(key);
        acc[transformedKey] = normalizedData[key];
      }
      return acc;
    }, {});
  }, [normalizedData, view, translateDegreeOfChange]);

  return {
    data: translatedData || {},
    domain: sortedDomain,
    isFetching,
    isError,
  };
};
