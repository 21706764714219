export const DEGREE_OF_CHANGE = [
  'large_increase',
  'increase',
  'no_change',
  'decrease',
  'large_decrease',
  // 'indeterminate',
];

export const THREAT_SCORES = ['very_high', 'high', 'medium', 'low', 'very_low'];

export const SAVED_LOCATIONS_VISIBILITY = ['shared', 'private'];

export const SAVED_LOCATION_TYPES = {
  office: 'Office',
  hotel: 'Hotel',
  event: 'Event',
  restaurant: 'Restaurant',
  travel: 'Travel',
  warehouse: 'Warehouse',
  parking: 'Parking',
  service_area: 'Service Area',
  point_of_interest: 'Point of Interest',
  facility: 'Facility',
  other: 'Other',
  retail: 'Retail',
};

export const SAVED_LOCATION_SEARCH = {
  search: undefined,
  page: 1,
  take: 12,
  sortBy: `name.ASC`,
  addressTypes: [],
  tags: null,
  minCustomerRating: 1,
  maxCustomerRating: 5,
  includeUnrated: true,
  visibility: undefined,
};

export const CHANGE_SORT_METRICS = [
  {
    value: 'one_month_period_over_year',
    feature: 'saved-locations-change',
    action: 'label',
    label: 'Monthly Change YoY',
  },
  {
    value: 'year_over_year',
    feature: 'saved-locations-change',
    action: 'label',
    label: 'Year-over-year Change',
  },
];

export const CHANGE_MAP_METRICS = {
  yearOverYear: {
    monthly: 'c_m_o_y',
    quarterly: 'c_3m_o_y',
    yearly: 'c_y_o_y',
  },
  periodOverPeriod: {
    monthly: 'c_m_o_m',
    quarterly: 'c_3m_o_m',
    yearly: 'c_y_o_y',
  },
};

export const CHANGE_CHART_METRICS = {
  yearOverYear: {
    monthly: 'one_month_period_over_year',
    quarterly: 'three_month_period_over_year',
    yearly: 'year_over_year',
  },
  periodOverPeriod: {
    monthly: 'one_month_period_over_period',
    quarterly: 'three_month_period_over_period',
    yearly: 'year_over_year',
  },
};

export const SAVED_SORT_OPTIONS = [
  {
    value: 'change',
    feature: 'saved-locations-change',
    action: 'hide',
    tooltip: 'changeTooltip',
  },
  {value: 'name'},
  {value: 'customerLocationId'},
  {value: 'customerRating'},
];

export const SAVED_LOCATIONS_DASHBOARD = [
  'saved-locations-map',
  'ranked-locations-table',
  'saved-locations-key-takeaways-table',
  'saved-locations-count-by-metric-chart',
  'saved-locations-type-distribution-chart',
  'saved-locations-count-type-breakdown-chart',
  'saved-locations-tag-distribution-chart',
  'saved-locations-count-tag-breakdown-chart',
];

export const TYPE = 'type';
export const TAG = 'tag';
export const OVERVIEW = 'locationOverview';
export const THREAT = 'threatProfile';
export const CHANGE = 'degreeOfChange';

export const DISTRIBUTION_SORT_OPTIONS = [
  'change',
  'name',
  'score',
  'lastVisited',
];

export const LEGEND_VISIBLE_ITEMS = 5;
