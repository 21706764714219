import PropTypes from 'prop-types';
import {useState} from 'react';
import {DownloadSimple} from '@phosphor-icons/react';
import {Box} from '@mui/material';

import BaseDonutChart from '@/components/common/charts/BaseDonutChart';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {styles} from '@/components/saved-locations/charts/SavedLocationCountBreakdown.styles';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {TAG, TYPE} from '@/components/saved-locations/constants';
import SavedLocationsNoTags from '@/components/saved-locations/cards/SavedLocationsNoTags';

import {useLocationsBreakdown} from '@/hooks/charts/useLocationsBreakdown';
import {useTranslation} from '@/hooks/useTranslation';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useChartTitle} from '@/hooks/charts/useChartTitle';

function SavedLocationCountBreakdown({id, settings: {mode}}) {
  const {getI18N} = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');

  const {dynamicTitle} = useChartTitle({
    title: 'Breakdown by Location {mode}',
    variants: [{id: 'mode', [TAG]: 'Tag', [TYPE]: 'Type'}],
    selections: [{id: 'mode', value: mode}],
  });

  const {dynamicTitle: dynamicTooltip} = useChartTitle({
    title: 'Groups locations by location {mode}',
    variants: [{id: 'mode', [TAG]: 'tag', [TYPE]: 'type'}],
    selections: [{id: 'mode', value: mode}],
  });

  const {copyImage, downloadImage} = useChartDownloads({
    id,
    title: dynamicTitle,
  });
  const {data, legend, colorAccessor, isFetching, isError} =
    useLocationsBreakdown({
      mode,
      expanded,
    });

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  if (isError) return null;

  return (
    <BaseCard
      id={id}
      title={dynamicTitle}
      tooltip={dynamicTooltip}
      sx={{content: styles.content}}
      headerActions={
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />
      }>
      <Box sx={styles.innerBox}>
        {data.length === 1 && data[0].id == null ? (
          <SavedLocationsNoTags />
        ) : (
          <>
            {!expanded && (
              <Box>
                <BaseDonutChart
                  data={{[dynamicTitle]: data}}
                  colorAccessor={colorAccessor}
                  height={240}
                  isLoading={isFetching}
                />
              </Box>
            )}
            <BaseLegend
              expandable
              expanded={expanded}
              onExpand={handleExpand}
              sx={expanded ? styles.expandedLegend : styles.legend}
              isLoading={isFetching}
              labels={legend}
            />
          </>
        )}
      </Box>
    </BaseCard>
  );
}

SavedLocationCountBreakdown.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};

export default SavedLocationCountBreakdown;
