import {Theme, SxProps} from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      background: {
        light: string;
      };
    };
  }
}

export const styles = {
  content: {
    height: 360,
  },
  container: {
    height: '100%',
    width: '100%',
  },
  item: ((theme: Theme): SxProps<Theme> => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.light,
    borderRadius: 2,
  })) as SxProps<Theme>,
  value: {
    fontWeight: 800,
    fontSize: 24,
  },
  label: {
    fontWeight: 400,
    fontSize: 14,
  },
};
