export const styles = {
  tooltip: {
    padding: '12px',
  },
  tooltipTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'white',
    paddingBottom: '5px',
  },
  tooltipSubtitle: (theme) => ({
    fontSize: '12px',
    color: theme.palette.text.secondary,
    paddingRight: '2px',
  }),
  tooltipSubtitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBox: (color) => ({
    width: '11px',
    height: '11px',
    backgroundColor: color,
    borderRadius: '4px',
    marginRight: '8px',
  }),
  chartContainer: {
    height: '100%',
    paddingBottom: 2,
  },
  content: {
    height: 360,
  },
};
