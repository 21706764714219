import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {useApi} from '@hooks/api/useApi';
import {validateChartParams} from '@utils/apiUtils';
import {
  getSelectedCategories,
  getSelectedMonths,
  getSelectedTimeslices,
  getAnalyticsRequestEnabled,
  getThreatAnalyticsAreaLat,
  getThreatAnalyticsAreaLon,
  getSelectionMarkerLon,
  getSelectionMarkerLat,
  getNewUserLocationLon,
  getNewUserLocationLat,
  getSelectedSources,
  getLocation,
  getSelectedDistrictIds,
  getSavedLocations,
  getFilters,
  getThreatCategories,
  getClickedLocation,
} from '@/selectors';
import {useMoment} from '@/hooks/useMoment';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useRouter} from '@/hooks/useRouter';
import {API_CHART_QUINTILES, API_CHART_SEVERITY} from '@/hooks/api/constants';
import {useClickedMap} from '@/hooks/charts/useClickedMap';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

export const useChart = ({
  id,
  title,
  path,
  granularity,
  customParams = {},
  exactDates = true,
  fromDate,
  toDate,
  disabled = false,
  latitude,
  longitude,
  radius,
  onlyDownloads = false,
}) => {
  // Hooks
  const {isReportRoute, isSavedRoute} = useRouter();
  const {useGetQuery} = useApi();
  const {computedRadius} = useClickedMap();
  const {
    getAnalyticsStartDate,
    getAnalyticsEndDate,
    getISOfromDate,
    getISOtoDate,
  } = useMoment();

  // Selectors
  const selectedCategories = useSelector(getSelectedCategories);
  const sources = useSelector(getSelectedSources);
  const daytimes = useSelector(getSelectedTimeslices);
  const months = useSelector(getSelectedMonths);
  const selectedDistrictsIds = useSelector(getSelectedDistrictIds);
  const isRequestEnabled = useSelector(getAnalyticsRequestEnabled);
  const locationLat = useSelector(getThreatAnalyticsAreaLat);
  const locationLon = useSelector(getThreatAnalyticsAreaLon);
  const {id: locationId} = useSelector(getLocation);
  const selectedLat = useSelector(getSelectionMarkerLat);
  const selectedLon = useSelector(getSelectionMarkerLon);
  const newLocationLat = useSelector(getNewUserLocationLat);
  const newLocationLon = useSelector(getNewUserLocationLon);
  const {filters: dashParams} = useSelector(getSavedLocations);
  const {isGTM} = useSelector(getFilters);
  const threatCategories = useSelector(getThreatCategories);
  const {id: savedLocationId, hoodId: areaHoodId} =
    useSelector(getClickedLocation);

  // State
  const [componentMounted, setComponentMounted] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  // Hood Chart Params
  const hoodParams = useMemo(() => {
    const singleHoodPaths = [API_CHART_QUINTILES, API_CHART_SEVERITY];
    if (singleHoodPaths.includes(path)) {
      return {hoodId: selectedDistrictsIds[0] ?? areaHoodId};
    }
    if (selectedDistrictsIds.length > 0) {
      return {hoodIds: JSON.stringify(selectedDistrictsIds)};
    }
    if (areaHoodId) {
      return {hoodIds: JSON.stringify([areaHoodId])};
    }
    return {};
  }, [
    selectedDistrictsIds,
    areaHoodId,
    selectedDistrictsIds,
    areaHoodId,
    path,
  ]);

  // Radius Chart Params
  const radiusParams = useMemo(
    () => ({
      latitude: latitude || selectedLat || newLocationLat || locationLat,
      longitude: longitude || selectedLon || newLocationLon || locationLon,
      radius: isSavedRoute ? radius : computedRadius,
      units: DISTANCE_UNIT.meters,
      savedLocationId,
    }),
    [
      selectedLat,
      newLocationLat,
      locationLat,
      selectedLon,
      newLocationLon,
      locationLon,
      computedRadius,
      latitude,
      longitude,
      radius,
      isSavedRoute,
    ],
  );

  // Location Chart Params
  const locationsParams = useMemo(
    () => ({
      locationId,
      ...(granularity === 'district' ? hoodParams : {}),
    }),
    [locationId, granularity, hoodParams],
  );

  // Category Params
  const categories = useMemo(() => {
    const {type: threatType} = customParams;
    if (!threatType) return selectedCategories;
    return threatCategories
      .filter(
        ({id, type}) => type === threatType && selectedCategories.includes(id),
      )
      .map(({id}) => id);
  }, [threatCategories, selectedCategories, customParams]);

  // Chart Filter Params
  const mapParams = useMemo(
    () => ({
      fromDate:
        fromDate ?? (!exactDates ? getAnalyticsStartDate() : getISOfromDate()),
      toDate: toDate ?? (!exactDates ? getAnalyticsEndDate() : getISOtoDate()),
      sources: JSON.stringify(sources),
      categories: JSON.stringify(categories),
      months: JSON.stringify(months),
      daytimes: JSON.stringify(daytimes),
    }),
    [
      sources,
      categories,
      months,
      daytimes,
      fromDate,
      toDate,
      exactDates,
      getISOfromDate,
      getISOtoDate,
      getAnalyticsStartDate,
      getAnalyticsEndDate,
    ],
  );

  // Chart Params based on granularity
  const params = useMemo(
    () => ({
      ...(granularity === 'radius' ? radiusParams : locationsParams),
      ...(isSavedRoute ? {fromDate, toDate} : mapParams),
      ...customParams,
      isGTM: customParams?.timeAggregation === 'daily' ? false : isGTM,
    }),
    [
      granularity,
      customParams,
      radiusParams,
      locationsParams,
      mapParams,
      isSavedRoute,
      dashParams,
      isGTM,
    ],
  );

  // Get chart data
  const {
    data: chartData,
    isFetching: isFetchingChart,
    error: chartError,
    isError: isChartError,
  } = useGetQuery({
    path,
    params,
    config: {
      enabled:
        validateChartParams(params, [granularity, 'dates']) &&
        (isRequestEnabled || isReportRoute || isSavedRoute) &&
        componentMounted &&
        !!path &&
        !disabled &&
        !onlyDownloads,
    },
  });

  useEffect(() => {
    if (chartError && isChartError) {
      setError(chartError);
      setData({});
    }
  }, [chartError, isChartError]);

  useEffect(() => {
    setIsFetching(isFetchingChart);
    if (chartData && !isFetchingChart) {
      setError(null);
      setData(chartData);
    }
  }, [chartData, isFetchingChart]);

  useEffect(() => {
    setComponentMounted(true);
    return () => {
      setComponentMounted(false);
      setData({});
      setError(null);
      setIsFetching(false);
    };
  }, []);

  // Chart download hook
  const {downloadCSV, copyCSV, copyImage, downloadImage} = useChartDownloads({
    id,
    title,
    path,
    granularity,
    params,
  });

  if (disabled) {
    return {
      data: {},
      error: true,
      isFetching: false,
      downloadCSV: () => {},
      copyCSV: () => {},
      downloadImage: () => {},
      copyImage: () => {},
    };
  }

  return {
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    copyImage,
    downloadImage,
  };
};
