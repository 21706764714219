import {
  SET_SAVED_LOCATIONS,
  SET_SAVED_LOCATIONS_CONTROLS,
  SET_SAVED_LOCATIONS_FILTERS,
  SET_SAVED_LOCATIONS_TAGS,
  SET_SAVED_LOCATIONS_ANALYSIS_FILTERS,
  SET_SAVED_LOCATIONS_TAB,
} from '@/store/modules/saved-locations/actionTypes';

export const setSavedLocationTab = (payload) => ({
  type: SET_SAVED_LOCATIONS_TAB,
  payload,
});

export const setSavedLocationsAnalysisFilters = (payload) => ({
  type: SET_SAVED_LOCATIONS_ANALYSIS_FILTERS,
  payload,
});

export const setSavedLocations = (payload) => ({
  type: SET_SAVED_LOCATIONS,
  payload,
});

export const setSavedLocationControls = (payload) => ({
  type: SET_SAVED_LOCATIONS_CONTROLS,
  payload,
});

export const setSavedLocationFilters = (payload) => ({
  type: SET_SAVED_LOCATIONS_FILTERS,
  payload,
});

export const setTags = (payload) => ({
  type: SET_SAVED_LOCATIONS_TAGS,
  payload,
});
