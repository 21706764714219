import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';
import {CaretLeft, CaretRight} from '@phosphor-icons/react';

import {useTranslation} from '@/hooks/useTranslation';

import {
  CHANGE,
  LEGEND_VISIBLE_ITEMS,
  OVERVIEW,
  THREAT,
  SAVED_LOCATION_TYPES,
} from '@/components/saved-locations/constants';
import {styles} from '@/components/saved-locations/map/SavedLocationsMap.styles';
import ActionColorScale from '@/components/map/actions/ActionColorScale';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {BaseIconButton} from '@/components/common/buttons';
import {useChartTranslation} from '@/hooks/charts/useChartTranslation';

function SavedLocationsLegend({view}) {
  const theme = useTheme();

  const {getI18N} = useTranslation();
  const {translateLocationType} = useChartTranslation();

  const [legend, setLegend] = useState([]);
  const [legendPage, setLegendPage] = useState(0);

  const {changeColorScaleLabel, changeSizeScaleLabel} =
    getI18N('savedLocations');

  const legendItems = Object.keys(SAVED_LOCATION_TYPES).map((type) => ({
    label: translateLocationType(type),
    colors: theme.palette.locationTypes[type],
  }));

  const handleLegendClick = (direction, page) => {
    const visiblePages = Math.ceil(legendItems.length / LEGEND_VISIBLE_ITEMS);

    let nextPage = page;
    if (direction === 'left') {
      nextPage = (page - 1 + visiblePages) % visiblePages;
    } else if (direction === 'right') {
      nextPage = (page + 1) % visiblePages;
    }

    const first = nextPage * LEGEND_VISIBLE_ITEMS;
    const last = first + LEGEND_VISIBLE_ITEMS;

    setLegend(legendItems.slice(first, last));
    setLegendPage(nextPage);
  };

  useEffect(() => {
    setLegend(legendItems.slice(0, LEGEND_VISIBLE_ITEMS));
  }, []);

  return (
    <Box sx={styles.legend}>
      {view === OVERVIEW && (
        <Box sx={styles.legendContainer}>
          <BaseIconButton
            size={16}
            icon={CaretLeft}
            onClick={() => handleLegendClick('left', legendPage)}
          />
          <BaseLegend sx={styles.typesLegend} labels={legend} />
          <BaseIconButton
            size={16}
            icon={CaretRight}
            onClick={() => handleLegendClick('right', legendPage)}
          />
        </Box>
      )}
      {view === THREAT && (
        <Box sx={styles.changeSizeScale}>
          <Box sx={styles.scoreIcon} />
          <Typography variant="caption">BaseScore</Typography>
        </Box>
      )}
      {view === CHANGE && (
        <>
          <ActionColorScale
            width="40px"
            height="18px"
            title={changeColorScaleLabel}
            sx={styles.changeColorScale}
          />
          <Box sx={styles.changeSizeScale}>
            <Box sx={styles.changeSizeIcon} />
            <Typography variant="caption">{changeSizeScaleLabel}</Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default SavedLocationsLegend;

SavedLocationsLegend.propTypes = {
  view: PropTypes.string.isRequired,
};
