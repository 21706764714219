interface LocationFilterOption {
  id: string;
  name: string;
  parentId: string;
}

export const getLocationFilterOptions = (
  locationFilterOptions: Record<string, string>,
): LocationFilterOption[] =>
  // Compute location filter options based
  Object.keys(locationFilterOptions).map((key) => ({
    id: key,
    name: locationFilterOptions[key],
    parentId: 'main',
  }));

export const getLocationDateRange = (
  startDate: string,
  endDate: string,
  separator = '-',
) => {
  if (!startDate || !endDate) return '';
  // Returns date range in format: "MM/YY - MM/YY"
  const startArr = startDate.split('-');
  const endArr = endDate.split('-');
  return `${startArr[1]}/${startArr[0].substring(2, 4)} ${separator} ${
    endArr[1]
  }/${endArr[0].substring(2, 4)}`;
};
