import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';

import {
  getFiltersLoading,
  getIsMapReady,
  getViewportBoundingBox,
} from '@/selectors';
import {
  setLayers,
  setActiveCity,
  setViewportBoundingBox,
  updateFilters,
} from '@/store/modules/filters/actions';
import {
  setClickedLocation,
  setNewUserLocation,
  setThreatAnalyticsArea,
  setUnits,
} from '@/store/modules/user/actions';
import {
  setMapLoading,
  setMapMountedStatus,
  setViewTypeView,
} from '@/store/modules/map/actions';

import {convertDistance} from '@/utils/utils';

import {useMap} from '@/hooks/useMap';
import {useMapTransformRequest} from '@/hooks/map/useMapTransformRequest';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useReportURL} from '@/hooks/report/useReportURL';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useMapResize} from '@/hooks/map/useMapResize';

import {LAYER_TYPE, MAP_STYLES} from '@/components/map/constants';
import LayerDistricts from '@/components/map/layers/LayerDistricts';
import LayerThreatPoints from '@/components/map/layers/LayerThreatPoints';
import LayerThreatRadius from '@/components/map/layers/LayerThreatRadius';
import LayerCustomMarker from '@/components/map/layers/LayerCustomMarker';
import LayerCountry from '@/components/map/layers/LayerCountry';
import BaseMap from '@/components/common/map/BaseMap';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

const styles = {
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function ReportMap() {
  const dispatch = useDispatch();
  const {setMap} = useMap();
  const {showSnackbar} = useSnackbar();
  const {handleTransformRequest} = useMapTransformRequest();
  const {reportURL} = useReportURL();
  const {getBoundingBox, resizeMap} = useMapboxGL();
  const {handleResize} = useMapResize();

  const isLoadingFilters = useSelector(getFiltersLoading);
  const viewportBoundingBox = useSelector(getViewportBoundingBox);
  const isMapReady = useSelector(getIsMapReady);

  const [viewport, setViewport] = useState({latitude: 0, longitude: 0});
  const [mapSize, setMapSize] = useState({width: 550, height: 300});
  const [init, setInit] = useState(true);
  const [setUpEnabled, setSetUpEnabled] = useState(false);
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [viewportEnabled, setViewportEnabled] = useState(false);
  const [resizeEnabled, setResizeEnabled] = useState(false);
  const [boundingBoxEnabled, setBoundingBoxEnabled] = useState(false);

  const handleMapOnLoad = (event) => {
    setMap(event.target);
    dispatch(setMapLoading(false));
  };

  const handleMapError = ({error}) => {
    showSnackbar({message: error.message});
  };

  useEffect(() => {
    dispatch(setMapMountedStatus(true));
    return () => {
      dispatch(setMapMountedStatus(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (reportURL && init) {
      if (
        reportURL?.height &&
        reportURL?.width &&
        mapSize.height === 300 &&
        mapSize.width === 550
      ) {
        setMapSize({
          height: reportURL.height,
          width: reportURL.width,
        });
        setResizeEnabled(true);
      } else {
        setViewportEnabled(true);
      }
      setInit(false);
    }
  }, [reportURL, mapSize, init]);

  useEffect(() => {
    if (
      isMapReady &&
      mapSize.height !== 300 &&
      mapSize.width !== 550 &&
      resizeEnabled
    ) {
      resizeMap();
      setResizeEnabled(false);
      setViewportEnabled(true);
    }
  }, [resizeMap, isMapReady, mapSize, resizeEnabled]);

  useEffect(() => {
    if (
      reportURL?.latitude &&
      reportURL?.longitude &&
      viewport.latitude === 0 &&
      viewport.longitude === 0 &&
      viewportEnabled
    ) {
      setViewport({
        latitude: reportURL.latitude,
        longitude: reportURL.longitude,
      });
      setSetUpEnabled(true);
      setViewportEnabled(false);
    }
  }, [reportURL, viewport, viewportEnabled]);

  useEffect(() => {
    if (reportURL && setUpEnabled) {
      let layer;
      let shape;
      const {
        id,
        granularity,
        locationId,
        hoodId,
        latitude,
        longitude,
        radius: urlRadius,
        units,
      } = reportURL;

      if (id === 'heatmap') {
        layer = 'heatmap';
      } else if (id === 'points') {
        layer = 'events';
      } else {
        layer = 'districts';
      }

      if (granularity === 'district') {
        shape = 'hood-polygon';
      } else if (granularity === 'radius') {
        shape = 'circle';
      } else {
        shape = 'city-polygon';
      }

      if (granularity === 'radius' && id !== 'district') {
        const radius = convertDistance(urlRadius, DISTANCE_UNIT.meters, units);
        dispatch(setNewUserLocation({lat: latitude, lon: longitude}));
        dispatch(setThreatAnalyticsArea({latitude, longitude, radius}));
        dispatch(setUnits(units));
        setBoundingBoxEnabled(true);
      } else {
        dispatch(setActiveCity(locationId));
        if (granularity === 'district') {
          dispatch(setClickedLocation({hoodId}));
        } else if (granularity === 'radius') {
          const radius = convertDistance(
            urlRadius,
            DISTANCE_UNIT.meters,
            units,
          );
          dispatch(setNewUserLocation({lat: latitude, lon: longitude}));
          dispatch(setThreatAnalyticsArea({latitude, longitude, radius}));
          dispatch(setUnits(units));
        }
        setFiltersEnabled(true);
      }

      dispatch(setLayers(layer));
      dispatch(setViewTypeView(LAYER_TYPE.CITY));
      dispatch(setThreatAnalyticsArea({display: true, shape}));
      setSetUpEnabled(false);
    }
  }, [dispatch, reportURL, setUpEnabled]);

  useEffect(() => {
    if (isMapReady && !isLoadingFilters && reportURL && filtersEnabled) {
      dispatch(
        updateFilters({
          sources: reportURL.sources,
          categories: reportURL.categories,
          months: reportURL.months,
          daytimes: reportURL.daytimes,
          fromDate: reportURL.fromDate,
          toDate: reportURL.toDate,
        }),
      );
      setFiltersEnabled(false);
      if (reportURL.granularity === 'radius' && reportURL.id !== 'district') {
        setBoundingBoxEnabled(true);
      }
    }
  }, [
    dispatch,
    isMapReady,
    isLoadingFilters,
    reportURL,
    viewportBoundingBox,
    filtersEnabled,
  ]);

  useEffect(() => {
    if (
      isMapReady &&
      viewport.latitude !== 0 &&
      viewport.longitude !== 0 &&
      isEmpty(viewportBoundingBox) &&
      boundingBoxEnabled
    ) {
      dispatch(setViewportBoundingBox(getBoundingBox()));
      setBoundingBoxEnabled(false);
      if (reportURL.granularity === 'radius' && reportURL.id !== 'district') {
        setFiltersEnabled(true);
      }
    }
  }, [
    dispatch,
    getBoundingBox,
    isMapReady,
    viewport,
    viewportBoundingBox,
    mapSize,
    viewportEnabled,
    reportURL,
    boundingBoxEnabled,
  ]);

  return (
    <Box
      id="map-container"
      sx={styles.mapContainer}
      style={{height: mapSize.height, width: mapSize.width}}>
      <BaseMap
        mapKey="report"
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={12}
        mapStyle={MAP_STYLES.map}
        onLoad={handleMapOnLoad}
        onError={handleMapError}
        onResize={handleResize}
        transformRequest={handleTransformRequest}>
        <LayerCountry />
        <LayerThreatPoints />
        <LayerDistricts />
        <LayerThreatRadius />
        <LayerCustomMarker />
      </BaseMap>
    </Box>
  );
}

export default ReportMap;
