import {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import {MAP_STYLES} from '@map/constants';

import {getUser, getViewport} from '@/selectors';
import {
  setMapLoading,
  setMapMountedStatus,
  setMapViewport,
} from '@/store/modules/map/actions';

import {useMapListeners} from '@/hooks/map/useMapListeners';
import {useMapZoom} from '@/hooks/map/useMapZoom';
import {useMapClick} from '@/hooks/map/useMapClick';
import {useMapTransformRequest} from '@/hooks/map/useMapTransformRequest';
import {useMapMouse} from '@/hooks/map/useMapMouse';
import {useMapDrag} from '@/hooks/map/useMapDrag';
import {useMapResize} from '@/hooks/map/useMapResize';
import {useMap} from '@/hooks/useMap';

import {styles} from '@/components/map/Map.styles';
import Actions from '@/components/map/actions/Actions';
import Legend from '@/components/map/legends/Legend';
import LayerDistricts from '@/components/map/layers/LayerDistricts';
import LayerTimezone from '@/components/map/layers/LayerTimezone';
import LayerThreatPoints from '@/components/map/layers/LayerThreatPoints';
import LayerCityMarkers from '@/components/map/layers/LayerCityMarkers';
import LayerCountry from '@/components/map/layers/LayerCountry';
import MapTooltip from '@/components/map/popups/MapTooltip';
import MapControls from '@/components/map/navigation/MapControls';
import LayerCustomMarker from '@/components/map/layers/LayerCustomMarker';
import LayerSavedLocations from '@/components/map/layers/LayerSavedLocations';
import UserLocation from '@/components/map/popups/UserLocation';
import LayerThreatRadius from '@/components/map/layers/LayerThreatRadius';
import BaseMap from '@/components/common/map/BaseMap';
import LayerThreatScore from '@/components/map/layers/LayerThreatScore';
import LayerPopulation from '@/components/map/layers/LayerPopulation';
import {setLayers} from '@/store/modules/filters/actions';

function Map() {
  const dispatch = useDispatch();
  const {
    cursor,
    handleMouseDown,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseUp,
    handleMouseMove,
    handleMouseOut,
  } = useMapMouse();
  const {map, setMap} = useMap();
  const {handleZoom, handleZoomEnd} = useMapZoom();
  const {handleClick, handleRightClick} = useMapClick();
  const {handleDrag, handleDragEnd} = useMapDrag();
  const {handleTransformRequest} = useMapTransformRequest();
  const {handleResize} = useMapResize();
  useMapListeners();

  const {mapStyle} = useSelector(getUser);
  const viewport = useSelector(getViewport);

  const viewportRef = useRef(viewport);

  const handleMapOnLoad = useCallback(
    ({target}) => {
      dispatch(setMapLoading(false));
      if (!map) {
        setMap(target);
      }
    },
    [dispatch, map],
  );

  const handleMapOnMove = ({target: map}) => {
    viewportRef.current = {
      latitude: map.getCenter().lat,
      longitude: map.getCenter().lng,
      zoom: map.getZoom(),
    };
  };

  useEffect(() => {
    dispatch(setMapMountedStatus(true));
    return () => {
      dispatch(setMapViewport(viewportRef.current));
      dispatch(setMapMountedStatus(false));
      dispatch(setMapLoading(true));
      setMap(null);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setLayers('countries'));
  }, []);

  return (
    <Box id="map-container" sx={styles.root}>
      <Legend />
      <Actions />
      <UserLocation />
      <BaseMap
        mapKey="main"
        cursor={cursor}
        mapStyle={MAP_STYLES[mapStyle]}
        initialViewState={viewport}
        style={styles.map}
        transformRequest={handleTransformRequest}
        onLoad={handleMapOnLoad}
        onResize={handleResize}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onZoom={handleZoom}
        onZoomEnd={handleZoomEnd}
        onClick={handleClick}
        onContextMenu={handleRightClick}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMove={handleMapOnMove}>
        <LayerCountry />
        <LayerThreatPoints />
        <LayerThreatScore />
        <LayerPopulation />
        <LayerDistricts />
        <LayerTimezone />
        <LayerThreatRadius />
        <LayerCityMarkers />
        <LayerCustomMarker />
        <LayerSavedLocations />
        <MapTooltip />
        <MapControls />
      </BaseMap>
    </Box>
  );
}

export default Map;
