export const styles = {
  root: (theme) => ({
    overflow: 'auto',
    height: '100%',
    paddingBottom: 2,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  grid: {
    display: 'flex',
    flexGrow: 1,
  },
  container: {
    gap: 2,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: {xs: 1},
    paddingRight: {xs: 2},
  },
  tabs: (theme) => ({
    backgroundColor: theme.palette.background.dark,
    width: 'fit-content',
    '& .MuiToggleButtonGroup-grouped': {
      border: 'none',
      borderRadius: 1,
      height: 32,
      width: 150,
      margin: '4px',
    },
  }),
  tabButton: {
    textTransform: 'none',
  },
};
