import PropTypes from 'prop-types';
import {Box, useTheme} from '@mui/material';
import {DownloadSimple} from '@phosphor-icons/react';

import {useTranslation} from '@/hooks/useTranslation';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useSavedLocationDistributionByKey} from '@/hooks/charts/useSavedLocationDistributionByKey';
import {useChartTitle} from '@/hooks/charts/useChartTitle';

import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {CHANGE, TAG, TYPE} from '@/components/saved-locations/constants';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';
import {styles} from '@/components/saved-locations/charts/SavedLocationDistributionByKey.styles';
import SavedLocationsNoTags from '@/components/saved-locations/cards/SavedLocationsNoTags';

function SavedLocationDistributionByKey({
  id,
  settings: {mode, view, aggregation, period},
}) {
  const theme = useTheme();
  const {getI18N} = useTranslation();
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');

  const {dynamicTitle} = useChartTitle({
    title: 'Location Count by {mode} & {view}',
    variants: [
      {id: 'view', threatProfile: 'BaseScore', degreeOfChange: 'Change'},
      {id: 'mode', [TAG]: 'Tag', [TYPE]: 'Type'},
    ],
    selections: [
      {id: 'view', value: view},
      {id: 'mode', value: mode},
    ],
  });

  const {dynamicTitle: dynamicTooltips} = useChartTitle({
    title: 'Groups location {mode} by {view}',
    variants: [
      {
        id: 'view',
        threatProfile: 'BaseScore',
        degreeOfChange: 'degree of change',
      },
      {id: 'mode', [TAG]: 'tags', [TYPE]: 'types'},
    ],
    selections: [
      {id: 'view', value: view},
      {id: 'mode', value: mode},
    ],
  });

  const {copyImage, downloadImage} = useChartDownloads({
    id,
    title: dynamicTitle,
  });

  const {data, domain, isFetching, isError} = useSavedLocationDistributionByKey(
    {
      mode,
      view,
      aggregation,
      period,
    },
  );

  const colorAccessor = ({stack}) =>
    theme.palette[view === CHANGE ? 'change' : 'threat'][stack];

  const tooltipColors = Object.values(
    theme.palette[view === CHANGE ? 'change' : 'threat'],
  );

  if (isError) return null;

  return (
    <BaseCard
      id={id}
      title={dynamicTitle}
      tooltip={dynamicTooltips}
      sx={{content: styles.content}}
      headerActions={
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />
      }>
      <Box sx={styles.container}>
        {domain.length === 1 && domain.includes('null') ? (
          <SavedLocationsNoTags />
        ) : (
          <BaseXYChart
            hideGrid
            numYTicks={4}
            height={320}
            marginBottom={80}
            angledTicks
            isLoading={isFetching}
            customColors={tooltipColors}
            xScale={{type: 'band', paddingInner: 0.3, domain}}>
            <BaseBar
              data={data}
              type="stacked"
              colorAccessor={colorAccessor}
              xAccessor={(d) => String(d?.x ?? 0)}
            />
          </BaseXYChart>
        )}
      </Box>
    </BaseCard>
  );
}

SavedLocationDistributionByKey.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};

export default SavedLocationDistributionByKey;
