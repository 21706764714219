import PropTypes from 'prop-types';
import {useCallback, useEffect, useState} from 'react';

import {Box, Typography} from '@mui/material';

import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import Quintiles from '@/components/analytics/quintile/Quintiles';
import {styles} from '@/components/analytics/sections/CountryAnalysisSection.styles';

import TextLimit from '@/components/common/typography/TextLimit';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import BaseSlider from '@/components/common/inputs/BaseSlider';

import {useTranslation} from '@/hooks/useTranslation';
import {useCountryAnalysis} from '@/hooks/charts/useCountryAnalysis';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function CountryAnalysisSection({id, sx, settings}) {
  const {getI18N} = useTranslation();
  const {getFeatureAccess} = useFeatureAccess();
  const {
    name,
    code,
    summary,
    score,
    isFetchingScore,
    quintiles,
    isFetchingQuintiles,
  } = useCountryAnalysis();
  const [controls, setControls] = useState([]);

  const {
    threatScore: threatScoreLabel,
    countryScoreBreakdown,
    overallScoreWeights,
    threatFrom,
    score: scoreLabel,
    quintile: quintileLabel,
  } = getI18N('threatAnalytics.countryAnalysis');
  const {countryCategories} = getI18N('filters');
  const hideSettings = getFeatureAccess(id, 'hide');

  const handleControls = useCallback(
    (id, value) =>
      setControls(
        controls.map((category) => {
          if (category.id === id) {
            return {...category, weight: value};
          }
          return category;
        }),
      ),
    [controls],
  );

  const columns = [
    {
      id: 'name',
      label: threatFrom,
      width: '55%',
      textAlign: 'start',
      type: 'text',
    },
    {
      id: 'score',
      label: scoreLabel,
      width: '15%',
      textAlign: 'center',
      type: 'text',
    },
    {
      id: 'severity',
      label: quintileLabel,
      width: '30%',
      textAlign: 'center',
      type: 'quintile',
    },
  ];

  useEffect(() => {
    setControls(settings);
  }, [settings]);

  // If no country summary is available, return null
  if (!code) return null;

  return (
    <Box id={id} sx={sx}>
      <Box sx={styles.row}>
        <Box
          component="img"
          src={`https://flagcdn.com/w80/${code.toLowerCase()}.png`}
          alt={name}
          width="64px"
          height="32px"
        />
        <Typography variant="body2" sx={styles.countryName}>
          {name}
        </Typography>
      </Box>
      <Box sx={styles.row}>
        <TextLimit linesLimit={5} text={summary} sx={styles.description} />
      </Box>
      {isFetchingScore ? (
        <BaseSkeleton sx={styles.scoreSkeleton} />
      ) : (
        <Box sx={styles.threatScoreRow}>
          <Typography sx={styles.threatScore}>{score}/100</Typography>
          <Typography variant="caption"> {threatScoreLabel}</Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="left">
        <AnalyticsWrapper
          title={countryScoreBreakdown}
          tooltip="countryScoreBreakdown"
          sx={{marginBottom: hideSettings ? 1 : 0}}
          chart={
            <Quintiles
              data={quintiles}
              isFetching={isFetchingQuintiles}
              columns={columns}
            />
          }
          controls={
            !hideSettings && (
              <>
                <Typography variant="caption" fontWeight="bold">
                  {overallScoreWeights}
                </Typography>
                {controls.map(({id, weight}) => (
                  <Box key={`country-chart-control-${id}`} sx={styles.controls}>
                    <Typography variant="caption" sx={styles.settingsCategory}>
                      {countryCategories[id]}
                    </Typography>
                    <Typography variant="caption" sx={styles.settingsCount}>
                      {weight}
                    </Typography>
                    <Box sx={styles.sliderBox}>
                      <BaseSlider
                        key={id}
                        min={1}
                        max={5}
                        value={weight}
                        sx={styles.slider}
                        onChange={(_, value) => handleControls(id, value)}
                      />
                    </Box>
                  </Box>
                ))}
              </>
            )
          }
        />
      </Box>
    </Box>
  );
}

CountryAnalysisSection.propTypes = {
  sx: PropTypes.object,
  id: PropTypes.string.isRequired,
  settings: PropTypes.object,
};

export default CountryAnalysisSection;
