import changeScale from '@images/magnitude-scale.svg';
import scoreScale from '@images/score-scale.svg';

export const styles = {
  mapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '400px',
    maxHeight: '400px',
  },
  mapContent: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  map: {
    borderRadius: 'inherit',
  },
  mapSkeleton: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
  },
  changeColorScale: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: 1,
  },
  changeSizeScale: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
  changeSizeIcon: {
    backgroundImage: `url("${changeScale}")`,
    height: '18px',
    width: '40px',
  },
  scoreIcon: {
    backgroundImage: `url("${scoreScale}")`,
    height: '18px',
    width: '40px',
  },
  legend: (theme) => ({
    position: 'absolute',
    zIndex: 1,
    transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    bottom: 26,
    right: 51,
    height: '30px',
    maxWidth: '680px',
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    paddingInline: 1,
    [theme.breakpoints.down('lg')]: {
      height: '60px',
      flexDirection: 'column',
      paddingBlock: 1,
      gap: 1,
    },
  }),
  typesLegend: (theme) => ({
    height: 16,
    [theme.breakpoints.down('lg')]: {
      height: 40,
    },
    padding: 0,
  }),
  legendContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
