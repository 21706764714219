import {Layer, Source} from 'react-map-gl';
import {useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {
  getDefaultThemeStatus,
  getIsFlying,
  getLocation,
  getMap,
  getSearchMarker,
  getSelectionMarker,
  getThreatAnalyticsAreaDisplay,
  getViewTypeCity,
  getViewTypeWorld,
} from '@/selectors';
import {CITY_MARKERS_LAYER} from '@/components/map/layers/constants';

function LayerCityMarkers() {
  const theme = useTheme();
  const isFlying = useSelector(getIsFlying);
  const {id: locationId} = useSelector(getLocation);
  const selectionMarker = useSelector(getSelectionMarker);
  const areaVisible = useSelector(getThreatAnalyticsAreaDisplay);
  const searchMarker = useSelector(getSearchMarker);
  const isCityView = useSelector(getViewTypeCity);
  const isDefaultTheme = useSelector(getDefaultThemeStatus);
  const isWorldView = useSelector(getViewTypeWorld);
  const {markers} = useSelector(getMap);

  const {dark: darkText, light: lightText} = theme.palette.map.text;

  return (
    <Source
      id="city-markers-source"
      type="vector"
      url={MARTIN_SERVICE_URL + CITY_MARKERS_LAYER}>
      <Layer
        id="city-markers-text-layer"
        source="city-markers-source"
        source-layer={CITY_MARKERS_LAYER}
        type="symbol"
        layout={{
          'text-field': ['get', 'n'],
          'text-size': 10,
          'text-max-width': 20,
          'text-radial-offset': 2,
          'text-justify': 'center',
          'text-anchor': 'bottom',
          'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
          'text-optional': true,
          visibility:
            !isFlying && isCityView && markers.cities ? 'visible' : 'none',
        }}
        paint={{
          'text-color': isDefaultTheme ? lightText : darkText,
          'text-halo-color': isDefaultTheme ? darkText : lightText,
          'text-halo-width': 1,
        }}
      />
      <Layer
        id="city-markers-layer"
        source="city-markers-source"
        source-layer={CITY_MARKERS_LAYER}
        type="symbol"
        filter={isWorldView ? ['step', ['zoom'], false, 4, ['all']] : ['all']}
        layout={{
          'icon-image': [
            'case',
            ['!=', ['get', 'id'], locationId || ''],
            'marker_default',
            !isEmpty(selectionMarker) || areaVisible || !isEmpty(searchMarker)
              ? 'marker_default'
              : 'marker_focused',
          ],
          'icon-size': 0.7,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          visibility: !isFlying && markers.cities ? 'visible' : 'none',
        }}
      />
    </Source>
  );
}

export default LayerCityMarkers;
