import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';

import {
  setClickedLocation,
  setThreatAnalyticsArea,
  setUnits,
} from '@/store/modules/user/actions';
import {setActiveCity, updateFilters} from '@/store/modules/filters/actions';
import {setViewTypeView} from '@/store/modules/map/actions';
import {getFiltersLoading} from '@/selectors';

import AnalyticsComponents from '@/components/analytics/AnalyticsComponents';
import AnalyticsGroup from '@/components/analytics/AnalyticsGroup';
import {LAYER_TYPE} from '@/components/map/constants';

import {useReportURL} from '@/hooks/report/useReportURL';
import {convertDistance} from '@/utils/utils';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

const styles = {
  gridContainer: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '100vw',
    height: '100vh',
    paddingInline: 40,
    paddingBlock: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function ReportCharts() {
  const dispatch = useDispatch();
  const {reportURL} = useReportURL();
  const isLoadingFilters = useSelector(getFiltersLoading);
  const [granularityTab, setGranularityTab] = useState('city');
  const [chartId, setChartId] = useState('chart-event-breakdown');
  const [chartSize, setChartSize] = useState({height: 300, width: 550});
  const chartsWithLegend = ['chart-event-trend', 'chart-source-trend'];

  const getLegendHeight = () => {
    if (chartsWithLegend.includes(reportURL.id)) {
      if (reportURL?.width >= 955) {
        return 62;
      }
      if (reportURL?.width >= 500) {
        return 88;
      }
      if (reportURL?.width >= 200) {
        return 192;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (reportURL) {
      let shape;
      const {latitude} = reportURL;
      const {longitude} = reportURL;

      setGranularityTab(reportURL.granularity);
      setChartId(reportURL.id);
      setChartSize((size) => ({
        height: (reportURL.height ?? size.height) - getLegendHeight(),
        width: reportURL.width ?? size.width,
      }));

      if (reportURL.granularity === 'district') {
        shape = 'hood-polygon';
        dispatch(setActiveCity(reportURL.locationId));
        dispatch(setClickedLocation({hoodId: reportURL.hoodId}));
      } else if (reportURL.granularity === 'radius') {
        shape = 'circle';
        const radius = convertDistance(
          reportURL.radius,
          DISTANCE_UNIT.meters,
          reportURL.units,
        );
        dispatch(setThreatAnalyticsArea({latitude, longitude, radius}));
        dispatch(setUnits(reportURL.units));
      } else {
        shape = 'city-polygon';
        dispatch(setActiveCity(reportURL.locationId));
      }

      dispatch(setThreatAnalyticsArea({display: true, shape}));
      dispatch(setViewTypeView(LAYER_TYPE.CITY));
    }
  }, [dispatch, reportURL]);

  useEffect(() => {
    if (!isLoadingFilters && reportURL) {
      dispatch(
        updateFilters({
          sources: reportURL.sources,
          categories: reportURL.categories,
          months: reportURL.months,
          daytimes: reportURL.daytimes,
          fromDate: reportURL.fromDate,
          toDate: reportURL.toDate,
        }),
      );
    }
  }, [dispatch, isLoadingFilters, reportURL]);

  const analyticsComponents = useMemo(
    () =>
      AnalyticsComponents.filter(
        ({id, granularity}) =>
          granularity.includes(granularityTab) && chartId === id,
      ),
    [granularityTab, chartId],
  );

  return (
    <Box sx={styles.gridContainer} id="analytics-grid-container">
      <AnalyticsGroup
        isReport
        sx={chartSize}
        components={analyticsComponents}
        granularity={granularityTab}
      />
    </Box>
  );
}

export default ReportCharts;
