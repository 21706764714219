import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {DownloadSimple} from '@phosphor-icons/react';

import BaseCard from '@/components/common/cards/BaseCard';
import {styles} from '@/components/saved-locations/cards/SavedLocationsKeyTakeaways.styles';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import {OVERVIEW} from '@/components/saved-locations/constants';

import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useTranslation} from '@/hooks/useTranslation';
import {useSavedLocationsSummary} from '@/hooks/api/useSavedLocationsSummary';

function SavedLocationsKeyTakeaways({
  id,
  settings: {view, aggregation, period},
}) {
  const {getI18N} = useTranslation();
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');
  const {keyTakeaways} = getI18N('myLocationsAnalysis');

  const {data, isFetching, isError} = useSavedLocationsSummary({
    enabled: view === OVERVIEW,
    aggregation,
    period,
  });

  const {copyImage, downloadImage} = useChartDownloads({
    id,
    title: keyTakeaways.title,
  });

  if (isError) return null;

  return (
    <BaseCard
      id={id}
      title={keyTakeaways.title}
      sx={{content: styles.content}}
      headerActions={
        <ChartOptionsDropdown
          icon={DownloadSimple}
          options={[
            {
              label: copyImageLabel,
              action: copyImage,
            },
            {
              label: downloadImageLabel,
              action: downloadImage,
            },
          ]}
        />
      }>
      <Grid container sx={styles.container} spacing={2}>
        {isFetching
          ? [0, 1, 2, 3].map((index) => (
              <Grid key={index} size={6}>
                <BaseSkeleton height="100%" />
              </Grid>
            ))
          : Object.keys(data).map((key) => (
              <Grid key={key} size={6}>
                <Box sx={styles.item}>
                  <Typography sx={styles.value}>
                    {data[key] ?? '--'}
                    {key === 'averageChange' ? '%' : ''}
                  </Typography>
                  <Typography sx={styles.label}>{keyTakeaways[key]}</Typography>
                </Box>
              </Grid>
            ))}
      </Grid>
    </BaseCard>
  );
}

export default SavedLocationsKeyTakeaways;

SavedLocationsKeyTakeaways.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};
