import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Link} from '@mui/material';
import {useTranslation} from '@/hooks/useTranslation';
import {useRouter} from '@/hooks/useRouter';
import {styles} from '@/components/saved-locations/cards/SavedLocationsNoTags.styles';

function SavedLocationsNoTags() {
  const {getI18N} = useTranslation();
  const {pushDashRoute} = useRouter();

  const {noTagsLabel1, noTagsLabel2, noTagsLabel3} = getI18N('chartLegend');

  return (
    <Box sx={styles.root}>
      <Typography variant="body2">
        {noTagsLabel1}{' '}
        <Link onClick={pushDashRoute} sx={styles.link}>
          {noTagsLabel2}
        </Link>{' '}
        {noTagsLabel3}
      </Typography>
    </Box>
  );
}

export default SavedLocationsNoTags;
