import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import {useTheme} from '@mui/material';

import {
  API_SAVED_LOCATIONS_BREAKDOWN_BY_TYPE,
  API_SAVED_LOCATIONS_BREAKDOWN_BY_TAG,
} from '@hooks/api/constants';

import {useApi} from '@/hooks/api/useApi';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {getSavedLocations, getUserSettings} from '@/selectors';
import {useChartTranslation} from '@/hooks/charts/useChartTranslation';
import {useTranslation} from '@/hooks/useTranslation';
import {
  CHANGE_CHART_METRICS,
  TYPE,
} from '@/components/saved-locations/constants';

const MAX_ITEMS = 5;

export const useLocationsBreakdown = ({mode, expanded}) => {
  const theme = useTheme();
  const {useGetQuery} = useApi();
  const {translateLocationTag, translateLocationType} = useChartTranslation();
  const {getI18N} = useTranslation();
  const {getChartColors} = useChartTheme();
  const {remaining} = getI18N('savedLocations');
  const {
    analysisFilters: {
      addressTypes,
      tags,
      visibility,
      degreesOfChange,
      threatScores,
    },
  } = useSelector(getSavedLocations);
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);

  const {data, isFetching, isError} = useGetQuery({
    path:
      mode === TYPE
        ? API_SAVED_LOCATIONS_BREAKDOWN_BY_TYPE
        : API_SAVED_LOCATIONS_BREAKDOWN_BY_TAG,
    params: {
      threatScoreQuintiles:
        threatScores?.length > 0 ? JSON.stringify(threatScores) : undefined,
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : undefined,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
    },
    config: {
      enabled: true,
    },
  });

  const translateLabel = (label) => {
    if (mode === TYPE) {
      return translateLocationType(label);
    }

    return translateLocationTag(label);
  };

  const donutData = useMemo(() => {
    if (!data?.chartData) return [];
    const {chartData} = data;

    const sortedNodes = chartData
      .sort((a, b) => b.value - a.value)
      .map(({label, value}, index) => ({
        id: label,
        order: index,
        label: translateLabel(label),
        value,
      }));

    if (sortedNodes.length > MAX_ITEMS && !expanded) {
      const topItems = sortedNodes.slice(0, MAX_ITEMS - 1);
      const remainingItems = sortedNodes.slice(MAX_ITEMS - 1);
      const remainingSum = remainingItems.reduce(
        (acc, data) => acc + Number(data.value),
        0,
      );

      topItems.push({
        order: MAX_ITEMS - 1,
        label: remaining,
        value: remainingSum,
      });

      return topItems;
    }

    return sortedNodes;
  }, [data, expanded]);

  const colorAccessor = ({id, order}) => {
    const color = theme.palette.locationTypes[id];
    if (color) return color;
    const colors = getChartColors(MAX_ITEMS);
    return colors[order];
  };

  const legend = useMemo(() => {
    if (!donutData) return null;

    return donutData
      .sort((a, b) => a.order - b.order)
      .map(({id, value, label, order}) => ({
        label: `${label} (${value})`,
        subtext: value,
        color: colorAccessor({order, id}),
      }));
  }, [donutData]);

  return {
    data: donutData,
    legend,
    colorAccessor,
    isFetching,
    isError,
  };
};
