import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import {CaretCircleUp, CaretCircleDown} from '@phosphor-icons/react';
import {API_THREAT, API_THREAT_RADIUS} from '@hooks/api/constants';
import {useTheme} from '@mui/material';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useTranslation} from '@/hooks/useTranslation';
import {useMoment} from '@/hooks/useMoment';
import {getSelectionMarker, getUserProfile, getLocation} from '@/selectors';
import BasePagination from '@/components/common/pagination/BasePagination';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import {useChart} from '@/hooks/api/useChart';
import {convertDistance} from '@/utils/utils';
import {useStyles} from '@/components/analytics/ThreatEventsTable.styles';
import {useClickedMap} from '@/hooks/charts/useClickedMap';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import SortIconButton from '@/components/common/buttons/SortIconButton';

const DIRECTIONS = {
  asc: 'ASC',
  desc: 'DESC',
};

const COLUMN_WIDTHS = ['35%', '35%', '20%', '10%'];

function Rows(props) {
  const {
    row,
    styles,
    labels,
    getThreatTypesTranslation,
    granularityLabels,
    formatDetailsDate,
    locationName,
  } = props;
  const {track} = useSegmentAnalytics();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleRow = () => {
    setOpen(!open);
    const event = open ? 'Expanded' : 'Contracted';
    track(`${event} Row Unrest Table`, {
      location: locationName,
    });
  };

  return (
    <>
      <Box sx={styles.parentBox} selected={open}>
        <Box sx={{...styles.rowsText, flexBasis: COLUMN_WIDTHS[0]}}>
          <Typography variant="caption">
            {getThreatTypesTranslation(row.categoryId)}
          </Typography>
        </Box>
        <Box sx={{...styles.rowsText, flexBasis: COLUMN_WIDTHS[1]}}>
          <Typography variant="caption">
            {formatDetailsDate(row.date, row.threatType)}
          </Typography>
        </Box>
        <Box sx={{...styles.rowsText, flexBasis: COLUMN_WIDTHS[2]}}>
          <Typography variant="caption">
            {granularityLabels[row.granularity]}
          </Typography>
        </Box>
        <Box sx={{...styles.rowsText, flexBasis: COLUMN_WIDTHS[3]}}>
          {row.threatType !== 'crime' && (
            <IconButton
              data-testid={`protest-areas-expand-row-${row.threatId}`}
              size="small"
              onClick={handleRow}>
              {open ? (
                <CaretCircleUp size={16} color={theme.palette.primary.main} />
              ) : (
                <CaretCircleDown size={16} color={theme.palette.primary.main} />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={styles.childBox} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={styles.detailsRow}>
            <Typography variant="caption">
              <b>{`${labels.actor1}: `}</b>
              {row.actors ? row.actors.join(', ') : labels.none}
            </Typography>
          </Box>
          <Box sx={styles.detailsRow}>
            <Typography variant="caption">
              <b>{`${labels.interaction}: `}</b>
              {row.interaction?.toLowerCase()}
            </Typography>
          </Box>
          <Box sx={styles.detailsRow}>
            <Typography variant="caption">
              <b>{`${labels.description}: `}</b>
              {row.description}
            </Typography>
          </Box>
          <Box sx={styles.detailsRow}>
            <Typography variant="caption">
              <b>{`${labels.fatalities}: `}</b>
              {row.fatalities}
            </Typography>
          </Box>
          <Box sx={styles.detailsRow}>
            <Typography variant="caption">
              <b>{`${labels.source}: `}</b>
              {row.source}
            </Typography>
          </Box>
        </Collapse>
      </Box>
    </>
  );
}

Rows.propTypes = {
  row: PropTypes.shape({
    actors: PropTypes.array,
    date: PropTypes.string,
    fatalities: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    interaction: PropTypes.string,
    description: PropTypes.string,
    source: PropTypes.string,
    threatType: PropTypes.string,
    categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    granularity: PropTypes.string,
    threatId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  styles: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  getThreatTypesTranslation: PropTypes.func.isRequired,
  granularityLabels: PropTypes.object.isRequired,
  formatDetailsDate: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
};

function Headers(props) {
  const {labels, styles, columnToSort, sortDirection, handleSort} = props;
  const {eventDate, subEventType, granularity} = labels;
  const detailsHeaders = [
    {
      id: 'categoryId',
      label: subEventType,
      align: 'left',
      width: COLUMN_WIDTHS[0],
      sortable: true,
    },
    {
      id: 'date',
      label: eventDate,
      align: 'left',
      width: COLUMN_WIDTHS[1],
      sortable: true,
    },
    {
      id: 2,
      label: granularity,
      align: 'left',
      width: COLUMN_WIDTHS[2],
    },
  ];

  return (
    <Box sx={styles.headersRow}>
      {detailsHeaders.map((header) => (
        <Box
          key={header.id}
          padding="normal"
          sx={{...styles.headersText, flexBasis: header.width}}>
          {header.component ? (
            header.component()
          ) : (
            <Typography sx={styles.headerLabelStyle} variant="caption">
              {header.label}
            </Typography>
          )}
          {header.sortable && columnToSort === header.id && (
            <SortIconButton
              onClick={() => handleSort(header.id)}
              sortDirection={sortDirection}
            />
          )}
        </Box>
      ))}
    </Box>
  );
}

Headers.propTypes = {
  columnToSort: PropTypes.any.isRequired,
  sortDirection: PropTypes.any.isRequired,
  handleSort: PropTypes.any.isRequired,
  styles: PropTypes.any.isRequired,
  labels: PropTypes.shape({
    eventDate: PropTypes.string.isRequired,
    subEventType: PropTypes.string.isRequired,
    granularity: PropTypes.string.isRequired,
  }),
};

function ThreatEventsTable({granularity, sx}) {
  const styles = useStyles();
  const {track} = useSegmentAnalytics();
  const {getTranslation, getI18N, getThreatTypesTranslation} = useTranslation();
  const {formatDetailsDate} = useMoment();

  const {name: locationName} = useSelector(getLocation);
  const selectionMarker = useSelector(getSelectionMarker);
  const {units} = useSelector(getUserProfile);
  const {computedRadius} = useClickedMap();

  const [sortDirection, setSortDirection] = useState(DIRECTIONS.desc);
  const [columnToSort, setColumnToSort] = useState('date');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [threats, setThreats] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filterByUnrest, setFilterByUnrest] = useState(false);

  const handleFilterByUnrest = () => {
    setFilterByUnrest(!filterByUnrest);
    track('Filtered By Unrest', {
      filter: !filterByUnrest,
      location: locationName,
    });
  };

  const customParams = useMemo(
    () => ({
      page,
      take: rowsPerPage,
      sortBy: `${columnToSort}.${sortDirection}`,
      type: filterByUnrest ? 'unrest' : undefined,
    }),
    [columnToSort, sortDirection, page, rowsPerPage, filterByUnrest],
  );

  const {data, isFetching} = useChart({
    path: granularity === 'radius' ? API_THREAT_RADIUS : API_THREAT,
    granularity,
    customParams,
  });

  const {eventsWithDescription} = getI18N('threatAnalytics');
  const {yesLabel, noLabel} = getI18N('savedLocations');

  const {
    granularity: granularityLabels,
    detailsHeaders: headerLabels,
    emptyTableLabel,
  } = getI18N('legendQuintile');

  // * Maybe this should be in a utils file
  const handleNewDirection = (direction) => {
    switch (direction) {
      case DIRECTIONS.asc:
        return DIRECTIONS.desc;
      case DIRECTIONS.desc:
        return DIRECTIONS.asc;
      default:
        return DIRECTIONS.desc;
    }
  };

  const handleSort = (column) => {
    const newDirection = handleNewDirection(sortDirection);
    setColumnToSort(column);
    setSortDirection(newDirection);
    track('Sorted Threat Details Table', {
      location: locationName,
      direction: newDirection,
      column,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(1);
    track('Changed Rows Number Threat Details Table', {
      location: locationName,
      rows: event.target.value,
    });
  };

  const handleChangePage = (event, newPage) => {
    track('Changed Page Threat Details Table', {
      location: locationName,
      page: newPage,
    });
    setPage(newPage + 1);
  };

  const handleResponse = ({meta, data}) => {
    setPage(meta.page ?? 1);
    setPagination(meta);
    setThreats(
      data.map((threat) => ({
        ...threat.auxInfo,
        id: threat.id,
        date: threat.date,
        categoryId: threat.categoryId,
        granularity: threat.granularity,
        threatType: threat.threatType,
        actors: threat?.auxInfo?.actors?.filter((v) => v !== 'N/A'),
      })),
    );
  };

  const handleEmptyMessage = useCallback(
    () =>
      emptyTableLabel
        .replace('{r}', convertDistance(computedRadius, units))
        .replace('{u}', units),
    [computedRadius, units],
  );

  useEffect(() => {
    if (!isEmpty(selectionMarker)) {
      setPagination({});
      setPage(1);
      setRowsPerPage(10);
    }
  }, [selectionMarker]);

  useEffect(() => {
    if (data?.meta) {
      handleResponse(data);
    }
  }, [data]);

  if (isFetching && isEmpty(data)) return <BaseSkeleton height="400px" />;

  return (
    <Box sx={{width: '100%', ...sx}}>
      <Box sx={styles.filters}>
        <BaseSwitch
          label={eventsWithDescription}
          checked={filterByUnrest}
          onChange={handleFilterByUnrest}
          justifyContent="start"
          checkedLabel={yesLabel}
          uncheckedLabel={noLabel}
        />
      </Box>
      <Box size="small" sx={styles.root}>
        <Headers
          styles={styles}
          labels={headerLabels}
          sortDirection={sortDirection}
          columnToSort={columnToSort}
          handleSort={handleSort}
        />
        <Box>
          {threats.length < 1 ? (
            <Box sx={styles.parentBox} selected>
              <Box
                colSpan={12}
                sx={styles.rowsText}
                padding="normal"
                align="center">
                {handleEmptyMessage()}
              </Box>
            </Box>
          ) : (
            threats.map((row) => (
              <Rows
                key={row.id}
                row={row}
                formatDetailsDate={formatDetailsDate}
                labels={headerLabels}
                getTranslation={getTranslation}
                getThreatTypesTranslation={getThreatTypesTranslation}
                granularityLabels={granularityLabels}
                styles={styles}
                locationName={locationName}
                tab={granularity}
              />
            ))
          )}
        </Box>
      </Box>
      <BasePagination
        component="div"
        count={pagination.itemCount || -1}
        rowsPerPage={pagination.take || -1}
        page={pagination.page - 1 || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

ThreatEventsTable.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
};

export default ThreatEventsTable;
