import {MouseEvent, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {ToggleButton, ToggleButtonGroup} from '@mui/material';

import {getDrawer, getSavedLocations, getUserSettings} from '@/selectors';
import {setSavedLocationTab} from '@/store/modules/saved-locations/actions';

import {
  OVERVIEW,
  THREAT,
  CHANGE,
  TYPE,
  TAG,
  SAVED_LOCATIONS_DASHBOARD,
} from '@/components/saved-locations/constants';
import {styles} from '@/components/views/MyLocationsAnalysisPage.styles';
import RankedLocations from '@/components/saved-locations/tables/RankedLocations';
import SavedLocationDistribution from '@/components/saved-locations/charts/SavedLocationDistribution';
import SavedLocationDistributionByKey from '@/components/saved-locations/charts/SavedLocationDistributionByKey';
import SavedLocationCountBreakdown from '@/components/saved-locations/charts/SavedLocationCountBreakdown';
import SavedLocationsMap from '@/components/saved-locations/map/SavedLocationsMap';
import SavedLocationsKeyTakeaways from '@/components/saved-locations/cards/SavedLocationsKeyTakeaways';

import {useTranslation} from '@/hooks/useTranslation';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function MyLocationsAnalysisPage() {
  const dispatch = useDispatch();
  const {locale, getI18N} = useTranslation();
  const {getFeatureAccess} = useFeatureAccess();

  const {
    myLocationsAnalysis: {
      listType,
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);
  const {isMyLocationsAnalysisFiltersOpen: filtersOpen} =
    useSelector(getDrawer);

  const {tab} = useSelector(getSavedLocations);

  const {locationOverview, threatProfile, degreeOfChange} =
    getI18N('savedLocations');

  const components = useMemo(
    () => [
      {
        id: SAVED_LOCATIONS_DASHBOARD[0],
        xs: 12,
        sm: 12,
        md: filtersOpen ? 12 : 6,
        lg: 6,
        xl: 6,
        component: SavedLocationsMap,
        views: [OVERVIEW, THREAT, CHANGE],
        settings: {
          view: tab,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[1],
        xs: 12,
        sm: 12,
        md: filtersOpen ? 12 : 6,
        lg: 6,
        xl: 6,
        component: RankedLocations,
        views: [OVERVIEW, THREAT, CHANGE],
        settings: {
          view: tab,
          mode: listType,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[2],
        xs: 12,
        sm: 12,
        md: filtersOpen ? 12 : 6,
        lg: 6,
        xl: 6,
        component: SavedLocationsKeyTakeaways,
        views: [OVERVIEW],
        settings: {
          view: tab,
          aggregation,
          period,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[3],
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: SavedLocationDistribution,
        views: [THREAT, CHANGE],
        settings: {
          view: tab,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[4],
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: SavedLocationDistributionByKey,
        views: [THREAT, CHANGE],
        settings: {
          view: tab,
          mode: TYPE,
          aggregation,
          period,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[5],
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
        xl: 3,
        component: SavedLocationCountBreakdown,
        views: [OVERVIEW],
        settings: {
          view: tab,
          mode: TYPE,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[6],
        xs: 12,
        sm: 12,
        md: 6,
        lg: 4,
        xl: 4,
        component: SavedLocationDistributionByKey,
        views: [THREAT, CHANGE],
        settings: {
          view: tab,
          mode: TAG,
          aggregation,
          period,
        },
      },
      {
        id: SAVED_LOCATIONS_DASHBOARD[7],
        xs: 12,
        sm: 12,
        md: 6,
        lg: 3,
        xl: 3,
        component: SavedLocationCountBreakdown,
        views: [OVERVIEW],
        settings: {
          view: tab,
          mode: TAG,
        },
      },
    ],
    [filtersOpen, aggregation, period, listType, tab],
  );

  const tabs = useMemo(
    () =>
      [
        {value: OVERVIEW, label: locationOverview},
        {
          value: THREAT,
          label: threatProfile,
          hide: getFeatureAccess('mld-threat-profile', 'hide'),
        },
        {value: CHANGE, label: degreeOfChange},
      ].filter(({hide}) => !hide),
    [locale, getFeatureAccess],
  );

  const handleTabSelection = (
    _: MouseEvent<HTMLElement>,
    selectedTab: string | null,
  ) => {
    if (selectedTab !== null) {
      dispatch(setSavedLocationTab(selectedTab));
    }
  };

  return (
    <Box sx={styles.root}>
      <Container maxWidth="xl" sx={styles.container}>
        <ToggleButtonGroup
          exclusive
          size="small"
          sx={styles.tabs}
          value={tab}
          onChange={handleTabSelection}>
          {tabs.map(({value, label}) => (
            <ToggleButton key={value} sx={styles.tabButton} value={value}>
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Grid container spacing={2}>
          {components
            .filter(({views}) => views.includes(tab))
            .map(({id, xs, sm, md, lg, xl, component: Component, ...props}) => (
              <Grid
                item
                sx={styles.grid}
                key={id}
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                xl={xl}>
                <Component id={id} {...props} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default MyLocationsAnalysisPage;
