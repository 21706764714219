export const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 1,
  },
  content: {
    padding: 0,
  },
  mapIcon: {
    marginRight: 1,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cellZero: {
    paddingLeft: 16,
  },
  locationNameCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'flex',
  },
  dataGrid: (theme) => ({
    border: 'none',
    '--DataGrid-rowBorderColor': 'transparent',
    '& .MuiDataGrid-overlay': {
      backgroundColor: `${theme.palette.background.light} !important`,
    },
    '& .MuiDataGrid-root': {
      border: 'none !important',
    },
    '& .MuiDataGrid-topContainer.MuiDataGrid-container--top': {
      '--DataGrid-rowBorderColor': 'transparent',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: `${theme.palette.background.dark} !important`,
      border: 'none !important',
      color: theme.palette.text.primary,
      fontSize: '12px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-columnHeaders': {
      height: '40px',
      borderBottom: `1px solid ${theme.palette.background.contrast.dark}`,
      paddingBlock: '4px',
      marginBottom: '8px',
    },
    '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
      border: 'none !important',
    },
    '& .MuiDataGrid-filler': {
      backgroundColor: `${theme.palette.background.light} !important`,
    },
    '& .MuiDataGrid-cell': {
      borderTop: 'none !important',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
      borderRight: 'none',
    },
    '& .MuiDataGrid-columnHeader:last-child, .MuiDataGrid-cell': {
      borderRight: 'none',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: `${theme.palette.primary.main}25`,
      cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '.MuiDataGrid-footerContainer': {
      borderTop: 'none',
      borderBottom: 'none !important',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  }),
  footer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};
