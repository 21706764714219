import {
  SET_MARKER,
  SET_SEARCH_MARKER,
  SET_LOADING_MAP,
  SET_DEBUG_MODE,
  SET_VIEWTYPE,
  SET_LOCATION_ADDRESS,
  SET_UNMAPPED_MODE,
  SET_MAP_TOOLTIP,
  SET_IS_FLYING,
  SET_CRIME_EVENTS_COUNT,
  SET_TILES,
  SET_ROUTED_TO_MAP,
  SET_MAP_MOUNTED_STATUS,
  SET_MAP_VIEWPORT,
  SET_SELECTED_CITY,
  SET_MAP_MARKERS,
  SET_MAP_CENTER,
} from '@/store/modules/map/actionTypes';

export const setMapCenter = (payload) => ({
  type: SET_MAP_CENTER,
  payload,
});

export const setMapMarkers = (payload) => ({
  type: SET_MAP_MARKERS,
  payload,
});

export const setSelectedCity = (payload) => ({
  type: SET_SELECTED_CITY,
  payload,
});

export const setMapViewport = (payload) => ({
  type: SET_MAP_VIEWPORT,
  payload,
});

export const setMapMountedStatus = (payload) => ({
  type: SET_MAP_MOUNTED_STATUS,
  payload,
});

export const setRoutedToMap = (payload) => ({
  type: SET_ROUTED_TO_MAP,
  payload,
});

export const setTiles = (payload) => ({
  type: SET_TILES,
  payload,
});

export const setCrimeEventsCount = (payload) => ({
  type: SET_CRIME_EVENTS_COUNT,
  payload,
});

export const setIsFlying = (payload) => ({
  type: SET_IS_FLYING,
  payload,
});

export const setMapTooltip = (mapTooltip) => ({
  type: SET_MAP_TOOLTIP,
  mapTooltip,
});

export const setSelectionMarker = (marker) => ({
  type: SET_MARKER,
  marker,
});

export const setSearchMarker = (search) => ({
  type: SET_SEARCH_MARKER,
  search,
});

export const setMapLoading = (loading) => ({
  type: SET_LOADING_MAP,
  loading,
});

export const setDebugMode = (debug) => ({
  type: SET_DEBUG_MODE,
  debug,
});

export const setUnmappedMode = (unmapped) => ({
  type: SET_UNMAPPED_MODE,
  unmapped,
});

export const setViewTypeView = (viewType) => ({
  type: SET_VIEWTYPE,
  viewType,
});

export const setLocationAddress = (address) => ({
  type: SET_LOCATION_ADDRESS,
  address,
});
