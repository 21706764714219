import {useState, useCallback, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Box, Typography, Menu, MenuItem, useTheme} from '@mui/material';

import {setUserSettings} from '@/store/modules/user/actions';
import {useTranslation} from '@/hooks/useTranslation';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useApi} from '@/hooks/api/useApi';

import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import BaseButton from '@/components/common/buttons/BaseButton';
import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/navigation/menus/MyLocationsAnalysisSettings.styles';

function MyLocationsAnalysisSettings({userSettings, anchorEl, onClose}) {
  const {track} = useSegmentAnalytics();
  const theme = useTheme();
  const {patchUserSettings} = useApi();
  const {getI18N} = useTranslation();
  const dispatch = useDispatch();
  const {settings} = getI18N('myLocationsAnalysis');
  const {saveDefaultLabel} = getI18N('threatAnalytics');

  const [aggregation, setAggregation] = useState('yearly');
  const [period, setPeriod] = useState('yearOverYear');
  const [listType, setListType] = useState('savedLocations');

  useEffect(() => {
    const {listType, change} = userSettings.myLocationsAnalysis;
    setAggregation(change.aggregation);
    setPeriod(change.period);
    setListType(listType);
  }, []);

  const options = useMemo(
    () => ({
      aggregationOptions: [
        {label: settings.aggregationOptions[0], value: 'monthly'},
        {label: settings.aggregationOptions[1], value: 'quarterly'},
        {label: settings.aggregationOptions[2], value: 'yearly'},
      ],
      periodOptions: [
        {label: settings.periodOptions[0], value: 'periodOverPeriod'},
        {label: settings.periodOptions[1], value: 'yearOverYear'},
      ],
      listTypeOptions: [
        {
          label: settings.listTypeOptions.savedLocations,
          value: 'savedLocations',
        },
        {label: settings.listTypeOptions.cities, value: 'cities'},
      ],
    }),
    [settings],
  );

  const patchSettings = useMemo(
    () => ({
      ...userSettings,
      myLocationsAnalysis: {
        change: {aggregation, period},
        listType,
      },
    }),
    [userSettings, aggregation, period, listType],
  );

  const handleSaveDefault = useCallback(() => {
    track('New defaults filters saved for my locations analysis', {
      aggregation,
      period,
      listType,
    });
    patchUserSettings.mutate({body: {...patchSettings}});
    onClose();
  }, [userSettings, aggregation, period, listType, patchSettings, onClose]);

  useEffect(() => {
    dispatch(setUserSettings(patchSettings));
  }, [aggregation, period, listType]);

  return (
    <Menu
      id="menu-my-locations-analysis-settings"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      sx={styles.menu}
      MenuListProps={{sx: {py: 0}}}>
      <MenuItem style={styles.menuItem} disableRipple>
        <Box display="flex" flexDirection="column" gap={1} sx={styles.box}>
          <Typography variant="body2" fontWeight={600}>
            {settings.changeTitle}:
            <BaseTooltip
              message={settings.changeTooltip}
              iconSize={16}
              iconColor={theme.palette.secondary.main}
            />
          </Typography>
          <BaseSelect
            variant="filled"
            size="small"
            value={aggregation}
            onChange={(e) => setAggregation(e.target.value)}
            options={options.aggregationOptions}
          />
          <BaseSelect
            variant="filled"
            size="small"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            options={options.periodOptions}
          />
          <BaseRadioGroup
            dense
            title={`${settings.listTitle}:`}
            onChange={(value) => setListType(value)}
            selected={listType}
            options={options.listTypeOptions}
          />
          <BaseButton sx={styles.button} onClick={handleSaveDefault}>
            {saveDefaultLabel}
          </BaseButton>
        </Box>
      </MenuItem>
    </Menu>
  );
}

MyLocationsAnalysisSettings.propTypes = {
  userSettings: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
};

export default MyLocationsAnalysisSettings;
