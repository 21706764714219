import {useMemo} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';
import {DownloadSimple} from '@phosphor-icons/react';

import {useTranslation} from '@/hooks/useTranslation';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useChartTitle} from '@/hooks/charts/useChartTitle';
import {useSavedLocationDistribution} from '@/hooks/charts/useSavedLocationDistribution';

import {styles} from '@/components/saved-locations/charts/SavedLocationDistribution.styles';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import {CHANGE, THREAT} from '@/components/saved-locations/constants';

function SavedLocationDistribution({id, settings: {view}}) {
  const theme = useTheme();
  const {getI18N, getTooltipTranslation, locale} = useTranslation();

  const {degreeOfChangeOptions} = getI18N('myLocationsAnalysis.filters');
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');
  const {magnitudeOfChange, locations, indeterminateCaption} =
    getI18N('savedLocations');

  const {dynamicTitle} = useChartTitle({
    title: 'Location Count by {view}',
    variants: [
      {
        id: 'view',
        threatProfile: 'BaseScore',
        degreeOfChange: 'Percentage Change',
      },
    ],
    selections: [{id: 'view', value: view}],
  });

  const {data, isFetching, isError, indeterminateCount} =
    useSavedLocationDistribution({
      view,
    });

  const {copyImage, downloadImage} = useChartDownloads({
    id,
    title: dynamicTitle,
  });

  const colorAccessor = ({score, range}) =>
    view === CHANGE ? theme.palette.change[range] : theme.palette.threat[score];

  const chartCaption = useMemo(
    () => indeterminateCaption.replace('{count1}', indeterminateCount),
    [indeterminateCount],
  );

  const tooltip = {
    [CHANGE]: {
      getValue: ({range}) =>
        degreeOfChangeOptions.find(({value}) => value === range)?.name,
      label: magnitudeOfChange,
    },
    [THREAT]: {
      getValue: ({score}) => score,
      label: 'BaseScore',
    },
  };

  const downloadOptions = useMemo(
    () => [
      {
        label: copyImageLabel,
        action: copyImage,
      },
      {
        label: downloadImageLabel,
        action: downloadImage,
      },
    ],
    [locale, copyImage, downloadImage],
  );

  if (isError) return null;

  return (
    <BaseCard
      id={id}
      title={dynamicTitle}
      sx={{content: styles.content}}
      tooltip={`*${getTooltipTranslation('numberOfLocationsByChange')}\n*${chartCaption}`}
      headerActions={
        <ChartOptionsDropdown icon={DownloadSimple} options={downloadOptions} />
      }>
      <Box sx={styles.chartContainer}>
        <BaseXYChart
          height={300}
          hideYAxis={false}
          orientation="horizontal"
          isLoading={isFetching}
          seriesLength={1}
          marginLeft={50}
          xScale={{type: 'linear'}}
          yScale={{type: 'band', paddingInner: 0.2}}
          hideGrid
          tooltipOptions={{
            renderTooltip: ({tooltipData}) => {
              const bar = tooltipData?.nearestDatum?.datum;
              if (!bar) return null;
              return (
                <Box sx={styles.tooltip}>
                  <Typography
                    variant="body2"
                    sx={
                      styles.tooltipTitle
                    }>{`${bar.x} ${locations}`}</Typography>
                  <Box style={styles.tooltipSubtitleSection}>
                    <Box style={styles.colorBox(colorAccessor(bar))} />
                    <Typography variant="caption">
                      <b>{`${tooltip[view].label}: `}</b>
                      {tooltip[view].getValue(bar)}
                    </Typography>
                  </Box>
                </Box>
              );
            },
          }}
          xAxisOptions={{
            tickLabelProps: () => ({fill: theme.palette.text.secondary}),
            hideZero: false,
            hideTicks: false,
          }}
          yAxisOptions={{
            tickLabelProps: {
              fill: theme.palette.text.secondary,
              dy: '26px',
            },
          }}>
          <BaseBar
            data={{[magnitudeOfChange]: data}}
            colorAccessor={colorAccessor}
            radius={1}
          />
        </BaseXYChart>
      </Box>
    </BaseCard>
  );
}

export default SavedLocationDistribution;

SavedLocationDistribution.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};
