const BASE_SCORE = 'BaseScore'; // This value is language agnostic and should not be translated

export const base_languages = {
  en_US: {
    errorPage: {
      title: 'Uh oh, an unexpected error occurred.',
      subtitle:
        'Our team has been notified of this issue, please refresh your browser to continue leveraging our platform.',
      refreshButton: 'Refresh Browser',
      goHome: 'Go Home',
      contactSupport: 'Contact Support',
    },
    featureAccess: {
      forecastFeedback: 'We’ll be in touch shortly about Trend Forecast.',
      forecastTeaser:
        'Trend Forecast is available via our Enterprise Plan. Contact us to upgrade.',
      chartTeaser: 'Track change over time with Enterprise',
      explore: 'Explore now',
      enterpriseFeature: 'This is an Enterprise feature',
      thanks: 'Thank you!',
      offerUpgrade: 'Upgrading your account will unlock it.',
      upgradeFeedback:
        'Your submission has been received. We’ll be in touch and contact soon',
      interest: 'I’m interested',
      closeLabel: 'Close',
      teaserTitle: 'Discover more insights in Enterprise',
      teaserBullet1: 'Gain insights into changes in location crime over time',
      teaserBullet2:
        'Dive deeper with side-by-side breakdowns of crime category changes',
      teaserBullet3:
        'Personalize change visualizations for precise crime categories, time spans, and data sources',
      contact: 'Contact us',
    },
    tooltips: {
      forecastTrend:
        'Displays a forecast of crime trends based on historical data, projecting potential future crime rates and patterns',
      gtmNotAvailable: 'BaseGTM Forecast is not available for this location',
      gtmDisabled: 'BaseGTM must be enabled',
      selectionNotAvailable: 'Not available for this location or granularity',
      gtmPowered: 'Powered by BaseGTM.',
      gtmAvailable:
        'Modeling is used to account for noise in the data resulting from reporting inconsistencies to improve utility of results.',
      gtmUnavailable: 'BaseGTM estimates are not available for this location',
      layerNotAvailable:
        'This layer is not available at the current zoom level, zoom in to see it.',
      districtsNotAvailable:
        'Boundaries layer is not available in this location.',
      eventsNotAvailable: 'Events layer are not available in this location.',
      heatmapNotAvailable: 'Heatmap layer is not available in this location.',
      threatScore: BASE_SCORE,
      threatScoreNotAvailable:
        'BaseScore layer is not available in this location.',
      population: 'Estimated Population',
      trendline:
        'Crime trend lines and averages are determined based on the Base Operations threat model. This model helps account for noise in the data resulting from reporting inconsistencies to improve utility of results.',
      historical:
        'Historical monthly average is determined from full available history.',
      amountAndDirectionOfChange:
        'Saved locations plotted by the direction and magnitude of change.',
      topTenCitiesWithMostChange:
        'Ranking of cities with saved locations with the largest increase or decrease in events reported.',
      numberOfLocationsByChange:
        'Number of locations falling into each change threshold for the time period chosen in settings.',
      violentCrimeChange:
        'Number of saved locations by amount of change in total instances of violent crime.',
      propertyCrimeChange:
        'Number of saved locations by amount of change in total instances of property crime.',
      optionNotAvailable:
        'This option is not available for the selected location.',
      sourceNotSelected:
        'This source has been unselected in the left filter panel. Please select it again if you want to display it in the chart.',
      sourceThreatCategoriesNotSelected:
        'All event categories within this source have been deselected in the filter panel.',
      notEnoughData:
        'There is not enough data to display change for this source. If you have applied filters, try removing them.',
      betaLabel: 'This is a Beta feature',
      addLocation: 'Add Location',
      deleteLocation: 'Remove Location',
      threatAnalyticsEventSummary:
        'Displays counts of events that occurred within the selected threat radius.',
      crimeFilterDataType: `Switch between crime and unrest:
      • Crime - Includes threat events such as robbery and homicide.
      • Unrest - Includes threat events such as riots and protests.`,
      crimeFilterMapView: `View available map visualizations, including:
      • Crime Events - Individually plotted threat events.
      • Crime Heatmap - Threat activity hotspots.
      • Districts - Threat activity in administrative areas within a country, region, or city.`,
      crimeFilterQuintile: `Filter districts based on threat level relative to other districts in this location.
      • Threat level is based on number and severity of events in a district.
      • Threat levels are ranked into quintiles.
      • Light blue indicates the lowest relative threat level (0-20% of districts) and dark pink indicates the highest relative threat level (80-100%).`,
      crimeFilterTypes:
        'Filter threat events by crime type or category. Violent crime and property crime are summary categorizations that include multiple crime types.',
      crimeFilterQuarters: 'Filter threat events by quarter of occurrence.',
      crimeFilterDay: 'Filter threat events by time of the day.',
      crimeFilterDate: 'Filter threat events by date range.',
      threatAnalyticsSeverities: `Displays the threat level for the selected district month-over-month relative to other districts in this location.
      • Threat level is based on number and severity of events in a district.
      • Threat levels are ranked into quintiles.
      • Light blue indicates the lowest relative threat level (0-20% of districts) and dark pink indicates the highest relative threat level (80-100%).`,
      threatAnalyticsMonthly:
        'Displays threat events over time. Displays both monthly event count and event count as a 30-day-rolling-average to assess both actual time-based variance and a smoothed trend line, respectively.',
      threatAnalyticsRolling:
        'Displays threat events over time. Displays both monthly event count and event count as a 30-day-rolling-average to assess both actual time-based variance and a smoothed trend line, respectively.',
      threatAnalyticsHistorical:
        'Displays the seasonal threat trend using the average number of monthly threat events for the past three or more years. The date range is displayed above the chart.',
      threatLandscape:
        'The most relevant insights about threats impacting a location.',
      featuredEvents:
        'The most recent and noteworthy unrest events that have occurred in a location.',
      notableEvents:
        'The most serious and noteworthy unrest events that have occurred within the selected granularity in the last three months from the most recent date selected.',
      eventSummary:
        'Displays counts of events that occurred within the selected granularity.',
      eventComposition:
        'Displays frequency of events that occurred within the selected granularity. The bigger the font, the more frequent the event.',
      eventBreakdown:
        'Displays event breakdown by categories, subcategories, and time of day.',
      eventTrend:
        'Displays threat events over time. Displays either monthly event counts or daily event counts, as well as overall average period count, rolling average count, and historical monthly average count to assess actual time-based variance, smoothed trend, and seasonal averages.',
      changeTrend:
        'Displays the change in events over time by source. Displays either monthly or quarterly change as it compares to the prior period or prior year.',
      categoryContributions:
        'Displays events over time broken down by event categories.',
      periodAverage:
        'Displays average of number of threats over the chosen period within the selected granularity.',
      severityScore: `Displays threat event counts and threat levels for the selected district relative to other districts in this location.
      • Threat level is based on number and severity of events in a district.
      • Threat levels are ranked into quintiles.
      • Light blue indicates the lowest relative threat level (0-20% of districts) and dark pink indicates the highest relative threat level (80-100%).`,
      severityScoreTrend: `Displays threat level broken down by categories for the selected district month-over-month relative to other districts in this location.
      • Threat level is based on number and severity of events in a district.
      • Threat levels are ranked into quintiles.
      • Light blue indicates the lowest relative threat level (0-20% of districts) and dark pink indicates the highest relative threat level (80-100%).`,
      dataSourceTrend:
        'Displays events over time broken down by data sources. Sources are sorted by data volume for the selected date range.',
      dataSourceInsights:
        'Displays information about nuances to data sources for the selected location.',
      locationInsights:
        'Displays information about nuances of data reporting for this location.',
      colorLegend:
        'Indicates the threat level in a location ranging from low (light blue) to high (dark pink). The threat level is based on number and severity of events.',
      unrestFilterDataType: `Switch between crime and unrest:
      • Crime - Includes threat events such as robbery and homicide.
      • Unrest - Includes threat events such as riots and protests.`,
      unrestFilterMapView:
        'View unrest event locations. The pins correspond to locations where unrest events have occurred.',
      unrestFilterTypes:
        'Filter unrest events by type. You can select a single unrest type or multiple unrest types at the same time.',
      unrestFilterDate: 'Filter events by date range.',
      unrestLegendSummary:
        'Displays a count of unrest events by type. Clicking the white arrow next to each type will expand to show event subtypes.',
      threatEventsDetail:
        'Displays additional information about each unrest event. Click the white arrow to show details, including actors involved, an event description, and data sources.',
      unrestLegendChart:
        'Displays unrest events in monthly increments. Each increment is an average of the last 3 months.',
      navbarScreenShot: 'Screenshot',
      saveLocation: 'Save Location',
      cancelSaveLocation: 'Cancel',
      toggleFiltersPanelTooltip: 'Show/Hide Filters Panel',
      disabledRadiusTooltip:
        'Select a saved location to view radius based analytics',
      disabledDistrictTooltip:
        'Change your view to boundaries, then select a district to view these analytics',
      disabledCityTooltip: 'We do not have a boundary for this location yet',
      disabledComparativeTooltip:
        'Comparative analytics are only available in the district tab',
      filterPriorityRating: 'Filter saved locations by priority rating',
      filterTags: 'Filter saved locations by tag',
      sourceTypeFilter:
        'Filter threat events by data source. Sources are sorted by data volume for the whole time range covered.',
      unassignedSource:
        'Newly added data is typically ingested as unassigned. Data with unassigned source type are updated on a monthly cadence.',
      comingSoon: 'Coming Soon',
      changeMinimum: 'Change is based on a minimum 1mi radius.',
      percentageChangeByLocationType:
        'Groups location type by degree of change. Alter the location type by editing the type for any saved location.',
      percentageChangeByLocationTag:
        'Groups location tag by degree of change. Alter by editing the tags for any saved location.',
      countryScoreBreakdown:
        'Country BaseScore represents the relative threat & stability of countries on a standardized scale from 0 (best) to 100 (worst).',
    },
    horizontalNavBar: {
      breadcrumbs: {
        map: 'Map',
        'my-locations/list': 'My Locations',
        user: 'Profile',
        home: 'Home',
        'my-locations/analysis': 'My Locations Dashboard',
      },
    },
    savedLocations: {
      locationOverview: 'Location Overview',
      threatProfile: 'Threat Profile',
      degreeOfChange: 'Degree of Change',
      filtersTitle: 'Location Filter',
      positionUpdated: 'Position updated! Save this changes?',
      threatChange: 'Average Change by Threat Category',
      threatLandscape: 'Threat landscape',
      indeterminateCaption: 'The chart includes change for {count1} locations.',
      emptyThreatLandscape:
        'There is not enough data for Change and Threat landscape for this particular location',
      showCharts: 'Show Charts',
      barChartTitle: 'Location Count by Percentage Change',
      magnitudeOfChange: 'Magnitude of Change',
      locations: 'locations',
      citiesTableTitle: 'Ranked Cities',
      locationsTableTitle: 'Ranked Locations',
      changeMapTitle: 'Amount and Direction of Change',
      changeColorScaleLabel: 'Direction of Change',
      changeSizeScaleLabel: 'Magnitude of Change',
      changeTrendHeaders: {
        cityName: 'Ranked Cities',
        locationName: 'Ranked Locations',
        change: 'Change',
        savedLocationCount: '# of Locations',
        lastVisited: 'Last Visited',
        score: BASE_SCORE,
      },
      remaining: 'Remaining',
      none: 'None',
      addNewTag: 'Create new tag',
      maxTags: 'Max number of tags allowed per location: 15',
      allTags: 'All Tagged',
      yesLabel: 'Yes',
      noLabel: 'No',
      createdLocationLabel: 'Location saved!',
      updatedLocationLabel: 'Location updated!',
      deletedLocationLabel: 'Location deleted!',
      collapseModalLabel: 'See less fields',
      expandModalLabel: 'See more fields',
      saveLocationLabel: 'Save this location?',
      unrestLabel: 'Unrest',
      crimeLabel: 'Crime',
      viewMapTooltip: 'View Map',
      viewDetailsTooltip: 'View Details',
      filterLocationsTitle: 'Filter Saved Locations',
      // Control Actions
      closeButtonText: 'Close',
      filterButtonText: 'Filter',
      sortButtonText: 'Sort by',
      searchInputText: 'Search Saved Locations',
      cancelButtonText: 'Cancel',
      editButtonText: 'Edit',
      goToMapPrefix: "You don't have any saved locations. Please ",
      goToMapLink: 'Click here',
      goToMapSuffix: ' to go back to the Map.',
      returnToMap: 'Return to Map',
      selectAll: 'Select All',
      moreDetails: 'Details',
      saveButton: {
        text: 'Save',
        loading: 'Saving',
      },
      deleteButton: {
        text: 'Delete',
        loading: 'Deleting',
      },
      // Filter Categories
      locationTypeLabel: 'Location Types',
      priorityRatingLabel: 'Priority Rating',
      includeUnratedLabel: 'Include Unrated Locations',
      tagsLabel: 'Tags',
      office: 'Office',
      hotel: 'Hotel',
      event: 'Event',
      restaurant: 'Restaurant',
      travel: 'Travel',
      warehouse: 'Warehouse',
      parking: 'Parking',
      service_area: 'Service Area',
      point_of_interest: 'Point of Interest',
      facility: 'Facility',
      retail: 'Retail',
      other: 'Other',
      // Detail/Edit Drawer
      locationNotFound: 'Location Not Found',
      locationNameLabel: 'Location Name',
      displayAddressLabel: 'Display Address',
      coverageLabel: 'Coverage',
      customerIdLabel: 'Customer Location ID',
      dateRangeLabel: 'Date Range',
      latitudeLabel: 'Latitude',
      longitudeLabel: 'Longitude',
      locationTypePlaceholder: 'Select Location Type',
      // Saved Locations List Tile Columns
      location: 'Location',
      coverageDates: 'Coverage Dates',
      area: 'Area',
      rating: 'Rating',
      tags: 'Tags',
      // More
      locationTypeTooltip: 'Filter saved locations by location type',
      visibilityTooltip: 'Filter saved locations by their visibility',
      sortOptions: {
        name: 'Name',
        customerLocationId: 'Location ID',
        customerRating: 'Priority Rating',
        change: 'Change',
        changeTooltip: `All options are based on trendline calculations at a city level and are normalized to compare between locations:
        • Monthly Change YoY:
        eg. August 2023 vs August 2022
        • Year-over-year Change:
        eg. September 2022 - August 2023 vs September 2021 - August 2022
        • Change Index
        A combination of three change metrics (most recent month-over-month, quarter-over-quarter, and quarterly year-over-year change) in order to include short-term, mid-term, and longer-term change patterns.`,
        changeIndex: 'Change Index',
        oneMonthPeriodOverYear: 'Monthly Change YoY',
        twelveMonthPeriodOverPeriod: 'Year-over-year Change',
      },
      visibilityLabel: 'Visibility',
      visibilityOptions: {
        private: 'Private',
        shared: 'Shared',
      },
      all: 'All',
      userCannotEdit:
        'Only Owners and Admins have permission to edit a shared saved location.',
      gridViewTooltip: 'Grid View',
      listViewTooltip: 'List View',
      sortOrderTooltip: 'Sort Order',
      filtersTooltip: 'Open Filters',
      noSavedLocationsFirst: 'No Saved Locations. Remove some filters or',
      clickHere: 'click here',
      noSavedLocationsSecond: 'to go to the map.',
      goToLocation: 'Go to Location',
      noCoverage: 'No Coverage',
      additionalTags: 'Additonal Tags',
      coverageFrom: 'Coverage from',
      change: 'Change',
      changeNotAvailable: 'Change is not available for this location',
      priorityRating: 'Priority Rating',
      percentageChangeByLocationType: 'Percentage Change by Location Type',
      percentageChangeByLocationTag: 'Percentage Change by Location Tag',
      breakdownByLocationType: 'Breakdown by Location Type',
      breakdownByLocationTag: 'Breakdown by Location Tag',
    },
    user: {
      tab1: 'User Profile',
      tab2: 'Change Password',
      tab3: 'Manage API Token',
      tab4: 'Manage Users',
      title: 'Account Settings',
      // TODO: Remove once API keys is re-implemented
      tempTitle: 'User Profile',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
      emailLabel: 'Email',
      languageLabel: 'Language',
      unitsLabel: 'Units',
      saveChanges: 'Save Changes',
      allChangesSaved: 'All Changes Saved',
      validationLabel: 'Name is required',
      textFieldLabel: "API key's name",
      copyLabel: 'Copy',
      deleteLabel: 'Delete',
      createLabel: 'Generate',
      warningText:
        'A personal API key has been generated to access the Base Operations Rest API. Make sure to copy your API key now. Once this window is closed, it will no longer be retrievable.',
      deleteText: 'You are about to delete the key',
      createTitle: 'Generate API Key',
      deleteAction: 'Delete Key',
      deleteFeedback: 'Deleted API Key',
      deleteTitle: 'Deleting API key...',
      copyTitle: 'API Key Successfully Generated',
      createFeedback: 'Generated API Key',
      copyFeedback: 'Copied API Key to clipboard!',
      createButton: 'Generate New API Token',
      nameLabel: 'Key Name',
      secretLabel: 'API Secret',
      dateLabel: 'Created On',
      actionsLabel: 'Actions',
      maxValidation: 'Text field must not exceed 30 characters',
    },
    threatAnalytics: {
      overallCrime: 'Overall Change',
      saveDefaultLabel: 'Save as default',
      changeMapTitle: 'Amount and Direction of Change',
      acledAttribution:
        '** Base Operations acquires data from thousands of sources. We apply advanced statistical, geographical, and AI techniques to validate and enhance these data. Some data sourced for this location were provided by: Armed Conflict Location & Event Data Project (ACLED)',
      noThreatEvents:
        'No threat events have been reported at this location for the selected threat types and time period.',
      titleSeverity: 'Severity Score Trend',
      titleIncidents: 'Incidents',
      titleIntro: 'Threat Analytics for ',
      tabDistrict: 'District',
      tabProvince: 'Province',
      tabRadius: 'Radius',
      tabCity: 'City',
      tabCountry: 'Country',
      titleDistrict: 'District',
      titleLocation: 'Location',
      titleThreatAnalytics: 'Threat Analytics',
      titleCrimeEvents: 'Threat Events',
      textRadius: 'Radius of',
      textRadiusPrefix: 'within a ',
      textRadiusSuffix: 'radius',
      titleJoin: 'in ',
      quintileTitle: 'Threat Quintiles',
      quintileSubtitle: 'Threat Quintile: ',
      chartSubtitleStart: 'From ',
      chartSubtitleJoin: ' to ',
      openAnalytics: 'Analytics Panel',
      closeAnalytics: 'Analytics Panel',
      chart: 'Chart',
      averageType: 'Average Type',
      period: 'Periodicity',
      aggregationPeriod: 'Aggregation Period',
      chartTitle1: 'Event Count by Threat Category',
      chartTitle2: 'Event Composition',
      chartTitle3: 'Time of Day Breakdown',
      chartTitle4: 'Monthly Event Trend',
      chartTitle5: 'Change Trend',
      chartTitle9: 'Severity Score',
      chartTitle10: 'Severity Score Trend',
      chartTitle11: 'Monthly Events by Source',
      chartTitle12: 'Average Events by Month',
      chartTitle13: 'Event Frequency by Threat Category',
      chartTitle14: 'Daily Event Trend',
      chartTitle15: 'Average Events by Month of Year',
      chartTitle16: 'Average Events by Day of Week',
      chartTitle17: 'Average Events by Time of Day',
      chartTitle18: 'Location Count by BaseScore',
      chartTitle19: 'Location Count by Percentage Change',
      chartTitle20: 'Location Count by Type & Change',
      chartTitle21: 'Location Count by Tag & Change',
      chartTitle22: 'Location Count by Type & BaseScore',
      chartTitle23: 'Location Count by Tag & BaseScore',
      chartTitle24: 'Breakdown by Location Type',
      chartTitle25: 'Breakdown by Location Tag',
      chartTooltip: 'Groups location types by degree of change',
      chartTooltip1: 'Groups location tags by degree of change',
      chartTooltip2: 'Groups location types by degree of change',
      chartTooltip3: 'Groups location types by BaseScore',
      chartTooltip4: 'Groups location tags by BaseScore',
      chartTooltip5: 'Groups locations by location type',
      chartTooltip6: 'Groups locations by location tag',
      dataObservationsTitle: 'Data Source Insights',
      locationInsightsTitle: 'Location Insights',
      takeAwaysTitle: 'Percent Change',
      threatEventsTableTitle: 'Threat Events Details',
      notableEventsTitle: 'Notable Events',
      numberOfLocationsByChange: 'Number of Locations by Change',
      numberOfLocationsByChangeSubtitle: 'Overall Change',
      citiesWithMostChange: 'Top 10 Cities with Most Change',
      changeTrendValues: {
        higher: 'higher',
        lower: 'lower',
      },
      label1: 'Crimes',
      label2: 'Average',
      label3: 'Severity',
      label4: 'Total Crime',
      label5: '30-Day Rolling Average',
      label6: '3 Years',
      label7: '4 Years',
      label8: '5 Years',
      label9: 'Full Range',
      label10: 'Monthly',
      label11: 'Event Count',
      label12: 'Threats',
      value1: 'January',
      value2: 'February',
      value3: 'March',
      value4: 'April',
      value5: 'May',
      value6: 'June',
      value7: 'July',
      value8: 'August',
      value9: 'September',
      value10: 'October',
      value11: 'November',
      value12: 'December',
      topicSelectionText: 'Select a topic for analysis',
      eventsWithDescription: 'Events with description only',
      panelType: {
        overview: 'Overview',
        analysis: 'Trend Analysis',
        changeAnalysis: 'Change Analysis',
        breakdown: 'Threat Breakdown',
        notableEvents: 'Notable Events',
        eventList: 'Events List',
        source: 'Data Source',
        comparative: 'Comparative',
        countryAnalysis: 'Country Analysis',
        forecast: 'Trend Forecast',
      },
      panelTitles: {
        overview: 'Overview of Threat Landscape',
      },
      overviewSection: {
        threatScore: BASE_SCORE,
        percentChange: 'Percent Change',
        monthlyEvents: 'Reported Events',
        monthlyEventsSuffix: 'mo',
        population: 'Population',
        area: 'Area',
      },
      reportButton: 'Generate Location Report',
      goToEventList: 'Go to event list for additional events',
      countryAnalysis: {
        threatScore: `Country ${BASE_SCORE}`,
        countryScoreBreakdown: 'Country Threat Breakdown',
        overallScoreWeights: 'Overall score weights',
        threatFrom: 'Threat from...',
        score: 'Score',
        quintile: 'Quintile',
      },
      forecastTrendSection: {
        eventCountLegend: 'Event Count',
        trendLineLegend: 'Trend Line',
        forecastLegend: 'Forecast',
        confidence75Legend: '75% Confidence',
        confidence90Legend: '90% Confidence',
        forecastTooltip: 'Forecast',
        trendTooltip: 'Trend',
        countTooltip: 'Count',
        confUb90Tooltip: '90% Upper',
        confUb75Tooltip: '75% Upper',
        confLb75Tooltip: '75% Lower',
        confLb90Tooltip: '90% Lower',
      },
    },
    navigation: {
      search: 'Search',
      filter: 'Filter',
      home: 'Home',
      saved: 'Saved',
      map: 'Map',
      help: 'Help',
      settings: 'Settings',
      login: 'Account',
      myLocationsAnalysis: 'My Locations Dashboard',
    },
    myLocationsAnalysis: {
      keyTakeaways: {
        title: 'Key Takeaways',
        count: 'Locations Saved',
        recentCount: 'Locations Reviewed',
        averageThreatScore: 'Average BaseScore',
        averageChange: 'Average Change',
      },
      downloadChartImages: 'Download Chart Images',
      downloadSavedLocationReport: 'Download Saved Location Report as CSV',
      settings: {
        aggregationOptions: ['Monthly', 'Quarterly', 'Yearly'],
        periodOptions: ['Period over Period', 'Year over Year'],
        listTypeOptions: {
          savedLocations: 'Locations',
          cities: 'Cities',
        },
        locationBreakdownOptions: {
          type: 'Type',
          tag: 'Tag',
        },
        changeTitle: 'Change',
        listTitle: 'List',
        locationBreakdownTitle: 'Location breakdown',
        changeTooltip: 'The change is based on a minimum radius of 1 mi.',
      },
      filters: {
        title: 'Location filters',
        allTypesLabel: 'Location types',
        allTagsLabel: 'Tags',
        allDegreeOfChangesLabel: 'Degree of change',
        degreeOfChangeOptions: [
          {name: 'Large Increase', value: 'large_increase'},
          {name: 'Increase', value: 'increase'},
          {name: 'Minor Change', value: 'no_change'},
          {name: 'Decrease', value: 'decrease'},
          {name: 'Large Decrease', value: 'large_decrease'},
          // {name: 'Indeterminate', value: 'indeterminate'},
        ],
        threatScoreOptions: [
          {name: '>80', value: 'very_high'},
          {name: '60 - 79', value: 'high'},
          {name: '40 - 59', value: 'medium'},
          {name: '20 - 39', value: 'low'},
          {name: '<20', value: 'very_low'},
        ],
      },
    },
    searchModal: {
      savedLocationsTitle: 'Saved Locations',
      gettingStarted: 'Getting Started',
      recentTranslation: 'Recent',
      searchResults: 'Search Results',
      seeSavedLocations: 'See on Saved Location Page',
      more: 'more',
    },
    actionZoom: {
      zoomIn: 'Zoom In',
      zoomOut: 'Zoom Out',
    },
    legendActions: {
      threatLevelTranslation: 'Threat Level',
      highScore: 'High',
      lowScore: 'Low',
      mapLayerLabels: {
        heatmap: 'Heatmap',
        events: 'Events',
        districts: 'Boundaries',
        countries: 'Boundaries',
        scores: 'Scores',
        population: 'Population',
        change: 'Change',
        none: 'None',
      },
      mapStyleLabels: {
        map: 'Light',
        dark: 'Dark',
        satellite: 'Satellite',
      },
      mapMarkersLabels: {
        cities: 'City Pins',
        savedLocations: 'Site Pins',
      },
      stylesTitle: 'Map Styles',
      layersTitle: 'Data Layers',
      markersTitle: 'Markers',
    },
    help: {
      userGuide: 'Assessment Guide',
      restAPIDocumentationTranslation: 'REST API Documentation',
      threatAssessmentTemplateI: 'Threat Assessment Template I',
      threatAssessmentTemplateII: 'Threat Assessment Template II',
      threatAssessmentTemplateIII: 'Threat Assessment Template III',
      threatAssessmentTemplateIV: 'Threat Assessment Template IV',
    },
    userFeedback: {
      message: 'Was this helpful?',
    },
    dataNotification: {
      message:
        'Threat coverage is currently unavailable on this area. Please search for another location',
    },
    units: {
      mile: 'mile',
      kilometer: 'kilometer',
      miles: 'Miles',
      kilometers: 'Kilometers',
      meters: 'meters',
    },
    dateSkip: {
      month: '1 Month',
      year: '1 Year',
      skipForward: 'Skip forward',
      skipBackward: 'Skip backward',
    },
    dateMessageValidation: {
      lessFrom:
        'The from date must not be less than the minimum date of the current city.',
      greaterFrom:
        'The to date must not be greater than the maximum date of the current city.',
      lessTo:
        'The to date must not be less than the minimum date of the current city.',
      greaterTo:
        'The to date must not be greater than the maximum date of the current city.',
    },
    chartQuintile: {
      quintile: 'Quintile',
      incidents: 'Incidents',
      date: 'Date',
      threatCategory: 'Threat Category',
      eventCount: 'Event Count',
      severity: 'Severity',
    },
    chartOptions: {
      copyImageLabel: 'Copy Image',
      downloadImageLabel: 'Download Image',
      copyCSVLabel: 'Copy as CSV',
      downloadCSVLabel: 'Download as CSV',
    },
    changeOverview: {
      yearOverYearChange:
        '{increase} in events reported in {currentPeriod} compared to the same {period} last year',
      periodOverPeriodChange:
        '{increase} in events reported in {currentPeriod} compared to the previous {period}',
      yearOverYearNoChange:
        'No change in events reported in {currentPeriod} compared to the same {period} last year',
      periodOverPeriodNoChange:
        'No change in events reported in {currentPeriod} compared to the previous {period}',
      increase: 'increase',
      decrease: 'decrease',
      month: 'month',
      quarter: 'quarter',
      change: 'Change',
      percentChange: 'Percent Change',
    },
    chartSelection: {
      forecast3Months: '3-month forecast',
      forecast6Months: '6-month forecast',
      forecast1Year: '1-year forecast',
      all: 'All',
      primaryCategories: 'Primary categories',
      subCategories: 'Sub categories',
      threatCount: 'Threat Count',
      percentOfTotal: 'Percent of Total',
      average: 'Average',
      rollingAverage: 'Rolling Average',
      trendline: 'Trendline',
      historicalAverage: 'Historical Monthly Average',
      daily: 'Daily',
      percent: 'Percent',
      monthOfYear: 'Month of Year',
      dayOfWeek: 'Day of Week',
      timeOfDay: 'Time of Day',
      rate: 'Rate',
      periodOverPeriod: 'Period over Period',
      yearOverYear: 'Year over Year',
      percentage: 'Percentage',
      changeMetric: 'Change Metric',
      normalize: 'Normalize',
      change: 'Change',
      source: 'Source',
      monthlyChange: 'Monthly Change',
      quarterlyChange: 'Quarterly Change',
      incidentsPerMonth: 'incidents per month',
      incidentsPerQuarter: 'incidents per quarter',
    },
    chartLegend: {
      legend: 'Legend',
      noTagsLabel1:
        'No tags have been saved for your locations, add tags to locations in the',
      noTagsLabel2: 'My Locations List',
      noTagsLabel3: 'view.',
    },
    snackbar: {
      cityWarningTitle: 'This location is coming soon.',
      cityWarningDesc: 'Questions? Contact ',
      cancelButton: 'Dismiss',
    },
    snackbarMessages: {
      downloadSuccess: 'Successfully downloaded',
      fetchingReport: 'Fetching report...',
      reachedLimitExport:
        'You have reached the maximum number of exports, please try again in 8 hours.',
      somethingWentWrong: 'Something went wrong',
      copiedToClipboard: 'Successfully copied to clipboard',
      fetchingData: 'Fetching chart data...',
    },
    updateNotification: {
      message: 'There is a new version available.',
      action: 'Try it now!',
    },
    navbar: {
      mapStyleTooltip: 'Map View',
      languageTooltip: 'Language',
      more: 'More',
      share: 'Share',
      copyToClipboard: 'Copy to clipboard',
    },
    reCenterComp: {
      fabTooltip: 'Go to free roaming experience',
      removeBoundaries: 'Remove Boundaries',
    },
    threatAnalyticsComp: {
      fabTooltip: 'Open threat analytics panel',
    },
    home: {
      homeHeader: 'Granular Threat Intelligence Starts Here',
      searchboxPlaceholder: 'Search for a location',
      viewMapButton: 'View Map',
    },
    legendQuintile: {
      emptyTableLabel: 'No threats within {r} {u} from the clicked location.',
      threatEvent: 'Threat Events Details',
      summaryTable: 'Summary',
      detailsTable: 'Details',
      severityScoreTrend: 'Severity Score Trend',
      incidents: 'Incidents',
      detailsHeaders: {
        eventDate: 'Date / Time',
        subEventType: 'Event Type',
        interaction: 'Type',
        granularity: 'Granularity',
        fatalities: 'Fatalities',
        actor1: 'Actors',
        description: 'Description',
        source: 'Source',
        none: 'None',
      },
      granularity: {
        street: 'Street Level',
        district: 'District Level',
        city: 'City Level',
      },
      rowsPerPage: 'Rows per page',
      of: 'of',
      moreThanLabel: 'more than',
      chart: {
        attributionText:
          'Data from Armed Conflict Location & Event Data Project (ACLED); https://www.acleddata.com',
        lineChartLabel: 'Three month rolling average',
        lineChartLabelShort: 'Average',
        barChartLabel: 'Total',
      },
    },
    tooltip: {
      city: 'City',
      crime: 'Crime',
      views: 'Map Views',
      lonLatText: 'Incidents, Heatmap',
      neighborhoodText: 'Districts',
      to: 'to',
      dates: 'Date Range',
      types: 'Crime Types',
      unrest: 'Unrest',
      subTypes: 'Unrest Event Types',
      addFavorite: 'Add to favorites',
      removeFavorite: 'Remove from favorites',
      loading: 'Loading...',
      noData: 'No Data Available',
    },
    legendPopup: {
      quintile: {
        title: 'Threat level',
        info: {
          0: 'High',
          1: 'Medium High',
          2: 'Medium',
          3: 'Medium Low',
          4: 'Low',
        },
      },
      crimeHeatmap: {
        title: 'Threat level',
        info: {
          10: 'High',
          9: '',
          8: '',
          7: '',
          6: '',
          5: '',
          4: '',
          3: '',
          2: '',
          1: 'Low',
        },
      },
    },
    crimeQuantile: 'Crime Quintile',
    riskQuantile: 'Threat Quintile',
    quantiles: {
      1: 'Low',
      2: 'Medium low',
      3: 'Medium',
      4: 'Medium high',
      5: 'High',
    },
    mapStyle: 'Map Style',
    map: 'Map',
    light: 'Light',
    satellite: 'Satellite',
    dark: 'Dark',
    cuadrante: 'Neighborhood',
    municipio: 'Municipality',
    zona: 'Zone',
    sector: 'Sector',
    status: 'Status',
    location: 'Location',
    contact: 'Contact',
    clearResults: 'Clear Results',
    downloadNow: 'Download Now',
    pleaseEnterYourEmailAddressBelowAndWeWillSendYouInformationToChangeYourPassword:
      'Please enter your email address below and we will send you information to change your password.',
    sendRecoveryEmail: 'Send Recovery Email',
    email: 'Email',
    login: 'Log In',
    'sign-up': 'Sign Up',
    'forgot-password': 'Forgot Password',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    forgotPassword: 'Forgot Password',
    confirmSignUp: 'Confirm Sign Up',
    download: 'Download',
    show: 'Show',
    hide: 'Hide',
    lastUpdated: 'last updated',
    neverReported: 'never reported',
    enterLocation: 'Enter Location',
    dismiss: 'Dismiss',
    sendANotification: 'Send a Notification',
    isThisAnEmergencyThatRequiresCheckIn:
      'Is this an emergency that requires check in?',
    cancel: 'Cancel',
    send: 'Send',
    settings: 'Settings',
    account: 'Account',
    thereAreNoTeamMembersCurrentlyInThisCity:
      'There are no team members currently in this city.',
    sendTo: 'Send to:',
    subject: 'Subject',
    message: 'Message',
    messageSent: 'Thank you! Your notification has been sent.',
    messageSentTitle: 'Information',
    emergencyContacts: 'Emergency Contacts',
    Position: 'Position',
    Description: 'Description',
    Email: 'Email',
    Phone_Number: 'Phone Number',
    timeFromNotification: 'Time from notification (hrs)',
    usersCheckingIn: 'Users checking in',
    percentOfUsersResponding: 'Percent of users responding',
    usersReportingAsSafe: 'Users reporting as safe',
    usersReportingAsUnsafe: 'Users reporting as unsafe',
    usersNotified: 'Users notified:',
    usersReportedSafe: 'Users reported safe:',
    usersNeedingHelp: 'Users needing help:',
    usersNotReporting: 'Users not reporting:',
    averageResponseTime: 'Average response time',
    reportsWithinFirst3Hours: 'Reports within first 3 hours:',
    reportsWithinFirst12Hours: 'Reports within first 12 hours:',
    reportsWithinFirst24Hours: 'Reports within first 24 hours:',
    reportsWithinFirst72Hours: 'Reports within first 72 hours:',
    reportsViaApp: 'Reports via app:',
    reportsViaEmail: 'Reports via email:',
    noNotificationsToShow: 'No notifications to show.',
    untitledNotification: 'Untitled notification',
    notificationReportsFor: 'Notification Reports for',
    chooseANotification: 'Choose a notification',
    notificationHistory: 'Notification History',
    menuSignOut: {
      signOut: 'Sign Out',
      areYouSureYouWantToSignOutOfBaseOperations:
        'Are you sure you want to sign out of Base Operations?',
      changePasswordEmail:
        'A password change link has been sent to your email address. Please check your email to continue the process.',
      changePasswordOption: 'Change password',
      yes: 'Yes',
      no: 'No',
      userSettings: 'User Settings',
    },
    allTeamMembers: 'All Employees',
    Name: 'Name',
    Role: 'Role',
    peopleSearch: 'Employee Search',
    teamMembersInThisCity: 'Employees in This City',
    categories: 'Categories',
    dates: 'Date Range',
    hours: 'Hours',
    teamMember: 'Employee',
    to: 'To',
    updatingFilters: 'Updating Filters',
    applyTimeFilters: 'Apply Time Filters',
    applyCrimeFilters: 'Apply Crime Filters',
    Harassment: 'Harassment',
    Theft: 'Theft',
    'Armed Robbery': 'Armed Robbery',
    'Car Robbery': 'Car Robbery',
    Assault: 'Assault',
    ViolentCrimes: 'Violent Crimes',
    Extortion: 'Extortion',
    Kidnapping: 'Kidnapping',
    Rape: 'Sex Offenses',
    Vandalism: 'Vandalism',
    Piracy: 'Piracy',
    'Drug Offenses': 'Drug Offenses',
    Fraud: 'Fraud',
    'Organized Crime': 'Organized Crime',
    Homicide: 'Homicide',
    Other: 'Petty Crime',
    Terrorism: 'Terrorism',
    'Updating Filters': 'Updating Filters',
    'Apply Crime Filters': 'Apply Crime Filters',
    searchByNameOrEmail: 'Search by name or email',
    findPeople: 'Find Employee',
    from: 'From',
    applyDateFilters: 'Apply Date Filters',
    companyContacts: 'Company Contacts',
    companyLocations: 'Company Locations',
    worldResources: 'In-Country Resources',
    address: 'Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    actions: 'Actions',
    pinned: 'Pinned',
    typemembername: 'Type member name',
    admin: 'Admin',
    officer: 'Officer',
    employee: 'Employee',
    delete: 'Delete',
    language: 'Language',
    none: 'None',
    sent: 'Sent',
    by: 'by',
    atunkowntime: 'at unknown time',
    unknown: 'unknown',
    tablelocalization: {
      body: {
        emptyDataSourceMessage: 'No locations to display',
        addTooltip: 'Add',
        deleteTooltip: 'Delete',
        editTooltip: 'Edit',
        filterRow: {
          filterTooltip: 'Filter',
        },
      },
      editRow: {
        deleteText: 'Are you sure you want to delete this?',
        saveTooltip: 'Save',
        cancelTooltip: 'Cancel',
      },
      toolbar: {
        searchTooltip: 'Search',
        searchPlaceholder: 'Search',
      },
      header: {
        actions: 'Actions',
      },
      pagination: {
        nextLabel: 'next',
        previousLabel: 'prev',
        labelDisplayedRows: ' {from}-{to} of {count}',
        firstTooltip: 'First Page',
        previousTooltip: 'Previous Page',
        nextTooltip: 'Next Page',
        lastTooltip: 'Last Page',
        showing: 'Showing',
      },
    },
    uploadPhotoModal: {
      addPhoto: 'Upload Photo',
      deletePhoto: 'Delete Photo',
      saveChange: 'Save Change',
      changePhoto: 'Change Photo',
    },
    timeRead: 'Time Read',
    responseMethod: 'Response Method',
    lastLocationUpdate: 'Last Location Update',
    deepLinkMessage:
      'Hello Base Operations user!<br/>You are seeing this page because your web browser does not support linking to our app.<br/><br/>Please open the Base Operations app and click on the “Emergency” icon to mark yourself as “Safe” or “In Danger”.<br/><br/>Thank you!<br/>The Base Operations Team<br/><br/>',
    filters: {
      gtmSwitchLabel: 'Enable BaseGTM',
      selectDeselectAll: 'Select/Deselect All',
      crimesNumber: ' Events in View',
      selectAll: 'Select All',
      violentCrime: 'Violent Crime',
      propertyCrime: 'Property Crime',
      boundariesLabel: 'Boundaries',
      cityHoodsLabel: 'Districts',
      countryHoodsLabel: 'Provinces',
      eventsLabel: 'Events',
      heatmapLabel: 'Heatmap',
      slider: {
        date: 'Select a date',
      },
      quarters: {
        1: 'Quarter 1',
        2: 'Quarter 2',
        3: 'Quarter 3',
        4: 'Quarter 4',
      },
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      sources: {
        1: 'News Media',
        2: 'Social Media',
        3: 'Data Aggregator',
        4: 'National Police',
        5: 'Local Police',
        6: 'University Police',
        7: '911 Calls for Service',
        8: 'State-Affiliated Media',
        9: 'National Government',
        10: 'Local Government',
        11: 'Non-Profit (NGO)',
        12: 'Other',
        13: 'Unknown',
        14: 'Media',
        15: 'Law Enforcement',
        16: 'Government',
        17: 'Non-Profit (NGO)',
        18: 'Unassigned',
        19: 'Unassigned',
        20: 'Political Movement',
        21: 'Union',
        22: 'Activist Movement',
        23: 'Special Interest Group',
      },
      categories: {
        1: 'Harassment',
        2: 'Theft',
        3: 'Robbery',
        4: 'Vehicle Theft',
        5: 'Simple Assault',
        6: 'Extortion',
        7: 'Kidnapping',
        8: 'Sex Offenses',
        9: 'Vandalism',
        10: 'Piracy',
        11: 'Trafficking of Illegal Goods',
        12: 'Fraud',
        13: 'Organized Crime',
        14: 'Homicide',
        15: 'Petty Crime',
        16: 'Terrorism',
        17: 'Corruption',
        18: 'Violent Crimes',
        19: 'Suspicius Acivity',
        20: 'Disturbance',
        21: 'Serious Traffic Violations',
        22: 'Minor Traffic Violations',
        23: 'Domestic Offences',
        24: 'Drug & Alcohol Violations',
        25: 'Trespassing',
        26: 'Weapon Violations',
        27: 'Not Relevant',
        28: 'Missing Person',
        29: 'Suicide',
        30: 'Air/Drone Strike',
        31: 'Change To Group/Activity',
        32: 'Headquarters Or Base Established',
        33: 'Agreement',
        34: 'Arrests',
        35: 'Non-Violent Transfer Of Territory',
        36: 'Attack',
        37: 'Grenade',
        38: 'Armed Clash',
        39: 'Disrupted Weapons Use',
        40: 'Shelling/Artillery/Missile Attack',
        41: 'Looting/Property Destruction',
        42: 'Petty Crime',
        43: 'Peaceful Protest',
        44: 'Protest With Intervention',
        45: 'Mob Violence',
        46: 'Excessive Force Against Protesters',
        47: 'Chemical Weapon',
        48: 'Sexual Violence',
        49: 'Non-State Actor Overtakes Territory',
        50: 'Suicide Bomb',
        51: 'Abduction/Forced Disappearance',
        52: 'Government Regains Territory',
        53: 'Remote Explosive/Landmine/IED',
        54: 'Violent Demonstration',
        55: 'Demonstrations',
        56: 'Non-Violent Unrest',
        57: 'Other Crime',
        58: 'Property Crime',
        59: 'Violent Crime',
        60: 'Political Violence',
        61: 'Aggravated Assault',
        62: 'Arson',
        63: 'Burglary',
        64: 'Fencing',
        65: 'Shoplifting',
        66: 'Theft from Vehicle',
        67: 'Property Crimes',
      },
      unrestTypes: {
        1: 'Battles',
        2: 'Riots',
        3: 'Violence against civilians',
        4: 'Explosions/Remote violence',
        5: 'Strategic developments',
        6: 'Protests',
      },
      unrestSubtypes: {
        30: 'Air/drone strike',
        31: 'Change to group/activity',
        32: 'Headquarters or base established',
        33: 'Agreement',
        34: 'Arrests',
        35: 'Non-violent transfer of territory',
        36: 'Attack',
        37: 'Grenade',
        38: 'Armed clash',
        39: 'Disrupted weapons use',
        40: 'Shelling/artillery/missile attack',
        41: 'Looting/property destruction',
        42: 'Other_unrest',
        43: 'Peaceful protest',
        44: 'Protest with intervention',
        45: 'Mob violence',
        46: 'Excessive force against protesters',
        47: 'Chemical weapon',
        48: 'Sexual violence',
        49: 'Non-state actor overtakes territory',
        50: 'Suicide bomb',
        51: 'Abduction/forced disappearance',
        52: 'Government regains territory',
        53: 'Remote explosive/landmine/IED',
        54: 'Violent demonstration',
      },
      timeslices: {
        0: 'Morning',
        1: 'Midmorning',
        2: 'Midday',
        3: 'Evening',
        4: 'Night',
      },
      weekdays: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      seasons: {
        spring: 'Spring',
        summer: 'Summer',
        fall: 'Fall',
        winter: 'Winter',
      },
      countryCategories: {
        1: 'Stability',
        2: 'Rule of Law',
        3: 'Corruption',
        4: 'Transparency',
        5: 'Fundamental Rights',
        6: 'Criminality',
        7: 'Conflict',
        8: 'Resilience',
        9: 'Political Leadership',
        10: 'Government Accountability',
        11: 'Territorial Integrity',
        12: 'International Cooperation',
        13: 'Order and Security',
        14: 'Legal System',
        15: 'Criminal Justice',
        16: 'Civil Justice',
        17: 'Economic Regulation',
        18: 'Regulatory Enforcement',
        19: 'Public Integrity',
        20: 'Judicial Independence',
        21: 'Constraints on Government',
        22: 'Government Transparency',
        23: 'Open Government',
        24: 'Internet Freedom',
        25: 'Press Freedom',
        26: 'Political Rights',
        27: 'Civil Liberties',
        28: 'Human Trafficking',
        29: 'Arms Trafficking',
        30: 'Cyber Crimes',
        31: 'Financial Crimes',
        32: 'Drug Trade',
        33: 'Extortion',
        34: 'Organized Crime',
      },
    },
    eventFilter: 'Event Filter',
    Filters: 'Filters',
    crimeNeighborhoods: 'Districts',
    protestAreas: 'Unrest Events',
    crimeTypes: 'Crime Types',
    threatCategories: 'Event Type',
    sourceCategories: 'Data Sources',
    timeOfYear: 'Time of Year',
    timeOfDay: 'Time of Day',
    mapGranularity: 'Map Granularity',
    mapViewOptions: 'Map View',
    messageDetails: 'Message Details',
    mapThreatType: 'Data Type',
    unrestSubtypes: 'Unrest Event Types',
    threatCrime: 'Crime',
    threatUnrest: 'Unrest',
    enterpriseLabel: 'Enterprise',
    betaLabel: 'Beta',
    popup: {
      neighborhood: {
        areaAverage: 'Area Average',
        cityAverage: 'City Average',
      },
      drawPolygon: {
        severityAverage: 'Severity Average: ',
        drawIcon: 'Select Crime Points',
        trashIcon: 'Clear Selection',
      },
    },
    sendOptions: 'Send Options',
    sendToAllCompanyEmployees: 'Send to all Employees',
    sendToAllEmployeesInTheSelectedCountries:
      'Send to all Employees in the selected Countries:',
    sendToAllEmployeesInTheSelectedCities:
      'Send to all Employees in the selected Cities:',
    sendMessage: {
      confirmationDialog: {
        title: 'Send to all Employees in {place}',
        titleCompanyWide: 'Send to all Employees',
        message:
          'Please be aware that this notification will be sent to every employee in {place}. Please confirm or cancel below.',
        messageCompanyWide:
          'Please be aware that this notification will be sent to every employee company-wide. Please confirm or cancel below.',
        hideForFutureSelections:
          'Hide this message for future country selections',
        confirmSend: 'Confirm send',
        confirmSendTo: 'Confirm send to {place}',
      },
    },
    modal: {
      continue: 'Continue',
      cancel: 'Cancel',
    },
    notificationHistoryModal: {
      sentOn: 'Sent on',
      sentBy: 'Sent by',
      notifications: 'Notifications',
      usersIncludedInNotification: 'Users included in Notification',
      countriesIncludedInNotification: 'Countries included in Notification',
      citiesIncludedInNotification: 'Cities included in Notification',
      notificationThread: 'Notification Thread',
      notificationUsers: 'Notification Users',
      notificationHistory: 'Notification History',
      addNotificationToThread: {
        addNotificationToThread: 'Add a Notification to Thread',
        confirmationDialog: {
          title: 'Send New Emergency Notification',
          message:
            'Would you like to reset the status of all users in this thread to "Unknown" requiring a new check-in?',
          resetAllUsers: 'Reset all users',
          retainCurrentUserStatus: 'Retain current user status',
        },
      },
    },
    countries: {
      Brazil: 'Brazil',
      Canada: 'Canada',
      Mexico: 'Mexico',
      'United States': 'United States',
    },
    cities: {
      'Mexico City': 'Mexico City',
      Bogotá: 'Bogotá',
      Denver: 'Denver',
      'São Paulo': 'São Paulo',
      'Rio de Janeiro': 'Rio de Janeiro',
      'Washington, DC': 'Washington, DC',
      Niterói: 'Niterói',
      'Buenos Aires': 'Buenos Aires',
      Fortaleza: 'Fortaleza',
      Baghdad: 'Baghdad',
      London: 'London',
      Vancouver: 'Vancouver',
      'Puerto Rico': 'Puerto Rico',
      'Bahía Blanca': 'Bahía Blanca',
      Santiago: 'Santiago',
      Montevideo: 'Montevideo',
      Other: 'Other',
    },
    locationInsights: {
      noData: 'No recent crime data is available for this location.',
      diffReportingFrequency:
        'Crime and unrest data have different time coverage and time granularity for this location. Crime data is reported {crime} and unrest data is reported {unrest}. Charts such as the Event Trend chart and the Category Contribution chart may be affected by this discrepancy. In order to visualize the charts properly, visualize crime data and unrest data separately using the Event Type Filter.',
    },
    keyTakeaways: {
      quintiles: {
        1: 'Low',
        2: 'Low-Medium',
        3: 'Medium',
        4: 'Medium-High',
        5: 'High',
      },
      // District Comparison
      districtCompare:
        '{totalCount} crimes reported relative to other districts (quintile {quintile}) within the same {locationType}. The district has a {threatLevel} threat level for the selected crime categories.',
      // Highest Crime
      highestCrimeRadius:
        '{crimeCategory} is the highest reported crime category within a {radius} {units} radius, representing {crimePercentage} of the events occurred.',
      highestCrimeDistrict:
        '{crimeCategory} is the highest reported crime category within the district, representing {crimePercentage} of the events occurred.',
      highestCrimeCity:
        '{crimeCategory} is the highest reported crime category within the city, representing {crimePercentage} of the events occurred.',
      // Day vs Night Greater Than 20
      dayVsNightGreaterRadius:
        '{nightPercentage} of events reported within a {radius} {units} radius occurred in the evening and nighttime, and {dayPercentage} occurred during the day',
      dayVsNightGreaterDistrict:
        '{nightPercentage} of events reported in the district occurred in the evening and nighttime, and {dayPercentage} occurred during the day',
      dayVsNightGreaterCity:
        '{nightPercentage} of events reported within the city occurred in the evening and nighttime, and {dayPercentage} occurred during the day',
      // Day vs Night Less Than 20
      dayVsNightLessRadius:
        'Within a {radius} {units} radius, crime is relatively uniform throughout the day, with {dayPercentage} of events  happening during the day, and {nightPercentage} of the events happening in the evening and nighttime.',
      dayVsNightLessDistrict:
        'Within the district, crime is relatively uniform throughout the day, with {dayPercentage} of events happening during the day, and {nightPercentage} of the events happening in the evening and nighttime.',
      dayVsNightLessCity:
        'For {locationName}, crime is relatively uniform throughout the day, with {dayPercentage} of events happening during the day, and {nightPercentage} of the events happening in the evening and nighttime.',
      // Property vs. Violent Crime
      propertyVsViolentRadius:
        '{violentCrimePercentage} of events reported within {radius} {units} radius are {violentCrime} and {propertyCrimePercentage} are {propertyCrime}.',
      propertyVsViolentDistrict:
        '{violentCrimePercentage} of events reported in the district are {violentCrime} and {propertyCrimePercentage} are {propertyCrime}.',
      propertyVsViolentCity:
        '{violentCrimePercentage} of events reported within the city are {violentCrime} and {propertyCrimePercentage} are {propertyCrime}.',
    },
    httpErrorMessages: {
      400: 'Invalid Request: {message}.',
      403: 'You are not authorized to perform this action.',
      429: 'Too Many Requests: Please try again later.',
      500: 'Internal Server Error: Uh Oh! Something went wrong, we are working on it.',
      clientError: 'Please Try Again',
      serverError: 'Something went wrong, please try again later.',
    },
    textLimit: {
      showLess: 'View less',
      showMore: 'View more',
      less: 'less',
      more: 'more',
    },
    reportDownloadModal: {
      allThreatsTypesLabel: 'All Threat Types',
      reportNameLabel: 'Report Name',
      defaultReport: 'Default Report',
      defaultReportName: 'My Report',
      onePageReport: 'One-Page Report',
      customReport: 'Custom Report',
      radiusHeader: 'Radius',
      districtHeader: 'District',
      cityHeader: 'City',
      summaryPageLabel: 'Summary',
      changeAnalysisLabel: 'Change Analysis',
      threatAnalysisLabel: 'Threat Breakdown',
      districtAnalysisLabel: 'District Analysis',
      trendAnalysisLabel: 'Trend Analysis',
      dataSourcesLabel: 'Data Sources',
      selectionLabel: 'Select Sections',
      infoCaptionLabel:
        'Each checked section is an additional page in the report',
      downloadLabel: 'Download Report',
      downloadMessage:
        "Report generation takes about 5 minutes, you will receive an email when it's ready for download.",
    },
  },
  es_MX: {
    errorPage: {
      title: '¡Ups! Ocurrió un error inesperado.',
      subtitle:
        'Nuestro equipo ha sido notificado de este problema, por favor actualiza tu navegador para seguir utilizando nuestra plataforma.',
      refreshButton: 'Actualizar Navegador',
      goHome: 'Ir a Inicio',
      contactSupport: 'Contactar Soporte',
    },
    featureAccess: {
      forecastFeedback:
        'Nos pondremos en contacto contigo pronto sobre la Previsión de Tendencias.',
      forecastTeaser:
        'El Pronóstico de Tendencias está disponible a través de nuestro Plan Empresarial. Contáctenos para actualizar.',
      chartTeaser: 'Sigue los cambios con Enterprise a lo largo del tiempo',
      explore: 'Explora ahora',
      enterpriseFeature: 'Esta es una característica de la empresa',
      thanks: '¡Gracias!',
      offerUpgrade: 'La actualización de tu cuenta desbloqueará esta función.',
      upgradeFeedback:
        'Hemos recibido tu solicitud. Nos pondremos en contacto contigo pronto.',
      interest: 'Estoy interesado',
      closeLabel: 'Cerrar',
      teaserTitle: 'Descubre más información en la empresa',
      teaserBullet1:
        'Obtén información sobre los cambios en la delincuencia en diferentes ubicaciones a lo largo del tiempo',
      teaserBullet2:
        'Profundiza con desgloses lado a lado de los cambios en las categorías de delincuencia',
      teaserBullet3:
        'Personaliza las visualizaciones de los cambios para categorías de delincuencia precisas, períodos de tiempo y fuentes de datos',
      contact: 'Contáctanos',
    },
    tooltips: {
      forecastTrend:
        'Muestra un pronóstico de las tendencias delictivas basado en datos históricos, proyectando posibles tasas y patrones de criminalidad futuros',
      gtmNotAvailable:
        'El pronóstico de BaseGTM no está disponible para esta ubicación',
      gtmDisabled: 'BaseGTM debe estar habilitado',
      selectionNotAvailable: 'No disponible para esta ubicación o granularidad',
      gtmPowered: 'Potenciado por BaseGTM.',
      gtmAvailable:
        'Se utiliza modelado para tener en cuenta el ruido en los datos resultante de inconsistencias en los informes para mejorar la utilidad de los resultados.',
      gtmUnavailable:
        'Las estimaciones de BaseGTM no están disponibles para esta ubicación',
      layerNotAvailable:
        'Esta capa no está disponible en el nivel de zoom actual, amplíe para verla.',
      districtsNotAvailable:
        'La capa de límites no está disponible en esta ubicación.',
      eventsNotAvailable:
        'La capa de eventos no está disponible en esta ubicación.',
      heatmapNotAvailable:
        'La capa de mapa de calor no está disponible en esta ubicación.',
      threatScore: BASE_SCORE,
      threatScoreNotAvailable:
        'La capa BaseScore no está disponible en esta ubicación.',
      population: 'Población Estimada',
      trendline:
        'Las líneas de tendencia y promedios de crimen se determinan basándose en el modelo de amenazas de Operaciones Base. Este modelo ayuda a tener en cuenta el ruido en los datos resultante de inconsistencias en los informes para mejorar la utilidad de los resultados.',
      historical:
        'El promedio mensual histórico se determina a partir de la historia completa disponible.',
      amountAndDirectionOfChange:
        'Ubicaciones guardadas representadas por la dirección y magnitud del cambio.',
      topTenCitiesWithMostChange:
        'Clasificación de ciudades con ubicaciones guardadas con el mayor aumento o disminución en eventos reportados.',
      numberOfLocationsByChange:
        'Número de ubicaciones que se encuentran en cada umbral de cambio para el período de tiempo elegido en la configuración.',
      violentCrimeChange:
        'Número de ubicaciones guardadas por cantidad de cambio en el total de instancias de crimen violento.',
      propertyCrimeChange:
        'Número de ubicaciones guardadas por cantidad de cambio en el total de instancias de crimen contra la propiedad.',
      optionNotAvailable:
        'Esta opción no está disponible para la ubicación seleccionada.',
      sourceNotSelected:
        'Esta fuente ha sido deseleccionada en el panel de filtro izquierdo. Por favor, selecciónela de nuevo si desea mostrarla en el gráfico.',
      sourceThreatCategoriesNotSelected:
        'Todas las categorías de eventos de esta fuente han sido deseleccionadas en el panel de filtro.',
      notEnoughData:
        'No hay suficientes datos para mostrar cambios en esta fuente. Si ha aplicado filtros, intente quitarlos.',
      betaLabel: 'Esta es una característica Beta',
      addLocation: 'Agregar Direccion',
      deleteLocation: 'Eliminar ubicación',
      threatAnalyticsEventSummary:
        'Muestra recuentos de eventos ocurridos dentro del radio de amenaza seleccionado.',
      crimeFilterDataType: `Cambiar entre crimen y disturbios:
      • Crimen: incluye eventos de amenaza como robo y homicidio.
      • Disturbios: incluye eventos de amenazas como disturbios y protestas.`,
      crimeFilterMapView: `Ver visualizaciones de mapas disponibles, que incluyen:
      • Eventos delictivos: eventos de amenazas trazados individualmente.
      • Crime Heatmap: puntos críticos de actividad de amenazas.
      • Distritos: actividad de amenaza en áreas administrativas dentro de un país, región o ciudad.`,
      crimeFilterQuintile: `Filtre los distritos según el nivel de amenaza en relación con otros distritos en esta ubicación.
      • El nivel de amenaza se basa en el número y la gravedad de los eventos en un distrito.
      • Los niveles de amenaza se clasifican en quintiles.
      • El azul claro indica el nivel de amenaza relativo más bajo (0-20 % de los distritos) y el rosa oscuro indica el nivel de amenaza relativo más alto (80-100 %).`,
      crimeFilterTypes:
        'Filtre los eventos de amenazas por tipo de delito o categoría. Los delitos violentos y los delitos contra la propiedad son categorizaciones resumidas que incluyen varios tipos de delitos.',
      crimeFilterQuarters:
        'Filtrar eventos de amenazas por trimestre de ocurrencia.',
      crimeFilterDay: 'Filtre los eventos de amenazas por hora del día.',
      crimeFilterDate:
        'Filtre los eventos de amenazas por intervalo de fechas.',
      threatAnalyticsSeverities: `Muestra el nivel de amenaza para el distrito seleccionado mes a mes en relación con otros distritos en esta ubicación.
      • El nivel de amenaza se basa en el número y la gravedad de los eventos en un distrito.
      • Los niveles de amenaza se clasifican en quintiles.
      • El azul claro indica el nivel de amenaza relativo más bajo (0-20 % de los distritos) y el rosa oscuro indica el nivel de amenaza relativo más alto (80-100 %).`,
      threatAnalyticsMonthly:
        'Muestra eventos de amenazas a lo largo del tiempo. Muestra el recuento de eventos mensuales y el recuento de eventos como un promedio móvil de 30 días para evaluar tanto la variación basada en el tiempo real como una línea de tendencia suavizada, respectivamente.',
      threatAnalyticsRolling:
        'Muestra eventos de amenazas a lo largo del tiempo. Muestra el recuento de eventos mensuales y el recuento de eventos como un promedio móvil de 30 días para evaluar tanto la variación basada en el tiempo real como una línea de tendencia suavizada, respectivamente.',
      threatAnalyticsHistorical:
        'Muestra la tendencia de amenazas estacionales utilizando el número promedio de eventos de amenazas mensuales durante los últimos tres años o más. El intervalo de fechas se muestra encima del gráfico.',
      threatLandscape:
        'Los datos más relevantes sobre las amenazas que afectan a un lugar.',
      featuredEvents:
        'Los disturbios más recientes y destacables ocurridos en un lugar.',
      notableEvents:
        'Los eventos de agitación más graves y destacados que han ocurrido en la granularidad seleccionada en los últimos tres meses a partir de la fecha más reciente seleccionada.',

      eventSummary:
        'Muestra el recuento de eventos que ocurrieron dentro de la granularidad seleccionada.',
      eventComposition:
        'Muestra la frecuencia de eventos que ocurrieron dentro de la granularidad seleccionada. Cuanto mayor sea la fuente, más frecuente será el evento.',
      eventBreakdown:
        'Muestra el desglose de eventos por categorías, subcategorías y hora del día.',
      eventTrend:
        'Muestra eventos de amenazas a lo largo del tiempo. Muestra ya sea el recuento mensual de eventos o el recuento diario de eventos, así como el recuento promedio general del período, el recuento promedio móvil y el recuento promedio mensual histórico para evaluar la variación real basada en el tiempo, la tendencia suavizada y los promedios estacionales.',
      changeTrend:
        'Muestra el cambio en eventos a lo largo del tiempo por fuente. Muestra el cambio mensual o trimestral en comparación con el período anterior o el año anterior.',
      categoryContributions:
        'Muestra eventos a lo largo del tiempo desglosados por categorías de eventos.',
      periodAverage:
        'Muestra el promedio del número de amenazas durante el período seleccionado dentro de la granularidad elegida.',
      severityScore:
        'Muestra el recuento de eventos de amenazas y los niveles de amenazas para el distrito seleccionado en relación con otros distritos en esta ubicación. \n • El nivel de amenaza se basa en el número y la gravedad de los eventos en un distrito. \n • Los niveles de amenaza se clasifican en quintiles. \n • El azul claro indica el nivel de amenaza relativo más bajo (0-20% de los distritos) y el rosa oscuro indica el nivel de amenaza relativo más alto (80-100%).',
      severityScoreTrend:
        'Muestra el nivel de amenaza desglosado por categorías para el distrito seleccionado mes a mes en relación con otros distritos en esta ubicación. \n • El nivel de amenaza se basa en el número y la gravedad de los eventos en un distrito. \n • Los niveles de amenaza se clasifican en quintiles. \n • El azul claro indica el nivel de amenaza relativo más bajo (0-20% de los distritos) y el rosa oscuro indica el nivel de amenaza relativo más alto (80-100%).',
      dataSourceTrend:
        'Muestra eventos a lo largo del tiempo desglosados por fuentes de datos.Las fuentes se ordenan por volumen de datos para el rango de fechas seleccionado.',
      dataSourceInsights:
        'Muestra información sobre matices de las fuentes de datos para la ubicación seleccionada.',
      locationInsights:
        'Muestra información sobre los matices de la generación de informes de datos para esta ubicación.',
      colorLegend:
        'Indica el nivel de amenaza en una ubicación que va desde bajo (azul claro) hasta alto (rosa oscuro). El nivel de amenaza se basa en el número y la gravedad de los eventos.',
      unrestFilterDataType: `Cambiar entre crimen y disturbios:
      • Crimen: incluye eventos de amenaza como robo y homicidio.
      • Disturbios: incluye eventos de amenazas como disturbios y protestas.`,
      unrestFilterMapView:
        'Ver ubicaciones de eventos de disturbios. Los pines corresponden a ubicaciones donde se han producido disturbios.',
      unrestFilterTypes:
        'Filtre los disturbios por tipo. Puede seleccionar un solo tipo de disturbios o varios tipos de disturbios al mismo tiempo.',
      unrestFilterDate: 'Filtra eventos por rango de fechas.',
      unrestLegendSummary:
        'Muestra un recuento de eventos de disturbios por tipo. Al hacer clic en la flecha blanca junto a cada tipo, se expandirá para mostrar los subtipos de eventos.',
      threatEventsDetail:
        'Muestra información adicional sobre cada evento de disturbios. Haga clic en la flecha blanca para mostrar los detalles, incluidos los actores involucrados, una descripción del evento y las fuentes de datos.',
      unrestLegendChart:
        'Muestra eventos de disturbios en incrementos mensuales. Cada incremento es un promedio de los últimos 3 meses.',
      navbarScreenShot: 'Captura de pantalla',
      saveLocation: 'Guardadar Ubication',
      cancelSaveLocation: 'Cancel',
      toggleFiltersPanelTooltip: 'Mostrar/Ocultar Painel de Filtros',
      filterPriorityRating:
        'Filtrar ubicaciones guardadas por calificación de prioridad',
      filterTags: 'Filtrar ubicaciones guardadas por etiquetas',
      sourceTypeFilter:
        'Filtra eventos de amenazas por fuente de datos. Las fuentes se ordenan por volumen de datos para todo el rango de tiempo cubierto.',
      unassignedSource:
        'Los datos recién agregados suelen ser ingestados como no asignados. Los datos con tipo de fuente no asignada se actualizan mensualmente.',
      comingSoon: 'Muy pronto',
      changeMinimum: 'El cambio se basa en un radio mínimo de 1 milla.',
      percentageChangeByLocationType:
        'Agrupa el tipo de ubicación por grado de cambio. Modifique el tipo de ubicación editando el tipo de cualquier ubicación guardada.',
      percentageChangeByLocationTag:
        'Etiqueta de ubicación de grupos por grado de cambio. Modifique editando las etiquetas de cualquier ubicación guardada.',
      countryScoreBreakdown:
        'Country BaseScore representa la amenaza relativa y la estabilidad de los países en una escala estandarizada de 0 (mejor) a 100 (peor).',
    },
    horizontalNavBar: {
      breadcrumbs: {
        map: 'Mapa',
        'my-locations/list': 'Mis Ubicaciones',
        user: 'Perfil',
        home: 'Inicio',
        'my-locations/analysis': 'Panel de Control de Mis Ubicaciones',
      },
    },
    savedLocations: {
      locationOverview: 'Visión General de la Ubicación',
      threatProfile: 'Perfil de Amenaza',
      degreeOfChange: 'Grado de Cambio',
      filtersTitle: 'Filtro de Ubicación',
      positionUpdated: '¡Posición actualizada! ¿Guardar estos cambios?',
      threatChange: 'Cambio Promedio por Categoría de Amenaza',
      threatLandscape: 'Paisaje de Amenazas',
      indeterminateCaption:
        'El gráfico incluye cambios para {count1} ubicaciones.',
      emptyThreatLandscape:
        'No hay suficientes datos para el Cambio y el Paisaje de Amenazas para esta ubicación particular',
      showCharts: 'Mostrar Gráficos',
      barChartTitle: 'Recuento de Ubicaciones por Cambio Porcentual',
      magnitudeOfChange: 'Magnitud del Cambio',
      locations: 'ubicaciones',
      citiesTableTitle: 'Città Classificate',
      locationsTableTitle: 'Ubicaciones Clasificadas',
      changeMapTitle: 'Cantidad y Dirección del Cambio',
      changeColorScaleLabel: 'Dirección del Cambio',
      changeSizeScaleLabel: 'Magnitud del Cambio',
      changeTrendHeaders: {
        cityName: 'Ciudades Clasificadas',
        locationName: 'Ubicaciones Clasificadas',
        change: 'Cambio',
        savedLocationCount: '# de Ubicaciones',
        lastVisited: 'Última Visita',
        score: BASE_SCORE,
      },
      remaining: 'Restante',
      none: 'Ninguno',
      addNewTag: 'Crear nueva etiqueta',
      maxTags: 'Número máximo de etiquetas permitidas por ubicación: 15',
      allTags: 'Todos Etiquetados',
      yesLabel: 'Sí',
      noLabel: 'No',
      createdLocationLabel: '¡Ubicación guardada!',
      updatedLocationLabel: '¡Ubicación actualizada!',
      deletedLocationLabel: '¡Ubicación eliminada!',
      collapseModalLabel: 'Ver menos campos',
      expandModalLabel: 'Ver más campos',
      saveLocationLabel: '¿Guardar esta ubicación?',
      unrestLabel: 'Disturbio',
      crimeLabel: 'Crimen',
      viewMapTooltip: 'Ver el mapa',
      viewDetailsTooltip: 'Ver detalles',
      filterLocationsTitle: 'Filtrar Ubicaciones Guardadas',
      // Control Actions
      closeButtonText: 'Cerrar',
      filterButtonText: 'Filtrar',
      sortButtonText: 'Ordenar por',
      searchInputText: 'Buscar Ubicaciones Guardadas',
      cancelButtonText: 'Cancelar',
      editButtonText: 'Editar',
      selectAll: 'Seleccionar Todo',
      moreDetails: 'Detalles',
      saveButton: {
        text: 'Guardar',
        loading: 'Guardar',
      },
      deleteButton: {
        text: 'Borrar',
        loading: 'Borrando',
      },
      // Filter Categories
      regionLabel: 'Región',
      countryLabel: 'País',
      locationTypeLabel: 'Tipos de Ubicación',
      priorityRatingLabel: 'Clasificación de Prioridad',
      includeUnratedLabel: 'Incluir ubicaciones sin clasificar',
      tagsLabel: 'Etiquetas',
      office: 'Oficina',
      hotel: 'Hotel',
      event: 'Evento',
      restaurant: 'Restaurante',
      travel: 'Viaje',
      warehouse: 'Almacén',
      parking: 'Estacionamiento',
      service_area: 'Área de Servicio',
      point_of_interest: 'Punto de Interés',
      facility: 'Instalación',
      retail: 'Venta al por menor',
      other: 'Otro',

      // Detail/Edit Drawer
      locationNotFound: 'Ubicación No Encontrada',
      locationNameLabel: 'Nombre del Lugar',
      displayAddressLabel: 'Mostrar Dirección',
      coverageLabel: 'Cobertura',
      customerIdLabel: 'ID de Ubicación del Cliente',
      dateRangeLabel: 'Rango de Fechas',
      latitudeLabel: 'Latitud',
      longitudeLabel: 'Longitud',
      locationTypePlaceholder: 'Seleccionar Tipo de Ubicación',
      // Saved Locations List Tile Columns
      location: 'Ubicación',
      coverageDates: 'Fechas de cobertura',
      area: 'Área',
      rating: 'Calificación',
      tags: 'Etiquetas',
      // More
      locationTypeTooltip:
        'Filtrar ubicaciones guardadas por tipo de ubicación',
      visibilityTooltip: 'Filtra las ubicaciones guardadas por su visibilidad',
      sortOptions: {
        name: 'Nombre',
        customerLocationId: 'ID de Ubicación',
        customerRating: 'Clasificación de Prioridad',
        change: 'Cambio',
        changeTooltip: `Todas las opciones se basan en cálculos de líneas de tendencia a nivel de ciudad y están normalizadas para comparar entre ubicaciones:
        • Cambio mensual interanual:
        p.ej. Agosto de 2023 frente a agosto de 2022
        • Cambio año tras año:
        p.ej. Septiembre 2022 - Agosto 2023 vs Septiembre 2021 - Agosto 2022
        • Índice de cambios
        Una combinación de tres métricas de cambio (el cambio más reciente mes a mes, trimestre a trimestre y trimestre a año) para incluir patrones de cambio a corto, mediano y largo plazo.`,
        changeIndex: 'Índice de cambio',
        oneMonthPeriodOverYear: 'Cambio mensual interanual',
        twelveMonthPeriodOverPeriod: 'Cambio año tras año',
      },
      visibilityLabel: 'Visibilidad',
      visibilityOptions: {
        private: 'Privado',
        shared: 'Compartido',
      },
      all: 'Todo',
      userCannotEdit:
        'Solo los propietarios y administradores tienen permiso para editar una ubicación guardada compartida.',
      gridViewTooltip: 'Vista en Cuadrícula',
      listViewTooltip: 'Vista de La Lista',
      sortOrderTooltip: 'Orden de Clasificación',
      filtersTooltip: 'Abrir Filtros',
      noSavedLocationsFirst:
        'No hay ubicaciones guardadas. Retire algunos filtros o',
      clickHere: 'haga clic aquí',
      noSavedLocationsSecond: 'para ir al mapa.',
      goToLocation: 'Ir a Ubicación',
      noCoverage: 'Sin Cobertura',
      additionalTags: 'Etiquetas Adicionales',
      coverageFrom: 'Cobertura de',
      change: 'Cambio',
      changeNotAvailable: 'El cambio no está disponible para esta ubicación',
      priorityRating: 'Clasificación de Prioridad',
      percentageChangeByLocationType:
        'Porcentaje de cambio por etiqueta de ubicación',
      percentageChangeByLocationTag:
        'Porcentaje de cambio por tipo de ubicación',
      breakdownByLocationType: 'Desglose por tipo de ubicación',
      breakdownByLocationTag: 'Desglose por etiqueta de ubicación',
    },
    user: {
      tab1: 'Perfil de Usuario',
      tab2: 'Cambiar Contraseña',
      tab3: 'Administrar Token de API',
      tab4: 'Administrar Usuarios',
      title: 'Configuración de la Cuenta',
      tempTitle: 'Perfil del Usuario',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',
      emailLabel: 'Correo electrónico',
      languageLabel: 'Idioma',
      unitsLabel: 'Unidades',
      saveChanges: 'Guardar Cambios',
      allChangesSaved: 'Todos los Cambios Guardados',
      validationLabel: 'Se requiere el nombre',
      textFieldLabel: 'Nombre de la clave API',
      copyLabel: 'Copiar',
      deleteLabel: 'Eliminar',
      createLabel: 'Generar',
      warningText:
        'Se ha generado una clave API personal para acceder a la API de operaciones base. Asegúrese de copiar su clave API ahora. Una vez que se cierre esta ventana, no se podrá recuperar.',
      deleteText: 'Está a punto de eliminar la clave',
      createTitle: 'Generar Clave API',
      deleteAction: 'Eliminar Clave',
      deleteFeedback: 'Clave API Eliminada',
      deleteTitle: 'Eliminando la clave API...',
      copyTitle: 'Clave API Generada con Éxito',
      createFeedback: 'Clave API Generada',
      copyFeedback: '¡Clave API copiada al portapapeles!',
      createButton: 'Generar Nuevo Token de API',
      nameLabel: 'Nombre de la Clave',
      secretLabel: 'Clave Secreta de la API',
      dateLabel: 'Creado en',
      actionsLabel: 'Acciones',
      maxValidation: 'El campo de texto no debe exceder los 30 caracteres',
    },

    threatAnalytics: {
      overallCrime: 'Cambio general',
      saveDefaultLabel: 'Guardar como predeterminado',
      changeMapTitle: 'Cantidad y Dirección del Cambio',
      acledAttribution:
        '** Base Operations adquiere datos de miles de fuentes. Aplicamos técnicas avanzadas de estadísticas, geografía e IA para validar y mejorar estos datos. Algunos datos obtenidos para esta ubicación fueron proporcionados por: Armed Conflict Location & Event Data Project (ACLED)',
      noThreatEvents:
        'No se han informado eventos de amenazas en esta ubicación para los tipos de amenazas y el período de tiempo seleccionados.',
      titleSeverity: 'Tendencia de Puntuación de Severidad',
      titleIncidents: 'Incidentes',
      titleIntro: 'Análisis de Amenazas para ',
      tabDistrict: 'Distrito',
      tabProvince: 'Provincia',
      tabRadius: 'Radio',
      tabCity: 'Ciudad',
      tabCountry: 'País',
      titleDistrict: 'el Distrito',
      titleLocation: 'Ubicación',
      titleThreatAnalytics: 'Análisis de Amenazas',
      titleCrimeEvents: 'Eventos de amenazas',
      textRadius: 'Radio de',
      textRadiusPrefix: 'dentro de un radio de ',
      textRadiusSuffix: '',
      titleJoin: 'en ',
      quintileTitle: 'Quintiles de Amenaza',
      quintileSubtitle: 'Quintile de Amenaza: ',
      chartSubtitleStart: 'De ',
      chartSubtitleJoin: ' al ',
      openAnalytics: 'Panel de análisis',
      closeAnalytics: 'Panel de análisis',
      chart: 'Gráfico',
      averageType: 'Tipo de Promedio',
      aggregationPeriod: 'Período de agregación',
      period: 'Periodicidad',
      chartTitle1: 'Recuento de Eventos por Categoría de Amenaza',
      chartTitle2: 'Composición del evento',
      chartTitle3: 'Desglose de la Hora Del Día',
      chartTitle4: 'Tendencia de Eventos Mensuales',
      chartTitle5: 'Tendencia de Cambio',
      chartTitle9: 'Puntuación de gravedad',
      chartTitle10: 'Tendencia de la puntuación de gravedad',
      chartTitle11: 'Eventos Mensuales por Fuente',
      chartTitle12: 'Promedio de Eventos por Mes',
      chartTitle13: 'Frecuencia de Eventos por Categoría de Amenaza',
      chartTitle14: 'Tendencia Diaria de Eventos',
      chartTitle15: 'Eventos Promedio por Mes del Año',
      chartTitle16: 'Eventos Promedio por Día de la Semana',
      chartTitle17: 'Eventos Promedio por Hora del Día',
      chartTitle18: 'Recuento de Ubicaciones por BaseScore',
      chartTitle19: 'Recuento de Ubicaciones por Cambio Porcentual',
      chartTitle20: 'Recuento de Ubicaciones por Tipo y Cambio',
      chartTitle21: 'Recuento de Ubicaciones por Etiqueta y Cambio',
      chartTitle22: 'Recuento de Ubicaciones por Tipo y BaseScore',
      chartTitle23: 'Recuento de Ubicaciones por Etiqueta y BaseScore',
      chartTitle24: 'Desglose por Tipo de Ubicación',
      chartTitle25: 'Desglose por Etiqueta de Ubicación',
      chartTooltip: 'Agrupa tipos de ubicaciones por grado de cambio',
      chartTooltip1: 'Agrupa etiquetas de ubicaciones por grado de cambio',
      chartTooltip2: 'Agrupa tipos de ubicaciones por grado de cambio',
      chartTooltip3: 'Agrupa tipos de ubicaciones por BaseScore',
      chartTooltip4: 'Agrupa etiquetas de ubicaciones por BaseScore',
      chartTooltip5: 'Agrupa ubicaciones por tipo de ubicación',
      chartTooltip6: 'Agrupa ubicaciones por etiqueta de ubicación',
      locationInsightsTitle: 'Información de la Ubicación',
      takeAwaysTitle: 'Cambio Porcentual',
      dataObservationsTitle: 'Análisis de la fuente de datos',
      threatEventsTableTitle: 'Detalles de eventos de amenazas',
      notableEventsTitle: 'Eventos Notables',
      numberOfLocationsByChange: 'Número de ubicaciones por cambio',
      numberOfLocationsByChangeSubtitle: 'Cambio general',
      citiesWithMostChange: 'Las 10 ciudades principales con más cambios',
      changeTrendValues: {
        higher: 'más alto',
        lower: 'más bajo',
      },
      label1: 'Crímenes',
      label2: 'Promedio',
      label3: 'Severidad',
      label4: 'Crimen Total',
      label5: '30-Dias Promedio Móvil',
      label6: '3 Años',
      label7: '4 Años',
      label8: '5 Años',
      label9: 'Rango Completo',
      label10: 'Mensual',
      label11: 'Recuento de eventos',
      label12: 'Amenazas',
      value1: 'Enero',
      value2: 'Febrero',
      value3: 'Marzo',
      value4: 'Abril',
      value5: 'Mayo',
      value6: 'Junio',
      value7: 'Julio',
      value8: 'Agosto',
      value9: 'Septiembre',
      value10: 'Octubre',
      value11: 'Noviembre',
      value12: 'Diciembre',
      topicSelectionText: 'Seleccione un tema para el análisis',
      eventsWithDescription: 'Eventos solo con descripción',
      panelType: {
        overview: 'Visión General',
        analysis: 'Análisis de Tendencias',
        changeAnalysis: 'Análisis de Cambio',
        breakdown: 'Desglose de Amenazas',
        notableEvents: 'Eventos Notables',
        eventList: 'Eventos',
        source: 'Fuente de Datos',
        comparative: 'Comparativo',
        countryAnalysis: 'Análisis de País',
        forecast: 'Pronóstico de Tendencias',
      },
      panelTitles: {
        overview: 'Descripción General del Panorama de Amenazas',
      },
      overviewSection: {
        threatScore: BASE_SCORE,
        percentChange: 'Cambio porcentual',
        monthlyEvents: 'Eventos reportados',
        monthlyEventsSuffix: 'mes',
        population: 'Población',
        area: 'Área',
      },
      reportButton: 'Generar Informe de Ubicación',
      goToEventList: 'Ir a la lista de eventos para eventos adicionales',
      countryAnalysis: {
        threatScore: `País ${BASE_SCORE}`,
        countryScoreBreakdown: 'Desglose de las Amenazas por País',
        overallScoreWeights: 'Ponderación de la puntuación general',
        threatFrom: 'Amenaza de...',
        score: 'Puntuación',
        quintile: 'Quintil',
      },
      forecastTrendSection: {
        eventCountLegend: 'Conteo de Eventos',
        trendLineLegend: 'Línea de Tendencia',
        forecastLegend: 'Pronóstico',
        confidence75Legend: 'Confianza del 75%',
        confidence90Legend: 'Confianza del 90%',
        forecastTooltip: 'Pronóstico',
        trendTooltip: 'Tendencia',
        countTooltip: 'Conteo',
        confUb90Tooltip: '90% Superior',
        confUb75Tooltip: '75% Superior',
        confLb75Tooltip: '75% Inferior',
        confLb90Tooltip: '90% Inferior',
      },
    },
    navigation: {
      search: 'Buscar',
      filter: 'Filtrar',
      home: 'Hogar',
      saved: 'Guardado',
      map: 'Mapa',
      help: 'Ayuda',
      settings: 'Ajustes',
      login: 'Cuenta',
      myLocationsAnalysis: 'Panel de Control de Mis Ubicaciones',
    },
    myLocationsAnalysis: {
      keyTakeaways: {
        title: 'Puntos Clave',
        count: 'Ubicaciones Guardadas',
        recentCount: 'Ubicaciones Revisadas',
        averageThreatScore: 'Puntuación Base Promedio',
        averageChange: 'Cambio Promedio',
      },
      downloadChartImages: 'Descargar Imágenes de Gráficos',
      downloadSavedLocationReport:
        'Descargar el Informe de Ubicación Guardada como CSV',
      settings: {
        aggregationOptions: ['Mensual', 'Trimestral', 'Anual'],
        periodOptions: ['Período sobre Período', 'Año sobre Año'],
        listTypeOptions: {
          savedLocations: 'Ubicaciones',
          cities: 'Ciudades',
        },
        locationBreakdownOptions: {
          type: 'Tipo',
          tag: 'Etiqueta',
        },
        changeTitle: 'Cambio',
        listTitle: 'Lista',
        locationBreakdownTitle: 'Desglose de ubicaciones',
        changeTooltip: 'El cambio se basa en un radio mínimo de 1 mi.',
      },
      filters: {
        title: 'Filtros de ubicación',
        allTypesLabel: 'Tipos de ubicación',
        allTagsLabel: 'Etiquetas',
        allDegreeOfChangesLabel: 'Grado de cambio',
        degreeOfChangeOptions: [
          {name: 'Gran Aumento', value: 'large_increase'},
          {name: 'Aumentar', value: 'increase'},
          {name: 'Cambio Menor', value: 'no_change'},
          {name: 'Disminuir', value: 'decrease'},
          {name: 'Gran Disminución', value: 'large_decrease'},
          // {name: 'Indeterminado', value: 'indeterminate'},
        ],
        threatScoreOptions: [
          {name: '>80', value: 'very_high'},
          {name: '60 - 79', value: 'high'},
          {name: '40 - 59', value: 'medium'},
          {name: '20 - 39', value: 'low'},
          {name: '<20', value: 'very_low'},
        ],
      },
    },
    searchModal: {
      savedLocationsTitle: 'Ubicaciones guardadas',
      gettingStarted: 'Empezando',
      recentTranslation: 'Reciente',
      searchResults: 'Resultados de la Busqueda',
      seeSavedLocations: 'Ver en La Página de Ubicación Guardada',
      more: 'más',
    },
    actionZoom: {
      zoomIn: 'Acercar',
      zoomOut: 'Alejar',
    },
    legendActions: {
      threatLevelTranslation: 'Nivel de Amenaza',
      highScore: 'Alto',
      lowScore: 'Bajo',
      mapLayerLabels: {
        heatmap: 'Mapa de Calor',
        events: 'Eventos',
        districts: 'Fronteras',
        countries: 'Fronteras',
        scores: 'Puntuaciones',
        population: 'Población',
        change: 'Cambio',
        none: 'Ninguno',
      },
      mapStyleLabels: {
        map: 'Claro',
        dark: 'Oscuro',
        satellite: 'Satélite',
      },
      mapMarkersLabels: {
        cities: 'Pines de Ciudades',
        savedLocations: 'Pines de Sitios',
      },
      stylesTitle: 'Estilos de Mapa',
      layersTitle: 'Capas de Datos',
      markersTitle: 'Marcadores',
    },
    help: {
      userGuide: 'Guía de Evaluación',
      restAPIDocumentationTranslation: 'Documentación de la API REST',
      threatAssessmentTemplateI: 'Plantilla de Evaluación de Amenazas I',
      threatAssessmentTemplateII: 'Plantilla de Evaluación de Amenazas II',
      threatAssessmentTemplateIII: 'Plantilla de Evaluación de Amenazas III',
      threatAssessmentTemplateIV: 'Plantilla de Evaluación de Amenazas IV',
    },
    userFeedback: {
      message: '¿Fue esto útil?',
    },
    dataNotification: {
      message:
        'La cobertura de amenazas no está disponible actualmente en esta área. Por favor busque otra ubicación',
    },
    units: {
      mile: 'milla',
      kilometer: 'kilómetro',
      miles: 'Millas',
      kilometers: 'Kilómetros',
      meters: 'metros',
    },
    dateSkip: {
      month: '1 Mes',
      year: '1 Año',
      skipForward: 'Saltar hacia adelante',
      skipBackward: 'Saltar hacia atrás',
    },
    dateMessageValidation: {
      lessFrom:
        'La fecha de inicio no debe ser menor a la fecha mínima de la ciudad actual.',
      greaterFrom:
        'La fecha de inicio no debe ser mayor a la fecha máxima de la ciudad actual.',
      lessTo:
        'La fecha final no debe ser menor a la fecha mínima de la ciudad actual.',
      greaterTo:
        'La fecha final no debe ser mayor a la fecha máxima de la ciudad actual.',
    },
    chartQuintile: {
      quintile: 'Quintil',
      incidents: 'Incidentes',
      date: 'Fecha',
      threatCategory: 'Categoría de Amenaza',
      eventCount: 'Recuento de eventos',
      severity: 'Gravedad',
    },
    chartOptions: {
      copyImageLabel: 'Copiar Imagen',
      downloadImageLabel: 'Descargar Imagen',
      copyCSVLabel: 'Copiar como CSV',
      downloadCSVLabel: 'Descargar como CSV',
    },
    changeOverview: {
      yearOverYearChange:
        '{increase} en eventos reportados en {currentPeriod} comparado con el mismo {period} del año pasado',
      periodOverPeriodChange:
        '{increase} en eventos reportados en {currentPeriod} comparado con el {period} anterior',
      yearOverYearNoChange:
        'Sin cambios en eventos reportados en {currentPeriod} comparado con el mismo {period} del año pasado',
      periodOverPeriodNoChange:
        'Sin cambios en eventos reportados en {currentPeriod} comparado con el {period} anterior',
      increase: 'aumento',
      decrease: 'disminución',
      month: 'mes',
      quarter: 'trimestre',
      change: 'Cambio',
      percentChange: 'Cambio porcentual',
    },
    chartSelection: {
      forecast3Months: 'Pronóstico de 3 meses',
      forecast6Months: 'Pronóstico de 6 meses',
      forecast1Year: 'Pronóstico de 1 año',
      all: 'Todo',
      primaryCategories: 'Categorías principales',
      subCategories: 'Subcategorías',
      threatCount: 'Recuento de amenazas',
      percentOfTotal: 'Porcentaje del total',
      average: 'Promedio',
      rollingAverage: 'Promedio móvil',
      trendline: 'Línea de Tendencia',
      historicalAverage: 'Promedio Mensual Histórico',
      daily: 'Diario',
      percent: 'Porcentaje',
      monthOfYear: 'Mes del Año',
      dayOfWeek: 'Día de la Semana',
      timeOfDay: 'Hora del Día',
      rate: 'Tasa',
      periodOverPeriod: 'Período sobre Período',
      yearOverYear: 'Año sobre Año',
      percentage: 'Porcentaje',
      changeMetric: 'Métrica de Cambio',
      normalize: 'Normalizar',
      change: 'Cambiar',
      source: 'Fuente',
      monthlyChange: 'Cambio Mensual',
      quarterlyChange: 'Cambio Trimestral',
      incidentsPerMonth: 'incidentes por mes',
      incidentsPerQuarter: 'incidencias por trimestre',
    },
    chartLegend: {
      legend: 'Leyenda',
      noTagsLabel1:
        'No se han guardado etiquetas para tus ubicaciones, añade etiquetas a las ubicaciones en la',
      noTagsLabel2: 'Lista de Mis Ubicaciones',
      noTagsLabel3: 'vista.',
    },
    snackbar: {
      cityWarningTitle: 'Esta ubicación llegará pronto.',
      cityWarningDesc: '¿Preguntas? Comuníquete con ',
      cancelButton: 'Descartar',
    },
    snackbarMessages: {
      downloadSuccess: 'Descargado exitosamente',
      fetchingReport: 'Obteniendo reporte...',
      reacherLimitExport:
        'Has alcanzado el número máximo de exportaciones, por favor inténtalo de nuevo en 8 horas.',
      somethingWentWrong: 'Algo salió mal',
      copiedToClipboard: 'Copiado correctamente al portapapeles',
      fetchingData: 'Obteniendo datos del gráfico...',
    },
    updateNotification: {
      message: 'Hay una nueva versión disponible.',
      action: 'Pruebalo ahora!',
    },
    navbar: {
      mapStyleTooltip: 'Vista del mapa',
      languageTooltip: 'Idioma',
      more: 'Más',
      share: 'Compartir',
      copyToClipboard: 'Copiar al portapapeles',
    },
    reCenterComp: {
      fabTooltip: 'Ir a la experiencia de itinerancia gratuita',
      removeBoundaries: 'Eliminar Límites',
    },
    threatAnalyticsComp: {
      fabTooltip: 'Abrir panel de análisis de amenazas',
    },
    home: {
      homeHeader: 'La inteligencia granular contra amenazas comienza aquí',
      searchboxPlaceholder: 'Buscar una ubicación',
      viewMapButton: 'Ver Mapa',
    },
    legendQuintile: {
      emptyTableLabel:
        'No hay amenazas dentro de {r} {u} desde la ubicación en la que se hizo clic.',
      threatEvent: 'Detalles del evento de amenaza',
      summaryTable: 'Resumen',
      detailsTable: 'Detalles',
      severityScoreTrend: 'indicador de gravedad',
      incidents: 'Incidentes',
      summaryHeaders: {
        eventType: 'Categoria del Evento',
        districtCount: 'Total por Distrito',
        areaCount: 'Total por Zona',
      },
      detailsHeaders: {
        eventDate: 'Fecha / Hora',
        subEventType: 'Tipo De Evento',
        interaction: 'Interacción',
        fatalities: 'Muertes',
        granularity: 'Granularidad',
        actor1: 'Participante 1',
        actor2: 'Participante 2',
        description: 'Descripción',
        id: 'ID Unica',
        source: 'Fuente',
        none: 'Ningun',
      },
      granularity: {
        street: 'Nivel de la Calle',
        district: 'Nivel de Distrito',
        city: 'Nivel de la Ciudad',
      },
      rowsPerPage: 'Filas por página',
      of: 'de',
      moreThanLabel: 'más de',
      chart: {
        attributionText:
          'Datos de Armed Conflict Location & Event Data Project (ACLED); https://www.acleddata.com',
        lineChartLabel: 'Media móvil de tres meses',
        lineChartLabelShort: 'Media',
        barChartLabel: 'Total',
      },
    },
    tooltip: {
      city: 'Ciudad',
      crime: 'Crimen',
      views: 'Vista del Mapa',
      lonLatText: 'Incidentes, Mapa de Calor',
      neighborhoodText: 'Distritos',
      to: 'a',
      dates: 'Rango de fechas',
      types: 'Tipo de crimenes',
      unrest: 'Disturbio',
      subTypes: 'Tipos de eventos de disturbios',
      addFavorite: 'Add to favorites',
      removeFavorite: 'Remove from favorites',
      loading: 'Cargando...',
      noData: 'Datos no disponibles',
    },
    legendPopup: {
      quintile: {
        title: 'Nivel de Amenaza',
        info: {
          0: 'Alto',
          1: 'Medio Alto',
          2: 'Medio',
          3: 'Medio Bajo',
          4: 'Bajo',
        },
      },
      crimeHeatmap: {
        title: 'Escala de Amenaza',
        info: {
          10: 'Alto',
          9: '',
          8: '',
          7: '',
          6: '',
          5: '',
          4: '',
          3: '',
          2: '',
          1: 'Bajo',
        },
      },
    },
    crimeQuantile: 'Quintil de crimen',
    riskQuantile: 'Quintil de Amenaza',
    quantiles: {
      1: 'Riesgo bajo',
      2: 'Riesgo medio bajo',
      3: 'Riesgo medio',
      4: 'Riesgo medio alto',
      5: 'Riesgo alto',
    },
    mapStyle: 'Estilo del mapa',
    map: 'Mapa',
    light: 'Light',
    satellite: 'Satélite',
    dark: 'Mapa Oscuro',
    cuadrante: 'Cuadrante',
    municipio: 'Municipio',
    zona: 'Zona',
    sector: 'Sector',
    contact: 'Contacto',
    clearResults: 'Resultados claros',
    downloadNow: 'Descargar',
    pleaseEnterYourEmailAddressBelowAndWeWillSendYouInformationToChangeYourPassword:
      'Ingrese su dirección de correo electrónico y le enviaremos información para cambiar su contraseña.',
    sendRecoveryEmail: 'Enviar correo electrónico de recuperación',
    email: 'Correo electrónico',
    login: 'Iniciar sesión',
    'sign-up': 'Registrarse',
    'forgot-password': 'Olvidé mi contraseña',
    download: 'Descargar',
    settings: 'Ajustes',
    account: 'La Cuenta',
    signIn: 'Iniciar sesión',
    signUp: 'Registrarse',
    forgotPassword: 'Olvidé mi contraseña',
    confirmSignUp: 'Confirmar Registrarse',
    show: 'Mostrar',
    hide: 'Ocultar',
    lastUpdated: 'Última actualización',
    neverReported: 'Nunca reportado',
    enterLocation: 'Buscar en el mapa',
    dismiss: 'Despedir',
    sendANotification: 'Enviar una Notificación',
    isThisAnEmergencyThatRequiresCheckIn:
      '¿Es una emergencia que requiere registrarse?',
    cancel: 'Cancelar',
    send: 'Enviar',
    thereAreNoTeamMembersCurrentlyInThisCity:
      'Actualmente no hay miembros del equipo en esta ciudad.',
    sendTo: 'Enviar a:',
    subject: 'Tema',
    message: 'Mensaje',
    messageSent: '¡Gracias! Su notificación ha sido enviada.',
    messageSentTitle: 'Información',
    emergencyContacts: 'Contactos de Emergencia',
    Name: 'Nombre',
    Position: 'Posición',
    Description: 'Descripción',
    Email: 'Email',
    Phone_Number: 'Número de teléfono',
    timeFromNotification: 'Tiempo desde la notificación (horas)',
    usersCheckingIn: 'Usuarios que responden',
    percentOfUsersResponding: 'Porcentaje de usuarios que respondieron',
    usersReportingAsSafe: 'Usuarios informando como seguros',
    usersReportingAsUnsafe: 'Usuarios que se reportan como inseguros',
    usersNotReporting: 'Usuarios que no informan:',
    usersNotified: 'Usuarios notificados',
    usersReportedSafe: 'Usuarios reportados seguros:',
    usersNeedingHelp: 'Usuarios que necesitan ayuda:',
    averageResponseTime: 'Tiempo promedio de respuesta',
    reportsWithinFirst3Hours: 'Informes dentro de las primeras 3 horas:',
    reportsWithinFirst12Hours: 'Informes dentro de las primeras 12 horas::',
    reportsWithinFirst24Hours: 'Informes dentro de las primeras 24 horas::',
    reportsWithinFirst72Hours: 'Informes dentro de las primeras 72 horas::',
    reportsViaApp: 'Informes a través de la aplicación:',
    reportsViaEmail: 'Informes por correo electrónico:',
    noNotificationsToShow: 'No hay notificaciones para mostrar.',
    untitledNotification: 'Notificación sin título',
    notificationReportsFor: 'Informes de notificación para',
    chooseANotification: 'Elige una notificación',
    notificationHistory: 'Historial de notificaciones',
    menuSignOut: {
      signOut: 'Desconectar',
      areYouSureYouWantToSignOutOfBaseOperations:
        '¿Está seguro de que desea su cerrar sesión en Base Operations?',
      changePasswordEmail:
        'El link para el cambio de contraseña se ha enviado a tu correo. Por favor verifica tu bandeja de entrada para continuar el proceso.',
      changePasswordOption: 'Cambiar contraseña',
      yes: 'Si',
      no: 'No',
      userSettings: 'Configuración de usuario',
    },
    allTeamMembers: 'Todos los miembros del equipo',
    Role: 'Papel',
    peopleSearch: 'Búsqueda de Personas',
    teamMembersInThisCity: 'Miembros del equipo en esta ciudad',
    categories: 'Categorías',
    dates: 'Rango de fechas',
    hours: 'Horas',
    searchByNameOrEmail: 'Buscar por nombre o correo electrónico',
    findPeople: 'Encontrar gente',
    from: 'De',
    to: 'A',
    updatingFilters: 'Actualización de filtros',
    applyDateFilters: 'Aplicar filtros de fecha',
    applyTimeFilters: 'Aplicar filtros de tiempo',
    applyCrimeFilters: 'Aplicar filtros de crimen',
    Harassment: 'Acoso',
    Theft: 'Robo',
    'Armed Robbery': 'Robo con arma',
    'Car Robbery': 'Robo de coche',
    Assault: 'Asalto',
    ViolentCrimes: 'Crímenes Violentos',
    Extortion: 'Extorción',
    Kidnapping: 'Secuestro',
    Rape: 'Delitos sexuales',
    Vandalism: 'Vandalismo',
    Piracy: 'Piratería',
    'Drug Offenses': 'Delitos de Drogas',
    Fraud: 'Fraude',
    'Organized Crime': 'Actividad de crimen organizado',
    Homicide: 'Homicidio',
    Other: 'Delitos Menores',
    Terrorism: 'Terrorismo',
    'Updating Filters': 'Actualización de Filtros',
    'Apply Crime Filters': 'Aplicar Filtros de Crimen',
    teamMember: 'Miembro del Equipo',
    status: 'Estado',
    location: 'Ubicación',
    companyContacts: 'Contactos de la Empresa',
    companyLocations: 'Ubicaciones de la Empresa',
    worldResources: 'Recursos Globales', // NEED UPDATE "Change "Global Resources" to "In-Country Resources"
    address: 'La dirección',
    latitude: 'Latitud',
    longitude: 'Longitud',
    actions: 'Acciones',
    pinned: 'Clavado',
    typemembername: 'Escriba el nombre del miembro',
    admin: 'Administrador',
    officer: 'Oficial',
    employee: 'Empleado',
    delete: 'Eliminar',
    language: 'Idioma',
    none: 'Ninguno',
    sent: 'Enviar',
    by: 'Por',
    atunkowntime: 'Un tiempo desconocido',
    unknown: 'Desconocido',
    tablelocalization: {
      body: {
        emptyDataSourceMessage: 'No hay ubicaciones para mostrar',
        addTooltip: 'Añadir',
        deleteTooltip: 'Eliminar',
        editTooltip: 'Editar',
        filterRow: {
          filterTooltip: 'Filtrar',
        },
        editRow: {
          deleteText: '¿Estás seguro que quieres eliminar esto?',
          saveTooltip: 'Salvar',
          cancelTooltip: 'Cancelar',
        },
      },
      toolbar: {
        searchTooltip: 'Buscar',
        searchPlaceholder: 'Buscar',
      },
      header: {
        actions: 'Acciones',
      },
      pagination: {
        nextLabel: 'siguiente',
        previousLabel: 'anterior',
        labelDisplayedRows: ' {from}-{to} de {count}',
        firstTooltip: 'Primera Página',
        previousTooltip: 'Página Anterior',
        nextTooltip: 'Siguiente Página',
        lastTooltip: 'Última Página',
        showing: 'Mostrando',
      },
    },
    uploadPhotoModal: {
      addPhoto: 'Subir Foto',
      deletePhoto: 'Eliminar Foto',
      saveChange: 'Guardar Cambios',
      changePhoto: 'Cambiar Foto',
    },
    timeRead: 'Tiempo leído',
    responseMethod: 'Método de respuesta',
    lastLocationUpdate: 'Última actualización ',
    deepLinkMessage:
      'Hola usuario de Base Operations!<br/>Estás viendo esta página porque tu navegador web no admite enlaces a nuestra aplicación.<br/><br/>Abra la aplicación Base Operations y haga clic en el icono "Emergencia" para marcarlo como "Seguro" o "En peligro".<br/><br/>¡Gracias!<br/>El equipo Base Operations<br/><br/>',
    filters: {
      gtmSwitchLabel: 'Activar BaseGTM',
      selectDeselectAll: 'Seleccionar/Deseleccionar Todo',
      crimesNumber: ' Eventos a la vista',
      selectAll: 'Seleccionar Todo',
      violentCrime: 'Crimen violento',
      propertyCrime: 'Crimen de propiedad',
      boundariesLabel: 'Límites',
      cityHoodsLabel: 'Distritos',
      countryHoodsLabel: 'Provincias',
      eventsLabel: 'Eventos',
      heatmapLabel: 'Mapa de Calor',
      slider: {
        date: 'Selecciona una fecha',
      },
      months: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      },
      quarters: {
        1: 'Trimestre 1',
        2: 'Trimestre 2',
        3: 'Trimestre 3',
        4: 'Trimestre 4',
      },
      sources: {
        1: 'Medios de comunicación',
        2: 'Redes sociales',
        3: 'Agregador de datos',
        4: 'Policía nacional',
        5: 'Policía local',
        6: 'Policía universitaria',
        7: 'Llamadas al 911 para servicio',
        8: 'Medios afiliados al estado',
        9: 'Gobierno nacional',
        10: 'Gobierno local',
        11: 'Organización sin fines de lucro (ONG)',
        12: 'Otros',
        13: 'Desconocido',
        14: 'Medios de comunicación',
        15: 'Aplicación de la ley',
        16: 'Gobierno',
        17: 'Organización sin fines de lucro (ONG)',
        18: 'No asignado',
        19: 'No asignado',
        20: 'Movimiento Político',
        21: 'Sindicato',
        22: 'Movimiento Activista',
        23: 'Grupo de Interés Especial',
      },
      categories: {
        1: 'Acoso',
        2: 'Hurto',
        3: 'Robo',
        4: 'Robo De Vehiculo',
        5: 'Agresión simple',
        6: 'Extorcion',
        7: 'Secuestro',
        8: 'Delitos Sexuales',
        9: 'Vandalismo',
        10: 'Pirateria',
        11: 'Trafico De Mercaderia Ilicita',
        12: 'Fraude',
        13: 'Actividad De Crimen Organizado',
        14: 'Homicidio',
        15: 'Delitos Menores',
        16: 'Terrorismo',
        17: 'Corrupción',
        18: 'Crímenes Violentos',
        19: 'Actividad Sospechosa',
        20: 'Disturbio',
        21: 'Infracciones De Tráfico Graves',
        22: 'Infracciones De Tráfico Menores',
        23: 'Delitos Domésticos',
        24: 'Delitos De Drogas y Alcohol',
        25: 'Violacion De La Propieda Privada',
        26: 'Infracciones Con Arma',
        27: 'Irrelevante',
        28: 'Persona Desaparecida',
        29: 'Suicidio',
        30: 'Ataque Aéreo/Dron',
        31: 'Cambiar A Grupo/Actividad',
        32: 'Sede O Base Establecida',
        33: 'Acuerdo',
        34: 'Arrestos',
        35: 'Transferencia No Violenta De Territorio',
        36: 'Ataque',
        37: 'Granada',
        38: 'Choque Armado',
        39: 'Uso De Armas Interrumpido',
        40: 'Bombardeos/Artillería/Ataque Con Misiles',
        41: 'Saqueo/Destrucción De Propiedades',
        42: 'Delito menor',
        43: 'Protesta Pacífica',
        44: 'Protesta Con Intervención',
        45: 'Violencia Callejera',
        46: 'Fuerza Excesiva Contra Los Manifestantes',
        47: 'Arma Química',
        48: 'Violencia Sexual',
        49: 'Actor No Estatal Toma Territorio',
        50: 'Bomba Suicida',
        51: 'Secuestro/Desaparición Forzada',
        52: 'Gobierno Recupera Territorio',
        53: 'Explosivo Remoto/Mina Terrestre/IED',
        54: 'Manifestación Violenta',
        55: 'Manifestaciones',
        56: 'Disturbios No Violentos',
        57: 'Otros Delitos',
        58: 'Delitos contra la Propiedad',
        59: 'Delitos Violentos',
        60: 'Violencia Política',
        61: 'Asalto Agravado',
        62: 'Incendio Provocado',
        63: 'Robo con Escalamiento',
        64: 'Tráfico de bienes robados',
        65: 'Hurto en Tiendas',
        66: 'Robo de Vehículo',
        67: 'Delitos de Propiedad',
      },
      unrestTypes: {
        1: 'Batallas',
        2: 'Disturbios',
        3: 'Violencia contra civiles',
        4: 'Explosiones/Violencia remota',
        5: 'Desarrollos estratégicos',
        6: 'Protestas',
      },
      unrestSubtypes: {
        1: 'Secuestro/desaparición forzada',
        2: 'Enfrentamiento armado',
        3: 'Arrestos',
        4: 'Ataque',
        5: 'Cambio de grupo/evento',
        6: 'Uso de armas cuerpo a cuerpo',
        7: 'Fuerza excesiva contra protestadores',
        8: 'Saqueo/vandalismo',
        9: 'Multitud violenta',
        10: 'Otro',
        11: 'Protesta pacífica',
        12: 'Protesta con intervención',
        13: 'Explosivos remotos/mina/Artefactos Explosivos Caseros',
        14: 'Violencia Sexual',
        15: 'Manifestación violenta',
        16: 'Convenio',
        17: 'Ataque aéreo/drones',
        18: 'Arma química',
        19: 'Gobierno recupera territorio',
        20: 'Granada',
        21: 'Sede o base establecida',
        22: 'Actor no estatal se apodera del territorio',
        23: 'Transferencia de territorio no violenta',
        24: 'Bombardeo/artillería/ataque con misiles',
        25: 'Bomba suicida',
      },
      timeslices: {
        0: 'Mañana',
        1: 'Media Mañana',
        2: 'Mediodía',
        3: 'Tarde',
        4: 'Noche',
      },
      weekdays: {
        0: 'Domingo',
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
      },
      seasons: {
        fall: 'Fall',
        summer: 'Summer',
        winter: 'Winter',
        spring: 'Spring',
      },
      countryCategories: {
        1: 'Estabilidad',
        2: 'Estado de Derecho',
        3: 'Corrupción',
        4: 'Transparencia',
        5: 'Derechos Fundamentales',
        6: 'Criminalidad',
        7: 'Conflicto',
        8: 'Resiliencia',
        9: 'Liderazgo Político',
        10: 'Responsabilidad Gubernamental',
        11: 'Integridad Territorial',
        12: 'Cooperación Internacional',
        13: 'Orden y Seguridad',
        14: 'Sistema Legal',
        15: 'Justicia Penal',
        16: 'Justicia Civil',
        17: 'Regulación Económica',
        18: 'Cumplimiento Regulatorio',
        19: 'Integridad Pública',
        20: 'Independencia Judicial',
        21: 'Restricciones al Gobierno',
        22: 'Transparencia Gubernamental',
        23: 'Gobierno Abierto',
        24: 'Libertad en Internet',
        25: 'Libertad de Prensa',
        26: 'Derechos Políticos',
        27: 'Libertades Civiles',
        28: 'Tráfico de Personas',
        29: 'Tráfico de Armas',
        30: 'Delitos Cibernéticos',
        31: 'Delitos Financieros',
        32: 'Comercio de Drogas',
        33: 'Extorsión',
        34: 'Crimen Organizado',
      },
    },
    eventFilter: 'Filtro de Eventos',
    Filters: 'Filtros',
    crimeNeighborhoods: 'Distritos',
    protestAreas: 'Eventos de disturbios',
    crimeTypes: 'Tipos de Crimen',
    threatCategories: 'Tipo de Evento',
    sourceCategories: 'Fuentes de Datos',
    timeOfYear: 'Época del Año',
    timeOfDay: 'Hora del Dia',
    mapGranularity: 'Granularidad del Mapa',
    mapViewOptions: 'Vista del Mapa',
    messageDetails: 'Detalles del mensaje',
    mapThreatType: 'Tipo de Datos',
    unrestSubtypes: 'Tipos de eventos de disturbios',
    threatCrime: 'Crimen',
    threatUnrest: 'Disturbio',
    enterpriseLabel: 'Empresa',
    betaLabel: 'Beta',
    popup: {
      neighborhood: {
        areaAverage: 'Promedio del área',
        cityAverage: 'Promedio de la ciudad',
      },
      drawPolygon: {
        severityAverage: 'Promedio de la severidad: ',
        drawIcon: 'Seleccionar puntos de crimen',
        trashIcon: 'Limpiar selección',
      },
    },
    sendOptions: 'Opciones de envío',
    sendToAllCompanyEmployees: 'Enviar a todos los empleados',
    sendToAllEmployeesInTheSelectedCountries:
      'Enviar a todos los empleados en países seleccionados:',
    sendToAllEmployeesInTheSelectedCities:
      'Enviar a todos los empleados en ciudades seleccionadas:',
    sendMessage: {
      confirmationDialog: {
        title: 'Enviar a todos los empleados en {place}',
        titleCompanyWide: 'Enviar a todos los empleados',
        message:
          'Tenga en tenga que esta notificación será enviada a cada empleado en {place}. Por favor confirma o cancela.',
        messageCompanyWide:
          'Tenga en tenga que esta notificación será enviada a cada empleado en toda la empresa. Por favor confirma o cancela.',
        hideForFutureSelections:
          'Oculta este mensaje para futuras selecciones de idiomas',
        confirmSend: 'Mensaje enviado',
        confirmSendTo: 'Confirma en envio en {place}',
      },
    },
    modal: {
      continue: 'Continuar',
      cancel: 'Cancelar',
    },
    notificationHistoryModal: {
      sentOn: 'Enviar en',
      sentBy: 'Enviar a',
      usersIncludedInNotification: 'Usuarios incluidos en la notificación',
      countriesIncludedInNotification: 'Paises incluidos en la notificación',
      citiesIncludedInNotification: 'Ciudades incluidas en la notificación',
      notificationThread: 'Hilo de notificación',
      notificationUsers: 'Notificación de usuarios',
      notificationHistory: 'Historial de notificaciones',
      addNotificationToThread: {
        addNotificationToThread: 'Agregar la notificación al hilo',
        confirmationDialog: {
          title: 'Enviar nueva notificación de emergencia',
          message:
            '¿Deseas reestablecer el estado a todos los usuarios en este hilo a "desconocido" que requieran un nuevo check-in?',
          resetAllUsers: 'Reestablecer todos los usuarios',
          retainCurrentUserStatus: 'Retener el estado actual del usuario',
        },
      },
    },
    countries: {
      Brazil: 'Brazil',
      Canada: 'Canada',
      Mexico: 'Mexico',
      'United States': 'Estados unidos',
    },
    cities: {
      'Mexico City': 'Ciudad de México',
      Bogotá: 'Bogotá',
      Denver: 'Denver',
      'São Paulo': 'São Paulo',
      'Rio de Janeiro': 'Rio de Janeiro',
      'Washington, DC': 'Washington, DC',
      Niterói: 'Niterói',
      'Buenos Aires': 'Buenos Aires',
      Fortaleza: 'Fortaleza',
      Baghdad: 'Baghdad',
      London: 'London',
      Vancouver: 'Vancouver',
      'Puerto Rico': 'Puerto Rico',
      'Bahía Blanca': 'Bahía Blanca',
      Santiago: 'Santiago',
      Montevideo: 'Montevideo',
      Other: 'Otro',
    },
    locationInsights: {
      noData:
        'No hay datos recientes de crímenes disponibles para esta ubicación.',
      diffReportingFrequency:
        'Los datos de crímenes y disturbios tienen cobertura y granularidad temporal diferentes para esta ubicación. Los datos de crímenes se informan {crime} y los datos de disturbios se informan {unrest}. Los gráficos como el gráfico de Tendencia de Eventos y el gráfico de Contribución de Categorías pueden verse afectados por esta discrepancia. Para visualizar correctamente los gráficos, visualice los datos de crímenes y los datos de disturbios por separado utilizando el Filtro de Tipo de Evento.',
    },
    keyTakeaways: {
      quintiles: {
        1: 'Bajo',
        2: 'Bajo-Medio',
        3: 'Medio',
        4: 'Medio-Alto',
        5: 'Alto',
      },
      // District Comparison
      districtCompare:
        '{totalCount} delitos reportados en comparación con otros distritos (quintil {quintile}) dentro del mismo {locationType}. El distrito tiene un nivel de amenaza {threatLevel} para las categorías de delitos seleccionadas.',
      // Highest Crime
      highestCrimeRadius:
        '{crimeCategory} es la categoría de crimen más alta reportada en un radio de {radius} {units}, representando el {crimePercentage} de los eventos ocurridos.',
      highestCrimeDistrict:
        '{crimeCategory} es la categoría de crimen más alta reportada dentro del distrito, representando el {crimePercentage} de los eventos ocurridos.',
      highestCrimeCity:
        '{crimeCategory} es la categoría de crimen más alta reportada dentro de la ciudad, representando el {crimePercentage} de los eventos ocurridos.',
      // Day vs Night Greater Than 20
      dayVsNightGreaterRadius:
        '{nightPercentage} de los eventos reportados en un radio de {radius} {units} ocurrieron en la tarde y noche, y {dayPercentage} ocurrieron durante el día.',
      dayVsNightGreaterDistrict:
        '{nightPercentage} de los eventos reportados en el distrito ocurrieron en la tarde y noche, y {dayPercentage} ocurrieron durante el día.',
      dayVsNightGreaterCity:
        '{nightPercentage} de los eventos reportados dentro de la ciudad ocurrieron en la tarde y noche, y {dayPercentage} ocurrieron durante el día.',
      // Day vs Night Less Than 20
      dayVsNightLessRadius:
        'En un radio de {radius} {units}, el crimen es relativamente uniforme a lo largo del día, con un {dayPercentage} de eventos ocurriendo durante el día, y un {nightPercentage} de los eventos ocurriendo en la tarde y noche.',
      dayVsNightLessDistrict:
        'Dentro del distrito, el crimen es relativamente uniforme a lo largo del día, con un {dayPercentage} de eventos ocurriendo durante el día, y un {nightPercentage} de los eventos ocurriendo en la tarde y noche.',
      dayVsNightLessCity:
        'Para {locationName}, el crimen es relativamente uniforme a lo largo del día, con un {dayPercentage} de eventos ocurriendo durante el día, y un {nightPercentage} de los eventos ocurriendo en la tarde y noche.',
      // Property vs. Violent Crime
      propertyVsViolentRadius:
        'El {violentCrimePercentage} de los eventos reportados en un radio de {radius} {units} son {violentCrime} y el {propertyCrimePercentage} son {propertyCrime}.',
      propertyVsViolentDistrict:
        'El {violentCrimePercentage} de los eventos reportados en el distrito son {violentCrime} y el {propertyCrimePercentage} son {propertyCrime}.',
      propertyVsViolentCity:
        'El {violentCrimePercentage} de los eventos reportados dentro de la ciudad son {violentCrime} y el {propertyCrimePercentage} son {propertyCrime}.',
    },
    httpErrorMessages: {
      400: 'Solicitud no válida: {message}.',
      403: 'No estás autorizado para realizar esta acción.',
      429: 'Demasiadas solicitudes: por favor, inténtalo nuevamente más tarde.',
      500: 'Error interno del servidor: ¡Uh oh! Algo salió mal, estamos trabajando en ello.',
      clientError: 'Por favor, inténtalo nuevamente.',
      serverError: 'Algo salió mal, por favor, inténtalo nuevamente más tarde.',
    },
    textLimit: {
      showLess: 'Ver menos',
      showMore: 'Ver más',
      less: 'menos',
      more: 'más',
    },
    reportDownloadModal: {
      allThreatsTypesLabel: 'Todos los Tipos de Amenazas',
      reportNameLabel: 'Nombre del Reporte',
      defaultReport: 'Reporte por defecto',
      defaultReportName: 'Mi Reporte',
      onePageReport: 'Reporte de una página',
      customReport: 'Reporte personalizado',
      radiusHeader: 'Radio',
      districtHeader: 'Distrito',
      cityHeader: 'Ciudad',
      summaryPageLabel: 'Resumen',
      changeAnalysisLabel: 'Análisis de Cambio',
      threatAnalysisLabel: 'Desglose de Amenazas',
      districtAnalysisLabel: 'Análisis de Distrito',
      trendAnalysisLabel: 'Análisis de Tendencias',
      dataSourcesLabel: 'Fuentes de Datos',
      selectionLabel: 'Seleccionar Secciones',
      infoCaptionLabel:
        'Cada sección marcada es una página adicional en el informe',
      downloadLabel: 'Descargar Informe',
      downloadMessage:
        'La generación del informe tarda unos 5 minutos; recibirá un correo electrónico cuando esté listo para descargar.',
    },
  },
  it_IT: {
    errorPage: {
      title: 'Ops, si è verificato un errore imprevisto.',
      subtitle:
        'Il nostro team è stato informato di questo problema, per favore aggiorna il tuo browser per continuare a utilizzare la nostra piattaforma.',
      refreshButton: 'Aggiorna Browser',
      goHome: 'Torna alla Home',
      contactSupport: 'Contatta il Supporto',
    },
    featureAccess: {
      forecastFeedback:
        'Ti contatteremo a breve riguardo alla Previsione delle Tendenze.',
      forecastTeaser:
        "La Previsione delle Tendenze è disponibile tramite il nostro Piano Enterprise. Contattaci per l'aggiornamento.",
      chartTeaser: 'Monitora le variazioni nel tempo con Enterprise',
      explore: 'Esplora ora',
      enterpriseFeature: 'Questa è una funzionalità aziendale',
      thanks: 'Grazie!',
      offerUpgrade: 'Aggiornando il tuo account sbloccherai questa funzione.',
      upgradeFeedback:
        'Abbiamo ricevuto la tua richiesta. Ti contatteremo a breve.',
      interest: 'Sono interessato',
      closeLabel: 'Chiudi',
      teaserTitle: 'Scopri ulteriori dettagli in Impresa',
      teaserBullet1:
        'Ottieni informazioni sulle variazioni nella criminalità in diverse località nel tempo',
      teaserBullet2:
        'Approfondisci con confronti dettagliati delle variazioni nelle categorie di reato',
      teaserBullet3:
        'Personalizza le visualizzazioni delle variazioni per categorie di reato specifiche, periodi di tempo e fonti di dati',
      contact: 'Contattaci',
    },
    tooltips: {
      forecastTrend:
        'Mostra una previsione delle tendenze criminali basata su dati storici, proiettando potenziali tassi e modelli di criminalità futuri',
      gtmNotAvailable:
        'La previsione BaseGTM non è disponibile per questa località',
      gtmDisabled: 'BaseGTM deve essere abilitato',
      selectionNotAvailable:
        'Non disponibile per questa località o granularità',
      gtmPowered: 'Alimentato da BaseGTM.',
      gtmAvailable:
        "La modellazione è utilizzata per tenere conto del rumore nei dati risultante da incongruenze nei rapporti per migliorare l'utilità dei risultati.",
      gtmUnavailable:
        'Le stime di BaseGTM non sono disponibili per questa località',
      layerNotAvailable:
        'Questo livello non è disponibile al livello di zoom attuale. Ingrandisci per vederlo.',
      districtsNotAvailable:
        'Lo strato dei confini non è disponibile in questa località.',
      eventsNotAvailable:
        'Lo strato degli eventi non è disponibile in questa località.',
      heatmapNotAvailable:
        'Lo strato della mappa di calore non è disponibile in questa località.',
      threatScore: BASE_SCORE,
      threatScoreNotAvailable:
        'Il livello BaseScore non è disponibile in questa località.',
      population: 'Popolazione Stimata',
      trendline:
        "Le linee di tendenza e le medie del crimine sono determinate basandosi sul modello di minaccia delle Operazioni Base. Questo modello aiuta a considerare il rumore nei dati risultante da inconsistenze nei rapporti per migliorare l'utilità dei risultati.",
      historical:
        "La media mensile storica è determinata dall'intera storia disponibile.",
      amountAndDirectionOfChange:
        'Località salvate rappresentate dalla direzione e magnitudo del cambiamento.',
      topTenCitiesWithMostChange:
        'Classifica delle città con località salvate con il maggior aumento o diminuzione di eventi segnalati.',
      numberOfLocationsByChange:
        'Numero di località che rientrano in ciascuna soglia di modifica per il periodo di tempo scelto nelle impostazioni.',
      violentCrimeChange:
        'Numero di località salvate per quantità di cambiamento nel totale di istanze di crimine violento.',
      propertyCrimeChange:
        'Numero di località salvate per quantità di cambiamento nel totale di istanze di crimine contro la proprietà.',
      optionNotAvailable:
        'Questa opzione non è disponibile per la posizione selezionata.',
      sourceNotSelected:
        'Questa fonte è stata deselezionata nel pannello di filtro a sinistra. Se desideri visualizzarla nel grafico, selezionala nuovamente.',
      sourceThreatCategoriesNotSelected:
        'Tutte le categorie di eventi di questa fonte sono state deselezionate nel pannello di filtro.',
      notEnoughData:
        'Non ci sono dati sufficienti per visualizzare le variazioni per questa fonte. Se hai applicato dei filtri, prova a rimuoverli.',
      betaLabel: 'Questa è una funzionalità Beta',
      addLocation: 'Aggiungi luogo',
      deleteLocation: 'Elimina posizione',
      threatAnalyticsEventSummary:
        'Visualizza i conteggi degli eventi verificatisi entro il raggio di minaccia selezionato.',
      crimeFilterDataType: `Passa da crimine a disordini:
      • Crimine - Includere eventi minacciosi come rapine e omicidi.
      • Disordini - Includere eventi di minaccia come rivolte e proteste.`,
      crimeFilterMapView: `Visualizza le visualizzazioni mappa disponibili, tra cui:
      • Eventi criminali: eventi di minaccia tracciati individualmente.
      • Crime Heatmap: hotspot di attività di minaccia.
      • Distretti: attività di minaccia nelle aree amministrative all'interno di un paese, una regione o una città.`,
      crimeFilterQuintile: `Filtra i distretti in base al livello di minaccia rispetto ad altri distretti in questa posizione.
      • Il livello di minaccia si basa sul numero e sulla gravità degli eventi in un distretto.
      • I livelli di minaccia sono classificati in quintili.
      • L'azzurro indica il livello di minaccia relativo più basso (0-20% dei distretti) e il rosa scuro indica il livello di minaccia relativo più alto (80-100%).`,
      crimeFilterTypes:
        'Filtra gli eventi di minaccia per tipo di crimine o categoria. Il crimine violento e il crimine contro il patrimonio sono classificazioni sintetiche che includono più tipi di crimine.',
      crimeFilterQuarters:
        'Filtra gli eventi di minaccia in base al trimestre in cui si sono verificati.',
      crimeFilterDay:
        'Filtra gli eventi di minaccia in base all’ora del giorno.',
      crimeFilterDate: 'Filtra gli eventi di minaccia per intervallo di date.',
      threatAnalyticsSeverities: `Visualizza il livello di minaccia per il distretto selezionato mese per mese rispetto ad altri distretti in questa posizione.
      • Il livello di minaccia si basa sul numero e sulla gravità degli eventi in un distretto.
      • I livelli di minaccia sono classificati in quintili.
      • L'azzurro indica il livello di minaccia relativo più basso (0-20% dei distretti) e il rosa scuro indica il livello di minaccia relativo più alto (80-100%).`,
      threatAnalyticsMonthly:
        'Visualizza gli eventi di minaccia nel tempo. Visualizza sia il conteggio mensile degli eventi che il conteggio degli eventi come media mobile di 30 giorni per valutare rispettivamente la varianza effettiva basata sul tempo e una linea di tendenza smussata.',
      threatAnalyticsRolling:
        'Visualizza gli eventi di minaccia nel tempo. Visualizza sia il conteggio mensile degli eventi che il conteggio degli eventi come media mobile di 30 giorni per valutare rispettivamente la varianza effettiva basata sul tempo e una linea di tendenza smussata.',
      threatAnalyticsHistorical:
        'Visualizza la tendenza stagionale delle minacce utilizzando il numero medio di eventi di minaccia mensili negli ultimi tre o più anni. L’intervallo di date viene visualizzato sopra il grafico.',
      threatLandscape:
        'Le informazioni più rilevanti sulle minacce che incidono su una determinata posizione.',
      featuredEvents:
        'Gli eventi di disordine più recenti e degni di nota che si sono verificati in una località.',
      notableEvents:
        'Gli eventi di agitazione più seri e rilevanti che si sono verificati nella granularità selezionata negli ultimi tre mesi dalla data più recente selezionata.',
      eventSummary:
        'Mostra il conteggio degli eventi che si sono verificati nella granularità selezionata.',
      eventComposition:
        "Mostra la frequenza degli eventi che si sono verificati nella granularità selezionata. Più grande è il carattere, più frequente è l'evento.",
      eventBreakdown:
        'Mostra la suddivisione degli eventi per categorie, sottocategorie e orario del giorno.',
      eventTrend:
        'Mostra gli eventi minacciosi nel tempo. Mostra il conteggio mensile o giornaliero degli eventi, nonché il conteggio medio generale del periodo, il conteggio medio mobile e il conteggio medio mensile storico per valutare la varianza effettiva basata sul tempo, la tendenza smussata e le medie stagionali.',
      changeTrend:
        "Mostra il cambiamento degli eventi nel tempo per origine. Mostra il cambiamento mensile o trimestrale rispetto al periodo precedente o all'anno precedente.",
      categoryContributions:
        'Mostra gli eventi nel tempo suddivisi per categorie di eventi.',
      periodAverage:
        "Mostra la media del numero di minacce nel periodo selezionato all'interno della granularità scelta.",
      severityScore:
        'Mostra il conteggio degli eventi minacciosi e i livelli di minaccia per il distretto selezionato rispetto agli altri distretti in questa posizione. \n • Il livello di minaccia si basa sul numero e la gravità degli eventi in un distretto. \n • I livelli di minaccia sono classificati in quintili. \n • Il blu chiaro indica il livello di minaccia relativo più basso (0-20% dei distretti) e il rosa scuro indica il livello di minaccia relativo più alto (80-100%).',
      severityScoreTrend:
        'Mostra il livello di minaccia suddiviso per categorie per il distretto selezionato mese per mese rispetto agli altri distretti in questa posizione. \n • Il livello di minaccia si basa sul numero e la gravità degli eventi in un distretto. \n • I livelli di minaccia sono classificati in quintili. \n • Il blu chiaro indica il livello di minaccia relativo più basso (0-20% dei distretti) e il rosa scuro indica il livello di minaccia relativo più alto (80-100%).',
      dataSourceTrend:
        "Mostra gli eventi nel tempo suddivisi per fonti di dati. Le fonti sono ordinate per volume di dati nell'intervallo di date selezionato.",
      dataSourceInsights:
        'Mostra informazioni sulle sfumature delle fonti di dati per la posizione selezionata.',
      locationInsights:
        'Visualizza informazioni sulle sfumature della segnalazione dei dati per questa posizione.',
      colorLegend:
        'Indica il livello di minaccia in una posizione che va da basso (azzurro) ad alto (rosa scuro). Il livello di minaccia si basa sul numero e sulla gravità degli eventi.',
      unrestFilterDataType: `Passa da crimine a disordini:
      • Crimine - Include eventi minacciosi come rapine e omicidi.
      • Disordini - Include eventi di minaccia come rivolte e proteste.`,
      unrestFilterMapView:
        'Visualizza i luoghi degli eventi disordini. I pin corrispondono ai luoghi in cui si sono verificati eventi di disordini.',
      unrestFilterTypes:
        'Filtra gli eventi di disordini per tipo. È possibile selezionare un singolo tipo di disordini o più tipi di disordini contemporaneamente.',
      unrestFilterDate: 'Filtra gli eventi per intervallo di date.',
      unrestLegendSummary:
        'Visualizza un conteggio degli eventi di disordini per tipo. Facendo clic sulla freccia bianca accanto a ciascun tipo si espanderà per mostrare i sottotipi di eventi.',
      threatEventsDetail:
        "Visualizza informazioni aggiuntive su ciascun evento disordini. Fare clic sulla freccia bianca per visualizzare i dettagli, inclusi gli attori coinvolti, una descrizione dell'evento e le origini dati.",
      unrestLegendChart:
        'Visualizza gli eventi di disordini con incrementi mensili. Ogni incremento è una media degli ultimi 3 mesi.',
      navbarScreenShot: 'Immagine dello schermo',
      saveLocation: 'Posizioni salvate',
      cancelSaveLocation: 'Cancel',
      toggleFiltersPanelTooltip: 'Mostra/Nascondi Pannello Filtri',
      filterPriorityRating:
        'Filtra posizioni salvate per valutazione di priorità',
      filterTags: 'Filtra posizioni salvate per tag',
      sourceTypeFilter:
        "Filtra gli eventi di minaccia per origine dei dati. Le fonti sono ordinate per volume di dati per l'intero intervallo di tempo coperto.",
      unassignedSource:
        'I dati appena aggiunti vengono di solito acquisiti come non assegnati. I dati con tipo di origine non assegnata vengono aggiornati mensilmente.',
      comingSoon: 'Prossimamente',
      changeMinimum: 'La modifica si basa su un raggio minimo di 1 mi.',
      percentageChangeByLocationType:
        'Raggruppa il tipo di posizione in base al grado di modifica. Modificare il tipo di posizione modificando il tipo per qualsiasi posizione salvata.',
      percentageChangeByLocationTag:
        'Tag di posizione dei gruppi in base al grado di modifica. Modificare modificando i tag per qualsiasi posizione salvata.',
      countryScoreBreakdown:
        'Il Country BaseScore rappresenta la minaccia relativa e la stabilità dei paesi su una scala standardizzata da 0 (migliore) a 100 (peggiore).',
    },
    horizontalNavBar: {
      breadcrumbs: {
        map: 'Mappa',
        'my-locations/list': 'Le Mie Posizioni',
        user: 'Profilo',
        home: 'Home',
        'my-locations/analysis': 'Dashboard delle Mie Posizioni',
      },
    },
    savedLocations: {
      locationOverview: 'Panoramica della Località',
      threatProfile: 'Profilo di Minaccia',
      degreeOfChange: 'Grado di Cambiamento',
      filtersTitle: 'Filtro di Località',
      positionUpdated: 'Posizione aggiornata! Salvare queste modifiche?',
      threatChange: 'Cambiamento Medio per Categoria di Minaccia',
      threatLandscape: 'Panorama delle Minacce',
      indeterminateCaption:
        'Il grafico include le modifiche per {count1} località.',
      emptyThreatLandscape:
        'Non ci sono dati sufficienti per il Cambiamento e il Panorama delle Minacce per questa specifica località',
      showCharts: 'Mostra Grafici',
      barChartTitle: 'Conteggio delle Località per Cambiamento Percentuale',
      magnitudeOfChange: 'Magnitudo del Cambiamento',
      locations: 'posizioni',
      citiesTableTitle: 'Città Classificate',
      locationsTableTitle: 'Posizioni Classificate',
      changeMapTitle: 'Quantità e Direzione del Cambiamento',
      changeColorScaleLabel: 'Direzione del Cambiamento',
      changeSizeScaleLabel: 'Magnitudo del Cambiamento',
      changeTrendHeaders: {
        cityName: 'Città Classificate',
        locationName: 'Località Classificate',
        change: 'Cambiamento',
        savedLocationCount: '# di Località',
        lastVisited: 'Ultima Visita',
        score: BASE_SCORE,
      },
      remaining: 'Residua',
      none: 'Nessuno',
      addNewTag: 'Crea nuovo tag',
      maxTags: 'Numero massimo di tag consentiti per posizione: 15',
      newTag: 'Nuovo Tag',
      allTags: 'Tutti Contrassegnati',
      yesLabel: 'Sì',
      noLabel: 'No',
      createdLocationLabel: 'Posizione salvata!',
      updatedLocationLabel: 'Posizione aggiornata!',
      deletedLocationLabel: 'Posizione eliminata!',
      collapseModalLabel: 'Mostra meno campi',
      expandModalLabel: 'Mostra più campi',
      saveLocationLabel: 'Salvare questa posizione?',
      unrestLabel: 'Rivolta',
      crimeLabel: 'Crimine',
      viewMapTooltip: 'Guarda la mappa',
      viewDetailsTooltip: 'Visualizza dettagli',
      filterLocationsTitle: 'Filtra Posizioni Salvate',
      // Control Actions
      closeButtonText: 'Chiudere',
      filterButtonText: 'Filtro',
      sortButtonText: 'Ordina per',
      searchInputText: 'Cerca Posizioni Salvate',
      cancelButtonText: 'Annulla',
      editButtonText: 'Modificare',
      selectAll: 'Seleziona Tutto',
      moreDetails: 'Dettagli',
      saveButton: {
        text: 'Salva',
        loading: 'Salvataggio',
      },
      deleteButton: {
        text: 'Elimina',
        loading: 'Eliminazione',
      },
      // Filter Categories
      regionLabel: 'Regione',
      countryLabel: 'Paese',
      locationTypeLabel: 'Tipi di Località',
      priorityRatingLabel: 'Valutazione di priorità',
      includeUnratedLabel: 'Includi località non classificate',
      tagsLabel: 'Tag',
      office: 'Ufficio',
      hotel: 'Hotel',
      event: 'Evento',
      restaurant: 'Ristorante',
      travel: 'Viaggiare',
      warehouse: 'Magazzino',
      parking: 'Parcheggio',
      service_area: 'Area di Servizio',
      point_of_interest: 'Punto di Interesse',
      facility: "l'infrastruttura",
      retail: 'Vendita al dettaglio',
      other: 'Altro',
      // Detail/Edit Drawer
      locationNotFound: 'Posizione Non Trovata',
      locationNameLabel: 'Nome del Luogo',
      displayAddressLabel: 'Display Address',
      coverageLabel: 'Visualizza Indirizzo',
      customerIdLabel: 'ID Posizione Cliente',
      dateRangeLabel: 'Intervallo di Date',
      latitudeLabel: 'Latitudine',
      longitudeLabel: 'Longitudine',
      locationTypePlaceholder: 'Seleziona Tipo di Posizione',
      // Saved Locations List Tile Columns
      location: 'Posizione',
      coverageDates: 'Date di copertura',
      area: 'Area',
      rating: 'Valutazione',
      tags: 'Tag',
      // More
      locationTypeTooltip: 'Filtra le posizioni salvate per tipo di posizione',
      visibilityTooltip:
        'Filtra le località salvate in base alla loro visibilità',
      sortOptions: {
        name: 'Nome',
        customerLocationId: 'ID Posizione',
        customerRating: 'Valutazione di priorità',
        change: 'Cambio',
        changeTooltip: `Tutte le opzioni si basano sui calcoli della linea di tendenza a livello di città e sono normalizzate per il confronto tra le località:
        • Variazione mensile su base annua:
        per esempio. Agosto 2023 vs agosto 2022
        • Variazione anno dopo anno:
        per esempio. Settembre 2022 - agosto 2023 vs settembre 2021 - agosto 2022
        • Modifica indice
        Una combinazione di tre parametri di cambiamento (il più recente cambiamento mese su mese, trimestre su trimestre e trimestre su anno) per includere modelli di cambiamento a breve, medio e lungo termine.`,
        changeIndex: 'Cambia indice',
        oneMonthPeriodOverYear: 'Variazione mensile anno su anno',
        twelveMonthPeriodOverPeriod: 'Cambiamento anno dopo anno',
      },
      visibilityLabel: 'Visibilità',
      visibilityOptions: {
        private: 'Privato',
        shared: 'Condiviso',
      },
      all: 'Tutto',
      userCannotEdit:
        'Solo i proprietari e gli amministratori sono autorizzati a modificare una posizione salvata condivisa.',
      gridViewTooltip: 'Vista a Griglia',
      listViewTooltip: 'Visualizzazione Elenco',
      sortOrderTooltip: 'Ordinamento',
      filtersTooltip: 'Apri Filtri',
      noSavedLocationsFirst:
        'Nessuna posizione salvata. Rimuovi alcuni filtri o',
      clickHere: 'clicca qui',
      noSavedLocationsSecond: 'per andare alla mappa.',
      goToLocation: 'Vai a Posizione',
      noCoverage: 'Nessuna Copertura',
      additionalTags: 'Tag Aggiuntive',
      coverageFrom: 'Copertura da',
      change: 'Cambio',
      changeNotAvailable: 'La modifica non è disponibile per questa posizione',
      priorityRating: 'Valutazione di priorità',
      percentageChangeByLocationType:
        'Percentuale di modifica per tag di posizione',
      percentageChangeByLocationTag:
        'Percentuale di variazione per tipo di ubicazione',
      breakdownByLocationType: 'Ripartizione per tipo di posizione',
      breakdownByLocationTag: 'Ripartizione per tag di posizione',
    },
    user: {
      tab1: 'Profilo Utente',
      tab2: 'Modifica Password',
      tab3: 'Gestisci Token API',
      tab4: 'Gestisci Utenti',
      title: 'Impostazioni Account',
      tempTitle: 'Profilo Utente',
      firstNameLabel: 'Nome',
      lastNameLabel: 'Cognome',
      emailLabel: 'Email',
      languageLabel: 'Lingua',
      unitsLabel: 'Unità',
      saveChanges: 'Salva Modifiche',
      allChangesSaved: 'Tutte le Modifiche Salvate',
      validationLabel: 'Il nome è obbligatorio',
      textFieldLabel: 'Nome della chiave API',
      copyLabel: 'Copia',
      deleteLabel: 'Elimina',
      createLabel: 'Genera',
      warningText:
        "È stata generata una chiave API personale per accedere all'API Rest delle operazioni di base. Assicurati di copiare la tua chiave API adesso. Una volta chiusa questa finestra, non sarà più recuperabile.",
      deleteText: 'Stai per eliminare la chiave',
      createTitle: 'Genera Chiave API',
      deleteAction: 'Elimina Chiave',
      deleteFeedback: 'Chiave API Eliminata',
      deleteTitle: 'Eliminazione chiave API in corso...',
      copyTitle: 'Chiave API Generata con Successo',
      createFeedback: 'Chiave API Generata',
      copyFeedback: 'Chiave API copiata negli appunti!',
      createButton: 'Genera Nuovo Token API',
      nameLabel: 'Nome Chiave',
      secretLabel: 'Segreto API',
      dateLabel: 'Creato il',
      actionsLabel: 'Azioni',
      maxValidation: 'Il campo di testo non deve superare i 30 caratteri',
    },

    threatAnalytics: {
      overallCrime: 'Cambiamento complessivo',
      saveDefaultLabel: 'Salva come predefinito',
      changeMapTitle: 'Quantità e Direzione del Cambiamento',
      acledAttribution:
        '** Base Operations acquisisce dati da migliaia di fonti. Applichiamo tecniche avanzate di statistica, geografia e IA per convalidare e migliorare questi dati. Alcuni dati ottenuti per questa posizione sono stati forniti da: Armed Conflict Location & Event Data Project (ACLED)',
      noThreatEvents:
        'Nessun evento di minaccia è stato segnalato in questa posizione per i tipi di minaccia e il periodo di tempo selezionati.',
      titleSeverity: 'Evoluzione del Punteggio di Gravità',
      titleIncidents: 'Incidenti',
      titleIntro: 'Analisi delle Minacce per ',
      tabDistrict: 'Distretto',
      tabProvince: 'Provincia',
      tabRadius: 'Raggio',
      tabCity: 'Città',
      tabCountry: 'Paese',
      titleDistrict: 'il Distretto',
      titleLocation: 'Posizione',
      titleThreatAnalytics: 'Analisi delle Minacce',
      titleCrimeEvents: 'Eventi di minaccia',
      textRadius: 'Raggio di',
      textRadiusPrefix: 'entro un raggio di ',
      textRadiusSuffix: '',
      titleJoin: 'a ',
      quintileTitle: 'Quintili di Rischio',
      quintileSubtitle: 'Quintile di Minaccia: ',
      chartSubtitleStart: 'Dal ',
      chartSubtitleJoin: ' al ',
      openAnalytics: 'Pannello di Analisi',
      closeAnalytics: 'Pannello delle Analisi',
      chart: 'Grafico',
      averageType: 'Tipo di Media',
      aggregationPeriod: 'Periodo di aggregazione',
      period: 'Periodicità',
      chartTitle1: 'Conteggio Eventi per Categoria di Minaccia',
      chartTitle2: "Composizione dell'evento",
      chartTitle3: "Suddivisione Dell'Ora del Giorno",
      chartTitle4: 'Andamento degli Eventi Mensili',
      chartTitle5: 'Andamento Cambiamento',
      chartTitle9: 'Punteggio di gravità',
      chartTitle10: 'Tendenza del punteggio di gravità',
      chartTitle11: 'Eventi Mensili per Fonte',
      chartTitle12: 'Eventi Medi per Mese',
      chartTitle13: 'Frequenza degli Eventi per Categoria di Minaccia',
      chartTitle14: 'Andamento Giornaliero degli Eventi',
      chartTitle15: 'Eventi Medi per Mese dell’Anno',
      chartTitle16: 'Eventi Medi per Giorno della Settimana',
      chartTitle17: 'Eventi Medi per Ora del Giorno',
      chartTitle18: 'Conteggio delle Località per BaseScore',
      chartTitle19: 'Conteggio delle Località per Cambiamento Percentuale',
      chartTitle20: 'Conteggio delle Località per Tipo e Cambiamento',
      chartTitle21: 'Conteggio delle Località per Tag e Cambiamento',
      chartTitle22: 'Conteggio delle Località per Tipo e BaseScore',
      chartTitle23: 'Conteggio delle Località per Tag e BaseScore',
      chartTitle24: 'Ripartizione per Tipo di Località',
      chartTitle25: 'Ripartizione per Tag di Località',
      chartTooltip: 'Raggruppa i tipi di località per grado di cambiamento',
      chartTooltip1: 'Raggruppa i tag delle località per grado di cambiamento',
      chartTooltip2: 'Raggruppa i tipi di località per grado di cambiamento',
      chartTooltip3: 'Raggruppa i tipi di località per BaseScore',
      chartTooltip4: 'Raggruppa i tag delle località per BaseScore',
      chartTooltip5: 'Raggruppa le località per tipo di località',
      chartTooltip6: 'Raggruppa le località per tag di località',
      locationInsightsTitle: 'Insight sulla Posizione',
      takeAwaysTitle: 'Variazione Percentuale',
      dataObservationsTitle: 'Analisi delle fonti di dati',
      threatEventsTableTitle: 'Dettagli sugli eventi di minaccia',
      notableEventsTitle: 'Eventi Rilevanti',
      numberOfLocationsByChange: 'Numero di posizioni per modifica',
      numberOfLocationsByChangeSubtitle: 'Cambiamento complessivo',
      citiesWithMostChange:
        'Le prime 10 città con il maggior numero di cambiamenti',
      changeTrendValues: {
        higher: 'più alto',
        lower: 'inferiore',
      },
      label1: 'Crimini',
      label2: 'Media',
      label3: 'Gravità',
      label4: 'Delitto Totale',
      label5: '30-Giorni Media Mobile',
      label6: '3 Anni',
      label7: '4 Anni',
      label8: '5 Anni',
      label9: 'Gamma Completa',
      label10: 'Mensile',
      label11: 'Conteggio eventi',
      label12: 'Minacce',
      value1: 'Gennaio',
      value2: 'Febbraio',
      value3: 'Marzo',
      value4: 'Aprile',
      value5: 'Maggio',
      value6: 'Giugno',
      value7: 'Luglio',
      value8: 'Agosto',
      value9: 'Settembre',
      value10: 'Ottobre',
      value11: 'Novembre',
      value12: 'Dicembre',
      topicSelectionText: "Seleziona un argomento per l'analisi",
      eventsWithDescription: 'Eventi solo con descrizione',
      panelType: {
        overview: 'Panoaramica',
        analysis: 'Analisi delle Tendenze',
        changeAnalysis: 'Analisi del Cambiamento',
        breakdown: 'Ripartizione delle Minacce',
        notableEvents: 'Eventi Notabili',
        eventList: 'Elenco Eventi',
        source: 'Fonti di Dati',
        comparative: 'Comparativo',
        countryAnalysis: 'Analisi del Paese',
        forecast: 'Previsione delle Tendenze',
      },
      panelTitles: {
        overview: 'Panoramica del Panorama delle Minacce',
      },
      overviewSection: {
        threatScore: BASE_SCORE,
        percentChange: 'Variazione percentuale',
        monthlyEvents: 'Eventi segnalati',
        monthlyEventsSuffix: 'mese',
        population: 'Popolazione',
        area: 'La zona',
      },
      reportButton: 'Genera Rapporto sulla Località',
      goToEventList: "Vai all'elenco degli eventi per ulteriori eventi",
      countryAnalysis: {
        threatScore: `Paese ${BASE_SCORE}`,
        countryScoreBreakdown: 'Ripartizione delle Minacce per Paese',
        overallScoreWeights: 'Pesatura del punteggio complessivo',
        threatFrom: 'Minaccia da...',
        score: 'Punteggio',
        quintile: 'Quintile',
      },
      forecastTrendSection: {
        eventCountLegend: 'Conteggio Eventi',
        trendLineLegend: 'Linea di Tendenza',
        forecastLegend: 'Previsione',
        confidence75Legend: 'Confidenza del 75%',
        confidence90Legend: 'Confidenza del 90%',
        forecastTooltip: 'Previsione',
        trendTooltip: 'Tendenza',
        countTooltip: 'Conteggio',
        confUb90Tooltip: '90% Superiore',
        confUb75Tooltip: '75% Superiore',
        confLb75Tooltip: '75% Inferiore',
        confLb90Tooltip: '90% Inferiore',
      },
    },
    navigation: {
      search: 'Cerca',
      filter: 'Filtro',
      home: 'Home',
      saved: 'Salvati',
      map: 'Mappa',
      help: 'Aiuto',
      settings: 'Impostazioni',
      login: 'Account',
      myLocationsAnalysis: 'Dashboard delle Mie Posizioni',
    },
    myLocationsAnalysis: {
      keyTakeaways: {
        title: 'Punti Chiave',
        count: 'Località Salvate',
        recentCount: 'Località Recensite',
        averageThreatScore: 'Punteggio Base Medio',
        averageChange: 'Cambiamento Medio',
      },
      downloadChartImages: 'Scarica le Immagini dei Grafici',
      downloadSavedLocationReport:
        'Scarica il Rapporto sulle Posizioni Salvate come CSV',
      settings: {
        aggregationOptions: ['Mensile', 'Trimestrale', 'Annuale'],
        periodOptions: ['Periodo su Periodo', 'Anno su Anno'],
        listTypeOptions: {
          savedLocations: 'Posizioni',
          cities: 'Città',
        },
        locationBreakdownOptions: {
          type: 'Tipo',
          tag: 'Etichetta',
        },
        changeTitle: 'Cambiamento',
        listTitle: 'Lista',
        locationBreakdownTitle: 'Ripartizione delle località',
        changeTooltip: 'Il cambiamento si basa su un raggio minimo di 1 mi.',
      },
      filters: {
        title: 'Filtri di posizione',
        allTypesLabel: 'Tipi di posizione',
        allTagsLabel: 'Tag',
        allDegreeOfChangesLabel: 'Grado di cambiamento',
        degreeOfChangeOptions: [
          {name: 'Grande Aumento', value: 'large_increase'},
          {name: 'Aumento', value: 'increase'},
          {name: 'Piccola Modifica', value: 'no_change'},
          {name: 'Diminuire', value: 'decrease'},
          {name: 'Grande Diminuzione', value: 'large_decrease'},
          // {name: 'Indeterminato', value: 'indeterminate'},
        ],
        threatScoreOptions: [
          {name: '>80', value: 'very_high'},
          {name: '60 - 79', value: 'high'},
          {name: '40 - 59', value: 'medium'},
          {name: '20 - 39', value: 'low'},
          {name: '<20', value: 'very_low'},
        ],
      },
    },
    searchModal: {
      savedLocationsTitle: 'Posizioni salvate',
      gettingStarted: 'Iniziare',
      recentTranslation: 'Recente',
      searchResults: 'Risultati della Ricerca',
      seeSavedLocations: 'Vedere nella Pagina Posizione Salvata',
      more: 'più',
    },
    actionZoom: {
      zoomIn: 'Ingrandisci',
      zoomOut: 'Riduci',
    },
    legendActions: {
      threatLevelTranslation: 'Livello di Minaccia',
      highScore: 'Alto',
      lowScore: 'Basso',
      mapLayerLabels: {
        heatmap: 'Mappa di Calore',
        events: 'Eventi',
        districts: 'Confini',
        countries: 'Confini',
        scores: 'Punteggi',
        population: 'Popolazione',
        change: 'Cambiamento',
        none: 'Nessuno',
      },
      mapStyleLabels: {
        map: 'Chiaro',
        dark: 'Scuro',
        satellite: 'Satellite',
      },
      mapMarkersLabels: {
        cities: 'Spilli Città',
        savedLocations: 'Spilli Siti',
      },
      stylesTitle: 'Stili di Mappa',
      layersTitle: 'Strati di Dati',
      markersTitle: 'Indicatori',
    },
    help: {
      userGuide: 'Guida alla Valutazione',
      restAPIDocumentationTranslation: 'Documentazione della REST API',
      threatAssessmentTemplateI: 'Modello di Valutazione della Minaccia I',
      threatAssessmentTemplateII: 'Modello di Valutazione della Minaccia II',
      threatAssessmentTemplateIII: 'Modello di Valutazione della Minaccia III',
      threatAssessmentTemplateIV: 'Modello di Valutazione della Minaccia IV',
    },
    userFeedback: {
      message: 'È stato utile?',
    },
    dataNotification: {
      message:
        "La copertura delle minacce non è attualmente disponibile in quest'area. Si prega di cercare un'altra posizione",
    },
    units: {
      mile: 'miglio',
      kilometer: 'chilometro',
      miles: 'Miglia',
      kilometers: 'Chilometri',
      meters: 'metri',
    },
    dateSkip: {
      month: '1 Mese',
      year: '1 Anno',
      skipForward: 'Salta in avanti',
      skipBackward: 'Salta indietro',
    },
    dateMessageValidation: {
      lessFrom:
        'La data di inizio non deve essere inferiore alla data minima della città corrente.',
      greaterFrom:
        'La data di inizio non deve essere maggiore della data massima della città corrente.',
      lessTo:
        'La data di fine non deve essere inferiore alla data minima della città corrente.',
      greaterTo:
        'La data di fine non deve essere maggiore della data massima della città corrente.',
    },
    chartQuintile: {
      quintile: 'Quintile',
      incidents: 'Incidenti',
      date: 'Date',
      threatCategory: 'Categoria di Minaccia',
      eventCount: 'Conteggio eventi',
      severity: 'Gravità',
    },
    chartOptions: {
      copyImageLabel: "Copia L'immagine",
      downloadImageLabel: "Scarica L'immagine",
      copyCSVLabel: 'Copia come CSV',
      downloadCSVLabel: 'Scarica come CSV',
    },
    changeOverview: {
      yearOverYearChange:
        '{increase} negli eventi segnalati in {currentPeriod} rispetto allo stesso {period} dell’anno scorso',
      periodOverPeriodChange:
        '{increase} negli eventi segnalati in {currentPeriod} rispetto al {period} precedente',
      yearOverYearNoChange:
        'Nessun cambiamento negli eventi segnalati in {currentPeriod} rispetto allo stesso {period} dell’anno scorso',
      periodOverPeriodNoChange:
        'Nessun cambiamento negli eventi segnalati in {currentPeriod} rispetto al {period} precedente',
      increase: 'aumento',
      decrease: 'diminuzione',
      month: 'mese',
      quarter: 'trimestre',
      change: 'Cambiamento',
      percentChange: 'Cambiamento percentuale',
    },
    chartSelection: {
      forecast3Months: 'Previsione di 3 mesi',
      forecast6Months: 'Previsione di 6 mesi',
      forecast1Year: 'Previsione di 1 anno',
      all: 'Tutto',
      primaryCategories: 'Categorie primarie',
      subCategories: 'Sottocategorie',
      threatCount: 'Conteggio minacce',
      percentOfTotal: 'Percentuale del totale',
      average: 'Media',
      rollingAverage: 'Media mobile',
      trendline: 'Linea di Tendenza',
      historicalAverage: 'Media Mensile Storica',
      daily: 'Giornaliero',
      percent: 'Percentuale',
      monthOfYear: "Mese dell'Anno",
      dayOfWeek: 'Giorno della Settimana',
      timeOfDay: 'Ora del Giorno',
      rate: 'Tasso',
      periodOverPeriod: 'Periodo su Periodo',
      yearOverYear: 'Anno su Anno',
      percentage: 'Percentuale',
      changeMetric: 'Metrica di Cambio',
      normalize: 'Normalizza',
      change: 'Modifica',
      source: 'Fonte',
      monthlyChange: 'Cambio Mensile',
      quarterlyChange: 'Cambio Trimestrale',
      incidentsPerMonth: 'incidenti al mese',
      incidentsPerQuarter: 'incidenti per trimestre',
    },
    chartLegend: {
      legend: 'Legenda',
      noTagsLabel1:
        'Non sono stati salvati tag per le tue località, aggiungi tag alle località nella',
      noTagsLabel2: 'Lista delle Mie Località',
      noTagsLabel3: 'vista.',
    },
    snackbar: {
      cityWarningTitle: 'Questo luogo sarà disponibile a breve.',
      cityWarningDesc: 'Qualche domanda? Contatta ',
      cancelButton: 'Chiudere',
    },
    snackbarMessages: {
      downloadSuccess: 'Download completato con successo',
      fetchingReport: 'Recupero del report...',
      reacherLimitExport:
        'Hai raggiunto il numero massimo di esportazioni, riprova tra 8 ore.',
      somethingWentWrong: 'Qualcosa è andato storto',
      copiedToClipboard: 'Copiato negli appunti con successo',
      fetchingData: 'Recupero dei dati del grafico in corso...',
    },
    updateNotification: {
      message: 'È disponibile una nuova versione.',
      action: 'Provalo ora!',
    },
    navbar: {
      mapStyleTooltip: 'Vista mappa',
      languageTooltip: 'Lingua',
      more: 'Di Più',
      share: 'Condividere',
      copyToClipboard: 'Copia negli appunti',
    },
    reCenterComp: {
      fabTooltip: "Vai all'esperienza di roaming gratuita",
      removeBoundaries: 'Rimuovi Confini',
    },
    threatAnalyticsComp: {
      fabTooltip: 'Apri il pannello di analisi delle minacce',
    },
    home: {
      homeHeader: "L'intelligence granulare sulle minacce inizia qui",
      searchboxPlaceholder: 'Cerca una posizione',
      viewMapButton: 'Guarda la Mappa',
    },
    legendQuintile: {
      emptyTableLabel:
        'Nessuna minaccia entro {r} {u} dalla posizione selezionata.',
      threatEvent: "Dettagli dell'evento di minaccia",
      summaryTable: 'Riepilogo',
      detailsTable: 'Dettagli',
      severityScoreTrend: 'Indice di Gravità',
      incidents: 'Eventi',
      summaryHeaders: {
        eventType: 'Categoria di Evento',
        districtCount: 'Conteggio per distretto',
        areaCount: 'Conteggio per zona',
      },
      detailsHeaders: {
        eventDate: 'Data / Ora',
        subEventType: 'Tipo Di Evento',
        interaction: 'Tipo di Interazione',
        fatalities: 'Vittime',
        granularity: 'Granularità',
        actor1: 'Partecipante 1',
        actor2: 'Partecipante 2',
        description: 'Descrizione',
        id: 'ID Unica',
        source: 'Fonte',
        none: 'NA',
      },
      granularity: {
        street: 'Livello della Strada',
        district: 'Livello Distrettuale',
        city: 'Livello Cittadino',
      },
      rowsPerPage: 'Righe per pagina',
      of: 'di',
      moreThanLabel: 'più di',
      chart: {
        attributionText:
          "Dati provenienti dall' Armed Conflict Location & Event Data Project (ACLED); https://www.acleddata.com",
        lineChartLabel: 'Media Mobile su tre mesi',
        lineChartLabelShort: 'Media',
        barChartLabel: 'Totale',
      },
    },
    tooltip: {
      city: 'Città',
      crime: 'Crimine',
      views: 'Vista Mappa',
      lonLatText: 'Eventi, Mappa di calore',
      neighborhoodText: 'Distrettos',
      to: 'a',
      dates: 'Intervallo di Date',
      types: 'Tipi di crimine',
      unrest: 'Disordine',
      subTypes: 'Tipi di eventi disordini',
      addFavorite: 'Add to favorites',
      removeFavorite: 'Remove from favorites',
      loading: 'Caricamento in corso...',
      noData: 'Nessun dato disponibile',
    },
    legendPopup: {
      quintile: {
        title: 'Livello di Minaccia',
        info: {
          0: 'Alto',
          1: 'Medio-Alto',
          2: 'Medio',
          3: 'Medio-Basso',
          4: 'Basso',
        },
      },
      crimeHeatmap: {
        title: 'Scala del Rischio',
        info: {
          10: 'Alto',
          9: '',
          8: '',
          7: '',
          6: '',
          5: '',
          4: '',
          3: '',
          2: '',
          1: 'Basso',
        },
      },
    },
    crimeQuantile: 'Quintile del crimine',
    riskQuantile: 'Quintile del Minaccia',
    quantiles: {
      1: 'A Basso Rischio',
      2: 'Rischio Medio Basso',
      3: 'Rischio Medio',
      4: 'Rischio Medio Alto',
      5: 'Alto Rischio',
    },
    mapStyle: 'Stile mappa',
    map: 'Mappa',
    light: 'Light',
    satellite: 'Satellitare',
    dark: 'Mappa Scura',
    cuadrante: 'Cuadrante',
    municipio: 'Municipio',
    zona: 'Zona',
    sector: 'Sector',
    contact: 'Contatto',
    clearResults: 'Risultati chiari',
    downloadNow: 'Scarica Adesso',
    pleaseEnterYourEmailAddressBelowAndWeWillSendYouInformationToChangeYourPassword:
      'Inserisci il tuo indirizzo e-mail di seguito e ti invieremo le informazioni per modificare la password.',
    sendRecoveryEmail: 'Invia Email di Recupero',
    email: 'E-mail',
    login: 'Accesso',
    'sign-up': 'Registrazione',
    'forgot-password': 'Ho Dimenticato la Password',
    settings: 'Impostazioni',
    account: 'Profilo Utente',
    signIn: 'Accesso',
    signUp: 'Registrazione',
    forgotPassword: 'Ho Dimenticato la Password',
    confirmSignUp: 'Registrazione Conferma',
    download: 'Scarica',
    show: 'Scarica',
    hide: 'Nascondi',
    lastUpdated: 'ultimo aggiornamento',
    neverReported: 'mai segnalato',
    enterLocation: 'Inserire Posizione',
    dismiss: 'Chiudere',
    sendANotification: 'Invia una Notifica',
    isThisAnEmergencyThatRequiresCheckIn:
      'Trattasi di una emergenza che necessita segnalazione?',
    cancel: 'Cancellare',
    send: 'Invia',
    thereAreNoTeamMembersCurrentlyInThisCity:
      'Al momento non ci sono membri del team in questa città.',
    sendTo: 'Invia a',
    subject: 'Argomento',
    message: 'Messaggio',
    messageSent: 'Grazie! La tua notifica è stata inviata.',
    messageSentTitle: 'Informazioni',
    emergencyContacts: 'Contatti di Emergenza',
    Position: 'Posizione',
    Description: 'Descrizione',
    Email: 'E-mail',
    Phone_Number: 'Numero di Telefono',
    timeFromNotification: 'Tempo dalla Notifica (ore)',
    usersCheckingIn: 'Utenti che effettuano il check-in',
    percentOfUsersResponding: 'Percentuale di utenti che rispondono',
    usersReportingAsSafe: 'Utenti che segnalano di essere al sicuro',
    usersReportingAsUnsafe: 'Utenti che segnalano di non essere al sicuro',
    usersNotified: 'Notificati Utenti:',
    usersReportedSafe: 'Utenti sono al sicuro:',
    usersNeedingHelp: 'Utenti che necessitano assistenza:',
    usersNotReporting: 'Utenti che non hanno risposto:',
    averageResponseTime: 'Tempo medio di risposta',
    reportsWithinFirst3Hours: 'Segnalazioni durante le prime 3 ore:',
    reportsWithinFirst12Hours: 'Segnalazioni durante le prime 12 ore:',
    reportsWithinFirst24Hours: 'Segnalazioni durante le prime 24 ore:',
    reportsWithinFirst72Hours: 'Segnalazioni durante le prime 72 ore:',
    reportsViaApp: 'Segnalazioni attraverso l’App:',
    reportsViaEmail: 'Segnalazioni attraverso E-mai:',
    noNotificationsToShow: 'Non ci sono segnalazioni.',
    untitledNotification: 'Notifica senza Titolo',
    notificationReportsFor: 'Rapporti di segnalazioni',
    chooseANotification: 'Seleziona una segnalazione',
    notificationHistory: 'Segnalazioni precedenti',
    menuSignOut: {
      signOut: 'Esci',
      areYouSureYouWantToSignOutOfBaseOperations:
        'Sei sicuro/a di volere uscire da Base Operations?',
      changePasswordEmail:
        'Un link per cambiare la password è stato inviato al suo indirizzo email. Per favore, controlli la sua mail per continuare.',
      changePasswordOption: 'Cambia la password',
      yes: 'Sí',
      no: 'No',
      userSettings: 'Impostazioni Utente',
    },
    allTeamMembers: 'Tutti i membri della squadra',
    Name: 'Nome',
    Role: 'Ruolo',
    peopleSearch: 'Cerca Persone',
    teamMembersInThisCity: 'Membri della squadra in questa Cittá',
    categories: 'Categorie',
    dates: 'Intervallo di date',
    hours: 'Orario',
    teamMember: 'Membro della Squadra',
    status: 'Situazione',
    location: 'Posizione',
    from: 'Da',
    to: 'A',
    updatingFilters: 'Aggiornamento dei Filtri',
    applyDateFilters: 'Applica I Filtri Del Crimine',
    applyTimeFilters: 'Applica I Filtri Dell’orario',
    applyCrimeFilters: 'Applica I Filtri Del Crimine',
    Harassment: 'Molestia',
    Theft: 'Furto',
    'Armed Robbery': 'Rapina a Mano Armata',
    'Car Robbery': 'Furto d’Auto',
    Assault: 'Assalto',
    ViolentCrimes: 'Crimini Violenti',
    Extortion: 'Estorsione',
    Kidnapping: 'Sequestro di Persona',
    Rape: 'Reati Sessuali',
    Vandalism: 'Vandalismo',
    Piracy: 'Pirateria',
    Terrorism: 'Terrorismo',
    'Drug Offenses': 'Reati di Droga',
    Fraud: 'Frode',
    'Organized Crime': 'Crimine Organizzato',
    Homicide: 'Omicidio',
    Other: 'Microcriminalità',
    'Updating Filters': 'Aggiornamento dei Filtri',
    'Apply Crime Filters': 'Applica I Filtri Del Crimine',
    searchByNameOrEmail: 'Cerca per nome o E-mail',
    findPeople: 'Trova persone',
    companyContacts: "Contatti dell'Azienda",
    companyLocations: "Sedi dell'Azienda",
    worldResources: 'Risorse Globali', // NEED UPDATE "Change "Global Resources" to "In-Country Resources"
    address: 'Indirizzo',
    latitude: 'Latitudine',
    longitude: 'Longitudine',
    actions: 'Azioni',
    pinned: 'Pinned',
    typemembername: 'Digitare il Nome del Membro',
    admin: 'Amministratore',
    officer: 'Ufficiale',
    employee: 'Dipendente',
    delete: 'Elimina',
    language: 'Lingue',
    none: 'None',
    sent: 'Sent',
    by: 'Di',
    atunkowntime: 'a tempo sconosciuto',
    unknown: 'sconosciuto',
    tablelocalization: {
      body: {
        emptyDataSourceMessage: 'Nessuna Localizzazione da Visualizzare',
        addTooltip: 'Inserisci',
        deleteTooltip: 'Elimina',
        editTooltip: 'Modificare',
        filterRow: {
          filterTooltip: 'Filtro',
        },
        editRow: {
          deleteText: 'Sei sicuro di voler cancellare questo?',
          saveTooltip: 'Salva',
          cancelTooltip: 'Cancellare',
        },
      },
      toolbar: {
        searchTooltip: 'Ricerca',
        searchPlaceholder: 'Ricerca',
      },
      header: {
        actions: 'Azioni',
      },
      pagination: {
        nextLabel: 'il prossimo',
        previousLabel: 'precedente',
        labelDisplayedRows: ' {from}-{to} di {count}',
        firstTooltip: 'Prima Pagina',
        previousTooltip: 'Pagina Precedente',
        nextTooltip: 'Pagina Successiva',
        lastTooltip: 'Ultima Pagina',
        showing: 'Visualizzazione',
      },
    },
    uploadPhotoModal: {
      addPhoto: 'Carica una Foto',
      deletePhoto: 'Elimina Foto',
      saveChange: 'Salva Modifiche',
      changePhoto: 'Cambia Foto',
    },
    timeRead: 'Tempo letto',
    responseMethod: 'Metodo di risposta',
    lastLocationUpdate: 'Ultimo aggiornamento posizione',
    deepLinkMessage:
      'Ciao utente Base Operations!<br/>Stai visualizzando questa pagina perché il tuo browser non supporta il collegamento alla nostra app.<br/><br/>Apri l\'app Base Operations e fai clic sull\'icona "Emergenza" per contrassegnarti come "Sicuro" o "In pericolo".<br/><br/>Grazie!<br/>Il team delle Base Operations<br/><br/>',
    filters: {
      gtmSwitchLabel: 'Attiva BaseGTM',
      selectDeselectAll: 'Seleziona/Deseleziona Tutto',
      crimesNumber: ' Eventi in vista',
      selectAll: 'Seleziona Tutto',
      violentCrime: 'Crimini Violenti',
      propertyCrime: 'Reati Contro il Patrimonio',
      boundariesLabel: 'Confini',
      cityHoodsLabel: 'Distretti',
      countryHoodsLabel: 'Province',
      eventsLabel: 'Eventi',
      heatmapLabel: 'Mappa di Calore',
      slider: {
        date: 'Seleziona una data',
      },
      quarters: {
        1: 'Trimestre 1',
        2: 'Trimestre 2',
        3: 'Trimestre 3',
        4: 'Trimestre 4',
      },
      months: {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre',
      },
      sources: {
        1: 'Media di informazione',
        2: 'Social media',
        3: 'Aggregatore di dati',
        4: 'Polizia nazionale',
        5: 'Polizia locale',
        6: 'Polizia universitaria',
        7: 'Chiamate al 911 per servizio',
        8: 'Media affiliati allo Stato',
        9: 'Governo nazionale',
        10: 'Governo locale',
        11: 'Organizzazione non-profit (ONG)',
        12: 'Altro',
        13: 'Sconosciuto',
        14: 'Media',
        15: "Forze dell'ordine",
        16: 'Governo',
        17: 'Organizzazione non-profit (ONG)',
        18: 'Non assegnato',
        19: 'Non assegnato',
        20: 'Movimento Politico',
        21: 'Sindacato',
        22: 'Movimento di Attivisti',
        23: 'Gruppo di Interesse Speciale',
      },
      categories: {
        1: 'Molestia',
        2: 'Furto',
        3: 'Rapina',
        4: 'Furto Di Veicoli',
        5: 'Aggressione semplice',
        6: 'Estorsione',
        7: 'Sequestro Di Persona',
        8: 'Reati Sessuali',
        9: 'Vandalismo',
        10: 'Pirateria',
        11: 'Traffico Di Merci Illegali',
        12: 'Frode',
        13: 'Crimine Organizzato',
        14: 'Omicidio',
        15: 'Microcriminalità',
        16: 'Terrorismo',
        17: 'Corruzione',
        18: 'Crimini Violenti',
        19: 'Attività Sospetta',
        20: 'Disturbo',
        21: 'Gravi Violazioni Della Circolazione',
        22: 'Violazioni Di Traffico Minori',
        23: 'Reati Domestici',
        24: 'Violazioni Di Droga E Alcol',
        25: 'Sconfinare',
        26: 'Violazioni Delle Armi',
        27: 'Non Rilevante',
        28: 'Persona Scomparsa',
        29: 'Suicidio',
        30: 'Attacco Aereo/Drone',
        31: 'Passare Al Gruppo/Attività',
        32: 'Stabilimento Del Quartier Generale O Della Base',
        33: 'Accordo',
        34: 'Arresti',
        35: 'Trasferimento Non Violento Di Territorio',
        36: 'Attacco',
        37: 'Bomba A Mano',
        38: 'Scontro Armato',
        39: 'Uso Interrotto Delle Armi',
        40: 'Bombardamento/Artiglieria/Attacco Missilistico',
        41: 'Saccheggio/Distruzione Di Proprietà',
        42: 'Reato minore',
        43: 'Protesta Pacifica',
        44: 'Protesta Con Intervento',
        45: 'Violenza Di Massa',
        46: 'Forza Eccessiva Contro I Manifestanti',
        47: 'Arma Chimica',
        48: 'Violenza Sessuale',
        49: "L'Attore Non Statale Conquista Il Territorio",
        50: 'Bomba Suicida',
        51: 'Rapimento/Sparizione Forzata',
        52: 'Il Governo Riconquista Il Territorio',
        53: 'Esplosivo Remoto/Mine Antiuomo/IED',
        54: 'Manifestazione Violenta',
        55: 'Manifestazioni',
        56: 'Proteste Non Violente',
        57: 'Altri Reati',
        58: 'Criminalità contro la Proprietà',
        59: 'Criminalità Violenta',
        60: 'Violenza Politica',
        61: 'Aggressione Aggravata',
        62: 'Incendio Doloso',
        63: 'Furto con Scasso',
        64: 'Ricettazione',
        65: 'Taccheggio',
        66: 'Furto da Veicolo',
        67: 'Reati di Proprietà',
      },
      unrestTypes: {
        1: 'Battaglie',
        2: 'Rivolte',
        3: 'Violenza contro i civili',
        4: 'Esplosioni/Violenza a distanza',
        5: 'Sviluppi strategici',
        6: 'Proteste',
      },
      unrestSubtypes: {
        1: 'Rapimento/Sparizione Forzata',
        2: 'Confronto armato',
        3: 'Arresti',
        4: 'Attacco',
        5: 'Cambio di gruppo/evento',
        6: 'Uso di armi da mischia',
        7: 'Uso eccessivo della forza contro i manifestanti',
        8: 'Saccheggio/vandalismo',
        9: 'Folla violenta',
        10: 'Altro',
        11: 'Protesta pacifica',
        12: 'Protesta con intervento',
        13: 'Esplosivo radiocomandato a distanza/mina/esplosivo artigianale',
        14: 'Violenza sessuale',
        15: 'Dimostrazione violenta',
        16: 'Accordo',
        17: 'Attacco aereo/drone',
        18: 'Arma chimica',
        19: 'Governo si riappropria del territorio',
        20: 'Granata',
        21: 'Sede o base stabilita',
        22: "L'attore non statale sorpassa il territorio",
        23: 'Trasferimento non violento di territorio',
        24: 'Bombardamenti/artiglieria/attacco missilistico',
        25: 'Bomba suicida',
      },
      timeslices: {
        0: 'Mattino',
        1: 'Metà Mattinata',
        2: 'Mezzogiorno',
        3: 'Sera',
        4: 'Notte',
      },
      weekdays: {
        0: 'Domenica',
        1: 'Lunedì',
        2: 'Martedì',
        3: 'Mercoledì',
        4: 'Giovedì',
        5: 'Venerdì',
        6: 'Sabato',
      },
      seasons: {
        fall: 'Autunno',
        summer: 'Estate',
        winter: 'Inverno',
        spring: 'Primavera',
      },
      countryCategories: {
        1: 'Stabilità',
        2: 'Stato di Diritto',
        3: 'Corruzione',
        4: 'Trasparenza',
        5: 'Diritti Fondamentali',
        6: 'Criminalità',
        7: 'Conflitto',
        8: 'Resilienza',
        9: 'Leadership Politica',
        10: 'Responsabilità Governativa',
        11: 'Integrità Territoriale',
        12: 'Cooperazione Internazionale',
        13: 'Ordine e Sicurezza',
        14: 'Sistema Legale',
        15: 'Giustizia Penale',
        16: 'Giustizia Civile',
        17: 'Regolamentazione Economica',
        18: 'Applicazione della Regolamentazione',
        19: 'Integrità Pubblica',
        20: 'Indipendenza Giudiziaria',
        21: 'Limitazioni al Governo',
        22: 'Trasparenza Governativa',
        23: 'Governo Aperto',
        24: 'Libertà su Internet',
        25: 'Libertà di Stampa',
        26: 'Diritti Politici',
        27: 'Libertà Civili',
        28: 'Traffico di Persone',
        29: 'Traffico di Armi',
        30: 'Crimini Informatici',
        31: 'Crimini Finanziari',
        32: 'Commercio di Droghe',
        33: 'Estorsione',
        34: 'Crimine Organizzato',
      },
    },
    eventFilter: 'Filtro Eventi',
    Filters: 'Filtri',
    crimeNeighborhoods: 'Distretti',
    protestAreas: 'Eventi disordini',
    crimeTypes: 'Tipi di crimine',
    threatCategories: 'Tipo di Evento',
    sourceCategories: 'Origine dei Dati',
    timeOfYear: "Periodo dell'anno",
    timeOfDay: 'Ora del giorno',
    mapGranularity: 'Granularità della Mappa',
    mapViewOptions: 'Vista Mappa',
    messageDetails: 'Dettagli del messaggio',
    mapThreatType: 'Tipo di Dati',
    unrestSubtypes: 'Tipi di eventi disordini',
    threatCrime: 'Crimine',
    threatUnrest: 'Disordine',
    enterpriseLabel: 'Azienda',
    betaLabel: 'Beta',
    popup: {
      neighborhood: {
        areaAverage: 'Area media',
        cityAverage: 'Media della città',
      },
      drawPolygon: {
        severityAverage: 'Gravità media: ',
        drawIcon: 'Seleziona i punti criminali',
        trashIcon: 'Cancella selezione',
      },
    },
    sendOptions: 'Opzioni di invio',
    sendToAllCompanyEmployees: 'Invia a tutti i dipendenti',
    sendToAllEmployeesInTheSelectedCountries:
      'Invia a tutti i dipendenti nei Paesi selezionati:',
    sendToAllEmployeesInTheSelectedCities:
      'Invia a tutti i dipendenti nelle città selezionate:',
    sendMessage: {
      confirmationDialog: {
        title: 'Invia a tutti i dipendenti in {place}',
        titleCompanyWide: 'Invia a tutti i dipendenti',
        message:
          'Si noti che questa notifica verrà inviata a tutti i dipendenti in {place}. Si prega di confermare o annullare di seguito.',
        messageCompanyWide:
          'Si noti che questa notifica verrà inviata a tutti i dipendenti dell"azienda. Si prega di confermare o annullare di seguito.',
        hideForFutureSelections:
          'Nascondi questo messaggio per future selezioni di paesi',
        confirmSend: 'Confirm send',
        confirmSendTo: 'Conferma invio a {place}',
      },
    },
    modal: {
      continue: 'Continua',
      cancel: 'Annulla',
    },
    notificationHistoryModal: {
      sentOn: 'Inviato',
      sentBy: 'Inviato da',
      usersIncludedInNotification: 'Utenti inclusi nella notifica',
      countriesIncludedInNotification: 'Paesi inclusi nella notifica',
      citiesIncludedInNotification: 'Città incluse nella notifica',
      notificationThread: 'Discussione di notifica',
      notificationUsers: 'Utenti delle notifiche',
      notificationHistory: 'Cronologia delle notifiche',
      addNotificationToThread: {
        addNotificationToThread: 'Aggiungi una notifica al thread',
        confirmationDialog: {
          title: 'Invia una nuova notifica di emergenza',
          message:
            'Desideri reimpostare lo stato di tutti gli utenti in questo thread su "Sconosciuto" che richiede un nuovo check-in?',
          resetAllUsers: 'Ripristina tutti gli utenti',
          retainCurrentUserStatus: 'Mantieni lo stato utente corrente',
        },
      },
    },
    countries: {
      Brazil: 'Brazil',
      Canada: 'Canada',
      Mexico: 'Mexico',
      'United States': 'United States',
    },
    cities: {
      'Mexico City': 'città del Messico',
      Bogotá: 'Bogotá',
      Denver: 'Denver',
      'São Paulo': 'São Paulo',
      'Rio de Janeiro': 'Rio de Janeiro',
      'Washington, DC': 'Washington, DC',
      Niterói: 'Niterói',
      'Buenos Aires': 'Buenos Aires',
      Fortaleza: 'Fortaleza',
      Baghdad: 'Baghdad',
      London: 'London',
      Vancouver: 'Vancouver',
      Santiago: 'Santiago',
      Montevideo: 'Montevideo',
      'Puerto Rico': 'Puerto Rico',
      'Bahía Blanca': 'Bahía Blanca',
      Other: 'Altro',
    },
    locationInsights: {
      noData:
        'Non sono disponibili dati recenti sui crimini per questa posizione.',
      diffReportingFrequency:
        'I dati sui crimini e i dati sugli eventi di agitazione hanno una copertura temporale e una granularità temporale diverse per questa posizione. I dati sui crimini vengono segnalati {crime} e i dati sugli eventi di agitazione vengono segnalati {unrest}. I grafici come il grafico di Tendenza degli Eventi e il grafico di Contribuzione delle Categorie potrebbero essere influenzati da questa discrepanza. Per visualizzare correttamente i grafici, visualizzare separatamente i dati sui crimini e i dati sugli eventi di agitazione utilizzando il Filtro Tipo di Evento.',
    },
    keyTakeaways: {
      quintiles: {
        1: 'Basso',
        2: 'Basso-Medio',
        3: 'Medio',
        4: 'Medio-Alto',
        5: 'Alto',
      },
      // District Comparison
      districtCompare:
        "{totalCount} crimini segnalati rispetto ad altri distretti (quintile {quintile}) all'interno dello stesso {locationType}. Il distretto ha un livello di minaccia {threatLevel} per le categorie di crimine selezionate.",
      // Highest Crime
      highestCrimeRadius:
        '{crimeCategory} è la categoria di crimine più alta segnalata entro un raggio di {radius} {units}, rappresentando il {crimePercentage} degli eventi verificati.',
      highestCrimeDistrict:
        '{crimeCategory} è la categoria di crimine più alta segnalata nel distretto, rappresentando il {crimePercentage} degli eventi verificati.',
      highestCrimeCity:
        '{crimeCategory} è la categoria di crimine più alta segnalata nella città, rappresentando il {crimePercentage} degli eventi verificati.',
      // Day vs Night Greater Than 20
      dayVsNightGreaterRadius:
        '{nightPercentage} degli eventi segnalati entro un raggio di {radius} {units} si sono verificati di sera e di notte, mentre il {dayPercentage} si sono verificati durante il giorno.',
      dayVsNightGreaterDistrict:
        '{nightPercentage} degli eventi segnalati nel distretto si sono verificati di sera e di notte, mentre il {dayPercentage} si sono verificati durante il giorno.',
      dayVsNightGreaterCity:
        '{nightPercentage} degli eventi segnalati nella città si sono verificati di sera e di notte, mentre il {dayPercentage} si sono verificati durante il giorno.',
      // Day vs Night Less Than 20
      dayVsNightLessRadius:
        'Entro un raggio di {radius} {units}, il crimine è relativamente uniforme durante la giornata, con il {dayPercentage} degli eventi che si verificano durante il giorno e il {nightPercentage} che si verificano di sera e di notte.',
      dayVsNightLessDistrict:
        'Nel distretto, il crimine è relativamente uniforme durante la giornata, con il {dayPercentage} degli eventi che si verificano durante il giorno e il {nightPercentage} che si verificano di sera e di notte.',
      dayVsNightLessCity:
        'Per {locationName}, il crimine è relativamente uniforme durante la giornata, con il {dayPercentage} degli eventi che si verificano durante il giorno e il {nightPercentage} che si verificano di sera e di notte.',
      // Property vs. Violent Crime
      propertyVsViolentRadius:
        'Il {violentCrimePercentage} degli eventi segnalati entro un raggio di {radius} {units} sono {violentCrime} e il {propertyCrimePercentage} sono {propertyCrime}.',
      propertyVsViolentDistrict:
        'Il {violentCrimePercentage} degli eventi segnalati nel distretto sono {violentCrime} e il {propertyCrimePercentage} sono {propertyCrime}.',
      propertyVsViolentCity:
        'Il {violentCrimePercentage} degli eventi segnalati nella città sono {violentCrime} e il {propertyCrimePercentage} sono {propertyCrime}.',
    },
    httpErrorMessages: {
      400: 'Richiesta non valida: {message}.',
      403: 'Non sei autorizzato a eseguire questa azione.',
      429: 'Troppe richieste: Riprova più tardi.',
      500: 'Errore interno del server: Uh Oh! Qualcosa è andato storto, stiamo lavorando per risolvere.',
      clientError: 'Per favore, riprova.',
      serverError: 'Qualcosa è andato storto, per favore riprova più tardi.',
    },
    textLimit: {
      showLess: 'Vedi meno',
      showMore: 'Vedi altro',
      less: 'meno',
      more: 'altro',
    },
    reportDownloadModal: {
      allThreatsTypesLabel: 'Tutti i Tipi di Minacce',
      reportNameLabel: 'Nome del Rapporto',
      defaultReport: 'Rapporto Predefinito',
      defaultReportName: 'Il Mio Rapporto',
      onePageReport: 'Rapporto di una Pagina',
      customReport: 'Rapporto Personalizzato',
      radiusHeader: 'Raggio',
      districtHeader: 'Distretto',
      cityHeader: 'Città',
      summaryPageLabel: 'Sommario',
      changeAnalysisLabel: 'Analisi delle Variazioni',
      threatAnalysisLabel: 'Analisi delle Minacce',
      districtAnalysisLabel: 'Analisi del Distretto',
      trendAnalysisLabel: 'Analisi delle Tendenze',
      dataSourcesLabel: 'Fonti di Dati',
      selectionLabel: 'Seleziona le Sezioni',
      infoCaptionLabel:
        'Ogni sezione selezionata è una pagina aggiuntiva nel rapporto',
      downloadLabel: 'Scarica il Rapporto',
      downloadMessage:
        "La generazione del report richiede circa 5 minuti, riceverai un'email quando sarà pronto per il download.",
    },
  },
  pt_BR: {
    errorPage: {
      title: 'Ops, ocorreu um erro inesperado.',
      subtitle:
        'Nossa equipe foi notificada desse problema, por favor atualize seu navegador para continuar utilizando nossa plataforma.',
      refreshButton: 'Atualizar Navegador',
      goHome: 'Ir para Página Inicial',
      contactSupport: 'Contatar Suporte',
    },
    featureAccess: {
      forecastFeedback:
        'Entraremos em contato em breve sobre a Previsão de Tendências.',
      forecastTeaser:
        'A Previsão de Tendências está disponível através do nosso Plano Empresarial. Contacte-nos para atualizar.',
      chartTeaser: 'Acompanhe as mudanças ao longo do tempo com Enterprise',
      explore: 'Explorar agora',
      enterpriseFeature: 'Esta é uma funcionalidade empresarial',
      thanks: 'Obrigado!',
      offerUpgrade: 'Atualizar sua conta desbloqueará esta funcionalidade.',
      upgradeFeedback:
        'Recebemos sua solicitação. Entraremos em contato em breve.',
      interest: 'Estou interessado',
      closeLabel: 'Fechar',
      teaserTitle: 'Descubra mais insights em Empresarial',
      teaserBullet1:
        'Obtenha insights sobre as mudanças na criminalidade em diferentes locais ao longo do tempo',
      teaserBullet2:
        'Aprofunde-se com análises detalhadas das mudanças nas categorias de crimes',
      teaserBullet3:
        'Personalize as visualizações de mudanças para categorias de crimes precisas, períodos de tempo e fontes de dados',
      contact: 'Entre em contato conosco',
    },
    tooltips: {
      forecastTrend:
        'Exibe uma previsão das tendências de criminalidade baseada em dados históricos, projetando possíveis taxas e padrões de criminalidade futuros',
      gtmNotAvailable:
        'A previsão do BaseGTM não está disponível para esta localização',
      gtmDisabled: 'BaseGTM deve ser ativado',
      selectionNotAvailable:
        'Não disponível para esta localização ou granularidade',
      gtmPowered: 'Impulsionado por BaseGTM.',
      gtmAvailable:
        'A modelagem é usada para contabilizar o ruído nos dados resultante de inconsistências nos relatórios para melhorar a utilidade dos resultados.',
      gtmUnavailable:
        'As estimativas de BaseGTM não estão disponíveis para esta localização',
      layerNotAvailable:
        'Esta camada não está disponível no nível de zoom atual; amplie para vê-la.',
      districtsNotAvailable:
        'Lo strato dei confini non è disponibile in questa località.',
      eventsNotAvailable:
        'Lo strato degli eventi non è disponibile in questa località.',
      heatmapNotAvailable:
        'Lo strato della mappa di calore non è disponibile in questa località.',
      threatScore: BASE_SCORE,
      threatScoreNotAvailable:
        'A camada BaseScore não está disponível nesta localização.',
      population: 'População Estimada',
      trendline:
        'As linhas de tendência e médias de crime são determinadas com base no modelo de ameaça de Operações Básicas. Este modelo ajuda a levar em conta o ruído nos dados resultante de inconsistências nos relatórios para melhorar a utilidade dos resultados.',
      historical:
        'A média mensal histórica é determinada a partir de todo o histórico disponível.',
      amountAndDirectionOfChange:
        'Localizações salvas plotadas pela direção e magnitude da mudança.',
      topTenCitiesWithMostChange:
        'Ranking de cidades com localizações salvas com o maior aumento ou diminuição em eventos relatados.',
      numberOfLocationsByChange:
        'Número de locais que se enquadram em cada limite de alteração para o período escolhido nas configurações.',
      violentCrimeChange:
        'Número de localizações salvas por quantidade de mudança no total de instâncias de crime violento.',
      propertyCrimeChange:
        'Número de localizações salvas por quantidade de mudança no total de instâncias de crime contra a propriedade.',
      optionNotAvailable:
        'Esta opção não está disponível para a localização selecionada.',
      sourceNotSelected:
        'Esta fonte foi desselecionada no painel de filtro à esquerda. Por favor, selecione-a novamente se desejar exibi-la no gráfico.',
      sourceThreatCategoriesNotSelected:
        'Todas as categorias de eventos desta fonte foram desselecionadas no painel de filtro.',
      notEnoughData:
        'Não há dados suficientes para exibir as mudanças para esta fonte. Se você aplicou filtros, tente removê-los.',
      betaLabel: 'Esta é uma funcionalidade Beta',
      addLocation: 'Adicionar local',
      deleteLocation: 'Remover localização',
      threatAnalyticsEventSummary:
        'Exibe contagens de eventos ocorridos dentro do raio de ameaça selecionado.',
      crimeFilterDataType: `Alternar entre crime e agitação:
      • Crime - Inclui eventos de ameaça como roubo e homicídio.
      • Desordem - Inclui eventos de ameaça, como tumultos e protestos.`,
      crimeFilterMapView: `Veja as visualizações de mapas disponíveis, incluindo:
      • Eventos de Crime - Eventos de ameaça planejados individualmente.
      • Mapa de calor do crime - hotspots de atividade de ameaças.
      • Distritos - Ameaça atividade em áreas administrativas dentro de um país, região ou cidade.`,
      crimeFilterQuintile: `Filtre os distritos com base no nível de ameaça em relação a outros distritos neste local.
      • O nível de ameaça é baseado no número e gravidade dos eventos em um distrito.
      • Os níveis de ameaça são classificados em quintis.
      • Azul claro indica o nível de ameaça relativa mais baixo (0-20% dos distritos) e rosa escuro indica o nível de ameaça relativa mais alto (80-100%).`,
      crimeFilterTypes:
        'Filtre eventos de ameaças por tipo ou categoria de crime. Crime violento e crime contra a propriedade são categorizações resumidas que incluem vários tipos de crimes.',
      crimeFilterQuarters:
        'Filtrar eventos de ameaças pelo trimestre em que ocorreram.',
      crimeFilterDay: 'Filtre eventos de ameaças por hora do dia.',
      crimeFilterDate: 'Filtre eventos de ameaças por intervalo de datas.',
      threatAnalyticsSeverities: `Exibe o nível de ameaça do distrito selecionado mês a mês em relação a outros distritos neste local.
      • O nível de ameaça é baseado no número e gravidade dos eventos em um distrito.
      • Os níveis de ameaça são classificados em quintis.
      • Azul claro indica o nível de ameaça relativa mais baixo (0-20% dos distritos) e rosa escuro indica o nível de ameaça relativa mais alto (80-100%).`,
      threatAnalyticsMonthly:
        'Exibe eventos de ameaças ao longo do tempo. Exibe a contagem mensal de eventos e a contagem de eventos como uma média contínua de 30 dias para avaliar a variação real com base no tempo e uma linha de tendência suavizada, respectivamente.',
      threatAnalyticsRolling:
        'Exibe eventos de ameaças ao longo do tempo. Exibe a contagem mensal de eventos e a contagem de eventos como uma média contínua de 30 dias para avaliar a variação real com base no tempo e uma linha de tendência suavizada, respectivamente.',
      threatAnalyticsHistorical:
        'Exibe a tendência de ameaças sazonais usando o número médio de eventos de ameaças mensais nos últimos três anos ou mais. O intervalo de datas é exibido acima do gráfico.',
      threatLandscape:
        'As informações mais relevantes sobre as ameaças que impactam uma localização.',
      featuredEvents:
        'Os eventos de agitação mais recentes e dignos de nota que ocorreram em um local.',
      notableEvents:
        'Os eventos de agitação mais sérios e notáveis que ocorreram na granularidade selecionada nos últimos três meses a partir da data mais recente selecionada.',
      eventSummary:
        'Exibe a contagem de eventos que ocorreram dentro da granularidade selecionada',
      eventComposition:
        'Exibe a frequência de eventos que ocorreram dentro da granularidade selecionada. Quanto maior a fonte, mais frequente é o evento.',
      eventBreakdown:
        'Exibe o desdobramento de eventos por categorias, subcategorias e horário do dia.',
      eventTrend:
        'Exibe eventos de ameaças ao longo do tempo. Exibe contagens mensais ou diárias de eventos, bem como contagem média geral do período, contagem média móvel e contagem média mensal histórica para avaliar a variação real baseada no tempo, tendência suavizada e médias sazonais.',
      changeTrend:
        'Exibe a mudança nos eventos ao longo do tempo por fonte. Exibe a mudança mensal ou trimestral em comparação com o período anterior ou o ano anterior.',
      categoryContributions:
        'Exibe eventos ao longo do tempo divididos por categorias de eventos.',
      periodAverage:
        'Exibe a média do número de ameaças ao longo do período escolhido dentro da granularidade selecionada.',
      dayOfWeek:
        'Exibe a média do número de ocorrências por dia da semana dentro da granularidade selecionada.',
      timeOfDay:
        'Exibe a média do número de ocorrências por horário do dia dentro da granularidade selecionada.',
      severityScore:
        'Exibe a contagem de eventos de ameaças e os níveis de ameaça para o distrito selecionado em relação a outros distritos nesta localização. \n • O nível de ameaça é baseado no número e na gravidade dos eventos em um distrito. \n • Os níveis de ameaça são classificados em quintis. \n • Azul claro indica o nível de ameaça relativo mais baixo (0-20% dos distritos) e rosa escuro indica o nível de ameaça relativo mais alto (80-100%).',
      severityScoreTrend:
        'Exibe o nível de ameaça dividido por categorias para o distrito selecionado mês a mês em relação a outros distritos nesta localização. \n • O nível de ameaça é baseado no número e na gravidade dos eventos em um distrito. \n • Os níveis de ameaça são classificados em quintis. \n • Azul claro indica o nível de ameaça relativo mais baixo (0-20% dos distritos) e rosa escuro indica o nível de ameaça relativo mais alto (80-100%).',
      dataSourceTrend:
        'Exibe eventos ao longo do tempo divididos por fontes de dados. As fontes são ordenadas pelo volume de dados para o intervalo de datas selecionado.',
      dataSourceInsights:
        'Exibe informações sobre nuances das fontes de dados para o local selecionado.',
      locationInsights:
        'Exibe informações sobre nuances de relatórios de dados para este local.',
      colorLegend:
        'Indica o nível de ameaça em um local que varia de baixo (azul claro) a alto (rosa escuro). O nível de ameaça é baseado no número e gravidade dos eventos.',
      unrestFilterDataType: `Alternar entre crime e agitação:
      • Crime - Inclui eventos de ameaça como roubo e homicídio.
      • Desordem - Inclui eventos de ameaça, como tumultos e protestos.`,
      unrestFilterMapView:
        'Veja os locais dos eventos de agitação. Os pinos correspondem a locais onde ocorreram eventos de agitação.',
      unrestFilterTypes:
        'Filtre eventos de agitação por tipo. Você pode selecionar um único tipo de agitação ou vários tipos de agitação ao mesmo tempo.',
      unrestFilterDate: 'Filtre eventos por período.',
      unrestLegendSummary:
        'Exibe uma contagem de eventos de agitação por tipo. Clicar na seta branca ao lado de cada tipo expandirá para mostrar os subtipos de eventos.',
      threatEventsDetail:
        'Exibe informações adicionais sobre cada evento de agitação. Clique na seta branca para mostrar detalhes, incluindo os atores envolvidos, uma descrição do evento e fontes de dados.',
      unrestLegendChart:
        'Exibe eventos de agitação em incrementos mensais. Cada incremento é uma média dos últimos 3 meses.',
      navbarScreenShot: 'Captura de tela',
      saveLocation: 'Locais salvos',
      cancelSaveLocation: 'Cancel',
      toggleFiltersPanelTooltip: 'Mostrar/Ocultar Painel de Filtros',
      filterPriorityRating:
        'Filtrar locais salvos por classificação de prioridade',
      filterTags: 'Filtrar locais salvos por tags',
      sourceTypeFilter:
        'Filtra eventos de ameaça por fonte de dados. As fontes são ordenadas pelo volume de dados para todo o intervalo de tempo abrangido.',
      unassignedSource:
        'Dados recém-adicionados normalmente são inseridos como não atribuídos. Dados com tipo de fonte não atribuída são atualizados mensalmente.',
      comingSoon: 'Em breve',
      changeMinimum: 'A alteração é baseada em um raio mínimo de 1mi.',
      percentageChangeByLocationType:
        'Agrupa o tipo de localização por grau de mudança. Altere o tipo de local editando o tipo de qualquer local salvo.',
      percentageChangeByLocationTag:
        'Agrupa a tag de localização por grau de mudança. Altere editando as tags de qualquer local salvo.',
      countryScoreBreakdown:
        'O Country BaseScore representa a ameaça e a estabilidade relativas dos países em uma escala padronizada de 0 (melhor) a 100 (pior).',
    },
    horizontalNavBar: {
      breadcrumbs: {
        map: 'Mapa',
        'my-locations/list': 'Meus Locais',
        user: 'Perfil',
        home: 'Início',
        'my-locations/analysis': 'Painel Meus Locais',
      },
    },
    savedLocations: {
      locationOverview: 'Visão Geral da Localização',
      threatProfile: 'Perfil de Ameaça',
      degreeOfChange: 'Grau de Mudança',
      filtersTitle: 'Filtro de Localização',
      positionUpdated: 'Posição atualizada! Salvar essas alterações?',
      threatChange: 'Mudança Média por Categoria de Ameaça',
      threatLandscape: 'Paisagem de Ameaças',
      indeterminateCaption: 'O gráfico inclui alterações para {count1} locais.',
      emptyThreatLandscape:
        'Não há dados suficientes para Mudança e Paisagem de Ameaças para esta localização específica',
      showCharts: 'Mostrar Gráficos',
      barChartTitle: 'Contagem de Localizações por Mudança Percentual',
      magnitudeOfChange: 'Magnitude da Mudança',
      locations: 'localizações',
      citiesTableTitle: 'Cidades Classificadas',
      locationsTableTitle: 'Locais Classificados',
      changeMapTitle: 'Quantidade e Direção da Mudança',
      changeColorScaleLabel: 'Direção da Mudança',
      changeSizeScaleLabel: 'Magnitude da Mudança',
      changeTrendHeaders: {
        cityName: 'Cidades Classificadas',
        locationName: 'Localizações Classificadas',
        change: 'Mudança',
        savedLocationCount: '# de Locais',
        lastVisited: 'Última Visita',
        score: BASE_SCORE,
      },
      remaining: 'Restante',
      none: 'Nenhum',
      addNewTag: 'Criar nova etiqueta',
      maxTags: 'Número máximo de tags permitidos por localização: 15',
      newTag: 'Nova etiqueta',
      allTags: 'Todos Etiquetados',
      yesLabel: 'Sim',
      noLabel: 'Não',
      createdLocationLabel: 'Localização salva!',
      updatedLocationLabel: 'Localização atualizada!',
      deletedLocationLabel: 'Localização excluída!',
      collapseModalLabel: 'Ver menos campos',
      expandModalLabel: 'Ver mais campos',
      saveLocationLabel: 'Salvar esta localização?',
      unrestLabel: 'Rebelião',
      crimeLabel: 'Crime',
      viewMapTooltip: 'Ver mapa',
      viewDetailsTooltip: 'Ver detalhes',
      filterLocationsTitle: 'Filtrar Locais Salvos',
      // Control Actions
      closeButtonText: 'Fechar',
      filterButtonText: 'Filtro',
      sortButtonText: 'Ordenar por',
      searchInputText: 'Pesquisar Locais Salvos',
      cancelButtonText: 'Cancelar',
      editButtonText: 'Editar',
      selectAll: 'Selecionar Tudo',
      moreDetails: 'Detalhes',
      saveButton: {
        text: 'Salvar',
        loading: 'Salvando',
      },
      deleteButton: {
        text: 'Excluir',
        loading: 'Excluindo',
      },
      // Filter Categories
      regionLabel: 'Região',
      countryLabel: 'País',
      locationTypeLabel: 'Tipos de Localização',
      priorityRatingLabel: 'Classificação de Prioridade',
      includeUnratedLabel: 'Incluir locais não classificados',
      tagsLabel: 'Etiquetas',
      office: 'Escritório',
      hotel: 'Hotel',
      event: 'Evento',
      restaurant: 'Restaurante',
      travel: 'Viagem',
      warehouse: 'Armazém',
      parking: 'Estacionamento',
      service_area: 'Área de Serviço',
      point_of_interest: 'Ponto de Interesse',
      retail: 'Varejo',
      facility: 'Instalações',
      other: 'Outro',
      // Detail/Edit Drawer
      locationNotFound: 'Local Não Encontrado',
      locationNameLabel: 'Nome do Local',
      displayAddressLabel: 'Endereço de Exibição',
      coverageLabel: 'Cobertura',
      customerIdLabel: 'ID do Local do ClienteD',
      dateRangeLabel: 'Período',
      latitudeLabel: 'Latitude',
      longitudeLabel: 'Longitude',
      locationTypePlaceholder: 'Selecione o Tipo de Local',
      // Saved Locations List Tile Columns
      location: 'Localização',
      coverageDates: 'Datas de cobertura',
      area: 'Área',
      rating: 'Classificação',
      tags: 'Tags',
      // More
      locationTypeTooltip: 'Filtrar locais salvos por tipo de local',
      visibilityTooltip: 'Filtrar locais salvos por sua visibilidade',
      sortOptions: {
        name: 'Nome',
        customerLocationId: 'ID da Localização',
        customerRating: 'Classificação de Prioridade',
        change: 'Alteração',
        changeTooltip: `Todas as opções são baseadas em cálculos de linha de tendência em nível de cidade e são normalizadas para comparação entre locais:
        • Variação mensal anual:
        por exemplo. Agosto de 2023 x agosto de 2022
        • Mudança anual:
        por exemplo. Setembro de 2022 - agosto de 2023 vs setembro de 2021 - agosto de 2022
        • Índice de Mudanças
        Uma combinação de três métricas de mudança (mês a mês mais recente, trimestre a trimestre e mudança trimestral ano a ano) para incluir padrões de mudança de curto, médio e longo prazo.`,
        changeIndex: 'Índice de alterações',
        oneMonthPeriodOverYear: 'Alteração Mensal anual',
        twelveMonthPeriodOverPeriod: 'Alteração ano após ano',
      },
      visibilityLabel: 'Visibilidade',
      visibilityOptions: {
        private: 'Privado',
        shared: 'Compartilhado',
      },
      all: 'Todos',
      userCannotEdit:
        'Somente proprietários e administradores têm permissão para editar um local salvo compartilhado.',
      gridViewTooltip: 'Exibição em Grade',
      listViewTooltip: 'Exibição de Lista',
      sortOrderTooltip: 'Ordem de Classificação',
      filtersTooltip: 'Filtros Abertos',
      noSavedLocationsFirst: 'Nenhum local salvo. Remova alguns filtros ou',
      clickHere: 'clique aqui',
      noSavedLocationsSecond: 'para ir ao mapa.',
      goToLocation: 'Ir para o local',
      noCoverage: 'Nenhuma Cobertura',
      additionalTags: 'Etiquetas adicionais',
      coverageFrom: 'Cobertura de',
      change: 'Alteração',
      changeNotAvailable: 'A alteração não está disponível para este local',
      priorityRating: 'Classificação de Prioridade',
      percentageChangeByLocationType:
        'Porcentagem de alteração por tag de local',
      percentageChangeByLocationTag: 'Porcentagem de mudança por tipo de local',
      breakdownByLocationType: 'Divisão por tipo de local',
      breakdownByLocationTag: 'Divisão por tag de localização',
    },
    user: {
      tab1: 'Perfil do Usuário',
      tab2: 'Alterar Senha',
      tab3: 'Gerenciar Token de API',
      tab4: 'Gerenciar Usuários',
      title: 'Configurações da Conta',
      tempTitle: 'Perfil de Usuário',
      firstNameLabel: 'Nome',
      lastNameLabel: 'Sobrenome',
      emailLabel: 'Email',
      languageLabel: 'Idioma',
      unitsLabel: 'Unidades',
      saveChanges: 'Salvar Alterações',
      allChangesSaved: 'Todas as Alterações Salvas',
      validationLabel: 'O nome é obrigatório',
      textFieldLabel: 'Nome da chave da API',
      copyLabel: 'Copiar',
      deleteLabel: 'Excluir',
      createLabel: 'Gerar',
      warningText:
        'Foi gerada uma chave de API pessoal para acessar a API Rest de Operações Base. Certifique-se de copiar sua chave de API agora. Uma vez que esta janela for fechada, ela não poderá mais ser recuperada.',
      deleteText: 'Você está prestes a excluir a chave',
      createTitle: 'Gerar Chave de API',
      deleteAction: 'Excluir Chave',
      deleteFeedback: 'Chave de API Excluída',
      deleteTitle: 'Excluindo a chave de API...',
      copyTitle: 'Chave de API Gerada com Sucesso',
      createFeedback: 'Chave de API Gerada',
      copyFeedback: 'Chave de API copiada para a área de transferência!',
      createButton: 'Gerar Novo Token de API',
      nameLabel: 'Nome da Chave',
      secretLabel: 'Segredo da API',
      dateLabel: 'Criado em',
      actionsLabel: 'Ações',
      maxValidation: 'O campo de texto não deve exceder 30 caracteres',
    },

    threatAnalytics: {
      overallCrime: 'Mudança geral',
      saveDefaultLabel: 'Salvar como padrão',
      changeMapTitle: 'Quantidade e Direção da Mudança',
      acledAttribution:
        '** A Base Operations adquire dados de milhares de fontes. Aplicamos técnicas avançadas de estatística, geografia e IA para validar e aprimorar esses dados. Alguns dados obtidos para esta localização foram fornecidos por: Armed Conflict Location & Event Data Project (ACLED)',
      noThreatEvents:
        'Nenhum evento de ameaça foi relatado neste local para os tipos de ameaças e período de tempo selecionados.',
      titleSeverity: 'Tendência da Pontuação de Gravidade',
      titleIncidents: 'Incidentes',
      titleIntro: 'Análise de ameaças para ',
      tabDistrict: 'Distrito',
      tabProvince: 'Província',
      tabRadius: 'Raio',
      tabCity: 'Cidade',
      tabCountry: 'País',
      titleDistrict: 'o distrito de',
      titleLocation: 'Localização',
      titleThreatAnalytics: 'Análise de Ameaças',
      titleCrimeEvents: 'Eventos de Ameaça',
      textRadius: 'Raio de',
      textRadiusPrefix: 'num raio de ',
      textRadiusSuffix: '',
      titleJoin: 'em ',
      quintileTitle: 'Quintiles de Ameaça',
      quintileSubtitle: 'Quintil de Ameaça: ',
      chartSubtitleStart: 'De ',
      chartSubtitleJoin: ' a ',
      openAnalytics: 'Painel de Análise',
      closeAnalytics: 'Painel de Análise',
      chart: 'Gráfico',
      averageType: 'Tipo de Média',
      aggregationPeriod: 'Período de Agregação',
      period: 'Periodicidade',
      chartTitle1: 'Contagem de Eventos por Categoria de Ameaça',
      chartTitle2: 'Composição do evento',
      chartTitle3: 'Divisão da Hora do Dia',
      chartTitle4: 'Tendência de Eventos Mensais',
      chartTitle5: 'Tendência de Mudança',
      chartTitle9: 'Pontuação de gravidade',
      chartTitle10: 'Tendência da pontuação de gravidade',
      chartTitle11: 'Eventos Mensais por Fonte',
      chartTitle12: 'Média de Eventos por Mês',
      chartTitle13: 'Frequência de Eventos por Categoria de Ameaça',
      chartTitle14: 'Tendência Diária de Eventos',
      chartTitle15: 'Eventos Médios por Mês do Ano',
      chartTitle16: 'Eventos Médios por Dia da Semana',
      chartTitle17: 'Eventos Médios por Hora do Dia',
      chartTitle18: 'Contagem de Localizações por BaseScore',
      chartTitle19: 'Contagem de Localizações por Mudança Percentual',
      chartTitle20: 'Contagem de Localizações por Tipo e Mudança',
      chartTitle21: 'Contagem de Localizações por Tag e Mudança',
      chartTitle22: 'Contagem de Localizações por Tipo e BaseScore',
      chartTitle23: 'Contagem de Localizações por Tag e BaseScore',
      chartTitle24: 'Desdobramento por Tipo de Localização',
      chartTitle25: 'Desdobramento por Tag de Localização',
      chartTooltip: 'Agrupa tipos de localização por grau de mudança',
      chartTooltip1: 'Agrupa tags de localizações por grau de mudança',
      chartTooltip2: 'Agrupa tipos de localização por grau de mudança',
      chartTooltip3: 'Agrupa tipos de localização por BaseScore',
      chartTooltip4: 'Agrupa tags de localizações por BaseScore',
      chartTooltip5: 'Agrupa localizações por tipo de localização',
      chartTooltip6: 'Agrupa localizações por tag de localização',
      locationInsightsTitle: 'Informações de Localização',
      takeAwaysTitle: 'Mudança Percentual',
      dataObservationsTitle: 'Informações sobre a fonte de dados',
      threatEventsTableTitle: 'Detalhes de eventos de ameaças',
      notableEventsTitle: 'Eventos Notáveis',
      numberOfLocationsByChange: 'Número de locais por mudança',
      numberOfLocationsByChangeSubtitle: 'Mudança geral',
      citiesWithMostChange: 'As 10 principais cidades com mais mudanças',
      changeTrendValues: {
        higher: 'mais alto',
        lower: 'mais baixo',
      },
      label1: 'Crimes',
      label2: 'Média',
      label3: 'Gravidade',
      label4: 'Crime Total',
      label5: '30-Dias Média Móvel',
      label6: '3 Anos',
      label7: '4 Anos',
      label8: '5 Anos',
      label9: 'Gama Completa',
      label10: 'Por Mês',
      label11: 'Contagem de eventos',
      label12: 'Ameaças',
      value1: 'Janeiro',
      value2: 'Fevereiro',
      value3: 'Março',
      value4: 'Abril',
      value5: 'Maio',
      value6: 'Junho',
      value7: 'Julho',
      value8: 'Agosto',
      value9: 'Setembro',
      value10: 'Outubro',
      value11: 'Novembro',
      value12: 'Dezembro',
      topicSelectionText: 'Selecione um tópico para análise',
      eventsWithDescription: 'Eventos apenas com descrição',
      panelType: {
        overview: 'Visão Geral',
        analysis: 'Análise de Tendências',
        changeAnalysis: 'Análise de Mudança',
        breakdown: 'Detalhamento da Ameaça',
        notableEvents: 'Eventos Notáveis',
        eventList: 'Lista de Eventos',
        source: 'Fonte de Dados',
        comparative: 'Comparativo',
        countryAnalysis: 'Análise do País',
        forecast: 'Previsão de Tendências',
      },
      panelTitles: {
        overview: 'Visão Geral do Cenário de Ameaças',
      },
      overviewSection: {
        threatScore: BASE_SCORE,
        percentChange: 'Mudança percentual',
        monthlyEvents: 'Eventos relatados',
        monthlyEventsSuffix: 'mês',
        population: 'População',
        area: 'Área',
      },
      reportButton: 'Gerar Relatório de Localização',
      goToEventList: 'Vá para a lista de eventos para eventos adicionais',
      countryAnalysis: {
        threatScore: `País ${BASE_SCORE}`,
        countryScoreBreakdown: 'Análise da Ameaça ao País',
        overallScoreWeights: 'Pesos da pontuação geral',
        threatFrom: 'Ameaça de...',
        score: 'Pontuação',
        quintile: 'Quintil',
      },
      forecastTrendSection: {
        eventCountLegend: 'Contagem de Eventos',
        trendLineLegend: 'Linha de Tendência',
        forecastLegend: 'Previsão',
        confidence75Legend: 'Confiança de 75%',
        confidence90Legend: 'Confiança de 90%',
        forecastTooltip: 'Previsão',
        trendTooltip: 'Tendência',
        countTooltip: 'Contagem',
        confUb90Tooltip: '90% Superior',
        confUb75Tooltip: '75% Superior',
        confLb75Tooltip: '75% Inferior',
        confLb90Tooltip: '90% Inferior',
      },
    },
    navigation: {
      search: 'Procurar',
      filter: 'Filtro',
      home: 'Lar',
      saved: 'Salvou',
      map: 'Mapa',
      help: 'Ajuda',
      settings: 'Configurações',
      login: 'Conta',
      myLocationsAnalysis: 'Meu Painel de Locais',
    },
    myLocationsAnalysis: {
      keyTakeaways: {
        title: 'Pontos Principais',
        count: 'Localizações Salvas',
        recentCount: 'Localizações Avaliadas',
        averageThreatScore: 'BaseScore Médio',
        averageChange: 'Mudança Média',
      },
      downloadChartImages: 'Baixar Imagens do Gráfico',
      downloadSavedLocationReport:
        'Baixe o Relatório de Localização Salva como CSV',
      settings: {
        aggregationOptions: ['Mensal', 'Trimestral', 'Anual'],
        periodOptions: ['Período sobre Período', 'Ano sobre Ano'],
        listTypeOptions: {
          savedLocations: 'Localizações',
          cities: 'Cidades',
        },
        locationBreakdownOptions: {
          type: 'Tipo',
          tag: 'Etiqueta',
        },
        changeTitle: 'Mudança',
        listTitle: 'Lista',
        locationBreakdownTitle: 'Desagregação de localizações',
        changeTooltip: 'A mudança é baseada em um raio mínimo de 1 mi.',
      },
      filters: {
        title: 'Filtros de localização',
        allTypesLabel: 'Tipos de localização',
        allTagsLabel: 'Tag',
        allDegreeOfChangesLabel: 'Grau de mudança',
        degreeOfChangeOptions: [
          {name: 'Grande Aumento', value: 'large_increase'},
          {name: 'Aumentar', value: 'increase'},
          {name: 'Pequena Alteração', value: 'no_change'},
          {name: 'Diminuir', value: 'decrease'},
          {name: 'Grande Diminuição', value: 'large_decrease'},
          // {name: 'Indeterminado', value: 'indeterminate'},
        ],
        threatScoreOptions: [
          {name: '>80', value: 'very_high'},
          {name: '60 - 79', value: 'high'},
          {name: '40 - 59', value: 'medium'},
          {name: '20 - 39', value: 'low'},
          {name: '<20', value: 'very_low'},
        ],
      },
    },
    searchModal: {
      savedLocationsTitle: 'Localizações Salvas',
      gettingStarted: 'Começando',
      recentTranslation: 'Recente',
      searchResults: 'Resultados da Pesquisa',
      seeSavedLocations: 'Veja na Página de Localização Salva',
      more: 'mais',
    },
    actionZoom: {
      zoomIn: 'Aumentar zoom',
      zoomOut: 'Diminuir zoom',
    },
    legendActions: {
      threatLevelTranslation: 'Nível de Ameaça',
      highScore: 'Alto',
      lowScore: 'Baixo',
      mapLayerLabels: {
        heatmap: 'Mapa de Calor',
        events: 'Eventos',
        districts: 'Fronteiras',
        countries: 'Fronteiras',
        scores: 'Pontuações',
        population: 'População',
        change: 'Mudança',
        none: 'Nenhum',
      },
      mapStyleLabels: {
        map: 'Claro',
        dark: 'Escuro',
        satellite: 'Satélite',
      },
      mapMarkersLabels: {
        cities: 'Pinos de Cidades',
        savedLocations: 'Pinos de Locais',
      },
      stylesTitle: 'Estilos de Mapa',
      layersTitle: 'Camadas de Dados',
      markersTitle: 'Marcadores',
    },
    help: {
      userGuide: 'Guia de Avaliação',
      restAPIDocumentationTranslation: 'Documentação da API REST',
      threatAssessmentTemplateI: 'Modelo de Avaliação de Ameaças I',
      threatAssessmentTemplateII: 'Modelo de Avaliação de Ameaças II',
      threatAssessmentTemplateIII: 'Modelo de Avaliação de Ameaças III',
      threatAssessmentTemplateIV: 'Modelo de Avaliação de Ameaças IV',
    },
    userFeedback: {
      message: 'Isto foi útil?',
    },
    dataNotification: {
      message:
        'No momento, a cobertura de ameaças não está disponível nesta área. Procure outro local',
    },
    units: {
      mile: 'milha',
      kilometer: 'quilômetro',
      miles: 'Milhas',
      kilometers: 'Quilômetros',
      meters: 'metros',
    },
    dateSkip: {
      month: '1 Mês',
      year: '1 Ano',
      skipForward: 'Pular para frente',
      skipBackward: 'Pular para trás',
    },
    dateMessageValidation: {
      lessFrom:
        'A data inicial não deve ser inferior à data mínima da cidade atual.',
      greaterFrom:
        'A data inicial não deve ser maior que a data máxima da cidade atual.',
      lessTo:
        'A data de término não deve ser inferior à data mínima da cidade atual.',
      greaterTo:
        'A data de término não deve ser maior que a data máxima da cidade atual.',
    },
    chartQuintile: {
      quintile: 'Quintil',
      incidents: 'Incidentes',
      date: 'Data',
      threatCategory: 'Categoria de Ameaça',
      eventCount: 'Contagem de eventos',
      severity: 'Severidade',
    },
    chartOptions: {
      copyImageLabel: 'Copiar Imagem',
      downloadImageLabel: 'Baixar Imagem',
      copyCSVLabel: 'Copiar como CSV',
      downloadCSVLabel: 'Baixar como CSV',
    },
    changeOverview: {
      yearOverYearChange:
        '{increase} em eventos relatados em {currentPeriod} comparado com o mesmo {period} do ano passado',
      periodOverPeriodChange:
        '{increase} em eventos relatados em {currentPeriod} comparado com o {period} anterior',
      yearOverYearNoChange:
        'Sem mudança nos eventos relatados em {currentPeriod} comparado com o mesmo {period} do ano passado',
      periodOverPeriodNoChange:
        'Sem mudança nos eventos relatados em {currentPeriod} comparado com o {period} anterior',
      increase: 'aumento',
      decrease: 'diminuição',
      month: 'mês',
      quarter: 'trimestre',
      change: 'Mudança',
      percentChange: 'Mudança Percentual',
    },
    chartSelection: {
      forecast3Months: 'Previsão de 3 meses',
      forecast6Months: 'Previsão de 6 meses',
      forecast1Year: 'Previsão de 1 ano',
      all: 'Todos',
      primaryCategories: 'Categorias primárias',
      subCategories: 'Subcategorias',
      threatCount: 'Contagem de ameaças',
      percentOfTotal: 'Porcentagem do total',
      average: 'Média',
      rollingAverage: 'Média móvel',
      trendline: 'Linha de Tendência',
      historicalAverage: 'Média Mensal Histórica',
      daily: 'Diariamente',
      percent: 'Porcentagem',
      monthOfYear: 'Mês do Ano',
      dayOfWeek: 'Dia da Semana',
      timeOfDay: 'Hora do Dia',
      rate: 'Taxa',
      periodOverPeriod: 'Período sobre Período',
      yearOverYear: 'Ano sobre Ano',
      percentage: 'Porcentagem',
      changeMetric: 'Métrica de Mudança',
      normalize: 'Normalizar',
      change: 'Mudar',
      source: 'Fonte',
      monthlyChange: 'Mudança Mensal',
      quarterlyChange: 'Mudança Trimestral',
      incidentsPerMonth: 'incidentes por mês',
      incidentsPerQuarter: 'incidentes por trimestre',
    },
    chartLegend: {
      legend: 'Legenda',
      noTagsLabel1:
        'Nenhum tag foi salvo para suas localizações, adicione tags às localizações na',
      noTagsLabel2: 'Lista de Minhas Localizações',
      noTagsLabel3: 'visão.',
    },
    snackbar: {
      cityWarningTitle: 'Este local estará disponível em breve.',
      cityWarningDesc: 'Questões? Contate ',
      cancelButton: 'Descartar',
    },
    snackbarMessages: {
      downloadSuccess: 'Download realizado com sucesso',
      fetchingReport: 'Obtendo relatório...',
      reacherLimitExport:
        'Você atingiu o número máximo de exportações, por favor tente novamente em 8 horas.',
      somethingWentWrong: 'Algo deu errado',
      copiedToClipboard: 'Copiado com sucesso para a área de transferência',
      fetchingData: 'Buscando dados do gráfico...',
    },
    updateNotification: {
      message: 'Existe uma nova versão disponível.',
      action: 'Tente agora!',
    },
    navbar: {
      mapStyleTooltip: 'Visão do mapa',
      languageTooltip: 'Língua',
      more: 'Mais',
      share: 'Compartilhar',
      copyToClipboard: 'Copiar para área de transferência',
    },
    reCenterComp: {
      fabTooltip: 'Ir para a experiência de roaming grátis',
      removeBoundaries: 'Remover Limites',
    },
    threatAnalyticsComp: {
      fabTooltip: 'Abra o painel de análise de ameaças',
    },
    home: {
      homeHeader: 'A inteligência de ameaças granular começa aqui',
      searchboxPlaceholder: 'Pesquisar um local',
      viewMapButton: 'Ver Mapa',
    },
    legendQuintile: {
      emptyTableLabel: 'Nenhuma ameaça dentro de {r} {u} do local clicado.',
      threatEvent: 'Detalhes do Evento de Ameaça',
      summaryTable: 'Sumário',
      detailsTable: 'Detalhe',
      severityScoreTrend: 'Indicador de gravidade',
      incidents: 'Incidentes',
      summaryHeaders: {
        eventType: 'Categoria do evento',
        districtCount: 'Total por distrito',
        areaCount: 'Total por área',
      },
      detailsHeaders: {
        eventDate: 'Data / Hora',
        subEventType: 'Tipo De Evento',
        interaction: 'Interação',
        fatalities: 'Fatalidades',
        granularity: 'Granularidade',
        actor1: 'Ator 1',
        actor2: 'Ator 2',
        description: 'Descrição',
        id: 'ID único',
        source: 'Fonte',
        none: 'Nenhum',
      },
      granularity: {
        street: 'Nível da Rua',
        district: 'Nível Distrital',
        city: 'Nível da Cidade',
      },
      rowsPerPage: 'Linhas por página',
      of: 'de',
      moreThanLabel: 'mais de',
      chart: {
        attributionText:
          'Dados de Armed Conflict Location & Event Data Project (ACLED); https://www.acleddata.com',
        lineChartLabel: 'Média móvel de três meses',
        lineChartLabelShort: 'Média',
        barChartLabel: 'Total',
      },
    },
    tooltip: {
      city: 'Cidade',
      crime: 'Crime',
      views: 'Visão do mapa',
      lonLatText: 'Incidents, Mapa de Calor',
      neighborhoodText: 'Distritos',
      to: 'a',
      dates: 'Intervalo de datas',
      types: 'Tipos de Crime',
      unrest: 'Agitação',
      subTypes: 'Tipos de eventos de agitação',
      addFavorite: 'Add to favorites',
      removeFavorite: 'Remove from favorites',
      loading: 'Carregando...',
      noData: 'Sem dados disponíveis',
    },
    legendPopup: {
      quintile: {
        title: 'Nível de Ameaça',
        info: {
          0: 'Alto',
          1: 'Médio Alto',
          2: 'Médio',
          3: 'Médio Baixo',
          4: 'Baixo',
        },
      },
      crimeHeatmap: {
        title: 'Escala de Ameaça',
        info: {
          10: 'Alto',
          9: '',
          8: '',
          7: '',
          6: '',
          5: '',
          4: '',
          3: '',
          2: '',
          1: 'Baixo',
        },
      },
    },
    crimeQuantile: 'Quintil do crime',
    riskQuantile: 'Quintil de Ameaça',
    quantiles: {
      1: 'Baixo Risco',
      2: 'Risco Médio Baixo',
      3: 'Risco Médio',
      4: 'Risco Médio Alto',
      5: 'Alto Risco',
    },
    mapStyle: 'Estilo do mapa',
    map: 'Mapa',
    light: 'Light',
    satellite: 'Satélite',
    dark: 'Mapa Escuro',
    cuadrante: 'Cuadrante',
    municipio: 'Municipio',
    zona: 'Zona',
    sector: 'Sector',
    contact: 'Contato',
    clearResults: 'Resultados claros',
    downloadNow: 'Baixar Agora',
    pleaseEnterYourEmailAddressBelowAndWeWillSendYouInformationToChangeYourPassword:
      'Digite seu endereço de e-mail abaixo e nós lhe enviaremos informações para alterar sua senha.',
    sendRecoveryEmail: 'Enviar E-mail de Recuperação',
    settings: 'Configurações',
    account: 'A Conta',
    email: 'E-mail',
    login: 'Entrar',
    'sign-up': 'Registrar-se',
    'forgot-password': 'Esqueceu a senha',
    signIn: 'Entrar',
    signUp: 'Registrar-se',
    forgotPassword: 'Esqueceu a senha',
    confirmSignUp: 'Confirmar Cadastro',
    download: 'Baixar',
    show: 'Mostrar',
    hide: 'Esconder',
    lastUpdated: 'Ultima Atualização',
    neverReported: 'Nunca Relatado',
    enterLocation: 'Inserir Localização',
    dismiss: 'Dispensar',
    sendANotification: 'Enviar uma Notificação',
    isThisAnEmergencyThatRequiresCheckIn:
      'Esta é uma emergência que requer check-in?',
    cancel: 'Cancelar',
    send: 'Enviar',
    thereAreNoTeamMembersCurrentlyInThisCity:
      'Atualmente não há membros da equipe nesta cidade.',
    sendTo: 'Enviar para',
    subject: 'Tópico',
    message: 'Mensagem',
    messageSent: 'Obrigado! Sua notificação foi enviada.',
    messageSentTitle: 'Alerta',
    emergencyContacts: 'Contatos de Emergência',
    Position: 'Position',
    Description: 'Descrição',
    Email: 'E-mail',
    Phone_Number: 'Número Telefone',
    timeFromNotification: 'tempo desde a notificação (horas)',
    usersCheckingIn: 'Usuários fazendo check-in',
    percentOfUsersResponding: 'Porcentagem de usuários respondendo',
    usersReportingAsSafe: 'Usuários reportando como seguros',
    usersReportingAsUnsafe: 'Usuários reportando como inseguros',
    usersNotReporting: 'Usuários sem relatos:',
    usersNotified: 'Usuários notificados:',
    usersReportedSafe: 'Usuários que relataram segurança:',
    usersNeedingHelp: 'Usuários precisando de ajuda:',
    averageResponseTime: 'Tempo médio de resposta',
    reportsWithinFirst3Hours: 'Relatórios dentro das primeiras 3 horas:',
    reportsWithinFirst12Hours: 'Relatórios dentro das primeiras 12 horas:',
    reportsWithinFirst24Hours: 'Relatórios dentro das primeiras 24 horas:',
    reportsWithinFirst72Hours: 'Relatórios dentro das primeiras 72 horas:',
    reportsViaApp: 'Relatórios via aplicativo:',
    reportsViaEmail: 'Relatórios via e-mail:',
    noNotificationsToShow: 'Sem notificações para mostrar.',
    untitledNotification: 'Notificação sem Título',
    notificationReportsFor: 'Histórico de Notificação para',
    chooseANotification: 'Escolha uma notificação',
    notificationHistory: 'Histórico de Notificação',
    menuSignOut: {
      signOut: 'Sair',
      areYouSureYouWantToSignOutOfBaseOperations:
        'Tem certeza de que deseja sair da Base Operations?',
      changePasswordEmail:
        'O link para alterar sua senha foi enviado para seu e-mail. Verifique sua caixa de entrada para continuar o processo.',
      changePasswordOption: 'Mudar senha',
      yes: 'Sim',
      no: 'Não',
      userSettings: 'Configurações do usuário',
    },
    allTeamMembers: 'Todos os membros do time',
    Name: 'Nome',
    Role: 'Cargo',
    peopleSearch: 'Busca de pessoas',
    teamMembersInThisCity: 'Membros da equipe nesta cidade',
    categories: 'Categorias',
    dates: 'Intervalo de datas',
    hours: 'Hours',
    teamMember: 'Membro da equipe',
    status: 'Status',
    location: 'Localização',
    from: 'De',
    to: 'Até',
    updatingFilters: 'Atualizando Filtros',
    applyTimeFilters: 'Aplicar Filtros de Horas',
    applyCrimeFilters: 'Aplicar Filtros de Crime',
    Harassment: 'Assédio',
    Theft: 'Roubo',
    'Armed Robbery': 'Furto Armado',
    'Car Robbery': 'Roubo de Carro',
    Assault: 'Assalto',
    ViolentCrimes: 'Crimes Violentos',
    Extortion: 'Extorsão',
    Kidnapping: 'Sequestro',
    Rape: 'Crimes sexuais',
    Vandalism: 'Vandalismo',
    Piracy: 'Pirataria',
    Terrorism: 'Terrorismo',
    'Drug Offenses': 'Crimes de Drogas',
    Fraud: 'Fraude',
    'Organized Crime': 'Crime Organizado',
    Homicide: 'Homicídio',
    Other: 'Pequena Delinquência',
    'Updating Filters': 'Atualizando Filtros',
    'Apply Crime Filters': 'Aplicar Filtros de Crime',
    searchByNameOrEmail: 'Pesquise por nome ou email',
    findPeople: 'Encontrar pessoas',
    applyDateFilters: 'Aplicar filtros de Data',
    companyContacts: 'Contatos da Empresa',
    companyLocations: 'Localizações da Empresa',
    worldResources: 'Recursos Globais', // NEED UPDATE "Change "Global Resources" to "In-Country Resources"
    address: 'Endereço',
    latitude: 'Latitude',
    longitude: 'Longitude',
    actions: 'Ações',
    pinned: 'Fixar',
    typemembername: 'Digite o nome do membro',
    admin: 'Administrador',
    officer: 'Oficial',
    employee: 'Funcionário',
    delete: 'Deletar',
    language: 'Idioma',
    none: 'Nenhum',
    sent: 'Enviado',
    by: 'por',
    atunkowntime: 'em tempo desconhecido',
    unknown: 'desconhecido',
    tablelocalization: {
      body: {
        emptyDataSourceMessage: 'Nenhum local para exibir',
        addTooltip: 'Adicionar',
        deleteTooltip: 'Deletar',
        editTooltip: 'Editar',
        filterRow: {
          filterTooltip: 'Filtro',
        },
        editRow: {
          deleteText: 'Você tem certeza de que deseja excluir isso?',
          saveTooltip: 'Salvar',
          cancelTooltip: 'Cancelar',
        },
      },
      toolbar: {
        searchTooltip: 'Procurar',
        searchPlaceholder: 'Procurar',
      },
      header: {
        actions: 'Ações',
      },
      pagination: {
        nextLabel: 'próximo',
        previousLabel: 'anterior',
        labelDisplayedRows: ' {from}-{to} de {count}',
        firstTooltip: 'Primeira Página',
        previousTooltip: 'Página Anterior',
        nextTooltip: 'Próxima Página',
        lastTooltip: 'Última Página',
        showing: 'Mostrando',
      },
    },
    uploadPhotoModal: {
      addPhoto: 'Carregar Foto',
      deletePhoto: 'Excluir Foto',
      saveChange: 'Salvar Alteração',
      changePhoto: 'Mudar Foto',
    },
    timeRead: 'Tempo Lido',
    responseMethod: 'Método de resposta',
    lastLocationUpdate: 'Última atualização de localização',
    deepLinkMessage:
      'Olá usuário de Base Operations!<br/>Você está vendo esta página porque seu navegador não suporta links para nosso aplicativo.<br/><br/>Por favor, abra o aplicativo Base Operations e clique no ícone "Emergência" para marcar a si mesmo como "Seguro" ou "Em Perigo".<br/><br/>Obrigado!<br/>Equipe de Base Operations<br/><br/>',
    filters: {
      gtmSwitchLabel: 'Ativar BaseGTM',
      selectDeselectAll: 'Selecionar/desmarcar Tudo',
      crimesNumber: ' Eventos em exibição',
      selectAll: 'Selecionar Tudo',
      violentCrime: 'Crime Violento',
      propertyCrime: 'Crime de Propriedade',
      boundariesLabel: 'Limites',
      cityHoodsLabel: 'Distritos',
      countryHoodsLabel: 'províncias',
      eventsLabel: 'Eventos',
      heatmapLabel: 'Mapa de Calor',
      slider: {
        date: 'Selecione uma data',
      },
      months: {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
        8: 'Agosto',
        9: 'Setembro',
        10: 'Outubro',
        11: 'Novembro',
        12: 'Dezembro',
      },
      quarters: {
        1: 'Trimestre 1',
        2: 'Trimestre 2',
        3: 'Trimestre 3',
        4: 'Trimestre 4',
      },
      sources: {
        1: 'Mídia de notícias',
        2: 'Redes sociais',
        3: 'Agregador de dados',
        4: 'Polícia Nacional',
        5: 'Polícia local',
        6: 'Polícia universitária',
        7: 'Chamadas para o 911',
        8: 'Mídia estatal',
        9: 'Governo Nacional',
        10: 'Governo local',
        11: 'Organização sem fins lucrativos (ONG)',
        12: 'Outros',
        13: 'Desconhecido',
        14: 'Mídia',
        15: 'Aplicação da Lei',
        16: 'Governo',
        17: 'Organização sem fins lucrativos (ONG)',
        18: 'Não atribuído',
        19: 'Não atribuído',
        20: 'Movimento Político',
        21: 'Sindicato',
        22: 'Movimento Ativista',
        23: 'Grupo de Interesse Especial',
      },
      categories: {
        1: 'Assédio',
        2: 'Furto',
        3: 'Roubo',
        4: 'Roubo De Veículo',
        5: 'Agressão simples',
        6: 'Extorsão',
        7: 'Sequestro',
        8: 'Crimes Sexuais',
        9: 'Vandalismo',
        10: 'Pirataria',
        11: 'Tráfico De Mercadorias Ilegais',
        12: 'Fraude',
        13: 'Crime Organizado',
        14: 'Homicídio',
        15: 'Pequena Delinquência',
        16: 'Terrorismo',
        17: 'Corrupção',
        18: 'Crimes Violentos',
        19: 'Atividade Suspeita',
        20: 'Perturbação',
        21: 'Infrações De Trânsito Graves',
        22: 'Infrações De Trânsito Menores',
        23: 'Crimes Domésticos',
        24: 'Violações De Drogas E Álcool',
        25: 'Invasão',
        26: 'Violações De Armas',
        27: 'Não Relevante',
        28: 'Pessoa Desaparecida',
        29: 'Suicídio',
        30: 'Ataque Aéreo/Drone',
        31: 'Alterar Para Grupo/Atividade',
        32: 'Sede Ou Base Estabelecida',
        33: 'Acordo',
        34: 'Prisões',
        35: 'Transferência Não Violenta De Território',
        36: 'Ataque',
        37: 'Granada',
        38: 'Confronto Armado',
        39: 'Uso Interrompido De Armas',
        40: 'Bombardeios/Artilharia/Ataque Com Mísseis',
        41: 'Pilhagem/Destruição De Propriedade',
        42: 'Crime leve',
        43: 'Protesto Pacífico',
        44: 'Protesto Com Intervenção',
        45: 'Violência Da Multidão',
        46: 'Excesso De Força Contra Manifestantes',
        47: 'Arma Química',
        48: 'Violência Sexual',
        49: 'Ator Não Estatal Ultrapassa Território',
        50: 'Bomba Suicida',
        51: 'Rapto/Desaparecimento Forçado',
        52: 'Governo Recupera Território',
        53: 'Explosivo Remoto/Mina Terrestre/IED',
        54: 'Manifestação Violenta',
        55: 'Manifestações',
        56: 'Distúrbios Não Violentos',
        57: 'Outros Crimes',
        58: 'Crimes contra a Propriedade',
        59: 'Crimes Violentos',
        60: 'Violência Política',
        61: 'Agressão Agravada',
        62: 'Incêndio Criminoso',
        63: 'Roubo',
        64: 'Receptação',
        65: 'Furto em Lojas',
        66: 'Furto de Veículo',
        67: 'Crimes de Propriedade',
      },
      unrestTypes: {
        1: 'Batalhas',
        2: 'Motins',
        3: 'Violência contra civis',
        4: 'Explosões/Violência remota',
        5: 'Desenvolvimentos estratégicos',
        6: 'Protestos',
      },
      unrestSubtypes: {
        1: 'Sequestro/Desaparecimento forçado',
        2: 'Conflito armado',
        3: 'Presos',
        4: 'Ataque',
        5: 'Cambio de grupo/atividade',
        6: 'Uso de armas brancas',
        7: 'Força excessiva contra manifestantes',
        8: 'Saque/Vandalismo',
        9: 'Aglomeração Violenta',
        10: 'Outro',
        11: 'Protesto pacífico',
        12: 'Protesto com intervenção',
        13: 'Explosivo remoto/Mina/Dispositivo Explosivo Improvisado',
        14: 'Violência sexual',
        15: 'Demonstração violenta',
        16: 'Acordo',
        17: 'Ataque aéreo/drone',
        18: 'Arma química',
        19: 'Governo recupera território',
        20: 'Grenade',
        21: 'Sede ou base estabelecida',
        22: 'Ator não estatal conquista território',
        23: 'Transferência não violenta de território',
        24: 'Bombardeio/artilharia/ataque de mísseis',
        25: 'Bomba suicida',
      },
      timeslices: {
        0: 'Manhã',
        1: 'Meio-Manhã',
        2: 'Meio-Dia',
        3: 'Tarde',
        4: 'Noite',
      },
      weekdays: {
        0: 'Domingo',
        1: 'Segunda-feira',
        2: 'Terça-feira',
        3: 'Quarta-feira',
        4: 'Quinta-feira',
        5: 'Sexta-feira',
        6: 'Sábado',
      },
      seasons: {
        fall: 'Falhar',
        summer: 'Verão',
        winter: 'Inverno',
        spring: 'Primavera',
      },
      countryCategories: {
        1: 'Estabilidade',
        2: 'Estado de Direito',
        3: 'Corrupção',
        4: 'Transparência',
        5: 'Direitos Fundamentais',
        6: 'Criminalidade',
        7: 'Conflito',
        8: 'Resiliência',
        9: 'Liderança Política',
        10: 'Responsabilidade Governamental',
        11: 'Integridade Territorial',
        12: 'Cooperação Internacional',
        13: 'Ordem e Segurança',
        14: 'Sistema Legal',
        15: 'Justiça Criminal',
        16: 'Justiça Civil',
        17: 'Regulação Econômica',
        18: 'Cumprimento Regulatório',
        19: 'Integridade Pública',
        20: 'Independência Judicial',
        21: 'Restrições ao Governo',
        22: 'Transparência Governamental',
        23: 'Governo Aberto',
        24: 'Liberdade na Internet',
        25: 'Liberdade de Imprensa',
        26: 'Direitos Políticos',
        27: 'Liberdades Civis',
        28: 'Tráfico de Pessoas',
        29: 'Tráfico de Armas',
        30: 'Crimes Cibernéticos',
        31: 'Crimes Financeiros',
        32: 'Tráfico de Drogas',
        33: 'Extorsão',
        34: 'Crime Organizado',
      },
    },
    eventFilter: 'Filtro de Eventos',
    Filters: 'Filtros',
    crimeNeighborhoods: 'Distritos',
    protestAreas: 'Eventos de agitação',
    crimeTypes: 'Tipos de crime',
    threatCategories: 'Tipo de Evento',
    sourceCategories: 'Fontes de Dados',
    timeOfYear: 'Época do ano',
    timeOfDay: 'Hora do dia',
    mapGranularity: 'Granularidade do Mapa',
    mapViewOptions: 'Visão do Mapa',
    messageDetails: 'Detalhes da mensagem',
    mapThreatType: 'Tipo de Dados',
    unrestSubtypes: 'Tipos de eventos de agitação',
    threatCrime: 'Crime',
    threatUnrest: 'Agitação',
    enterpriseLabel: 'Empresa',
    betaLabel: 'Beta',
    popup: {
      neighborhood: {
        areaAverage: 'Área Média',
        cityAverage: 'Média da cidade',
      },
      drawPolygon: {
        severityAverage: 'Média de Gravidade: ',
        drawIcon: 'Selecione Pontos de Crime',
        trashIcon: 'Seleção Limpiar',
      },
    },
    sendOptions: 'Opções de Envio',
    sendToAllCompanyEmployees: 'Enviar a todos os funcionários',
    sendToAllEmployeesInTheSelectedCountries:
      'Enviar para todos os funcionários em países selecionados:',
    sendToAllEmployeesInTheSelectedCities:
      'Enviar para todos os funcionários em países selecionados:',
    sendMessage: {
      confirmationDialog: {
        title: 'Enviar para todos os funcionários em {place}',
        titleCompanyWide: 'Enviar a todos os funcionários',
        message:
          'Observe que esta notificação será enviada a cada funcionário em {place}. Confirme ou cancele.',
        messageCompanyWide:
          'Observe que esta notificação será enviada a cada funcionário em toda a empresa. Confirme ou cancele.',
        hideForFutureSelections:
          'Ocultar esta mensagem para futuras seleções de idioma',
        confirmSend: 'Mensagem enviada',
        confirmSendTo: 'Confirme o envio em {place}',
      },
    },
    modal: {
      continue: 'Continuar',
      cancel: 'Cancelar',
    },
    notificationHistoryModal: {
      sentOn: 'Enviar',
      sentBy: 'Enviar para',
      usersIncludedInNotification: 'Usuários incluídos na notificação',
      countriesIncludedInNotification: 'Países incluídos na notificação',
      citiesIncludedInNotification: 'Cidades incluídas na notificação',
      notificationThread: 'Tópico de notificação',
      notificationUsers: 'Usuários de notificação',
      notificationHistory: 'Histórico de Notificações',
      addNotificationToThread: {
        addNotificationToThread: 'Adicionar uma notificação ao thread',
        confirmationDialog: {
          title: 'Enviar nova notificação de emergência',
          message:
            'Deseja redefinir o status de todos os usuários deste segmento para "Desconhecido" que exige um novo check-in?',
          resetAllUsers: 'Redefinir todos os usuários',
          retainCurrentUserStatus: 'Manter o status atual do usuário',
        },
      },
    },
    countries: {
      Brazil: 'Brazil',
      Canada: 'Canada',
      Mexico: 'Mexico',
      'United States': 'Estados Unidos',
    },
    cities: {
      'Mexico City': 'Cidade do México',
      Bogotá: 'Bogotá',
      Denver: 'Denver',
      'São Paulo': 'São Paulo',
      'Rio de Janeiro': 'Rio de Janeiro',
      'Washington, DC': 'Washington, DC',
      Niterói: 'Niterói',
      'Buenos Aires': 'Buenos Aires',
      Fortaleza: 'Fortaleza',
      Baghdad: 'Baghdad',
      London: 'London',
      Vancouver: 'Vancouver',
      'Puerto Rico': 'Puerto Rico',
      'Bahía Blanca': 'Bahía Blanca',
      Santiago: 'Santiago',
      Montevideo: 'Montevideo',
      Other: 'Outros',
    },
    locationInsights: {
      noData: 'Não há dados recentes de crimes disponíveis para este local.',
      diffReportingFrequency:
        'Os dados de crimes e os dados de instabilidade possuem coberturas temporais e granularidades temporais diferentes para este local. Os dados de crimes são relatados {crime} e os dados de instabilidade são relatados {unrest}. Gráficos como o gráfico de Tendência de Eventos e o gráfico de Contribuição de Categoria podem ser afetados por essa discrepância. Para visualizar corretamente os gráficos, visualize os dados de crimes e os dados de instabilidade separadamente usando o Filtro de Tipo de Evento.',
    },
    keyTakeaways: {
      quintiles: {
        1: 'Baixo',
        2: 'Baixo-Médio',
        3: 'Médio',
        4: 'Médio-Alto',
        5: 'Alto',
      },
      // District Comparison
      districtCompare:
        '{totalCount} crimes relatados em comparação com outros distritos (quintil {quintile}) dentro do mesmo {locationType}. O distrito possui um nível de ameaça {threatLevel} para as categorias de crime selecionadas.',
      // Highest Crime
      highestCrimeRadius:
        '{crimeCategory} é a categoria de crime mais alta relatada dentro de um raio de {radius} {units}, representando {crimePercentage} dos eventos ocorridos.',
      highestCrimeDistrict:
        '{crimeCategory} é a categoria de crime mais alta relatada dentro do distrito, representando {crimePercentage} dos eventos ocorridos.',
      highestCrimeCity:
        '{crimeCategory} é a categoria de crime mais alta relatada dentro da cidade, representando {crimePercentage} dos eventos ocorridos.',
      // Day vs Night Greater Than 20
      dayVsNightGreaterRadius:
        '{nightPercentage} dos eventos relatados em um raio de {radius} {units} ocorreram durante a noite, e {dayPercentage} ocorreram durante o dia.',
      dayVsNightGreaterDistrict:
        '{nightPercentage} dos eventos relatados no distrito ocorreram durante a noite, e {dayPercentage} ocorreram durante o dia.',
      dayVsNightGreaterCity:
        '{nightPercentage} dos eventos relatados na cidade ocorreram durante a noite, e {dayPercentage} ocorreram durante o dia.',
      // Day vs Night Less Than 20
      dayVsNightLessRadius:
        'Dentro de um raio de {radius} {units}, o crime é relativamente uniforme ao longo do dia, com {dayPercentage} dos eventos ocorrendo durante o dia, e {nightPercentage} ocorrendo durante a noite.',
      dayVsNightLessDistrict:
        'Dentro do distrito, o crime é relativamente uniforme ao longo do dia, com {dayPercentage} dos eventos ocorrendo durante o dia, e {nightPercentage} ocorrendo durante a noite.',
      dayVsNightLessCity:
        'Para {locationName}, o crime é relativamente uniforme ao longo do dia, com {dayPercentage} dos eventos ocorrendo durante o dia, e {nightPercentage} ocorrendo durante a noite.',
      // Property vs. Violent Crime
      propertyVsViolentRadius:
        '{violentCrimePercentage} dos eventos relatados em um raio de {radius} {units} são {violentCrime} e {propertyCrimePercentage} são {propertyCrime}.',
      propertyVsViolentDistrict:
        '{violentCrimePercentage} dos eventos relatados no distrito são {violentCrime} e {propertyCrimePercentage} são {propertyCrime}.',
      propertyVsViolentCity:
        '{violentCrimePercentage} dos eventos relatados na cidade são {violentCrime} e {propertyCrimePercentage} são {propertyCrime}.',
    },
    httpErrorMessages: {
      400: 'Requisição inválida: {message}.',
      403: 'Você não está autorizado a realizar esta ação.',
      429: 'Muitas solicitações: Por favor, tente novamente mais tarde.',
      500: 'Erro interno no servidor: Uh Oh! Algo deu errado, estamos trabalhando nisso.',
      clientError: 'Por favor, tente novamente.',
      serverError: 'Algo deu errado, por favor, tente novamente mais tarde.',
    },
    textLimit: {
      showLess: 'Ver menos',
      showMore: 'Ver mais',
      less: 'menos',
      more: 'mais',
    },
    reportDownloadModal: {
      allThreatsTypesLabel: 'Todos os Tipos de Ameaças',
      reportNameLabel: 'Nome do relatório',
      defaultReport: 'Relatório padrão',
      defaultReportName: 'Meu Relatório',
      onePageReport: 'Relatório de uma página',
      customReport: 'Relatório personalizado',
      radiusHeader: 'Raio',
      districtHeader: 'Distrito',
      cityHeader: 'Cidade',
      summaryPageLabel: 'Resumo',
      changeAnalysisLabel: 'Análise de Mudança',
      threatAnalysisLabel: 'Análise de Ameaças',
      districtAnalysisLabel: 'Análise do Distrito',
      trendAnalysisLabel: 'Análise de Tendências',
      dataSourcesLabel: 'Fontes de Dados',
      selectionLabel: 'Selecionar Seções',
      infoCaptionLabel:
        'Cada seção marcada é uma página adicional no relatório',
      downloadLabel: 'Baixar Relatório',
      downloadMessage:
        'A geração do relatório leva cerca de 5 minutos. Você receberá um e-mail quando estiver pronto para download.',
    },
  },
  fr_FR: {
    errorPage: {
      title: "Oups, une erreur inattendue s'est produite.",
      subtitle:
        'Notre équipe a été informée de ce problème, veuillez rafraîchir votre navigateur pour continuer à utiliser notre plateforme.',
      refreshButton: 'Rafraîchir le Navigateur',
      goHome: "Aller à la Page d'Accueil",
      contactSupport: 'Contacter le Support',
    },
    featureAccess: {
      forecastFeedback:
        'Nous vous contacterons bientôt à propos de la Prévision des Tendances.',
      forecastTeaser:
        'La Prévision des Tendances est disponible via notre Plan Entreprise. Contactez-nous pour mettre à niveau.',
      chartTeaser: 'Suivez les évolutions au fil du temps avec Enterprise',
      explore: 'Explorer maintenant',
      enterpriseFeature: "Il s'agit d'une fonctionnalité d'entreprise",
      thanks: 'Merci !',
      offerUpgrade:
        'La mise à niveau de votre compte déverrouillera cette fonctionnalité.',
      upgradeFeedback:
        'Nous avons bien reçu votre soumission. Nous vous contacterons bientôt.',
      interest: 'Je suis intéressé',
      closeLabel: 'Fermer',
      teaserTitle: "Découvrez plus d'informations en Entreprise",
      teaserBullet1:
        "Obtenez des informations sur l'évolution de la criminalité dans différentes localités au fil du temps",
      teaserBullet2:
        'Approfondissez avec des comparaisons détaillées des changements dans les catégories de crimes',
      teaserBullet3:
        'Personnalisez les visualisations des changements pour des catégories de crimes précises, des périodes de temps et des sources de données',
      contact: 'Contactez-nous',
    },
    tooltips: {
      forecastTrend:
        'Affiche une prévision des tendances criminelles basée sur des données historiques, projetant des taux et des motifs de criminalité potentiels futurs',
      gtmNotAvailable:
        'La prévision de BaseGTM n’est pas disponible pour cet emplacement',
      gtmDisabled: 'BaseGTM doit être activé',
      selectionNotAvailable:
        'Non disponible pour cet emplacement ou cette granularité',
      gtmPowered: 'Propulsé par BaseGTM.',
      gtmAvailable:
        "La modélisation est utilisée pour tenir compte du bruit dans les données résultant des incohérences de rapport pour améliorer l'utilité des résultats.",
      gtmUnavailable:
        'Les estimations de BaseGTM ne sont pas disponibles pour cet emplacement',
      layerNotAvailable:
        "Cette couche n'est pas disponible au niveau de zoom actuel, zoomez pour la voir.",
      districtsNotAvailable:
        'La couche des frontières n’est pas disponible à cet emplacement.',
      eventsNotAvailable:
        'La couche des événements n’est pas disponible à cet emplacement.',
      heatmapNotAvailable:
        'La couche de la carte thermique n’est pas disponible à cet emplacement.',
      threatScore: BASE_SCORE,
      threatScoreNotAvailable:
        'La couche BaseScore n’est pas disponible à cet emplacement.',
      population: 'Population Estimée',
      trendline:
        "Les lignes de tendance et les moyennes criminelles sont déterminées en fonction du modèle de menace des Opérations de Base. Ce modèle aide à prendre en compte le bruit dans les données résultant des incohérences de rapport pour améliorer l'utilité des résultats.",
      historical:
        "La moyenne mensuelle historique est déterminée à partir de l'historique complet disponible.",
      amountAndDirectionOfChange:
        "Emplacements enregistrés tracés par la direction et l'ampleur du changement.",
      topTenCitiesWithMostChange:
        'Classement des villes avec les emplacements enregistrés ayant la plus grande augmentation ou diminution des événements signalés.',
      numberOfLocationsByChange:
        "Nombre d'emplacements tombant dans chaque seuil de changement pour la période choisie dans les paramètres.",
      violentCrimeChange:
        "Nombre d'emplacements enregistrés par quantité de changement dans le total des instances de crime violent.",
      propertyCrimeChange:
        "Nombre d'emplacements enregistrés par quantité de changement dans le total des instances de crime contre la propriété.",
      optionNotAvailable:
        "Cette option n'est pas disponible pour l'emplacement sélectionné.",
      sourceNotSelected:
        "Cette source a été désélectionnée dans le panneau de filtre de gauche. Veuillez la sélectionner à nouveau si vous souhaitez l'afficher dans le graphique.",
      sourceThreatCategoriesNotSelected:
        "Toutes les catégories d'événements de cette source ont été désélectionnées dans le panneau de filtre.",
      notEnoughData:
        "Il n'y a pas suffisamment de données pour afficher les modifications pour cette source. Si vous avez appliqué des filtres, essayez de les supprimer.",
      betaLabel: "Il s'agit d'une fonctionnalité Bêta",
      addLocation: 'Ajouter un emplacement',
      deleteLocation: "Supprimer l'emplacement",
      crimeFilterDataType: `Basculer entre crime et troubles :
      • Crime - Inclut les événements menaçants tels que le vol qualifié et l'homicide.
      • Troubles - Inclut les événements menaçants tels que les émeutes et les manifestations.`,
      crimeFilterMapView: `Affichez les visualisations cartographiques disponibles, notamment :
      • Événements criminels - Événements menaçants tracés individuellement.
      • Crime Heatmap - Points chauds d'activité de menace.
      • Districts - Activité de menace dans les zones administratives d'un pays, d'une région ou d'une ville.`,
      crimeFilterQuintile: `Filtrer les districts en fonction du niveau de menace par rapport aux autres districts de cet emplacement.
      • Le niveau de menace est basé sur le nombre et la gravité des événements dans un district.
      • Les niveaux de menace sont classés en quintiles.
      • Le bleu clair indique le niveau de menace relative le plus bas (0-20 % des districts) et le rose foncé indique le niveau de menace relative le plus élevé (80-100 %).`,
      crimeFilterTypes:
        'Filtrez les événements de menace par type ou catégorie de crime. Les crimes violents et les crimes contre les biens sont des catégorisations sommaires qui comprennent plusieurs types de crimes.',
      crimeFilterQuarters:
        "Filtrer les événements de menace par trimestre d'occurrence",
      crimeFilterDay:
        'Filtrez les événements de menace par heure de la journée.',
      crimeFilterDate: 'Filtrez les événements de menace par plage de dates.',
      threatAnalyticsSeverities: `Affiche le niveau de menace pour le district sélectionné mois après mois par rapport aux autres districts de cet emplacement.
      • Le niveau de menace est basé sur le nombre et la gravité des événements dans un district.
      • Les niveaux de menace sont classés en quintiles.
      • Le bleu clair indique le niveau de menace relative le plus bas (0-20 % des districts) et le rose foncé indique le niveau de menace relative le plus élevé (80-100 %).`,
      threatAnalyticsMonthly:
        'Affiche les événements de menace au fil du temps. Affiche à la fois le nombre d événements mensuels et le nombre d événements sous forme de moyenne mobile sur 30 jours pour évaluer à la fois la variance temporelle réelle et une ligne de tendance lissée, respectivement.',
      threatAnalyticsRolling:
        'Affiche les événements de menace au fil du temps. Affiche à la fois le nombre d événements mensuels et le nombre d événements sous forme de moyenne mobile sur 30 jours pour évaluer à la fois la variance temporelle réelle et une ligne de tendance lissée, respectivement.',
      threatAnalyticsHistorical:
        'Affiche la tendance des menaces saisonnières en utilisant le nombre moyen d événements de menace mensuels au cours des trois dernières années ou plus. La plage de dates est affichée au-dessus du graphique.',
      threatLandscape:
        'Les informations les plus pertinentes sur les menaces qui affectent un lieu.',
      featuredEvents:
        'Les troubles les plus récents et les plus notables survenus dans un lieu donné.',
      notableEvents:
        'Les événements de troubles les plus graves et remarquables qui se sont produits dans la granularité sélectionnée au cours des trois derniers mois à partir de la date la plus récente sélectionnée.',
      eventSummary:
        "Affiche le nombre d'événements survenus dans la granularité sélectionnée.",
      eventComposition:
        "Affiche la fréquence des événements survenus dans la granularité sélectionnée. Plus la police est grande, plus l'événement est fréquent.",
      eventBreakdown:
        'Affiche le détail des événements par catégories, sous-catégories et heure de la journée.',
      eventTrend:
        "Affiche les événements de menace au fil du temps. Affiche soit le décompte mensuel des événements, soit le décompte journalier des événements, ainsi que la moyenne générale du nombre d'événements sur la période, la moyenne mobile, et la moyenne mensuelle historique pour évaluer les variations réelles basées sur le temps, la tendance lissée et les moyennes saisonnières.",
      changeTrend:
        "Affiche la variation des événements au fil du temps par source. Affiche la variation mensuelle ou trimestrielle par rapport à la période précédente ou à l'année précédente.",
      categoryContributions:
        "Affiche les événements au fil du temps répartis par catégories d'événements.",
      periodAverage:
        'Affiche la moyenne du nombre de menaces sur la période choisie dans la granularité sélectionnée.',
      dayOfWeek:
        "Affiche la moyenne du nombre d'occurrences par jour de la semaine dans la granularité sélectionnée.",
      timeOfDay:
        "Affiche la moyenne du nombre d'occurrences par heure de la journée dans la granularité sélectionnée.",
      severityScore:
        "Affiche le nombre d'événements de menace et les niveaux de menace pour le district sélectionné par rapport aux autres districts de cet emplacement. \n • Le niveau de menace est basé sur le nombre et la gravité des événements dans un district. \n • Les niveaux de menace sont classés en quintiles. \n • Le bleu clair indique le niveau de menace relatif le plus bas (0-20% des districts) et le rose foncé indique le niveau de menace relatif le plus élevé (80-100%).",
      severityScoreTrend:
        'Affiche le niveau de menace décomposé par catégories pour le district sélectionné mois après mois par rapport aux autres districts de cet emplacement. \n • Le niveau de menace est basé sur le nombre et la gravité des événements dans un district. \n • Les niveaux de menace sont classés en quintiles. \n • Le bleu clair indique le niveau de menace relatif le plus bas (0-20% des districts) et le rose foncé indique le niveau de menace relatif le plus élevé (80-100%).',
      dataSourceTrend:
        'Affiche les événements au fil du temps répartis par sources de données. Les sources sont triées par volume de données pour la plage de dates sélectionnée.',
      dataSourceInsights:
        "Affiche des informations sur les subtilités des sources de données pour l'emplacement sélectionné.",
      locationInsights:
        'Affiche des informations sur les nuances de la génération de rapports de données pour cet emplacement.',
      colorLegend:
        'Indique le niveau de menace dans un lieu allant de faible (bleu clair) à élevé (rose foncé). Le niveau de menace est basé sur le nombre et la gravité des événements.',
      unrestFilterDataType: `Basculer entre crime et troubles :
      • Crime - Inclut les événements menaçants tels que le vol qualifié et l'homicide.
      • Troubles - Inclut les événements menaçants tels que les émeutes et les manifestations.`,
      unrestFilterMapView:
        'Afficher les lieux des événements de troubles. Les épingles correspondent aux endroits où des événements de troubles se sont produits.',
      unrestFilterTypes:
        "Filtrez les événements d'agitation par type. Vous pouvez sélectionner un seul type d'agitation ou plusieurs types d'agitation en même temps.",
      unrestFilterDate: 'Filtrer les événements par plage de dates.',
      unrestLegendSummary:
        "Affiche un nombre d'événements d'agitation par type. Cliquez sur la flèche blanche à côté de chaque type pour développer les sous-types d'événement.",
      threatEventsDetail:
        "Affiche des informations supplémentaires sur chaque événement d'agitation. Cliquez sur la flèche blanche pour afficher les détails, y compris les acteurs impliqués, une description de l'événement et les sources de données.",
      unrestLegendChart:
        "Affiche les événements d'agitation par incréments mensuels. Chaque incrément est une moyenne des 3 derniers mois.",
      navbarScreenShot: "Capture d'écran",
      saveLocation: 'Emplacements enregistrés',
      cancelSaveLocation: 'Cancel',
      toggleFiltersPanelTooltip: 'Afficher/Masquer le Panneau de Filtres',
      filterPriorityRating:
        'Filtrer les emplacements enregistrés par évaluation de priorité',
      filterTags: 'Filtrer les emplacements enregistrés par balises',
      sourceTypeFilter:
        "Filtrer les événements de menace par source de données. Les sources sont triées par volume de données pour l'ensemble de la plage de temps couverte.",
      unassignedSource:
        'Les données nouvellement ajoutées sont généralement intégrées comme non attribuées. Les données avec un type de source non attribué sont mises à jour mensuellement.',
      comingSoon: 'À venir',
      changeMinimum: 'Le changement est basé sur un rayon minimum de 1 mi.',
      percentageChangeByLocationType:
        "Regroupe le type d’emplacement par degré de changement. Modifiez le type d'emplacement en modifiant le type de n'importe quel emplacement enregistré.",
      percentageChangeByLocationTag:
        'Regroupe la balise d’emplacement par degré de changement. Modifiez en modifiant les balises pour n’importe quel emplacement enregistré.',
      countryScoreBreakdown:
        'Le Country BaseScore représente la menace relative et la stabilité des pays sur une échelle standardisée allant de 0 (meilleur) à 100 (pire).',
    },
    horizontalNavBar: {
      breadcrumbs: {
        map: 'Carte',
        'my-locations/list': 'Mes Emplacements',
        user: 'Profil',
        home: 'Accueil',
        'my-locations/analysis': 'Tableau de Bord de Mes Emplacements',
      },
    },
    savedLocations: {
      locationOverview: 'Vue d’Ensemble de l’Emplacement',
      threatProfile: 'Profil de Menace',
      degreeOfChange: 'Degré de Changement',
      filtersTitle: 'Filtre de Localisation',
      positionUpdated: 'Position mise à jour ! Enregistrer ces modifications ?',
      threatChange: 'Changement Moyen par Catégorie de Menace',
      threatLandscape: 'Paysage des Menaces',
      indeterminateCaption:
        'Le graphique inclut les changements pour {count1} emplacements.',
      emptyThreatLandscape:
        "Il n'y a pas assez de données pour le Changement et le Paysage de Menaces pour cet emplacement particulier",
      showCharts: 'Afficher les Graphiques',
      barChartTitle: 'Nombre de Sites par Changement en Pourcentage',
      magnitudeOfChange: 'Ampleur du Changement',
      locations: 'emplacements',
      citiesTableTitle: 'Villes Classées',
      locationsTableTitle: 'Emplacements Classés',
      changeMapTitle: 'Quantité et Direction du Changement',
      changeColorScaleLabel: 'Direction du Changement',
      changeSizeScaleLabel: 'Ampleur du Changement',
      changeTrendHeaders: {
        cityName: 'Villes Classées',
        locationName: 'Lieux Classés',
        change: 'Changement',
        savedLocationCount: '# d’Emplacements',
        lastVisited: 'Dernière Visite',
        score: BASE_SCORE,
      },
      remaining: 'Restante',
      none: 'Aucun',
      addNewTag: 'Créer une nouvelle balise',
      maxTags: 'Nombre maximal de tags autorisés par emplacement: 15',
      allTags: 'Tous Étiquetés',
      yesLabel: 'Oui',
      noLabel: 'Non',
      createdLocationLabel: 'Emplacement enregistré !',
      updatedLocationLabel: 'Emplacement mis à jour !',
      deletedLocationLabel: 'Emplacement supprimé !',
      collapseModalLabel: 'Voir moins de champs',
      expandModalLabel: 'Voir plus de champs',
      saveLocationLabel: 'Enregistrer cette localisation ?',
      unrestLabel: 'Troubles',
      crimeLabel: 'Crime',
      viewMapTooltip: 'Voir la carte',
      viewDetailsTooltip: 'Voir les détails',
      filterLocationsTitle: 'Filtrer les Emplacements Enregistrés',
      // Control Actions
      closeButtonText: 'Fermer',
      filterButtonText: 'Filtre',
      sortButtonText: 'Trier par',
      searchInputText: 'Rechercher des Emplacements Enregistrés',
      cancelButtonText: 'Annuler',
      editButtonText: 'Éditer',
      selectAll: 'Sélectionner Tous',
      moreDetails: 'Détails',
      saveButton: {
        text: 'Sauver',
        loading: 'Sauver',
      },
      deleteButton: {
        text: 'Effacer',
        loading: 'Effacer',
      },
      // Filter Categories
      regionLabel: 'Région',
      countryLabel: 'Pays',
      locationTypeLabel: 'Types de Lieux',
      priorityRatingLabel: 'Évaluation de priorité',
      includeUnratedLabel: 'Inclure les emplacements non classés',
      tagsLabel: 'Etiquettes',
      office: 'Bureau',
      hotel: 'Hôtel',
      event: 'Événement',
      restaurant: 'Restaurant',
      travel: 'Voyager',
      warehouse: 'Entrepôt',
      parking: 'Parking',
      service_area: 'Zone de Service',
      point_of_interest: "Point d'Intérêt",
      facility: 'installations',
      retail: 'Commerce de détail',
      other: 'Autre',
      // Detail/Edit Drawer
      locationNotFound: 'Emplacement Introuvable',
      locationNameLabel: 'Nom de la Localisation',
      displayAddressLabel: 'Adresse',
      coverageLabel: 'Couverture',
      customerIdLabel: "ID de L'emplacement du Client",
      dateRangeLabel: 'Plage de Dates',
      latitudeLabel: 'Latitude',
      longitudeLabel: 'Longitude',

      locationTypePlaceholder: "Sélectionnez le Type d'Emplacement",
      // Saved Locations List Tile Columns
      location: 'Emplacement',
      coverageDates: 'Dates de couverture',
      area: 'Zone',
      rating: 'Évaluation',
      tags: 'Balises',
      // More
      locationTypeTooltip:
        "Filtrer les emplacements enregistrés par type d'emplacement",
      visibilityTooltip:
        'Filtrer les emplacements enregistrés en fonction de leur visibilité',
      sortOptions: {
        name: 'Nom',
        customerLocationId: "ID de l'emplacement",
        customerRating: 'Évaluation de priorité',
        change: 'Changement',
        changeTooltip: `Toutes les options sont basées sur des calculs de courbes de tendance au niveau de la ville et sont normalisées pour permettre une comparaison entre les emplacements:
        • Variation mensuelle sur un an:
        par exemple. Août 2023 vs août 2022
        • Changement d'une année à l'autre:
        par exemple. Septembre 2022 - août 2023 vs septembre 2021 - août 2022
        • Modifier l'indice
        Une combinaison de trois mesures de changement (changement le plus récent d'un mois à l'autre, d'un trimestre à l'autre et d'un trimestre à l'autre) afin d'inclure les modèles de changement à court, moyen et long terme.`,
        changeIndex: "Changer l'indice",
        oneMonthPeriodOverYear: 'Changement mensuelle sur un an',
        twelveMonthPeriodOverPeriod: "Changement d'une année à l'autre",
      },
      visibilityLabel: 'Visibilité',
      visibilityOptions: {
        private: 'Privé',
        shared: 'Partagé',
      },
      all: 'Tout',
      userCannotEdit:
        'Seuls les propriétaires et les administrateurs sont autorisés à modifier un emplacement enregistré partagé.',
      gridViewTooltip: 'Vue Grille',
      listViewTooltip: 'Affichage de Liste',
      sortOrderTooltip: 'Ordre de Tri',
      filtersTooltip: 'Ouvrir les Filtres',
      noSavedLocationsFirst:
        'Aucun lieu enregistré. Supprimez certains filtres ou',
      clickHere: 'cliquez ici',
      noSavedLocationsSecond: 'pour aller à la carte.',
      goToLocation: "Aller à L'emplacement",
      noCoverage: 'Pas de Couverture',
      additionalTags: 'Étiquettes supplémentaires',
      coverageFrom: 'Couverture de',
      change: 'Changement',
      changeNotAvailable: "Le changement n'est pas disponible pour ce lieu",
      priorityRating: 'Évaluation de priorité',
      percentageChangeByLocationType:
        "Pourcentage de changement par balise d'emplacement",
      percentageChangeByLocationTag:
        "Pourcentage de changement par type d'emplacement",
      breakdownByLocationType: "Répartition par type d'emplacement",
      breakdownByLocationTag: 'Répartition par balise de localisation',
    },
    user: {
      tab1: "Profil de l'Utilisateur",
      tab2: 'Changer le Mot de Passe',
      tab3: 'Gérer le Token API',
      tab4: 'Gérer les Utilisateurs',
      title: 'Paramètres du Compte',
      tempTitle: "Profil de L'utilisateur",
      firstNameLabel: 'Prénom',
      lastNameLabel: 'Nom',
      emailLabel: 'E-mail',
      languageLabel: 'Langue',
      unitsLabel: 'Unités',
      saveChanges: 'Enregistrer les Modifications',
      allChangesSaved: 'Toutes les Modifications ont été Enregistrées',
      validationLabel: 'Le nom est requis',
      textFieldLabel: 'Nom de la clé API',
      copyLabel: 'Copier',
      deleteLabel: 'Supprimer',
      createLabel: 'Générer',
      warningText:
        "Une clé API personnelle a été générée pour accéder à l'API Rest des opérations de base. Assurez-vous de copier votre clé API maintenant. Une fois que cette fenêtre est fermée, elle ne pourra plus être récupérée.",
      deleteText: 'Vous êtes sur le point de supprimer la clé',
      createTitle: 'Générer une Clé API',
      deleteAction: 'Supprimer la Clé',
      deleteFeedback: 'Clé API Supprimée',
      deleteTitle: 'Suppression de la clé API en cours...',
      copyTitle: 'Clé API Générée avec Succès',
      createFeedback: 'Clé API Générée',
      copyFeedback: 'Clé API copiée dans le presse-papiers !',
      createButton: 'Générer un Nouveau Jeton API',
      nameLabel: 'Nom de la Clé',
      secretLabel: "Secret de l'API",
      dateLabel: 'Créé le',
      actionsLabel: 'Actions',
      maxValidation: 'Le champ de texte ne doit pas dépasser 30 caractères',
    },

    threatAnalytics: {
      overallCrime: 'Changement global',
      saveDefaultLabel: 'Enregistrer par défaut',
      changeMapTitle: 'Quantité et Direction du Changement',
      acledAttribution:
        "** Base Operations acquiert des données auprès de milliers de sources. Nous appliquons des techniques statistiques, géographiques et d'IA avancées pour valider et améliorer ces données. Certaines données provenant de cette localisation ont été fournies par : Armed Conflict Location & Event Data Project (ACLED)",
      noThreatEvents:
        "Aucun événement de menace n'a été signalé à cet endroit pour les types de menace et la période sélectionnés.",
      titleSeverity: 'Tendance du Score de Gravité',
      titleIncidents: 'Incidents',
      titleIntro: 'Analyse des Risques pour ',
      tabDistrict: 'District',
      tabProvince: 'Province',
      tabRadius: 'Rayon',
      tabCity: 'Ville',
      tabCountry: 'Pays',
      titleDistrict: 'le District',
      titleLocation: 'Emplacement',
      titleThreatAnalytics: 'Analyse des Menaces',
      titleCrimeEvents: 'Événements de menace',
      textRadius: 'Rayon de',
      textRadiusPrefix: 'dans un rayon de ',
      textRadiusSuffix: '',
      titleJoin: 'à ',
      quintileTitle: 'Quintiles de Risque',
      quintileSubtitle: 'Quintile de Risque: ',
      chartSubtitleStart: 'De ',
      chartSubtitleJoin: ' à ',
      openAnalytics: "Panneau d'analyse",
      closeAnalytics: "Panneau d'analyse",
      chart: 'Graphique',
      averageType: 'Type de Moyenne',
      aggregationPeriod: 'Période d agrégation',
      period: 'Périodicité',
      chartTitle1: "Nombre D'Événements par Catégorie de Menace",
      chartTitle2: "Composition de l'événement",
      chartTitle3: 'Répartition par Heure de la Journée',
      chartTitle4: 'Tendance des Événements Mensuels',
      chartTitle5: 'Tendance du Changement',
      chartTitle9: 'Score de gravité',
      chartTitle10: 'Tendance du score de gravité',
      chartTitle11: 'Événements Mensuels par Source',
      chartTitle12: 'Événements Moyens par Mois',
      chartTitle13: 'Fréquence des Événements par Catégorie de Menace',
      chartTitle14: 'Tendance Quotidienne des Événements',
      chartTitle15: 'Événements Moyens par Mois de l’Année',
      chartTitle16: 'Événements Moyens par Jour de la Semaine',
      chartTitle17: 'Événements Moyens par Heure de la Journée',
      chartTitle18: 'Nombre de Lieux par BaseScore',
      chartTitle19: 'Nombre de Lieux par Changement en Pourcentage',
      chartTitle20: 'Nombre de Lieux par Type et Changement',
      chartTitle21: 'Nombre de Lieux par Tag et Changement',
      chartTitle22: 'Nombre de Lieux par Type et BaseScore',
      chartTitle23: 'Nombre de Lieux par Tag et BaseScore',
      chartTitle24: 'Répartition par Type de Lieu',
      chartTitle25: 'Répartition par Tag de Lieu',
      chartTooltip: 'Regroupe les types de lieux par degré de changement',
      chartTooltip1: 'Regroupe les tags de lieux par degré de changement',
      chartTooltip2: 'Regroupe les types de lieux par degré de changement',
      chartTooltip3: 'Regroupe les types de lieux par BaseScore',
      chartTooltip4: 'Regroupe les tags de lieux par BaseScore',
      chartTooltip5: 'Regroupe les lieux par type de lieu',
      chartTooltip6: 'Regroupe les lieux par tag de lieu',
      locationInsightsTitle: "Aperçu de l'emplacement",
      takeAwaysTitle: 'Changement en Pourcentage',
      dataObservationsTitle: 'Aperçus des sources de données',
      threatEventsTableTitle: 'Détails des événements de menace',
      notableEventsTitle: 'Événements Notables',
      numberOfLocationsByChange: "Nombre d'emplacements par changement",
      numberOfLocationsByChangeSubtitle: 'Changement global',
      citiesWithMostChange: 'Top 10 des villes avec le plus de changements',
      changeTrendValues: {
        higher: 'plus haut',
        lower: 'inférieur',
      },
      label1: 'Crimes',
      label2: 'Moyenne',
      label3: 'Gravité',
      label4: 'Criminalité Totale',
      label5: '30-Jours Moyenne Mobile',
      label6: '3 Ans',
      label7: '4 Ans',
      label8: '5 Ans',
      label9: 'Gamme Complète',
      label10: 'Mensuel',
      label11: "Nombre d'événements",
      label12: 'Des menaces',
      value1: 'Janvier',
      value2: 'Février',
      value3: 'Mars',
      value4: 'Avril',
      value5: 'Mai',
      value6: 'Juin',
      value7: 'Juillet',
      value8: 'Août',
      value9: 'Septembre',
      value10: 'Octobre',
      value11: 'Novembre',
      value12: 'Décembre',
      topicSelectionText: "Sélectionnez un sujet pour l'analyse",
      eventsWithDescription: 'Événements avec description uniquement',
      panelType: {
        overview: 'Aperçu',
        analysis: 'Analyse des Tendances',
        changeAnalysis: 'Analyse du Changement',
        breakdown: 'Répartition des Menaces',
        notableEvents: 'Événements Notables',
        eventList: 'Liste des Événements',
        source: 'Source de Données',
        comparative: 'Comparatif',
        countryAnalysis: 'Analyse des Pays',
        forecast: 'Prévision des Tendances',
      },
      panelTitles: {
        overview: 'Aperçu du Paysage des Menaces',
      },
      overviewSection: {
        threatScore: BASE_SCORE,
        percentChange: 'Pourcentage de changement',
        monthlyEvents: 'Événements signalés',
        monthlyEventsSuffix: 'mois',
        population: 'Population',
        area: 'Zone',
      },
      reportButton: 'Générer un Rapport de Localisation',
      goToEventList:
        'Accédez à la liste des événements pour des événements supplémentaires',
      countryAnalysis: {
        threatScore: `Pays ${BASE_SCORE}`,
        countryScoreBreakdown: 'Répartition des Menaces par Pays',
        overallScoreWeights: 'Poids des scores globaux',
        threatFrom: 'Menace de...',
        score: 'Score',
        quintile: 'Quintile',
      },
      forecastTrendSection: {
        eventCountLegend: 'Compte des Événements',
        trendLineLegend: 'Ligne de Tendance',
        forecastLegend: 'Prévision',
        confidence75Legend: 'Confiance de 75%',
        confidence90Legend: 'Confiance de 90%',
        forecastTooltip: 'Prévision',
        trendTooltip: 'Tendance',
        countTooltip: 'Compte',
        confUb90Tooltip: '90% Supérieur',
        confUb75Tooltip: '75% Supérieur',
        confLb75Tooltip: '75% Inférieur',
        confLb90Tooltip: '90% Inférieur',
      },
    },
    navigation: {
      search: 'Recherche',
      filter: 'Filtre',
      home: 'Accueil',
      saved: 'Enregistré',
      map: 'Carte',
      help: 'Aide',
      settings: 'Réglages',
      login: 'Compte',
      myLocationsAnalysis: 'Tableau de Bord de Mes Emplacements',
    },
    myLocationsAnalysis: {
      keyTakeaways: {
        title: 'Points Clés',
        count: 'Lieux Enregistrés',
        recentCount: 'Lieux Révisés',
        averageThreatScore: 'Score de Base Moyen',
        averageChange: 'Changement Moyen',
      },
      downloadChartImages: 'Télécharger des Images de Graphiques',
      downloadSavedLocationReport:
        'Télécharger le Rapport de Localisation Enregistrée au Format CSV',
      settings: {
        aggregationOptions: ['Mensuel', 'Trimestriel', 'Annuel'],
        periodOptions: ['Période sur Période', 'Année sur Année'],
        listTypeOptions: {
          savedLocations: 'Emplacements',
          cities: 'Villes',
        },
        locationBreakdownOptions: {
          type: 'Type',
          tag: 'Étiquette',
        },
        changeTitle: 'Changement',
        listTitle: 'Liste',
        locationBreakdownTitle: 'Répartition des emplacements',
        changeTooltip: 'Le changement est basé sur un rayon minimum de 1 mi.',
      },
      filters: {
        title: 'Filtres de localisation',
        allTypesLabel: "Types d'emplacement",
        allTagsLabel: 'Mots clés',
        allDegreeOfChangesLabel: 'Degré de changement',
        degreeOfChangeOptions: [
          {name: 'Forte Augmentation', value: 'large_increase'},
          {name: 'Augmenter', value: 'increase'},
          {name: 'Changement Mineur', value: 'no_change'},
          {name: 'Diminuer', value: 'decrease'},
          {name: 'Forte Diminution', value: 'large_decrease'},
          // {name: 'Indéterminé', value: 'indeterminate'},
        ],
        threatScoreOptions: [
          {name: '>80', value: 'very_high'},
          {name: '60 - 79', value: 'high'},
          {name: '40 - 59', value: 'medium'},
          {name: '20 - 39', value: 'low'},
          {name: '<20', value: 'very_low'},
        ],
      },
    },
    searchModal: {
      savedLocationsTitle: 'Emplacements sauvegardés',
      gettingStarted: 'Commencer',
      recentTranslation: 'Récent',
      searchResults: 'Résultats de recherche',
      seeSavedLocations: 'Voir sur La Page Emplacement Enregistré',
      more: 'plus',
    },
    actionZoom: {
      zoomIn: 'Zoom avant',
      zoomOut: 'Zoom arrière',
    },
    legendActions: {
      threatLevelTranslation: 'Niveau de Menace',
      highScore: 'Élevé',
      lowScore: 'Faible',
      mapLayerLabels: {
        heatmap: 'Carte Thermique',
        events: 'Événements',
        districts: 'Frontières',
        countries: 'Frontières',
        scores: 'Scores',
        population: 'Population',
        change: 'Changement',
        none: 'Aucun',
      },
      mapStyleLabels: {
        map: 'Clair',
        dark: 'Sombre',
        satellite: 'Satellite',
      },
      mapMarkersLabels: {
        cities: 'Épingles de Villes',
        savedLocations: 'Épingles de Sites',
      },
      stylesTitle: 'Styles de Carte',
      layersTitle: 'Couches de Données',
      markersTitle: 'Marqueurs',
    },
    help: {
      userGuide: "Guide d'évaluation",
      restAPIDocumentationTranslation: "Documentation de l'API REST",
      threatAssessmentTemplateI: "Modèle d'Évaluation de Menace I",
      threatAssessmentTemplateII: "Modèle d'Évaluation de Menace II",
      threatAssessmentTemplateIII: "Modèle d'Évaluation de Menace III",
      threatAssessmentTemplateIV: "Modèle d'Évaluation de Menace IV",
    },
    userFeedback: {
      message: 'Est-ce que cela a été utile?',
    },
    dataNotification: {
      message:
        "La couverture des menaces n'est actuellement pas disponible dans cette zone. Veuillez rechercher un autre emplacement",
    },
    units: {
      mile: 'mile',
      kilometer: 'kilomètre',
      miles: 'Milles',
      kilometers: 'Kilomètres',
      meters: 'mètres',
    },
    dateSkip: {
      month: '1 Mois',
      year: '1 An',
      skipForward: 'Aller en avant',
      skipBackward: 'Revenir en arrière',
    },
    dateMessageValidation: {
      lessFrom:
        'La date de début ne doit pas être inférieure à la date minimale de la ville actuelle.',
      greaterFrom:
        'La date de début ne doit pas être supérieure à la date maximale de la ville actuelle.',
      lessTo:
        'La date de fin ne doit pas être inférieure à la date minimale de la ville actuelle.',
      greaterTo:
        'La date de fin ne doit pas être supérieure à la date maximale de la ville actuelle.',
    },
    chartQuintile: {
      quintile: 'Quintile',
      incidents: 'Incidents',
      date: 'Date',
      threatCategory: 'Catégorie de Menace',
      eventCount: "Nombre d'événements",
      severity: 'Gravité',
    },
    chartOptions: {
      copyImageLabel: "Copier L'image",
      downloadImageLabel: "Télécharger L'image",
      copyCSVLabel: 'Copier au format CSV',
      downloadCSVLabel: 'Télécharger au format CSV',
    },
    changeOverview: {
      yearOverYearChange:
        '{increase} dans les événements signalés en {currentPeriod} comparé à la même {period} l’année dernière',
      periodOverPeriodChange:
        '{increase} dans les événements signalés en {currentPeriod} comparé à la {period} précédente',
      yearOverYearNoChange:
        'Aucun changement dans les événements signalés en {currentPeriod} comparé à la même {period} l’année dernière',
      periodOverPeriodNoChange:
        'Aucun changement dans les événements signalés en {currentPeriod} comparé à la {period} précédente',
      increase: 'augmentation',
      decrease: 'diminution',
      month: 'mois',
      quarter: 'trimestre',
      change: 'Changement',
      percentChange: 'Changement en pourcentage',
    },
    chartSelection: {
      forecast3Months: 'Prévision de 3 mois',
      forecast6Months: 'Prévision de 6 mois',
      forecast1Year: 'Prévision d’un an',
      all: 'Tout',
      percentOfTotal: 'Pourcentage du total',
      primaryCategories: 'Catégories primaires',
      subCategories: 'Sous-catégories',
      threatCount: 'Nombre de menaces',
      average: 'Moyenne',
      rollingAverage: 'Moyenne Mobile',
      trendline: 'Ligne de Tendance',
      historicalAverage: 'Moyenne Mensuelle Historique',
      daily: 'Quotidien',
      percent: 'Pourcentage',
      monthOfYear: "Mois de l'Année",
      dayOfWeek: 'Jour de la Semaine',
      timeOfDay: 'Heure de la Journée',
      rate: 'Taux',
      periodOverPeriod: 'Période sur Période',
      yearOverYear: 'Année sur Année',
      percentage: 'Pourcentage',
      changeMetric: 'Métrique de Changement',
      normalize: 'Normaliser',
      change: 'Changement',
      source: 'Source',
      monthlyChange: 'Changement Mensuel',
      quarterlyChange: 'Changement Trimestriel',
      incidentsPerMonth: 'incidents par mois',
      incidentsPerQuarter: 'incidents par trimestre',
    },
    chartLegend: {
      legend: 'Légende',
      noTagsLabel1:
        'Aucun tag n’a été enregistré pour vos emplacements, ajoutez des tags aux emplacements dans la',
      noTagsLabel2: 'Liste de Mes Emplacements',
      noTagsLabel3: 'vue.',
    },
    snackbar: {
      cityWarningTitle: 'Cet emplacement arrive bientôt.',
      cityWarningDesc: 'Des questions? Contactez ',
      cancelButton: 'Reconnaître',
    },
    snackbarMessages: {
      downloadSuccess: 'Téléchargement réussi',
      fetchingReport: 'Récupération du rapport...',
      reacherLimitExport:
        "Vous avez atteint le nombre maximum d'exportations, veuillez réessayer dans 8 heures.",
      somethingWentWrong: "Quelque chose s'est mal passé",
      copiedToClipboard: 'Copié avec succès dans le presse-papiers',
      fetchingData: 'Récupération des données du graphique...',
    },
    updateNotification: {
      message: 'Une nouvelle version est disponible.',
      action: 'Essayez-le maintenant!',
    },
    navbar: {
      mapStyleTooltip: 'Afficher La Carte',
      languageTooltip: 'Langue',
      more: 'Plus',
      share: 'Partager',
      copyToClipboard: 'Copier dans le presse-papier',
    },
    reCenterComp: {
      fabTooltip: "Accéder à l'expérience d'itinérance gratuite",
      removeBoundaries: 'Supprimer les Limites',
    },
    threatAnalyticsComp: {
      fabTooltip: "Ouvrir le panneau d'analyse des menaces",
    },
    home: {
      homeHeader: "L'intelligence granulaire des menaces commence ici",
      searchboxPlaceholder: 'Rechercher un lieu',
      viewMapButton: 'Voir la Carte',
    },
    legendQuintile: {
      emptyTableLabel:
        "Aucune menace à moins de {r} {u} de l'emplacement cliqué.",
      threatEvent: "Détails de l'événement de menace",
      summaryTable: 'Sommaire',
      detailsTable: 'Renseignements',
      severityScoreTrend: 'Tendance du Score de Sévérité',
      incidents: 'Incidents',
      summaryHeaders: {
        eventType: "Catégorie d'événement",
        districtCount: 'Total de Districts',
        areaCount: 'Total de Zones',
      },
      detailsHeaders: {
        eventDate: 'Jour / Heure',
        subEventType: "Type D'Événement",
        interaction: 'Interaction',
        fatalities: 'Décès',
        granularity: 'Granularité',
        actor1: 'Acteur 1',
        actor2: 'Acteur 2',
        description: 'La description',
        id: 'Identifiant Unique',
        source: 'Source',
        none: 'Aucun',
      },
      granularity: {
        street: 'Niveau Rue',
        district: 'Niveau du District',
        city: 'Niveau de la Ville',
      },
      rowsPerPage: 'Lignes par page',
      of: 'de',
      moreThanLabel: 'plus de',
      chart: {
        attributionText:
          'Données du Armed Conflict Location & Event Data Project (ACLED); https://www.acleddata.com',
        lineChartLabel: 'Moyenne Mobile sur Trois Mois',
        lineChartLabelShort: 'Moyen',
        barChartLabel: 'Total',
      },
    },
    tooltip: {
      city: 'Ville',
      crime: 'Délit',
      views: 'Vues de Carte',
      lonLatText: 'Incidents, Carte De Chaleur',
      neighborhoodText: 'Districts',
      to: 'à',
      dates: 'Plage De Dates',
      types: 'Types de crimes',
      unrest: 'Agitation',
      subTypes: "Types d'événements d'agitation",
      addFavorite: 'Add to favorites',
      removeFavorite: 'Remove from favorites',
      loading: 'Chargement...',
      noData: 'Pas de données disponibles',
    },
    legendPopup: {
      quintile: {
        title: 'Niveau de Risque',
        info: {
          0: 'Elevé',
          1: 'Moyen Elevé',
          2: 'Moyen',
          3: 'Moyen Faible',
          4: 'Faible',
        },
      },
      crimeHeatmap: {
        title: 'Niveau de Risque',
        info: {
          10: 'Elevé',
          9: '',
          8: '',
          7: '',
          6: '',
          5: '',
          4: '',
          3: '',
          2: '',
          1: 'Faible',
        },
      },
    },
    crimeQuantile: 'Quintile du crime',
    riskQuantile: 'Menace Quintile',
    quantiles: {
      1: 'Low',
      2: 'Medium low',
      3: 'Medium',
      4: 'Medium high',
      5: 'High',
    },
    mapStyle: 'Style de Carte',
    map: 'Carte',
    light: 'Light',
    satellite: 'Satellite',
    dark: 'Obscur',
    cuadrante: 'Quartier',
    municipio: 'Municipalité',
    zona: 'Zone',
    sector: 'Secteur',
    status: 'Statut',
    location: 'Lieu',
    contact: 'Contact',
    clearResults: 'Clear Results',
    downloadNow: 'Télécharger',
    pleaseEnterYourEmailAddressBelowAndWeWillSendYouInformationToChangeYourPassword:
      'Veuillez saisir ci-dessous votre courriel et nous vous enverrons les instructions sur la façon de changer votre mot de passe.',
    sendRecoveryEmail: 'Envoyer un email de récupération',
    email: 'Email',
    login: 'Se Connecter',
    'sign-up': "S'enregistrer",
    'forgot-password': 'Mot de passe oublier?',
    signIn: "S'identifier",
    signUp: "S'enregistrer",
    forgotPassword: 'Mot de passe oublier?',
    confirmSignUp: "Confirmer l'inscription",
    download: 'Télécharger',
    show: 'Montrer',
    hide: 'Cacher',
    lastUpdated: 'dernière mis à jour',
    neverReported: "n'est jamais signalés",
    enterLocation: 'Saissez un lieu',
    dismiss: 'Dismiss',
    sendANotification: 'Envoyer un notification',
    isThisAnEmergencyThatRequiresCheckIn:
      "Est-ce une urgence qui nécessite l'enregistrement",
    cancel: 'Annuler',
    send: 'Envoyer',
    settings: 'Paramètres',
    account: 'Compte',
    thereAreNoTeamMembersCurrentlyInThisCity:
      "Il n'y a actuellement aucun membre de l'équipe dans cette ville.",
    sendTo: 'Envoyer à:',
    subject: 'Sujet',
    message: 'Message',
    messageSent: 'Merci! Votre notification a été envoyé.',
    messageSentTitle: 'Information',
    emergencyContacts: 'Emergency Contacts',
    Position: 'Position',
    Description: 'Description',
    Email: 'Email',
    Phone_Number: 'Numéro de téléphone',
    timeFromNotification: 'Time from notification (hrs)',
    usersCheckingIn: 'Users checking in',
    percentOfUsersResponding: 'Percent of users responding',
    usersReportingAsSafe: 'Users reporting as safe',
    usersReportingAsUnsafe: 'Users reporting as unsafe',
    usersNotified: 'Users notified:',
    usersReportedSafe: 'Users reported safe:',
    usersNeedingHelp: 'Users needing help:',
    usersNotReporting: 'Users not reporting:',
    averageResponseTime: 'Délai de réponse moyen,',
    reportsWithinFirst3Hours: 'Reports within first 3 hours:',
    reportsWithinFirst12Hours: 'Reports within first 12 hours:',
    reportsWithinFirst24Hours: 'Reports within first 24 hours:',
    reportsWithinFirst72Hours: 'Reports within first 72 hours:',
    reportsViaApp: 'Reports via app:',
    reportsViaEmail: 'Reports via email:',
    noNotificationsToShow: 'No notifications to show.',
    untitledNotification: 'Untitled notification',
    notificationReportsFor: 'Notification Reports for',
    chooseANotification: 'Choose a notification',
    notificationHistory: 'Notification History',
    menuSignOut: {
      signOut: 'Se déconnecter',
      areYouSureYouWantToSignOutOfBaseOperations:
        'Voulez-vous vraiment vous déconnecter de...',
      changePasswordEmail:
        'Le lien pour changer votre mot de passe a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception pour continuer le processus.',
      changePasswordOption: 'Changer le mot de passe',
      yes: 'Oui',
      no: 'Non',
      userSettings: 'Paramètres utilisateur',
    },
    allTeamMembers: 'Tous les employés',
    Name: 'Nom',
    Role: 'Fonction',
    peopleSearch: 'Recherche des employés',
    teamMembersInThisCity: 'Employees in This City',
    categories: 'Catégories',
    dates: 'Plage De Dates',
    hours: 'Heures',
    teamMember: 'Employés',
    to: 'À',
    updatingFilters: 'Updating Filters',
    applyTimeFilters: 'Apply Time Filters',
    applyCrimeFilters: 'Apply Crime Filters',
    Harassment: 'Harcèlement',
    Theft: 'Vol',
    'Armed Robbery': 'Vol à main armée',
    'Car Robbery': "Vol d'auto",
    Assault: 'Attaque',
    ViolentCrimes: 'Crimes Violents',
    Extortion: 'Extorsion',
    Kidnapping: 'Enlèvement',
    Rape: 'Infractions Sexuelles',
    Vandalism: 'Vandalisme',
    Piracy: 'Piraterie',
    'Drug Offenses': 'Infractions Liées à la Drogue',
    Fraud: 'Fraude',
    'Organized Crime': 'Crime organisé',
    Homicide: 'Homicide',
    Other: 'Petite Délinquance',
    Terrorism: 'Terrorisme',
    'Updating Filters': 'Updating Filters',
    'Apply Crime Filters': 'Apply Crime Filters',
    searchByNameOrEmail: 'Search by name or email',
    findPeople: 'Trouver un employé',
    from: 'De',
    applyDateFilters: 'Apply Date Filters',
    companyContacts: 'Coordonnées des entrprises',
    companyLocations: 'Localisations des entreprises ',
    worldResources: 'In-Country Resources',
    address: 'Adresse',
    latitude: 'Latitude',
    longitude: 'Longitude',
    actions: 'Actions',
    pinned: 'Pinned',
    typemembername: 'Saissez le nom de membre',
    admin: 'Admin',
    officer: 'Agent',
    employee: 'Employée',
    delete: 'Supprimer',
    language: 'Langue',
    none: 'Aucun/Aucune',
    sent: 'Envoyé',
    by: 'de',
    atunkowntime: 'à un moment indéterminé',
    unknown: 'Inconnu',
    tablelocalization: {
      body: {
        emptyDataSourceMessage: 'No locations to display',
        addTooltip: 'Ajouter',
        deleteTooltip: 'Supprimer',
        editTooltip: 'Éditer/réviser',
        filterRow: {
          filterTooltip: 'Filtre',
        },
      },
      editRow: {
        deleteText: 'Êtes-vous sûr de vouloir supprimer ceci?',
        saveTooltip: 'Sauvegarder',
        cancelTooltip: 'Annuler',
      },
      toolbar: {
        searchTooltip: 'Chercher',
        searchPlaceholder: 'Chercher',
      },
      header: {
        actions: 'Actions',
      },
      pagination: {
        nextLabel: 'suivant',
        previousLabel: 'précédent',
        labelDisplayedRows: ' {from}-{to} of {count}',
        firstTooltip: 'La première page',
        previousTooltip: 'La page précédente',
        nextTooltip: 'La page suivante',
        lastTooltip: 'La dernière',
        showing: 'Affichage',
      },
    },
    uploadPhotoModal: {
      addPhoto: 'Charger une Photo',
      deletePhoto: 'Delete Photo',
      saveChange: 'Supprimer la Photo',
      changePhoto: 'Changer la Photo',
    },
    timeRead: 'Time Read',
    responseMethod: 'La Méthode Réponse',
    lastLocationUpdate: 'Last Location Update',
    deepLinkMessage:
      'Hello Base Operations user!<br/>You are seeing this page because your web browser does not support linking to our app.<br/><br/>Please open the Base Operations app and click on the “Emergency” icon to mark yourself as “Safe” or “In Danger”.<br/><br/>Thank you!<br/>The Base Operations Team<br/><br/>',
    filters: {
      gtmSwitchLabel: 'Activer BaseGTM',
      selectDeselectAll: 'Tout Sélectionner/Désélectionner',
      crimesNumber: ' Événements en vue',
      selectAll: 'Sélectionner Tous',
      violentCrime: 'Crime Violent',
      propertyCrime: 'Crimes Contre les Biens',
      boundariesLabel: 'Limites',
      cityHoodsLabel: 'Districts',
      countryHoodsLabel: 'Provinces',
      eventsLabel: 'Événements',
      heatmapLabel: 'Carte de Chaleur',
      slider: {
        date: 'Select a date',
      },
      months: {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Mai',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre',
      },
      quarters: {
        1: 'Trimestre 1',
        2: 'Trimestre 2',
        3: 'Trimestre 3',
        4: 'Trimestre 4',
      },
      sources: {
        1: "Médias d'information",
        2: 'Réseaux sociaux',
        3: 'Aggrégateur de données',
        4: 'Police nationale',
        5: 'Police locale',
        6: 'Police universitaire',
        7: 'Appels au 911 pour service',
        8: "Médias affiliés à l'État",
        9: 'Gouvernement national',
        10: 'Gouvernement local',
        11: 'Organisation à but non lucratif (ONG)',
        12: 'Autre',
        13: 'Inconnu',
        14: 'Médias',
        15: "Forces de l'ordre",
        16: 'Gouvernement',
        17: 'Organisation à but non lucratif (ONG)',
        18: 'Non attribué',
        19: 'Non attribué',
        20: 'Mouvement Politique',
        21: 'Syndicat',
        22: 'Mouvement Activiste',
        23: "Groupe d'Intérêt Spécial",
      },
      categories: {
        1: 'Harcèlement',
        2: 'Brigandage',
        3: 'Vol',
        4: 'Vol De Véhicule',
        5: 'Agression simple',
        6: 'Extorsion',
        7: 'Enlèvement',
        8: 'Infractions Sexuelles',
        9: 'Vandalisme',
        10: 'Piraterie',
        11: 'Trafic De Marchandises illégales',
        12: 'Fraude',
        13: 'Crime Organisé',
        14: 'Homicide',
        15: 'Petite Délinquance',
        16: 'Terrorisme',
        17: 'Corruption',
        18: 'Crimes Violents',
        19: 'Activité Suspecte',
        20: 'Perturbation',
        21: 'Grave Infraction Au Code De La Route',
        22: 'Infractions Mineures Au Code De La Route',
        23: 'Infractions Domestiques',
        24: "Infractions Aux Drogues Et À L'Alcool",
        25: 'Intrusion',
        26: "Violations D'Armes",
        27: 'Non Pertinent',
        28: 'Personne Manquante',
        29: 'Suicide',
        30: 'Frappe Aérienne/Drone',
        31: "Changer De Groupe/D'Activité",
        32: 'Siège Social Ou Base Établie',
        33: 'Accord',
        34: 'Arrestations',
        35: 'Transfert Non Violent De Territoire',
        36: 'Attaque',
        37: 'Grenade',
        38: 'Affrontement Armé',
        39: 'Utilisation Des Armes Perturbée',
        40: 'Bombardement/Artillerie/Missile',
        41: 'Pillage/Destruction De Biens',
        42: 'Délit mineur',
        43: 'Manifestation Pacifique',
        44: 'Protestation Avec Intervention',
        45: 'Violence Collective',
        46: 'Force Excessive Contre Les Manifestants',
        47: 'Arme Chimique',
        48: 'Violence Sexuelle',
        49: "L'Acteur Non Étatique Envahit Le Territoire",
        50: 'Bombe Suicide',
        51: 'Enlèvement/Disparition Forcée',
        52: 'Le Gouvernement Regagne Du Terrain',
        53: 'Explosif Télécommandé/Mine Antipersonnel/EEI',
        54: 'Manifestation Violente',
        55: 'Manifestations',
        56: 'Troubles Non Violents',
        57: 'Autres Crimes',
        58: 'Criminalité contre les Biens',
        59: 'Criminalité Violente',
        60: 'Violence Politique',
        61: 'Agression Aggravée',
        62: 'Incendie Criminel',
        63: 'Cambriolage',
        64: 'Recel',
        65: "Vol à l'Étalage",
        66: "Vol à partir d'un Véhicule",
        67: 'Crimes de Propriété',
      },
      unrestTypes: {
        1: 'Batailles',
        2: 'Émeutes',
        3: 'Violences contre les civils',
        4: 'Explosions/Violence à distance',
        5: 'Développements stratégiques',
        6: 'Manifestations',
      },
      unrestSubtypes: {
        1: 'Enlèvement/Disparition Forcée',
        2: 'Affrontement Armé',
        3: 'Arrestations',
        4: 'Attaque',
        5: 'Changement de Group/Activité',
        6: "Utilisation d'Armes Interrompue",
        7: 'Force excessive contre les manifestants',
        8: 'Pillage/Destruction de Biens',
        9: 'Violence de Foule',
        10: 'Autre',
        11: 'Manifestation Pacifique',
        12: 'Manifestation Avec Intervention',
        13: 'Bombe Télécommandée/Mine Terreste/Engin Explosif Improvisé',
        14: 'Violence Sexuelle',
        15: 'Manifestation violente',
        16: 'Une entente',
        17: 'Frappe aérienne/drone',
        18: 'Arme chimique',
        19: 'Le gouvernement regagne du terrain',
        20: 'Grenade',
        21: 'Siège social ou base établi',
        22: "Un acteur non étatique s'empare du territoire",
        23: 'Transfert non violent de territoire',
        24: 'Bombardement/artillerie/attaque au missile',
        25: 'Bombe suicide',
      },
      timeslices: {
        0: 'Matin',
        1: 'Milieu de matinée',
        2: 'Mi-journée',
        3: 'Soir',
        4: 'Nuit',
      },
      weekdays: {
        0: 'Dimanche',
        1: 'Lundi',
        2: 'Mardi',
        3: 'Mercredi',
        4: 'Jeudi',
        5: 'Vendredi',
        6: 'Samedi',
      },
      seasons: {
        spring: 'Printemps',
        summer: 'Été',
        fall: 'Automne',
        winter: 'Hiver',
      },
      countryCategories: {
        1: 'Stabilité',
        2: 'État de Droit',
        3: 'Corruption',
        4: 'Transparence',
        5: 'Droits Fondamentaux',
        6: 'Criminalité',
        7: 'Conflit',
        8: 'Résilience',
        9: 'Leadership Politique',
        10: 'Responsabilité Gouvernementale',
        11: 'Intégrité Territoriale',
        12: 'Coopération Internationale',
        13: 'Ordre et Sécurité',
        14: 'Système Juridique',
        15: 'Justice Pénale',
        16: 'Justice Civile',
        17: 'Régulation Économique',
        18: 'Exécution Réglementaire',
        19: 'Intégrité Publique',
        20: 'Indépendance Judiciaire',
        21: 'Contraintes sur le Gouvernement',
        22: 'Transparence Gouvernementale',
        23: 'Gouvernement Ouvert',
        24: 'Liberté sur Internet',
        25: 'Liberté de Presse',
        26: 'Droits Politiques',
        27: 'Libertés Civiles',
        28: 'Traite des Personnes',
        29: "Trafic d'Armes",
        30: 'Cybercrimes',
        31: 'Crimes Financiers',
        32: 'Trafic de Drogues',
        33: 'Extorsion',
        34: 'Crime Organisé',
      },
    },
    eventFilter: "Filtre D'événements",
    Filters: 'Fitres de Recherche',
    crimeNeighborhoods: 'Districts',
    protestAreas: 'Événements de troubles',
    crimeTypes: 'Formes de Crime',
    threatCategories: "Type d'événement",
    sourceCategories: 'Fontes de Dados',
    timeOfYear: "La Période de l'année",
    timeOfDay: 'Moment de la Jounrée',
    mapGranularity: 'Granularité de la Carte',
    mapViewOptions: 'Afficher la Carte',
    messageDetails: 'Les Détails du Message',
    mapThreatType: 'Type de Données',
    unrestSubtypes: "Types d'événements d'agitation",
    threatCrime: 'Délit',
    threatUnrest: 'Agitation',
    enterpriseLabel: 'Entreprise',
    betaLabel: 'Bêta',
    popup: {
      neighborhood: {
        areaAverage: 'Area Average',
        cityAverage: 'Moyenne Municipale',
      },
      drawPolygon: {
        severityAverage: 'Severity Average: ',
        drawIcon: 'Select Crime Points',
        trashIcon: 'Effacer la Sélection',
      },
    },
    sendOptions: 'Parametres pour envoyer',
    sendToAllCompanyEmployees: 'Envoyer à tous les employés',
    sendToAllEmployeesInTheSelectedCountries:
      'Envoyer à tous les employés dans les pays sélectionnés:',
    sendToAllEmployeesInTheSelectedCities:
      'Envoyer à tous les employés dans les villes sélectionnés:',
    sendMessage: {
      confirmationDialog: {
        title: 'Envoyer à tous {place}',
        titleCompanyWide: 'Envoyer à tous les employés',
        message:
          'Veuillez noter que cette notification sera envoyée à chaque employé de {place}. Veuillez confirmer ou annuler ci-dessous.',
        messageCompanyWide:
          "Veuillez noter que cette notification sera envoyée à tous les employés de l'entreprise. Veuillez confirmer ou annuler ci-dessous.",
        hideForFutureSelections:
          'Hide this message for future country selections',
        confirmSend: 'Confirm send',
        confirmSendTo: 'Confirm send to {place}',
      },
    },
    modal: {
      continue: 'Continuer',
      cancel: 'Annuler',
    },
    notificationHistoryModal: {
      sentOn: 'Envoyé le',
      sentBy: 'Envoyé par',
      notifications: 'Notifications',
      usersIncludedInNotification: 'Users included in Notification',
      countriesIncludedInNotification: 'Countries included in Notification',
      citiesIncludedInNotification: 'Cities included in Notification',
      notificationThread: 'Notification Thread',
      notificationUsers: 'Notification Users',
      notificationHistory: 'Notification History',
      addNotificationToThread: {
        addNotificationToThread: 'Add a Notification to Thread',
        confirmationDialog: {
          title: 'Send New Emergency Notification',
          message:
            'Would you like to reset the status of all users in this thread to "Unknown" requiring a new check-in?',
          resetAllUsers: 'Remettre tous les utilisateurs',
          retainCurrentUserStatus: 'Retain current user status',
        },
      },
    },
    countries: {
      Brazil: 'Brésil',
      Canada: 'Canada',
      Mexico: 'Le Mexique',
      'United States': 'Les États-Unis',
    },
    cities: {
      'Mexico City': 'Mexico',
      Bogotá: 'Bogotá',
      Denver: 'Denver',
      'São Paulo': 'São Paulo',
      'Rio de Janeiro': 'Rio de Janeiro',
      'Washington, DC': 'Washington, DC',
      Niterói: 'Niterói',
      'Buenos Aires': 'Buenos Aires',
      Fortaleza: 'Fortaleza',
      Baghdad: 'Baghdad',
      London: 'London',
      Vancouver: 'Vancouver',
      'Puerto Rico': 'Puerto Rico',
      'Bahía Blanca': 'Bahía Blanca',
      Santiago: 'Santiago',
      Montevideo: 'Montevideo',
      Other: 'Autre',
    },
    locationInsights: {
      noData:
        "Aucune donnée récente sur les crimes n'est disponible pour cet emplacement.",
      diffReportingFrequency:
        "Les données sur les crimes et les données sur les troubles ont une couverture temporelle et une granularité temporelle différentes pour cet emplacement. Les données sur les crimes sont signalées {crime} et les données sur les troubles sont signalées {unrest}. Les graphiques tels que le graphique de tendance des événements et le graphique de contribution des catégories peuvent être affectés par cette divergence. Pour visualiser correctement les graphiques, visualisez séparément les données sur les crimes et les données sur les troubles à l'aide du filtre de type d'événement.",
    },
    keyTakeaways: {
      quintiles: {
        1: 'Faible',
        2: 'Faible-Moyen',
        3: 'Moyen',
        4: 'Moyen-Élevé',
        5: 'Élevé',
      },
      // District Comparison
      districtCompare:
        '{totalCount} crimes signalés par rapport aux autres districts (quintile {quintile}) dans le même {locationType}. Le district présente un niveau de menace {threatLevel} pour les catégories de crimes sélectionnées.',
      // Highest Crime
      highestCrimeRadius:
        '{crimeCategory} est la catégorie de crime la plus élevée signalée dans un rayon de {radius} {units}, représentant {crimePercentage} des événements survenus.',
      highestCrimeDistrict:
        '{crimeCategory} est la catégorie de crime la plus élevée signalée dans le district, représentant {crimePercentage} des événements survenus.',
      highestCrimeCity:
        '{crimeCategory} est la catégorie de crime la plus élevée signalée dans la ville, représentant {crimePercentage} des événements survenus.',
      // Day vs Night Greater Than 20
      dayVsNightGreaterRadius:
        '{nightPercentage} des événements signalés dans un rayon de {radius} {units} se sont produits le soir et la nuit, et {dayPercentage} se sont produits pendant la journée.',
      dayVsNightGreaterDistrict:
        '{nightPercentage} des événements signalés dans le district se sont produits le soir et la nuit, et {dayPercentage} se sont produits pendant la journée.',
      dayVsNightGreaterCity:
        '{nightPercentage} des événements signalés dans la ville se sont produits le soir et la nuit, et {dayPercentage} se sont produits pendant la journée.',
      // Day vs Night Less Than 20
      dayVsNightLessRadius:
        'Dans un rayon de {radius} {units}, la criminalité est relativement uniforme tout au long de la journée, avec {dayPercentage} des événements se produisant pendant la journée et {nightPercentage} se produisant le soir et la nuit.',
      dayVsNightLessDistrict:
        'Dans le district, la criminalité est relativement uniforme tout au long de la journée, avec {dayPercentage} des événements se produisant pendant la journée et {nightPercentage} se produisant le soir et la nuit.',
      dayVsNightLessCity:
        'Pour {locationName}, la criminalité est relativement uniforme tout au long de la journée, avec {dayPercentage} des événements se produisant pendant la journée et {nightPercentage} se produisant le soir et la nuit.',
      // Property vs. Violent Crime
      propertyVsViolentRadius:
        '{violentCrimePercentage} des événements signalés dans un rayon de {radius} {units} sont des {violentCrime} et {propertyCrimePercentage} sont des {propertyCrime}.',
      propertyVsViolentDistrict:
        '{violentCrimePercentage} des événements signalés dans le district sont des {violentCrime} et {propertyCrimePercentage} sont des {propertyCrime}.',
      propertyVsViolentCity:
        '{violentCrimePercentage} des événements signalés dans la ville sont des {violentCrime} et {propertyCrimePercentage} sont des {propertyCrime}.',
    },
    httpErrorMessages: {
      400: 'Requête invalide : {message}.',
      403: "Vous n'êtes pas autorisé à effectuer cette action.",
      429: 'Trop de demandes : veuillez réessayer plus tard.',
      500: "Erreur interne du serveur : Uh Oh ! Quelque chose s'est mal passé, nous y travaillons.",
      clientError: 'Veuillez réessayer.',
      serverError:
        "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
    },
    textLimit: {
      showLess: 'Voir moins',
      showMore: 'Voir plus',
      less: 'moins',
      more: 'plus',
    },
    reportDownloadModal: {
      allThreatsTypesLabel: 'Tous les Types de Menaces',
      reportNameLabel: 'Nom du rapport',
      defaultReport: 'Rapport par défaut',
      defaultReportName: 'Mon Rapport',
      onePageReport: "Rapport d'une page",
      customReport: 'Rapport personnalisé',
      radiusHeader: 'Rayon',
      districtHeader: 'Arrondissement',
      cityHeader: 'Ville',
      summaryPageLabel: 'Résumé',
      changeAnalysisLabel: 'Analyse des Changements',
      threatAnalysisLabel: 'Analyse des Menaces',
      districtAnalysisLabel: 'Analyse du District',
      trendAnalysisLabel: 'Analyse des Tendances',
      dataSourcesLabel: 'Sources de Données',
      selectionLabel: 'Sélectionner les Sections',
      infoCaptionLabel:
        'Chaque section cochée est une page supplémentaire dans le rapport',
      downloadLabel: 'Télécharger le Rapport',
      downloadMessage:
        "La génération du rapport prend environ 5 minutes, vous recevrez un e-mail lorsqu'il sera prêt à être téléchargé.",
    },
  },
};
