// packages
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// state handlers
import {
  setInspectingLocationStatus,
  setNewUserLocation,
  saveLocationPopupStatus,
  setClickedLocation,
  setSavingLocationStatus,
  toggleAddingLocationStatus,
  setSelectedLocation,
  setThreatAnalyticsArea,
} from '@store/modules/user/actions';
import {
  setSelectionMarker,
  setSearchMarker,
  setLocationAddress,
  setMapTooltip,
  setRoutedToMap,
  setViewTypeView,
  setMapCenter,
} from '@/store/modules/map/actions';
import {
  setMapFiltersStatus,
  setAnalyticsPanelStatus,
} from '@/store/modules/drawer/actions';
import {setActiveCity, setGranularity} from '@/store/modules/filters/actions';
import {displaySearchModal} from '@/store/modules/modal/actions';
import {setSelectedDistrict} from '@/store/modules/districts/actions';
import {
  getThreatAnalyticsAreaDisplay,
  getClickedLocationId,
  getLocation,
} from '@/selectors';
import {
  COUNTRY_LAYER,
  COUNTRY_SOURCE,
  THREAT_RADIUS_SOURCE,
} from '@/components/map/layers/constants';

// hooks
import {LAYER_TYPE} from '@/components/map/constants';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useApi} from '@/hooks/api/useApi';

export const useMapHandlers = () => {
  const dispatch = useDispatch();
  const {navigateTo, hoverFeature} = useMapboxGL();
  const {getFeatureAccess} = useFeatureAccess();
  const {getSavedLocation} = useApi();

  const areaVisibility = useSelector(getThreatAnalyticsAreaDisplay);
  const savedLocationId = useSelector(getClickedLocationId);
  const {id: locationId} = useSelector(getLocation);

  const cleanUpLocation = useCallback(() => {
    dispatch(setMapFiltersStatus(false));
    dispatch(setSelectionMarker({}));
    dispatch(setSelectedDistrict());
    dispatch(setClickedLocation());
    dispatch(setThreatAnalyticsArea({display: false}));
  }, [dispatch]);

  const searchLocation = useCallback(
    ({
      search,
      type,
      latitude,
      longitude,
      zoom,
      locationId,
      exactMatch,
      bounds,
    }) => {
      cleanUpLocation();
      dispatch(displaySearchModal(false));
      dispatch(setActiveCity(locationId));
      dispatch(setLocationAddress(search));
      navigateTo({
        type: bounds ? 'fitBounds' : 'flyTo',
        longitude,
        latitude,
        zoom,
        bounds,
        onEnd: () => {
          dispatch(setMapFiltersStatus(true));
          dispatch(setAnalyticsPanelStatus(true));
          dispatch(
            setViewTypeView(
              type === 'Country' ? LAYER_TYPE.COUNTRY : LAYER_TYPE.CITY,
            ),
          );
          dispatch(setMapCenter({latitude, longitude}));
          if (!exactMatch) {
            dispatch(
              setSearchMarker({
                lat: latitude,
                lng: longitude,
              }),
            );
          }
        },
      });
    },
    [dispatch, cleanUpLocation, navigateTo],
  );

  const navigateToSavedLocation = useCallback(
    (savedLocation, map) => {
      const {
        latitude,
        longitude,
        locationId: location,
        radiusMeters,
        zoom,
      } = savedLocation;
      dispatch(setNewUserLocation({}));
      dispatch(setSelectionMarker({}));
      dispatch(setSelectedDistrict());
      dispatch(setSavingLocationStatus(false));
      dispatch(setClickedLocation(savedLocation));
      navigateTo(
        {
          longitude,
          latitude,
          zoom,
          type: locationId === location ? 'easeTo' : 'flyTo',
          onEnd: () => {
            dispatch(setAnalyticsPanelStatus(true));
            dispatch(setActiveCity(location));
            dispatch(setMapFiltersStatus(true));
            dispatch(setViewTypeView(LAYER_TYPE.CITY));
            dispatch(
              setThreatAnalyticsArea({
                latitude,
                longitude,
                radius: radiusMeters,
                display: true,
              }),
            );
            dispatch(setMapCenter({latitude, longitude}));
            getSavedLocation.mutate({query: savedLocation.id});
          },
        },
        map,
      );
    },
    [dispatch, navigateTo, locationId],
  );

  const clickSavedLocation = useCallback(
    (savedLocation, map) => {
      const {id, isPrivate} = savedLocation;
      if (
        savedLocationId === id &&
        areaVisibility &&
        (isPrivate || !getFeatureAccess('shared-saved-locations', 'disable'))
      ) {
        dispatch(setInspectingLocationStatus(true));
        dispatch(saveLocationPopupStatus(true));
      } else {
        navigateToSavedLocation(savedLocation, map);
      }
    },
    [
      savedLocationId,
      areaVisibility,
      getFeatureAccess,
      navigateToSavedLocation,
    ],
  );

  const queryLocation = useCallback(
    ({id, latitude, longitude, defaultZoomLevel: zoom, type}) => {
      dispatch(setActiveCity(id));
      navigateTo({
        longitude,
        latitude,
        zoom,
        type: 'jumpTo',
        onEnd: () => {
          dispatch(setMapFiltersStatus(true));
          dispatch(setAnalyticsPanelStatus(true));
          dispatch(
            setViewTypeView(
              type === 'Country' ? LAYER_TYPE.COUNTRY : LAYER_TYPE.CITY,
            ),
          );
          dispatch(setMapCenter({latitude, longitude}));
        },
      });
    },
    [dispatch, navigateTo],
  );

  const clickLocation = useCallback(
    ({id, type, latitude, longitude, zoom, bounds}, map) => {
      cleanUpLocation();
      dispatch(setActiveCity(id));

      let navigationType = 'flyTo';
      if (locationId === id) {
        navigationType = 'easeTo';
      }
      if (bounds) {
        navigationType = 'fitBounds';
      }
      navigateTo(
        {
          longitude,
          latitude,
          zoom,
          type: navigationType,
          bounds,
          onEnd: () => {
            dispatch(setMapFiltersStatus(true));
            dispatch(setAnalyticsPanelStatus(true));
            dispatch(
              setViewTypeView(
                type === 'Country' ? LAYER_TYPE.COUNTRY : LAYER_TYPE.CITY,
              ),
            );
            dispatch(setMapCenter({latitude, longitude}));
          },
        },
        map,
      );
    },
    [dispatch, cleanUpLocation, navigateTo, locationId],
  );

  const clickThreatPoints = useCallback(
    ({lon, lat}) => {
      dispatch(setSelectionMarker({lon, lat}));
      dispatch(setThreatAnalyticsArea({display: false}));
      dispatch(setAnalyticsPanelStatus(true));
    },
    [dispatch],
  );

  const searchSavedLocation = useCallback(
    (savedLocation) => {
      dispatch(setAnalyticsPanelStatus(false));
      navigateToSavedLocation(savedLocation);
      dispatch(setRoutedToMap(false));
      dispatch(displaySearchModal(false));
      dispatch(setSelectedLocation({}));
    },
    [dispatch, navigateToSavedLocation],
  );

  const createUserLocation = useCallback(
    ({latitude, longitude, ...location}) => {
      dispatch(setNewUserLocation({lat: latitude, lon: longitude}));
      dispatch(
        setThreatAnalyticsArea({
          latitude,
          longitude,
          radius: location.radiusMeters,
          display: true,
          shape: 'circle',
        }),
      );
      dispatch(setGranularity('radius'));
      dispatch(setClickedLocation({latitude, longitude, ...location}));
      dispatch(setAnalyticsPanelStatus(true));
      dispatch(setSavingLocationStatus(true));
      dispatch(toggleAddingLocationStatus(false));
      dispatch(setSelectionMarker({}));
    },
    [dispatch],
  );

  const clickDistrict = useCallback(
    (district, isMultipleSelection) => {
      dispatch(
        setSelectedDistrict(isMultipleSelection ? district : [district]),
      );
      dispatch(setAnalyticsPanelStatus(true));
      dispatch(setThreatAnalyticsArea({display: false}));
      dispatch(setClickedLocation());
    },
    [dispatch],
  );

  const cancelAddUserLocation = useCallback(() => {
    dispatch(toggleAddingLocationStatus(false));
  }, [dispatch]);

  const cancelSaveLocation = useCallback(() => {
    dispatch(setNewUserLocation({}));
    dispatch(setThreatAnalyticsArea({display: false}));
    dispatch(setClickedLocation());
    dispatch(setSavingLocationStatus(false));
    dispatch(saveLocationPopupStatus(false));
  }, [dispatch]);

  const cancelInspectLocation = useCallback(() => {
    dispatch(setInspectingLocationStatus(false));
    dispatch(saveLocationPopupStatus(false));
  }, [dispatch]);

  /**
   * Shows a map tooltip with the specified message, latitude, and longitude.
   *
   * @param {string} message - The message to display in the tooltip.
   * @param {number} latitude - The latitude coordinate.
   * @param {number} longitude - The longitude coordinate.
   */
  const showMapTooltip = useCallback(
    (message, latitude, longitude) => {
      dispatch(
        setMapTooltip({
          visible: true,
          location: message,
          lat: latitude,
          lon: longitude,
        }),
      );
    },
    [dispatch],
  );

  const hideMapTooltip = useCallback(() => {
    dispatch(setMapTooltip({visible: false}));
  }, [dispatch]);

  const clearMapSelection = useCallback(() => {
    dispatch(setSelectionMarker({}));
    dispatch(setSelectedDistrict());
  }, [dispatch]);

  const highlightCountry = useCallback(
    (id) =>
      hoverFeature({
        id,
        source: COUNTRY_SOURCE,
        sourceLayer: COUNTRY_LAYER,
      }),
    [hoverFeature],
  );

  const highlightRadius = useCallback(
    (id) =>
      hoverFeature({
        id,
        source: THREAT_RADIUS_SOURCE,
      }),
    [hoverFeature],
  );

  return {
    clickLocation,
    queryLocation,
    clickSavedLocation,
    searchLocation,
    createUserLocation,
    hideMapTooltip,
    showMapTooltip,
    cancelAddUserLocation,
    searchSavedLocation,
    clickDistrict,
    clickThreatPoints,
    clearMapSelection,
    cleanUpLocation,
    cancelSaveLocation,
    cancelInspectLocation,
    highlightCountry,
    highlightRadius,
  };
};
