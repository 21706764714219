import {useMemo} from 'react';

import {
  CHANGE,
  DEGREE_OF_CHANGE,
  THREAT,
  THREAT_SCORES,
} from '@/components/saved-locations/constants';

import {useDegreeOfChange} from '@/hooks/api/useDegreeOfChange';
import {useSavedLocationsDistribution as useAPISavedLocationsDistribution} from '@/hooks/api/useSavedLocationsDistribution';

const yAxisLabels = {
  very_low: '',
  low: '20',
  medium: '40',
  high: '60',
  very_high: '80',
};

export const useSavedLocationDistribution = ({view}) => {
  const {data: buckets} = useDegreeOfChange();

  const {data, isFetching, isError} = useAPISavedLocationsDistribution({
    metric: view,
  });

  const formattedData = useMemo(() => {
    if (view === THREAT)
      return data
        .map(({count, threatScoreQuintile}) => ({
          x: count,
          y: yAxisLabels[threatScoreQuintile],
          score: threatScoreQuintile,
        }))
        .sort(
          (a, b) =>
            THREAT_SCORES.indexOf(b.score) - THREAT_SCORES.indexOf(a.score),
        );
    return data
      .filter(({range}) => range !== 'indeterminate')
      .map(({value, range}) => {
        const bucket = buckets.find((bucket) => bucket.range === range);
        return {
          x: value,
          y:
            bucket && range !== 'large_decrease' ? `${bucket.bucket.min}%` : '',
          range,
        };
      })
      .sort(
        (a, b) =>
          DEGREE_OF_CHANGE.indexOf(b.range) - DEGREE_OF_CHANGE.indexOf(a.range),
      );
  }, [data, buckets, view]);

  const indeterminateCount = useMemo(
    () =>
      view === CHANGE
        ? data.reduce((acc, {value, range}) => {
            if (range === 'indeterminate') return acc;
            return acc + value;
          }, 0)
        : [],
    [data, view],
  );

  return {
    data: formattedData,
    isFetching,
    isError,
    indeterminateCount,
  };
};
