import {useState} from 'react';

// Hooks
import {useApi} from '@hooks/api/useApi';

// State Handlers
import {useSnackbar} from '@hooks/useSnackbar';
import {useTranslation} from '@hooks/useTranslation';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

export const useSavedLocationsReportDownload = () => {
  const {getSavedLocationsReport} = useApi();
  const {getI18N} = useTranslation();
  const {showSnackbar} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const {downloadSuccess, fetchingReport} = getI18N('snackbarMessages');

  const getReportCSV = (onSuccess) => {
    setIsLoading(true);
    getSavedLocationsReport.mutate(
      {
        params: {
          radius: 1,
          units: DISTANCE_UNIT.miles,
        },
      },
      {
        onSuccess: (payload) => {
          onSuccess(payload);
          setIsLoading(false);
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  /**
   * Download CSV report file with all saved locations
   * @returns {void}
   */
  const downloadCSVReport = () => {
    showSnackbar({
      message: fetchingReport,
      loading: true,
    });

    const download = (payload) => {
      const blob = new Blob([payload], {type: 'text/csv'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'saved-locations-report.csv';
      link.click();
      link.remove();
      showSnackbar({
        icon: 'check',
        message: downloadSuccess,
      });
    };

    getReportCSV(download);
  };

  return {isLoading, downloadCSVReport};
};
