import {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {CircularProgress, Typography} from '@mui/material';
import {Stack, CaretDoubleUp, CaretDoubleDown} from '@phosphor-icons/react';
import {useDispatch, useSelector} from 'react-redux';

import {
  getFiltersLoading,
  getLoadingNeighborhoods,
  getLoadingTile,
  getSummary,
  getUserSettings,
  getLayers,
  getViewTypeWorld,
  getViewTypeCity,
  getMap,
} from '@/selectors';
import {setUserMapStyle} from '@/store/modules/user/actions';
import {setLayers} from '@/store/modules/filters/actions';
import {setMapMarkers} from '@/store/modules/map/actions';

import {
  mapStyles,
  mapLayers,
  mapMarkers,
} from '@/components/map/actions/constants';
import {styles} from '@/components/map/actions/ActionMapSettings.styles';
import ActionColorScale from '@/components/map/actions/ActionColorScale';
import BaseIconButton from '@/components/common/buttons/BaseIconButton';
import BaseTooltip from '@/components/common/BaseTooltip';

import {useTranslation} from '@/hooks/useTranslation';
import {useApi} from '@/hooks/api/useApi';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function ActionMapSettings({sx}) {
  const {getI18N} = useTranslation();
  const {patchUserSettings} = useApi();
  const dispatch = useDispatch();
  const {track} = useSegmentAnalytics();
  const {getFeatureAccess} = useFeatureAccess();

  const settings = useSelector(getUserSettings);
  const layers = useSelector(getLayers);
  const loadingTiles = useSelector(getLoadingTile);
  const loadingDistricts = useSelector(getLoadingNeighborhoods);
  const loadingFilters = useSelector(getFiltersLoading);
  const {hasLatLonData, hasNeighborhoodData, hasThreatScore} =
    useSelector(getSummary);
  const isWorldView = useSelector(getViewTypeWorld);
  const isCityView = useSelector(getViewTypeCity);
  const {markers} = useSelector(getMap);

  const [expanded, setExpanded] = useState(true);

  const {
    highScore,
    lowScore,
    mapStyleLabels,
    stylesTitle,
    layersTitle,
    mapLayerLabels,
    mapMarkersLabels,
    markersTitle,
  } = getI18N('legendActions');
  const {
    layerNotAvailable,
    districtsNotAvailable,
    eventsNotAvailable,
    heatmapNotAvailable,
    threatScoreNotAvailable,
  } = getI18N('tooltips');

  const selectedLayerLabel = useMemo(
    () =>
      mapLayerLabels[
        mapLayers.find(
          ({id}) => id === Object.keys(layers).find((id) => layers[id]),
        ).id
      ],
    [layers, mapLayerLabels],
  );

  const handleOptions = useCallback(() => setExpanded(!expanded), [expanded]);

  const handleMapStyle = useCallback(
    ({mapStyle, disabled}) => {
      if (disabled) return null;
      track('Map style change', {
        newStyle: mapStyle,
        previousStyle: settings.mapStyle,
      });
      dispatch(setUserMapStyle(mapStyle));
      patchUserSettings.mutate({body: {...settings, mapStyle}});
    },
    [dispatch, settings],
  );

  const handleMapLayer = useCallback(
    ({layer, disabled}) => {
      if (disabled) return null;
      track('Map Layer Changed', {
        newLayer: mapLayerLabels[layer],
        oldLayer: selectedLayerLabel,
      });
      dispatch(setLayers(layer));
    },
    [dispatch, selectedLayerLabel, mapLayerLabels],
  );

  const handleMapMarkers = useCallback(
    ({marker, disabled}) => {
      if (disabled) return null;
      track('Map Markers Changed', {
        newMarkers: mapMarkersLabels[marker],
        oldMarkers: mapMarkersLabels,
      });
      dispatch(setMapMarkers(marker));
    },
    [dispatch, mapMarkersLabels, markers],
  );

  const controlledLayers = useMemo(
    () =>
      mapLayers.map((layer) => {
        let tooltip = '';
        let disabled = true;
        let hidden = false;
        let loading = layers[layer.id] && (loadingTiles || loadingFilters);
        switch (layer.id) {
          case 'countries':
            hidden = !isWorldView;
            disabled = !isWorldView;
            if (disabled) {
              tooltip = layerNotAvailable;
            }
            break;
          case 'heatmap':
            disabled = !hasLatLonData || isWorldView;
            if (isWorldView) {
              tooltip = layerNotAvailable;
            } else if (!hasLatLonData) {
              tooltip = heatmapNotAvailable;
            }
            break;
          case 'events':
            disabled = !hasLatLonData || isWorldView;
            if (isWorldView) {
              tooltip = layerNotAvailable;
            } else if (!hasLatLonData) {
              tooltip = eventsNotAvailable;
            }
            break;
          case 'districts':
            disabled = !hasNeighborhoodData || isWorldView;
            hidden = isWorldView;
            loading =
              layers[layer.id] &&
              (loadingTiles || loadingDistricts || loadingFilters);
            if (isWorldView) {
              tooltip = layerNotAvailable;
            } else if (!hasNeighborhoodData) {
              tooltip = districtsNotAvailable;
            }
            break;
          case 'scores':
            disabled = !hasThreatScore || !isCityView;
            if (!isCityView) {
              tooltip = layerNotAvailable;
            } else if (!hasThreatScore) {
              tooltip = threatScoreNotAvailable;
            }
            break;
          case 'change':
            hidden = getFeatureAccess('map-layers', 'hide');
            break;
          default:
            disabled = false;
            break;
        }
        return {
          ...layer,
          selected: layers[layer.id],
          disabled,
          hidden,
          loading,
          tooltip,
        };
      }),
    [
      mapLayers,
      layers,
      isWorldView,
      hasLatLonData,
      hasNeighborhoodData,
      isCityView,
      loadingFilters,
      loadingDistricts,
      loadingTiles,
      getFeatureAccess,
    ],
  );

  return (
    <Box
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}
      style={{height: expanded ? 'auto' : '64px'}}>
      {!expanded ? (
        <Box sx={styles.collapsed}>
          <Box sx={styles.legend}>
            <Box sx={styles.title}>
              <Stack size={20} />
              <Typography variant="body2">{selectedLayerLabel}</Typography>
            </Box>
            <Box sx={styles.scale}>
              <Typography variant="caption">{lowScore}</Typography>
              <ActionColorScale />
              <Typography variant="caption">{highScore}</Typography>
            </Box>
          </Box>
          <BaseIconButton
            icon={CaretDoubleUp}
            sx={styles.options}
            size={25}
            onClick={handleOptions}
          />
        </Box>
      ) : (
        <Box sx={styles.expanded}>
          <Box sx={styles.header}>
            <BaseIconButton
              icon={CaretDoubleDown}
              sx={styles.options}
              size={25}
              onClick={handleOptions}
            />
          </Box>
          <Box sx={styles.content}>
            <Box sx={styles.section}>
              <Typography variant="body2" fontWeight="bold">
                {markersTitle}
              </Typography>
              <Box sx={styles.container}>
                {mapMarkers.map(({id, preview}) => (
                  <Box key={id} sx={styles.option}>
                    <Box
                      sx={() =>
                        styles.preview({preview, selected: markers[id]})
                      }
                      onClick={() => handleMapMarkers({marker: id})}
                    />
                    <Typography
                      variant="caption"
                      sx={() => styles.caption(false)}>
                      {mapMarkersLabels[id]}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={styles.section}>
              <Typography variant="body2" fontWeight="bold">
                {layersTitle}
              </Typography>
              <Box sx={styles.container}>
                {controlledLayers
                  .filter(({hidden}) => !hidden)
                  .map(
                    ({
                      id,
                      preview,
                      icon: Icon,
                      selected,
                      loading,
                      disabled,
                      tooltip,
                    }) => (
                      <Box key={id} sx={styles.option}>
                        <Box
                          sx={() =>
                            styles.preview({preview, selected, disabled})
                          }
                          onClick={() => handleMapLayer({layer: id, disabled})}>
                          {Icon && <Icon size={25} weight="bold" />}
                          {disabled && (
                            <BaseTooltip message={tooltip} icon={null}>
                              <Box sx={styles.disabled} />
                            </BaseTooltip>
                          )}
                          {loading && (
                            <Box sx={styles.disabled}>
                              <CircularProgress size={25} />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          variant="caption"
                          sx={() => styles.caption(disabled)}>
                          {mapLayerLabels[id]}
                        </Typography>
                      </Box>
                    ),
                  )}
              </Box>
            </Box>
            <Box sx={styles.section}>
              <Typography variant="body2" fontWeight="bold">
                {stylesTitle}
              </Typography>
              <Box sx={styles.container}>
                {mapStyles.map(({id, preview, disabled}) => {
                  const selected = id === settings.mapStyle;
                  return (
                    <Box key={id} sx={styles.option}>
                      <Box
                        sx={() => styles.preview({preview, selected, disabled})}
                        onClick={() => handleMapStyle({mapStyle: id, disabled})}
                      />
                      <Typography
                        variant="caption"
                        sx={() => styles.caption(disabled)}>
                        {mapStyleLabels[id]}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

ActionMapSettings.propTypes = {
  sx: PropTypes.object,
};

export default ActionMapSettings;
