import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  getThreatAnalyticsAreaDisplay,
  getClickedLocation,
  getSummary,
  getSelectedDistrictIds,
  getGranularity,
  getNewUserLocation,
  getLocation,
  getDistricts,
} from '@/selectors';

export const useGranularity = () => {
  const areaVisibility = useSelector(getThreatAnalyticsAreaDisplay);
  const {hasLatLonData, hasNeighborhoodData} = useSelector(getSummary);
  const {id: savedLocationId, hoodId} = useSelector(getClickedLocation);
  const selectedDistrictIds = useSelector(getSelectedDistrictIds);
  const granularity = useSelector(getGranularity);
  const newLocation = useSelector(getNewUserLocation);
  const {id: locationId} = useSelector(getLocation);
  const {locationDistricts} = useSelector(getDistricts);

  const isRadiusEnabled = useMemo(
    () =>
      areaVisibility &&
      (savedLocationId >= 0 || hasLatLonData || !isEmpty(newLocation)),
    [savedLocationId, areaVisibility, hasLatLonData, newLocation],
  );

  const isDistrictEnabled = useMemo(
    () =>
      areaVisibility
        ? hoodId >= 0 && hasNeighborhoodData && locationDistricts?.length > 1
        : selectedDistrictIds.length > 0 && hasNeighborhoodData,
    [
      selectedDistrictIds,
      areaVisibility,
      hasNeighborhoodData,
      hoodId,
      locationDistricts,
    ],
  );

  const isCityEnabled = useMemo(
    () => (areaVisibility ? locationId >= 0 : hasNeighborhoodData),
    [hasNeighborhoodData, areaVisibility, locationId],
  );

  return {
    isRadiusEnabled,
    isDistrictEnabled,
    isCityEnabled,
    granularity,
  };
};
