import {useSelector} from 'react-redux';
import {getSavedLocations, getUserSettings} from '@/selectors';
import {API_SAVED_LOCATIONS} from '@/hooks/api/constants';
import {useApi} from '@/hooks/api/useApi';
import {CHANGE_CHART_METRICS} from '@/components/saved-locations/constants';

export const useRankSavedLocations = ({
  filters,
  sortColumn,
  sortDirection,
  config = {},
}) => {
  const {useGetQuery} = useApi();
  const {
    myLocationsAnalysis: {
      change: {aggregation, period},
    },
  } = useSelector(getUserSettings);
  const {
    analysisFilters: {
      addressTypes,
      tags,
      visibility,
      degreesOfChange,
      threatScores,
    },
  } = useSelector(getSavedLocations);

  const allowedSortColumns = ['change', 'name', 'lastVisited', 'score'];
  const validatedSortColumn = allowedSortColumns.includes(sortColumn)
    ? sortColumn
    : 'change';

  const {data, isFetching, refetch} = useGetQuery({
    path: API_SAVED_LOCATIONS,
    params: {
      ...filters,
      threatScoreQuintiles:
        threatScores?.length > 0 ? JSON.stringify(threatScores) : undefined,
      addressTypes:
        addressTypes?.length > 0 ? JSON.stringify(addressTypes) : undefined,
      tags: tags?.length > 0 ? JSON.stringify(tags) : undefined,
      degreesOfChange:
        degreesOfChange?.length > 0
          ? JSON.stringify(degreesOfChange)
          : undefined,
      changeMetric: CHANGE_CHART_METRICS[period][aggregation],
      visibility,
      sortBy: `${validatedSortColumn}.${sortDirection}`,
    },
    config: {
      enabled: true,
      ...config,
    },
  });

  return {
    isFetching,
    data: data?.data ?? [],
    meta: data?.meta ?? {},
    refetch,
  };
};
