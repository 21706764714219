import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {Box, useTheme} from '@mui/material';

import BaseRadioGroup from '@/components/common/checkboxes/BaseRadioGroup';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseLine from '@/components/common/charts/XYCharts/BaseLine';
import BaseLegend from '@/components/common/charts/BaseLegend';
import {styles} from '@/components/analytics/sections/EventTrendSection.styles';
import SectionTabs from '@/components/analytics/sections/SectionTabs';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

import {useTranslation} from '@/hooks/useTranslation';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {useCategoryTrend} from '@/hooks/charts/useCategoryTrend';
import {useTimeSeries} from '@/hooks/charts/useTimeSeries';
import {useTrendLine} from '@/hooks/charts/useTrendLine';
import {useMoment} from '@/hooks/useMoment';
import {useChartTitle} from '@/hooks/charts/useChartTitle';

function EventTrendSection({id, granularity, sx, isReport, settings, isGTM}) {
  const theme = useTheme();
  const {moment} = useMoment();
  const {locale, getI18N} = useTranslation();
  const {getChartColors} = useChartTheme();

  const [averageType, setAverageType] = useState('trendline');
  const [aggregation, setAggregation] = useState('monthly');

  const {eventCount} = getI18N('chartQuintile');
  const {label10: monthly} = getI18N('threatAnalytics');
  const {trendline: trendLineTooltip} = getI18N('tooltips');
  const {average, trendline, daily} = getI18N('chartSelection');

  // Average Type Options
  const averageTypeOptions = useMemo(
    () => [
      {
        label: trendline,
        value: 'trendline',
        loading: true,
        tooltip: trendLineTooltip,
      },
      {
        label: average,
        value: 'average',
        loading: true,
      },
    ],
    [locale],
  );

  // Aggregation Options
  const aggregationOptions = useMemo(
    () => [
      {
        label: daily,
        value: 'daily',
      },
      {
        label: monthly,
        value: 'monthly',
      },
    ],
    [locale],
  );

  const {dynamicTitle} = useChartTitle({
    title: '{aggregation} Event Trend',
    variants: [{id: 'aggregation', daily, monthly}],
    selections: [{id: 'aggregation', value: aggregation}],
  });

  // Generate file title for downloads
  const downloadTitle = useMemo(() => {
    if (averageType === 'average')
      return `${dynamicTitle} - ${eventCount} & ${average}`;
    if (averageType === 'trendline') return `${dynamicTitle} - ${trendline}`;
  }, [averageType, locale, dynamicTitle]);

  // Time Series Hook
  const {
    averageData,
    error: averageError,
    isFetching: averageIsLoading,
  } = useTimeSeries({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'crime',
  });

  // Category Trend Hook
  const {
    seriesLength: crimeSeriesLength,
    data: crimeCategoryData,
    error: crimeCategoryError,
    isFetching: crimeCategoryIsLoading,
    legend: crimeLegend,
    disabled: crimeDisabled,
    setDisabled: setCrimeDisabled,
  } = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'crime',
  });

  const {
    seriesLength: unrestSeriesLength,
    data: unrestCategoryData,
    error: unrestCategoryError,
    isFetching: unrestCategoryIsLoading,
    legend: unrestLegend,
    disabled: unrestDisabled,
    setDisabled: setUnrestDisabled,
  } = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    type: 'unrest',
  });

  const {downloadImage, copyImage, downloadCSV, copyCSV} = useCategoryTrend({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
    onlyDownloads: true,
  });

  const {
    data: trendlineData,
    error: trendlineError,
    isFetching: trendlineIsLoading,
    downloadCSV: downloadTrendlineCSV,
    copyCSV: copyTrendlineCSV,
  } = useTrendLine({
    id,
    title: downloadTitle,
    granularity,
    aggregation,
  });

  const isAnyLoading = useMemo(
    () =>
      unrestCategoryIsLoading ||
      crimeCategoryIsLoading ||
      trendlineIsLoading ||
      averageIsLoading,
    [
      unrestCategoryIsLoading,
      crimeCategoryIsLoading,
      trendlineIsLoading,
      averageIsLoading,
    ],
  );

  const isCrimeChartLoading = useMemo(
    () =>
      crimeCategoryIsLoading ||
      (trendlineIsLoading && averageType === 'trendline') ||
      (averageIsLoading && averageType === 'average'),
    [crimeCategoryIsLoading, averageType, trendlineIsLoading, averageIsLoading],
  );

  const enabledAverageTypes = useMemo(
    () =>
      averageTypeOptions.map((option) => {
        const disabled =
          (option.value === 'trendline' && Boolean(trendlineError)) ||
          (option.value === 'average' && Boolean(averageError));

        const loading =
          (option.value === 'trendline' && Boolean(trendlineIsLoading)) ||
          (option.value === 'average' && Boolean(averageIsLoading));

        return {
          ...option,
          disabled,
          loading,
        };
      }),
    [
      averageTypeOptions,
      trendlineError,
      averageError,
      trendlineIsLoading,
      averageIsLoading,
      aggregation,
    ],
  );

  // Get colors for each series
  const crimeColors = useMemo(() => {
    const chartColors = getChartColors(crimeSeriesLength + 7).slice(
      0,
      crimeSeriesLength,
    );
    const primary = theme.palette.primary.main;
    return [...chartColors, primary, primary, primary, primary];
  }, [getChartColors, crimeSeriesLength, theme]);

  const unrestColors = useMemo(() => {
    const chartColors = getChartColors(unrestSeriesLength + 7);
    return [chartColors[3], chartColors[6], chartColors[8]];
  }, [getChartColors, unrestSeriesLength]);

  const trendlineChart = useMemo(
    () => ({[trendline]: averageType === 'trendline' ? trendlineData : []}),
    [trendline, averageType, trendlineData],
  );

  const averageChart = useMemo(
    () => ({[average]: averageType === 'average' ? averageData : []}),
    [average, averageType, averageData],
  );

  const handleDownload = () => {
    if (averageType === 'trendline') {
      downloadTrendlineCSV();
    } else if (averageType === 'average') {
      downloadCSV();
    }
  };

  const handleCopy = () => {
    if (averageType === 'trendline') {
      copyTrendlineCSV();
    } else if (averageType === 'average') {
      copyCSV();
    }
  };

  const handleLegendClick = useCallback(
    (value) => {
      if (crimeDisabled.includes(value)) {
        setCrimeDisabled(crimeDisabled.filter((v) => v !== value));
      } else {
        setCrimeDisabled([...crimeDisabled, value]);
      }

      if (unrestDisabled.includes(value)) {
        setUnrestDisabled(unrestDisabled.filter((v) => v !== value));
      } else {
        setUnrestDisabled([...unrestDisabled, value]);
      }
    },
    [crimeDisabled, unrestDisabled],
  );

  const allError = useMemo(
    () =>
      unrestCategoryError &&
      crimeCategoryError &&
      trendlineError &&
      averageError,
    [unrestCategoryError, crimeCategoryError, trendlineError, averageError],
  );

  const xAxisValues = useMemo(
    () =>
      Array.from(
        new Set([
          ...Object.values(
            isEmpty(crimeCategoryData) ? unrestCategoryData : crimeCategoryData,
          ).flatMap((values) => values.map(({x}) => moment(x).toISOString())),
          ...trendlineData.map(({x}) => moment(x).toISOString()),
          ...averageData.map(({x}) => moment(x).toISOString()),
        ]),
      )
        .sort((date1, date2) => moment(date1).diff(moment(date2)))
        .map((date) => moment(date).toDate()),
    [crimeCategoryData, unrestCategoryData, trendlineData, averageData],
  );

  const legend = useMemo(() => {
    const crimeLabels = crimeLegend.map((label, index) => ({
      ...label,
      color: crimeColors[index],
    }));
    const unrestLabels = unrestLegend.map((label, index) => ({
      ...label,
      color: unrestColors[index],
    }));
    return [...crimeLabels, ...unrestLabels];
  }, [crimeLegend, crimeColors, unrestLegend, unrestColors]);

  const hideUnrestChart = useMemo(
    () => unrestCategoryError,
    [unrestCategoryError],
  );

  const hideCrimeChart = useMemo(
    () => crimeCategoryError,
    [crimeCategoryError],
  );

  const crimeChartHeight = useMemo(() => {
    if (hideCrimeChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.6 || 125 : 125;
    return hideUnrestChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  const unrestChartHeight = useMemo(() => {
    if (hideUnrestChart) return 0;
    const singleHeight = isReport ? sx.height || 200 : 200;
    const stackedHeight = isReport ? sx.height * 0.4 || 75 : 75;
    return hideCrimeChart ? singleHeight : stackedHeight;
  }, [hideUnrestChart, hideCrimeChart, isReport, sx]);

  // Set averageType to first enabled averageType if current averageType is disabled
  useEffect(() => {
    const selected = enabledAverageTypes.find(
      (option) => option.value === averageType,
    );

    if (!selected || selected?.disabled) {
      const firstEnabled = enabledAverageTypes.find(
        (option) => !option.disabled,
      );
      setAverageType(firstEnabled?.value);
    }
  }, [enabledAverageTypes, averageType]);

  useEffect(() => {
    setAverageType(settings.metric);
    setAggregation(settings.period);
  }, [settings]);

  const crimeYMax = useMemo(() => {
    const crimeCounts = Object.values(crimeCategoryData)
      .flat()
      .reduce((acc, {x, y}) => {
        acc[x] = (acc[x] || 0) + y;
        return acc;
      }, {});

    return Math.max(
      ...trendlineData.map(({y}) => y),
      ...averageData.map(({y}) => y),
      ...Object.values(crimeCounts),
    );
  }, [crimeCategoryData, trendlineData, averageData]);

  if (isReport)
    return (
      <ReportChartWrapper isLoading={isAnyLoading} error={Boolean(allError)}>
        <BaseXYChart
          hideGrid
          numYTicks={4}
          hideYOrigin={!hideUnrestChart}
          marginBottom={hideUnrestChart ? 20 : 0}
          height={crimeChartHeight}
          isLoading={isCrimeChartLoading}
          customColors={crimeColors}
          dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
          xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
          yScale={{
            type: 'linear',
            domain: [0, Math.max(5, crimeYMax)],
          }}>
          <BaseBar data={crimeCategoryData} type="stacked" />
          {!trendlineError && <BaseLine data={trendlineChart} />}
          <BaseLine data={averageChart} />
        </BaseXYChart>
        <BaseXYChart
          hideGrid
          hideYLastTick={!hideCrimeChart}
          numYTicks={!hideCrimeChart ? 3 : 4}
          marginTop={0}
          height={unrestChartHeight}
          isLoading={unrestCategoryIsLoading}
          customColors={unrestColors}
          dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
          xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
          backgroundColor={theme.palette.background.contrast.dark}>
          <BaseBar data={unrestCategoryData} type="stacked" />
        </BaseXYChart>
        <BaseLegend
          sx={styles.legend}
          isLoading={isCrimeChartLoading && unrestCategoryIsLoading}
          labels={legend}
          disabled={crimeDisabled && unrestDisabled}
          onClick={handleLegendClick}
        />
      </ReportChartWrapper>
    );

  if (allError) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={dynamicTitle}
        tooltip="eventTrend"
        granularity={granularity}
        downloadCSV={handleDownload}
        copyCSV={handleCopy}
        downloadImage={() => downloadImage()}
        copyImage={() => copyImage()}
        isGTM={isGTM && aggregation === 'monthly'}
        chart={
          <>
            <BaseXYChart
              hideGrid
              numYTicks={4}
              hideYOrigin={!hideUnrestChart}
              marginBottom={hideUnrestChart ? 20 : 0}
              height={crimeChartHeight}
              isLoading={isCrimeChartLoading}
              customColors={crimeColors}
              tooltipOptions={{
                formatValue: (key, datum) => {
                  if (key === 'Trendline' || !datum?.y || datum?.y < 1)
                    return datum.y;
                  return Math.round(datum.y);
                },
              }}
              dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
              xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
              yScale={{
                type: 'linear',
                domain: [0, Math.max(5, crimeYMax)],
              }}>
              <BaseBar data={crimeCategoryData} type="stacked" />
              {!trendlineError && <BaseLine data={trendlineChart} />}
              <BaseLine data={averageChart} />
            </BaseXYChart>
            <BaseXYChart
              hideGrid
              showBackground={!hideCrimeChart}
              hideYLastTick={!hideCrimeChart}
              numYTicks={!hideCrimeChart ? 3 : 4}
              marginTop={0}
              height={unrestChartHeight}
              isLoading={unrestCategoryIsLoading}
              customColors={unrestColors}
              dateFormat={aggregation === 'monthly' ? 'MM/YYYY' : 'MM/DD/YYYY'}
              xScale={{type: 'band', domain: xAxisValues, paddingInner: 0.3}}
              backgroundColor={theme.palette.background.contrast.dark}>
              <BaseBar data={unrestCategoryData} type="stacked" />
            </BaseXYChart>
            <BaseLegend
              sx={styles.legend}
              isLoading={isCrimeChartLoading && unrestCategoryIsLoading}
              labels={legend}
              disabled={crimeDisabled && unrestDisabled}
              onClick={handleLegendClick}
            />
          </>
        }
        controls={
          <Box display="flex" flexDirection="column" gap={2}>
            <Box sx={{width: 170}}>
              <SectionTabs
                options={aggregationOptions}
                value={aggregation}
                onChange={(_, value) => setAggregation(value)}
              />
            </Box>
            <BaseRadioGroup
              dense
              onChange={setAverageType}
              selected={averageType}
              options={enabledAverageTypes}
            />
          </Box>
        }
        settings={{id, metric: averageType, period: aggregation}}
      />
    </Box>
  );
}

EventTrendSection.propTypes = {
  sx: PropTypes.object,
  granularity: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isReport: PropTypes.bool,
  settings: PropTypes.object,
  isGTM: PropTypes.bool,
};

export default EventTrendSection;
