import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {MAP_BOX_USERNAME} from '@config';
import {getSavedLocations} from '@/selectors';
import {setLayers} from '@/store/modules/filters/actions';

import {useMapTransformRequest} from '@/hooks/map/useMapTransformRequest';
import {useMapMouse} from '@/hooks/map/useMapMouse';
import {useMouseMove} from '@/hooks/saved-locations/useMouseMove';
import {useMapZoom} from '@/hooks/saved-locations/useMapZoom';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useMapDrag} from '@/hooks/saved-locations/useMapDrag';

import {SAVED_LOCATIONS_ANALYSIS_SOURCE} from '@/components/map/layers/constants';
import {styles} from '@/components/saved-locations/map/SavedLocationsMap.styles';
import LayerSavedLocationAnalysis from '@/components/map/layers/LayerSavedLocationAnalysis';
import BaseMap from '@/components/common/map/BaseMap';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import SavedLocationsLegend from '@/components/saved-locations/map/SavedLocationsLegend';
import MapControls from '@/components/map/navigation/MapControls';
import MapTooltip from '@/components/map/popups/MapTooltip';

function SavedLocationsMap({id, settings: {view}}) {
  const dispatch = useDispatch();
  const {handleTransformRequest} = useMapTransformRequest();
  const {
    cursor,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseOut,
    handleMouseDown,
    handleMouseUp,
  } = useMapMouse();
  const {handleZoomEnd} = useMapZoom();
  const {handleDragEnd} = useMapDrag();
  const {handleMouseMove} = useMouseMove();
  const {triggerMapRepaint} = useMapboxGL();

  const {analysisFilters} = useSelector(getSavedLocations);

  const [map, setMap] = useState(null);
  const [selected, setSelected] = useState(false);

  const handleMapOnLoad = ({target: map}) => {
    map.jumpTo({center: [-30, 30], zoom: 1});
    setMap(map);
  };

  const handleMapMouseDown = (event) => {
    handleMouseDown(event);
    setSelected(true);
  };

  const handleMapMouseOut = (event) => {
    handleMouseOut(event);
    setSelected(false);
  };

  useEffect(() => {
    triggerMapRepaint(SAVED_LOCATIONS_ANALYSIS_SOURCE, map);
  }, [triggerMapRepaint, analysisFilters, map]);

  useEffect(() => {
    dispatch(setLayers('change'));
  }, []);

  return (
    <Box id={id} sx={styles.mapContainer}>
      <Box sx={styles.mapContent} visibility={map ? 'visible' : 'hidden'}>
        <BaseMap
          mapKey="saved-locations"
          dragPan
          style={styles.map}
          scrollZoom={selected}
          minZoom={0.5}
          cursor={cursor}
          mapStyle={`mapbox://styles/${MAP_BOX_USERNAME}/clrpxjcmk008d01pbh3rh6n4h`}
          onLoad={handleMapOnLoad}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMapMouseOut}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseDown={handleMapMouseDown}
          onZoomEnd={handleZoomEnd}
          onDragEnd={handleDragEnd}
          transformRequest={handleTransformRequest}>
          <LayerSavedLocationAnalysis view={view} />
          <MapControls />
          <MapTooltip />
        </BaseMap>
        <SavedLocationsLegend view={view} />
        <Box sx={styles.mapSkeleton} visibility={map ? 'hidden' : 'visible'}>
          <BaseSkeleton height="100%" width="100%" />
        </Box>
      </Box>
    </Box>
  );
}

export default SavedLocationsMap;

SavedLocationsMap.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};
