import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Source, Layer} from 'react-map-gl';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material';

import {MARTIN_SERVICE_URL} from '@config';
import {getLayers} from '@/selectors';
import {CHANGE, OVERVIEW, THREAT} from '@/components/saved-locations/constants';
import {
  SAVED_LOCATIONS_ANALYSIS_LAYER,
  SAVED_LOCATIONS_ANALYSIS_SOURCE,
} from '@/components/map/layers/constants';
import {useDegreeOfChange} from '@/hooks/api/useDegreeOfChange';

function LayerSavedLocationAnalysis({view}) {
  const theme = useTheme();
  const {data, isFetching} = useDegreeOfChange();

  const {change: isLayerEnabled} = useSelector(getLayers);

  const buckets = useMemo(() => {
    if (isFetching || !data || data.length < 1) {
      return [-10, -3.75, 3.75, 10];
    }
    return data.map(({bucket}) => bucket.max);
  }, [data, isFetching]);

  return (
    <Source
      id={SAVED_LOCATIONS_ANALYSIS_SOURCE}
      type="vector"
      url={MARTIN_SERVICE_URL + SAVED_LOCATIONS_ANALYSIS_LAYER}>
      <Layer
        id="saved-locations-change-layer"
        source={SAVED_LOCATIONS_ANALYSIS_SOURCE}
        source-layer={`public.${SAVED_LOCATIONS_ANALYSIS_LAYER}_fn`}
        type="circle"
        filter={['all', ['has', 'c'], ['has', 'n']]}
        paint={{
          'circle-opacity': 0.75,
          'circle-radius': [
            'step',
            ['get', 'c'],
            14,
            buckets[0],
            7,
            buckets[1],
            4,
            0,
            4,
            buckets[2],
            7,
            buckets[3],
            14,
          ],
          'circle-color': [
            'step',
            ['get', 'c'],
            theme.palette.change.large_decrease,
            buckets[0],
            theme.palette.change.decrease,
            buckets[1],
            theme.palette.change.no_change,
            0,
            theme.palette.change.no_change,
            buckets[2],
            theme.palette.change.increase,
            buckets[3],
            theme.palette.change.large_increase,
          ],
        }}
        layout={{
          visibility:
            view === CHANGE && isLayerEnabled && !isFetching
              ? 'visible'
              : 'none',
        }}
      />
      <Layer
        id="saved-locations-types-layer"
        source={SAVED_LOCATIONS_ANALYSIS_SOURCE}
        source-layer={`public.${SAVED_LOCATIONS_ANALYSIS_LAYER}_fn`}
        type="symbol"
        filter={['all', ['has', 't']]}
        layout={{
          'icon-image': [
            'case',
            ['==', ['get', 't'], 'office'],
            'marker_office',
            ['==', ['get', 't'], 'hotel'],
            'marker_hotel',
            ['==', ['get', 't'], 'event'],
            'marker_event',
            ['==', ['get', 't'], 'restaurant'],
            'marker_restaurant',
            ['==', ['get', 't'], 'travel'],
            'marker_travel',
            ['==', ['get', 't'], 'supply_chain'],
            'marker_supply_chain',
            ['==', ['get', 't'], 'facility'],
            'marker_facility',
            ['==', ['get', 't'], 'parking'],
            'marker_parking',
            ['==', ['get', 't'], 'service_area'],
            'marker_service_area',
            ['==', ['get', 't'], 'warehouse'],
            'marker_warehouse',
            ['==', ['get', 't'], 'retail'],
            'marker_retail',
            ['==', ['get', 't'], 'point_of_interest'],
            'marker_point_of_interest',
            'marker_other',
          ],
          'icon-size': 0.7,
          visibility: view === OVERVIEW && isLayerEnabled ? 'visible' : 'none',
        }}
      />
      <Layer
        id="saved-locations-threat-score-layer"
        source={SAVED_LOCATIONS_ANALYSIS_SOURCE}
        source-layer={`public.${SAVED_LOCATIONS_ANALYSIS_LAYER}_fn`}
        type="circle"
        filter={['all', ['has', 'ts'], ['>=', 'ts', 0]]}
        paint={{
          'circle-opacity': 0.75,
          'circle-radius': [
            'step',
            ['get', 'ts'],
            14,
            20,
            7,
            40,
            4,
            60,
            7,
            80,
            14,
          ],
          'circle-color': [
            'step',
            ['get', 'ts'],
            theme.palette.threat.very_low,
            20,
            theme.palette.threat.low,
            40,
            theme.palette.threat.medium,
            60,
            theme.palette.threat.high,
            80,
            theme.palette.threat.very_high,
          ],
        }}
        layout={{
          visibility: view === THREAT && isLayerEnabled ? 'visible' : 'none',
        }}
      />
    </Source>
  );
}
LayerSavedLocationAnalysis.propTypes = {
  view: PropTypes.string.isRequired,
};

export default LayerSavedLocationAnalysis;
