import PropTypes from 'prop-types';
import {useState, useMemo, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {DataGrid} from '@mui/x-data-grid';
import {ArrowDown, ArrowUp, MapPin} from '@phosphor-icons/react';
import {Box, Grid, useTheme} from '@mui/material';
import {DownloadSimple} from '@phosphor-icons/react/dist/ssr';
import {styles} from '@/components/saved-locations/tables/RankedLocations.styles';
import {useTranslation} from '@/hooks/useTranslation';
import {useLocationsMostChange} from '@/hooks/api/useLocationsMostChange';
import BaseSkeleton from '@/components/common/BaseSkeleton';
import {useChartDownloads} from '@/hooks/charts/useChartDownloads';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useRankSavedLocations} from '@/hooks/api/useRankSavedLocations';
import RankedLocationsHeader from '@/components/saved-locations/tables/RankedLocationsHeader';
import {setSelectedLocation} from '@/store/modules/user/actions';
import {setRoutedToMap, setSelectedCity} from '@/store/modules/map/actions';
import {useRouter} from '@/hooks/useRouter';
import {useApi} from '@/hooks/api/useApi';
import BaseCard from '@/components/common/cards/BaseCard';
import ChartOptionsDropdown from '@/components/analytics/ChartOptionsDropdown';
import BasePagination from '@/components/common/pagination/BasePagination';
import {BaseIconButton} from '@/components/common/buttons';
import {CHANGE, OVERVIEW, THREAT} from '@/components/saved-locations/constants';

const defaultSort = {
  [OVERVIEW]: 'lastVisited',
  [THREAT]: 'score',
  [CHANGE]: 'change',
};

function RankedLocations({id, settings: {mode = 'cities', view = OVERVIEW}}) {
  const {getI18N} = useTranslation();
  const {track} = useSegmentAnalytics();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {pushMapRoute} = useRouter();
  const {getLocation} = useApi();

  const [sortColumn, setSortColumn] = useState(defaultSort[OVERVIEW]);
  const [sortAsc, setSortAsc] = useState(false);
  const [page, setPage] = useState(0);

  const {
    citiesTableTitle,
    locationsTableTitle,
    changeTrendHeaders: columnHeaders,
  } = getI18N('savedLocations');
  const {copyImageLabel, downloadImageLabel} = getI18N('chartOptions');

  const {
    data: cityData,
    meta: cityMeta,
    isFetching: isCityFetching,
  } = useLocationsMostChange({
    filters: {
      page: page + 1, // 1-based index
    },
    sortColumn,
    sortDirection: sortAsc ? 'ASC' : 'DESC',
  });

  const {
    data: savedLocationData,
    meta: savedLocationMeta,
    isFetching: isFetchingSavedLocations,
    refetch: refetchSavedLocations,
  } = useRankSavedLocations({
    filters: {
      page: page + 1, // 1-based index
    },
    sortColumn,
    sortDirection: sortAsc ? 'ASC' : 'DESC',
  });

  const {copyImage, downloadImage} = useChartDownloads({
    id,
    title: mode === 'cities' ? citiesTableTitle : locationsTableTitle,
  });

  const data = useMemo(
    () => (mode === 'cities' ? cityData : savedLocationData),
    [cityData, savedLocationData, mode],
  );

  const isFetching = useMemo(
    () => (mode === 'cities' ? isCityFetching : isFetchingSavedLocations),
    [isCityFetching, isFetchingSavedLocations, mode],
  );

  const formatChange = (change) => {
    if (change || change === 0) {
      const formattedChange = change.toFixed(1);
      return `${formattedChange}%`;
    }
    return 'N/A';
  };

  const cityRows = useMemo(
    () =>
      (cityData ?? []).map((row) => ({
        id: row.locationId,
        name: row.name,
        change: row.change,
        score: row.score,
        savedLocationCount: row.savedLocationCount,
      })),
    [data],
  );

  const savedLocationRows = useMemo(
    () =>
      savedLocationData.map(({name, changes, lastVisited, ...row}) => ({
        ...row,
        name,
        score: row.score,
        change: changes.length > 0 && changes[0].change,
        lastVisited: lastVisited
          ? new Date(lastVisited).toLocaleDateString()
          : '-',
      })),
    [savedLocationData],
  );

  const rows = useMemo(
    () => (mode === 'cities' ? cityRows : savedLocationRows),
    [cityRows, savedLocationRows, mode],
  );

  const handleSort = (field) => {
    setSortColumn(field);
    setSortAsc((currentSortAsc) => {
      track('Sort cities with saved locations with most change', {
        sortDirection: !currentSortAsc ? 'ASC' : 'DESC',
      });
      return !currentSortAsc;
    });
  };

  const sortDirection = sortAsc ? 'ASC' : 'DESC';

  const columns = useMemo(
    () => [
      {
        field: 'name',
        renderHeader: () =>
          RankedLocationsHeader({
            text:
              mode === 'cities'
                ? columnHeaders.cityName
                : columnHeaders.locationName,
            sortable: true,
            active: sortColumn === 'name',
            sortDirection,
          }),
        flex: 0.4,
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => (
          <Box title={params.value} style={styles.locationNameCell}>
            <BaseIconButton sx={styles.mapIcon}>
              <MapPin size={18} color={theme.palette.primary.main} />
            </BaseIconButton>
            {params.value}
          </Box>
        ),
      },
      {
        field: 'score',
        renderHeader: () =>
          RankedLocationsHeader({
            text: columnHeaders.score,
            sortable: true,
            active: sortColumn === 'score',
            sortDirection,
          }),
        flex: 0.2,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
      },
      {
        field: 'change',
        renderHeader: () =>
          RankedLocationsHeader({
            text: columnHeaders.change,
            sortable: true,
            active: sortColumn === 'change',
            sortDirection,
          }),
        flex: 0.2,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          if (params.value > 0) {
            return (
              <Box title={`${params.value}%`}>
                <ArrowUp size={18} color={theme.palette.quintile[5]} />
                {formatChange(params.value)}
              </Box>
            );
          }
          if (params.value < 0) {
            return (
              <Box title={`${params.value}%`}>
                <ArrowDown size={18} color={theme.palette.quintile[1]} />
                {formatChange(params.value)}
              </Box>
            );
          }

          return (
            <Box title={`${params.value}%`} style={styles.cellZero}>
              {formatChange(params.value)}
            </Box>
          );
        },
      },
      {
        field: 'savedLocationCount',
        renderHeader: () =>
          RankedLocationsHeader({
            text: columnHeaders.savedLocationCount,
            sortable: true,
            active: sortColumn === 'savedLocationCount',
            sortDirection,
          }),
        flex: 0.2,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        hide: mode === 'savedLocations',
      },
      {
        field: 'lastVisited',
        renderHeader: () =>
          RankedLocationsHeader({
            text: columnHeaders.lastVisited,
            sortable: true,
            active: sortColumn === 'lastVisited',
            sortDirection,
          }),
        flex: 0.2,
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        hide: mode === 'cities',
      },
    ],
    [mode, sortAsc, sortColumn],
  );

  const computedColumns = useMemo(
    () => columns.filter((column) => !column.hide),
    [columns],
  );

  const pageModel = useMemo(() => {
    if (mode === 'cities') {
      return {
        page: cityMeta?.page ? cityMeta.page - 1 : 0,
        pageSize: cityMeta?.take ?? 10,
      };
    }

    return {
      page: savedLocationMeta?.page ? savedLocationMeta.page - 1 : 0,
      pageSize: savedLocationMeta?.take ?? 10,
    };
  }, [savedLocationMeta, cityMeta, mode]);

  const rowCount = useMemo(() => {
    if (mode === 'cities') {
      return cityMeta?.itemCount ?? 0;
    }

    return savedLocationMeta?.itemCount ?? 0;
  }, [cityMeta, savedLocationMeta, mode]);

  const navigateToSavedLocation = useCallback(
    (location) => {
      if (mode === 'savedLocations') {
        dispatch(setSelectedLocation(location));
        pushMapRoute();
        dispatch(setRoutedToMap(true));
      } else {
        getLocation.mutate(
          {query: location.id},
          {
            onSuccess: ({defaultZoomLevel, ...response}) => {
              dispatch(setSelectedCity({...response, zoom: defaultZoomLevel}));
              pushMapRoute();
              dispatch(setRoutedToMap(true));
            },
          },
        );
      }
    },
    [dispatch, mode],
  );

  useEffect(() => {
    refetchSavedLocations();
  }, []);

  useEffect(() => {
    setSortColumn(defaultSort[view]);
  }, [view]);

  return (
    <BaseCard
      id={id}
      title={mode === 'cities' ? citiesTableTitle : locationsTableTitle}
      hideHeader
      sx={{root: styles.root, content: styles.content}}
      footer={
        !isFetching && (
          <Box sx={styles.footer}>
            <BasePagination
              count={rowCount}
              page={pageModel.page}
              rowsPerPage={pageModel.pageSize}
              onPageChange={(_, newPage) => setPage(newPage)}
            />
            <ChartOptionsDropdown
              icon={DownloadSimple}
              options={[
                {
                  label: copyImageLabel,
                  action: copyImage,
                },
                {
                  label: downloadImageLabel,
                  action: downloadImage,
                },
              ]}
            />
          </Box>
        )
      }>
      {isFetching ? (
        <Grid container spacing={1}>
          {Array(31)
            .fill(0)
            .map((_, index) => (
              <Grid item xs={index === 0 ? 12 : 4} key={index}>
                <BaseSkeleton height="20px" width="100%" />
              </Grid>
            ))}
        </Grid>
      ) : (
        <DataGrid
          sx={styles.dataGrid}
          rows={rows}
          columns={computedColumns}
          onColumnHeaderClick={({field}) => handleSort(field)}
          loading={isFetching}
          rowHeight={30}
          columnHeaderHeight={30}
          hideFooter
          onRowClick={({row}) => navigateToSavedLocation(row)}
        />
      )}
    </BaseCard>
  );
}

RankedLocations.propTypes = {
  id: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
};

export default RankedLocations;
