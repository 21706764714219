// Packages
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import escapeRegExp from 'lodash/escapeRegExp';

// Styles

// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// Constants
import {useTranslation} from '@hooks/useTranslation';

// Hooks
import {styles} from '@/components/saved-locations/cards/SavedLocationCard.styles';
import {convertDistance} from '@/utils/utils';
import SavedVisibilityLabel from '@/components/saved-locations/cards/SavedVisibilityLabel';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import {getLocationDateRange} from '@/components/saved-locations/utils';

// Highlight part of text based on substring
const markText = (text, searchText) => {
  const regex = new RegExp(escapeRegExp(searchText), 'gi');
  const parts = text.split(regex);
  const matches = text.match(regex);
  const result = [];
  for (let i = 0; i < parts.length; i += 1) {
    if (parts[i]) {
      result.push(parts[i]);
    }
    if (matches && matches[i]) {
      result.push(
        <Box
          key={i}
          component="mark"
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.main,
            borderRadius: '3px',
          })}>
          {matches[i]}
        </Box>,
      );
    }
  }
  return result;
};

function SavedLocationTitle({
  name = '<Title>',
  isPrivate,
  address = '',
  customerLocationId = null,
  startDate = null,
  endDate = null,
  searchText = '',
  radiusMeters = null,
  units = null,
  view = 'grid',
}) {
  const {getI18N} = useTranslation();
  const {
    noCoverage,
    coverageFrom,
    location: locationText,
  } = getI18N('savedLocations');
  const to = getI18N('to');
  const {of} = getI18N('legendQuintile');

  const getTitle = () => {
    if (customerLocationId) {
      return `${customerLocationId}: ${name}`;
    }
    return name;
  };

  return (
    <Box sx={styles.cardTitle}>
      <Box sx={styles.cardHeaderTitleContainer}>
        <Typography variant="subtitle1" sx={styles.cardHeaderTitle}>
          {markText(getTitle(), searchText)}
        </Typography>
        {view !== 'list' && (
          <FeatureAccessWrapper feature="shared-locations-visibility">
            <SavedVisibilityLabel sx={{marginLeft: 1}} isPrivate={isPrivate} />
          </FeatureAccessWrapper>
        )}
      </Box>
      {radiusMeters && (
        <Typography variant="caption" sx={styles.cardHeaderSubtitle}>
          {`${convertDistance(radiusMeters, units).toFixed(2)} ${units} ${of} `}
          {address ? markText(address, searchText) : locationText}
        </Typography>
      )}
      {view !== 'list' && (
        <Typography variant="caption" sx={styles.cardHeaderSubtitleAlt}>
          {startDate && endDate
            ? `${coverageFrom} ${getLocationDateRange(startDate, endDate, to)}`
            : noCoverage}
        </Typography>
      )}
    </Box>
  );
}

SavedLocationTitle.propTypes = {
  name: PropTypes.string,
  customerLocationId: PropTypes.string,
  address: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  searchText: PropTypes.string,
  radiusMeters: PropTypes.number,
  units: PropTypes.string,
  view: PropTypes.string,
  isPrivate: PropTypes.bool,
};

export default memo(SavedLocationTitle);
