export const styles = {
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingInline: 2,
  },
  link: {
    cursor: 'pointer',
  },
};
