import PropTypes from 'prop-types';
import millify from 'millify';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material';

import {
  AnimatedAxis,
  AnimatedGrid,
  XYChart,
  Tooltip,
  Grid,
} from '@visx/xychart';
import {defaultStyles} from '@visx/tooltip';
import {ParentSize} from '@visx/responsive';

import BaseSkeleton from '@common/BaseSkeleton';
import {useMoment} from '@/hooks/useMoment';
import {useChartTheme} from '@/hooks/charts/useChartTheme';

function BaseXYChart({
  width,
  height = 200,
  hideXAxis = false,
  hideYAxis = false,
  xAxisOptions = {},
  yAxisOptions = {},
  hideGrid = false,
  showZeroLine = false,
  children,
  seriesLength,
  angledTicks,
  marginBottom = 20,
  marginLeft = 40,
  marginRight = 20,
  marginTop = 20,
  showBackground,
  isLoading,
  xScale,
  yScale,
  customColors,
  dateFormat = 'MM/DD/YYYY',
  tooltipOptions = {},
  orientation = 'vertical',
  hideYAxisLine = true,
  numYTicks = 5,
  hideXOrigin = false,
  hideYOrigin = false,
  backgroundColor,
  hideYLastTick = false,
  breakTickLabels = false,
}) {
  const theme = useTheme();
  const {theme: chartTheme} = useChartTheme(seriesLength, customColors);
  const {moment} = useMoment();

  function truncateLabel(label, maxLength) {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength - 3)}...`;
    }
    return label;
  }

  const formatTickLabel = (value, maxLength = undefined, ticks = []) => {
    if (typeof value === 'number') {
      if (value >= 1000) return millify(value, {precision: 1});

      const min = Math.min(...ticks.map(({value}) => value));
      const max = Math.max(...ticks.map(({value}) => value));
      if (max - min <= 0.5) return value.toFixed(2);
      if (max - min < 5) return value.toFixed(1);
      return value.toFixed(0);
    }
    if (value instanceof Date) {
      return moment(value).format(dateFormat);
    }
    if (maxLength > 0) {
      return truncateLabel(value, maxLength);
    }
    return value;
  };

  const formatYTick = (value, index, ticks) => {
    if (hideYLastTick && index === ticks.length - 1) return '';
    if (hideYOrigin && index === 0) return '';

    return formatTickLabel(value, 0, ticks);
  };

  const formatXTick = (value, index, ticks) => {
    if (hideXOrigin && index === 0) {
      return '';
    }
    return formatTickLabel(value, 25, ticks);
  };

  const numXTicks = (width) => {
    let max;
    if (angledTicks) {
      max = Math.max(3, Math.floor(width / 30));
    } else {
      max = Math.max(3, Math.floor(width / 100));
    }
    return Math.min(16, max);
  };

  const customizedTicks = ({formattedValue, x, y}) => (
    <text x={x} y={y} fill={theme.palette.text.secondary} fontSize={10}>
      {formattedValue.split(' ').map((part, index) => (
        <tspan key={index} x={0} dy={`${index}em`} textAnchor="middle">
          {part}
        </tspan>
      ))}
    </text>
  );

  return (
    <ParentSize style={{height: 'inherit'}}>
      {(parent) => {
        const w = width ?? parent.width;
        const h = height ?? parent.height;

        if (!w || !h) return null;

        if (isLoading)
          return (
            <BaseSkeleton
              width={`${w.toString()}px`}
              height={`${h.toString()}px`}
              sx={{marginBottom: 1}}
            />
          );

        return (
          <XYChart
            theme={chartTheme}
            width={w}
            height={h}
            margin={{
              top: marginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            }}
            xScale={xScale ?? {type: 'band', paddingInner: 0.3}}
            yScale={yScale ?? {type: 'linear'}}>
            {showBackground && (
              <rect
                x={marginLeft}
                y={marginTop}
                width={w - marginLeft - marginRight}
                height={h - marginTop - marginBottom}
                fill={backgroundColor || theme.palette.background.light}
                rx={0}
              />
            )}
            {hideXAxis === false && (
              <AnimatedAxis
                orientation="bottom"
                tickLength={breakTickLabels ? 0 : undefined}
                tickLabelProps={
                  angledTicks && {
                    angle: -45,
                    textAnchor: 'end',
                    scaleToFit: true,
                  }
                }
                numTicks={numXTicks(w)}
                tickFormat={formatXTick}
                tickComponent={breakTickLabels ? customizedTicks : undefined}
                {...xAxisOptions}
              />
            )}
            {hideYAxis === false && (
              <AnimatedAxis
                orientation="left"
                hideTicks
                hideAxisLine={hideYAxisLine}
                numTicks={numYTicks}
                tickFormat={formatYTick}
                {...yAxisOptions}
              />
            )}
            {hideGrid === false && (
              <AnimatedGrid columns={false} numTicks={5} />
            )}
            {showZeroLine && (
              <Grid
                columns={false}
                tickValues={[0]}
                lineStyle={{
                  stroke: theme.palette.text.primary,
                  strokeWidth: 2,
                }}
                stroke={theme.palette.text.primary}
                strokeWidth={2}
              />
            )}
            {children}
            <Tooltip
              snapTooltipToDatumY={orientation === 'horizontal'}
              snapTooltipToDatumX={orientation === 'vertical'}
              style={{
                ...defaultStyles,
                backgroundColor: theme.palette.background.paper,
                padding: 0,
                margin: 0,
                zIndex: 1500,
              }}
              renderTooltip={({tooltipData, colorScale}) => {
                const {colorAccessor, formatValue, renderTooltip} =
                  tooltipOptions;
                if (renderTooltip)
                  return renderTooltip({tooltipData, colorScale});

                const data = tooltipData.datumByKey || [];
                return (
                  <Box
                    sx={{
                      paddingX: '16px',
                      paddingY: '8px',
                      fontSize: '12px',
                      borderRadius: '4px',
                    }}>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                      fontWeight="bold">
                      {formatTickLabel(tooltipData.nearestDatum.datum.x)}
                    </Typography>
                    {Object.keys(data).map((key) => {
                      if (data[key].datum.disabled) return null;
                      return (
                        <Box
                          key={key}
                          sx={{
                            marginTop: '4px',
                            alignItems: 'center',
                            fontWeight: 'normal',
                            color: theme.palette.text.secondary,
                          }}>
                          <Box
                            sx={{
                              display: 'inline-flex',
                              width: '11px',
                              height: '11px',
                              marginRight: '8px',
                              backgroundColor: colorAccessor
                                ? colorAccessor(
                                    key,
                                    data[key].datum,
                                    colorScale,
                                  )
                                : colorScale(key),
                              borderRadius: '4px',
                            }}
                          />
                          <strong>{key}: </strong>
                          {formatValue
                            ? formatValue(key, data[key].datum)
                            : data[key].datum.y}
                        </Box>
                      );
                    })}
                  </Box>
                );
              }}
            />
          </XYChart>
        );
      }}
    </ParentSize>
  );
}

BaseXYChart.propTypes = {
  breakTickLabels: PropTypes.bool,
  hideYLastTick: PropTypes.bool,
  backgroundColor: PropTypes.string,
  hideXOrigin: PropTypes.bool,
  hideYOrigin: PropTypes.bool,
  hideFirstTick: PropTypes.bool,
  numYTicks: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  seriesLength: PropTypes.number,
  children: PropTypes.node,
  hideYAxis: PropTypes.bool,
  hideXAxis: PropTypes.bool,
  yAxisOptions: PropTypes.object,
  xAxisOptions: PropTypes.object,
  hideGrid: PropTypes.bool,
  showZeroLine: PropTypes.bool,
  angledTicks: PropTypes.bool,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  showBackground: PropTypes.bool,
  isLoading: PropTypes.bool,
  customColors: PropTypes.arrayOf(PropTypes.string),
  dateFormat: PropTypes.string,
  hideYAxisLine: PropTypes.bool,
  xScale: PropTypes.shape({
    type: PropTypes.string,
    paddingInner: PropTypes.number,
    domain: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    ),
  }),
  yScale: PropTypes.shape({
    type: PropTypes.string,
    paddingInner: PropTypes.number,
    domain: PropTypes.arrayOf(PropTypes.string),
  }),
  tooltipOptions: PropTypes.shape({
    colorAccessor: PropTypes.func,
    formatValue: PropTypes.func,
    renderTooltip: PropTypes.func,
  }),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default BaseXYChart;
