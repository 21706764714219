import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {SENTRY_ENV} from '@config';
import {
  CONTROLLED_FEATURES,
  ControlledFeature,
  FeatureAction,
} from './constants';

import {getOrganization, getUser} from '@/selectors';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import {
  Environment,
  Organization,
  OrganizationTier,
  User,
  UserRole,
} from '@/types/shared';

interface FeatureWithStatus extends ControlledFeature {
  status: boolean;
}

interface FeatureAccessHook {
  getFeatureAccess: (
    controlledFeature: string,
    action: FeatureAction,
  ) => boolean;
  getControlledFeature: (controlledFeature: string) => FeatureWithStatus;
  currentEnv: Environment;
}

export const useFeatureAccess = (): FeatureAccessHook => {
  const {reportAccessToken} = useAuth0Service();
  const user: User = useSelector(getUser);
  const {active: organization}: {active: Organization} =
    useSelector(getOrganization);

  // Add environment detection
  const currentEnv = (SENTRY_ENV || 'local') as Environment;

  const getControlledFeature = useCallback(
    (controlledFeature: string): FeatureWithStatus => {
      if (!controlledFeature) return {} as FeatureWithStatus;

      const feature = CONTROLLED_FEATURES.find(
        ({id, name}) => controlledFeature === id || controlledFeature === name,
      );

      if (!feature) return {} as FeatureWithStatus;

      // Report access token is a special case
      if (reportAccessToken) {
        return {
          ...feature,
          status: false,
        };
      }

      // If roles/tiers are undefined = no restrictions
      const hasRoleAccess = (role: UserRole): boolean =>
        !feature.roles || feature.roles.includes(role);

      const hasTierAccess = (tier: OrganizationTier): boolean =>
        !feature.tiers || feature.tiers.includes(tier);

      const hasEnvironmentAccess = (env: Environment): boolean =>
        !feature.environments || feature.environments.includes(env);

      return {
        ...feature,
        status: !(
          hasRoleAccess(user.role) &&
          hasTierAccess(organization.tier) &&
          hasEnvironmentAccess(currentEnv)
        ),
      };
    },
    [user, organization, reportAccessToken, currentEnv],
  );

  const getFeatureAccess = useCallback(
    (controlledFeature: string, action: FeatureAction): boolean => {
      if (!controlledFeature || !action) return false;
      const feature = getControlledFeature(controlledFeature);
      return feature.actions?.includes(action) && feature.status;
    },
    [getControlledFeature],
  );

  return {
    getFeatureAccess,
    getControlledFeature,
    currentEnv,
  };
};
