import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';
import {setIsFlying} from '@/store/modules/map/actions';
import {
  setLocationOverrideSettings,
  setTimezone,
} from '@/store/modules/filters/actions';
import {getIsMapReady} from '@/selectors';
import {hasTimezoneFeature, getTimezoneFeature} from '@/utils/mapUtils';
import {NEIGHBORHOOD_LAYER} from '@/components/map/layers/constants';
import {useMap} from '@/hooks/useMap';

// minZoomLevel = location.defaultZoomLevel - MIN_ZOOM_OFFSET
const MIN_ZOOM_OFFSET = 2.5;

export const useMapboxGL = () => {
  const dispatch = useDispatch();
  const {map: contextMap} = useMap();
  const isMapReady = useSelector(getIsMapReady);

  const getTilesInViewPort = useCallback(() => {
    if (contextMap && isMapReady) {
      const tiles = contextMap.style.sourceCaches.composite._tiles;
      const tilesInView = Object.keys(tiles)
        .filter((item) => tiles[item].latestFeatureIndex !== undefined)
        .map((item) => {
          const {x, y, z} = tiles[item].latestFeatureIndex;
          return {x, y, z};
        });

      return tilesInView;
    }
  }, [contextMap, isMapReady]);

  const getTimezone = useCallback(
    (lat, lon) => {
      if (contextMap && isMapReady) {
        const queryFeatures = contextMap.queryRenderedFeatures(
          contextMap.project([lon, lat]),
          {layers: ['timezone-layer']},
        );
        if (hasTimezoneFeature(queryFeatures)) {
          const {properties} = getTimezoneFeature(queryFeatures);
          return properties?.tzid;
        }
      }
      return '';
    },
    [contextMap, isMapReady],
  );

  /**
   * Navigates the map to a specific location using Mapbox GL.
   *
   * @param {Object} options - The navigation options.
   * @param {string} options.type - The type of navigation. Values include 'flyTo', 'jumpTo', 'easeTo' or 'fitBounds'. Defaults to 'flyTo'.
   * @param {number} options.latitude - The latitude of the target location.
   * @param {number} options.longitude - The longitude of the target location.
   * @param {number} options.bounds - The bounds of the target location, attempts to fit the map to the bounds if provided.
   * @param {number} options.zoom - The zoom level of the map.
   * @param {Function} options.onStart - A callback function to be called when the navigation starts.
   * @param {Function} options.onMove - A callback function to be called when the map is being moved.
   * @param {Function} options.onEnd - A callback function to be called when the navigation ends.
   * @param {Object} map - The map object to use for navigation. If not provided, the context map will be used.
   */

  const navigateTo = useCallback(
    (
      {
        type = 'flyTo',
        latitude,
        longitude,
        zoom,
        onStart,
        onMove,
        onEnd,
        bounds,
      },
      eventMap,
    ) => {
      const map = eventMap ?? contextMap;
      if (map && isMapReady) {
        const isFlyingNavigation = ['flyTo', 'fitBounds'].includes(type);
        if (isFlyingNavigation) {
          dispatch(setIsFlying(true));
        }

        const animationOptions = {
          speed: 2.3,
          curve: 0.7,
          easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
        };

        // Resolve the navigation options based on the navigation type
        let navigationOptions;
        switch (type) {
          case 'flyTo':
            navigationOptions = {
              center: [longitude, latitude],
              zoom,
              ...animationOptions,
            };
            break;

          case 'fitBounds':
            navigationOptions = {
              padding: {top: 10, bottom: 10, left: 80, right: 80},
              ...animationOptions,
            };
            break;
          default:
            navigationOptions = {
              center: [longitude, latitude],
              zoom,
            };
            break;
        }

        // If bounds are provided, fit the map to the bounds
        if (bounds && type === 'fitBounds') {
          const {neLatitude, neLongitude, swLatitude, swLongitude} = bounds;
          map.fitBounds(
            [
              [swLongitude, swLatitude], // [lng, lat] - southwestern corner of the bounds
              [neLongitude, neLatitude], // [lng, lat] - northeastern corner of the bounds
            ],
            navigationOptions,
          );
        }
        // Fall back to fly to if bounds are not provided for fitBounds navigation
        else if (type === 'fitBounds') {
          map.flyTo(navigationOptions);
        } else {
          map[type](navigationOptions);
        }

        map.once('movestart', () => {
          map.resize();
          if (typeof onStart === 'function') {
            onStart();
          }
        });

        map.once('move', () => {
          map.resize();
          if (typeof onMove === 'function') {
            onMove();
          }
        });

        map.once('moveend', () => {
          dispatch(setTimezone(getTimezone(latitude, longitude)));
          if (isFlyingNavigation) {
            dispatch(setIsFlying(false));
          }
          if (typeof onEnd === 'function') {
            onEnd();
          }

          // Store locations current zoom level and computed min zoom level
          const currentZoom = map.getZoom();
          const locationOverride = {
            defaultZoomLevel: currentZoom,
            minZoomLevel: Math.max(currentZoom - MIN_ZOOM_OFFSET, 0),
          };
          dispatch(setLocationOverrideSettings(locationOverride));
        });
      }
    },
    [dispatch, contextMap, isMapReady, getTimezone],
  );

  const triggerMapRepaint = useCallback(
    (source, eventMap) => {
      const map = eventMap ?? contextMap;
      if (
        map &&
        map.isStyleLoaded() &&
        map.getSource(source) &&
        (isMapReady || eventMap)
      ) {
        const sourceConfig = map.getSource(source).serialize();
        const {layers} = map.getStyle();
        layers
          .filter((layer) => layer.source === source)
          .forEach((layer) => {
            if (map.getLayer(layer.id)) {
              map.removeLayer(layer.id);
            }
          });
        map.removeSource(source);
        map.addSource(source, sourceConfig);
      }
    },
    [contextMap, isMapReady],
  );

  const hoverFeature = useCallback(
    ({id, source, layer, sourceLayer}) => {
      if (
        contextMap &&
        contextMap.isStyleLoaded() &&
        contextMap.getSource(source) &&
        isMapReady
      ) {
        contextMap.removeFeatureState({
          source,
          sourceLayer,
          layer,
        });
        if (id) {
          contextMap.setFeatureState(
            {
              source,
              sourceLayer,
              id,
            },
            {hover: true},
          );
        }
      }
    },
    [contextMap, isMapReady],
  );

  const getDistricts = useCallback(
    (locationName) => {
      if (contextMap && contextMap.isStyleLoaded() && isMapReady) {
        return contextMap
          .querySourceFeatures('nhoods', {
            sourceLayer: NEIGHBORHOOD_LAYER,
            filter: ['in', locationName, ['get', 'id']],
          })
          .map(({properties}) => `${properties.id_without_location}`);
      }
      return [];
    },
    [contextMap, isMapReady],
  );

  const getBoundingBox = useCallback(() => {
    if (contextMap && isMapReady) {
      return contextMap.getBounds();
    }
    return {};
  }, [contextMap, isMapReady]);

  const recenterMap = useCallback(
    ({latitude, longitude}) => {
      if (contextMap && contextMap.getCanvas() && isMapReady) {
        return contextMap.setCenter([longitude, latitude]);
      }
    },
    [contextMap, isMapReady],
  );

  const resizeMap = useCallback(() => {
    if (contextMap && contextMap.getCanvas() && isMapReady) {
      return contextMap.resize();
    }
    return null;
  }, [contextMap, isMapReady]);

  const setMapTranslations = useCallback(
    (locale) => {
      if (contextMap && contextMap.isStyleLoaded() && isMapReady) {
        const language =
          locale.split('_')[0] !== 'it' ? locale.split('_')[0] : 'en';
        contextMap.setLayoutProperty('country-label', 'text-field', [
          'get',
          `name_${language}`,
        ]);
      }
    },
    [contextMap, isMapReady],
  );

  const setZoom = useCallback(
    (zoom) => {
      if (contextMap && isMapReady) {
        return contextMap.setZoom(zoom);
      }
      return null;
    },
    [contextMap, isMapReady],
  );

  const getZoom = useCallback(() => {
    if (contextMap && isMapReady) {
      return contextMap.getZoom();
    }
    return null;
  }, [contextMap, isMapReady]);

  return {
    getZoom,
    setZoom,
    navigateTo,
    triggerMapRepaint,
    getTilesInViewPort,
    getDistricts,
    getBoundingBox,
    resizeMap,
    setMapTranslations,
    hoverFeature,
    recenterMap,
  };
};
