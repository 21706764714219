import {useState} from 'react';
import {useSelector} from 'react-redux';
import {SlidersHorizontal} from '@phosphor-icons/react';
import {getUserSettings} from '@/selectors';

import {useRouter} from '@/hooks/useRouter';
import {BaseIconButton} from '@/components/common/buttons';
import MyLocationsAnalysisSettings from '@/components/navigation/menus/MyLocationsAnalysisSettings';
import {styles} from '@/components/navigation/list/TopNavigation.styles';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function ActionMyLocationsAnalysisSettings() {
  const {isMyLocationsAnalysisRoute} = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const userSettings = useSelector(getUserSettings);
  const {getFeatureAccess} = useFeatureAccess();

  const hideMyLocationsAnalysis = getFeatureAccess(
    'my-locations-analysis',
    'hide',
  );

  return (
    isMyLocationsAnalysisRoute &&
    !hideMyLocationsAnalysis && (
      <>
        <BaseIconButton
          icon={SlidersHorizontal}
          disabled={!isMyLocationsAnalysisRoute}
          sx={anchorEl ? styles.rightIconActive : styles.rightIcon}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
        <MyLocationsAnalysisSettings
          userSettings={userSettings}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
        />
      </>
    )
  );
}

export default ActionMyLocationsAnalysisSettings;
