import green from '@mui/material/colors/green';
import lightBlue from '@mui/material/colors/lightBlue';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import yellow from '@styles/colors/yellow';
import grey from '@styles/colors/grey';

export const baseTheme = {
  palette: {
    primary: {
      main: yellow[600],
      light: yellow[100],
      dark: yellow[1000],
      contrastText: grey[900],
    },
    secondary: {
      main: grey[600],
      light: grey[500],
      dark: grey[700],
      contrastText: grey[50],
    },
    success: {
      main: green[500],
      light: green[300],
      dark: green[700],
      contrastText: grey[50],
    },
    info: {
      main: lightBlue[500],
      light: lightBlue[300],
      dark: lightBlue[700],
      contrastText: grey[900],
    },
    warning: {
      main: orange[500],
      light: orange[300],
      dark: orange[700],
      contrastText: grey[50],
    },
    error: {
      main: red[500],
      light: red[300],
      dark: red[700],
      contrastText: grey[50],
    },
    action: {
      active: grey[50],
      activatedOpacity: 0.25,
      hover: grey[900],
      hoverOpacity: 0.1,
      selected: yellow[600],
      selectedOpacity: 0.15,
      focus: yellow[600],
      focusOpacity: 0.1,
      disabled: grey[500],
      disabledOpacity: 0.35,
      disabledBackground: grey[900],
    },
    map: {
      none: 'rgba(0, 0, 0, 0)',
      disabled: grey[400],
      text: {
        dark: grey[200],
        light: grey[800],
      },
    },
    quintile: {
      0: '#81e3fc',
      1: '#78c6f5',
      2: '#6a93e7',
      3: '#676dd8',
      4: '#9151b9',
      5: '#bd268e',
    },
    decile: {
      10: '#bd268e',
      9: '#af3da2',
      8: '#9151b9',
      7: '#7961ca',
      6: '#676dd8',
      5: '#6481e3',
      4: '#6a93e7',
      3: '#71abed',
      2: '#78c6f5',
      1: '#81e3fc',
    },
    change: {
      large_increase: '#bd268e',
      increase: '#9151b9',
      no_change: '#676dd8',
      decrease: '#6a93e7',
      large_decrease: '#78c6f5',
      indeterminate: '#fafafa',
    },
    threat: {
      very_high: '#bd268e',
      high: '#9151b9',
      medium: '#676dd8',
      low: '#6a93e7',
      very_low: '#78c6f5',
    },
    chart: {
      1: '#1E3082',
      2: '#2C5A9C',
      3: '#3984B6',
      4: '#47AED0',
      5: '#5CB0D3',
      6: '#62BED2',
      7: '#78C6D0',
      8: '#8BCDD1',
      9: '#AADACC',
      10: '#DCECC9',
    },
    locationTypes: {
      other: '#1E3082',
      office: '#24438D',
      hotel: '#295297',
      event: '#2E61A1',
      service_area: '#347AAC',
      travel: '#3984B6',
      warehouse: '#3C92BF',
      parking: '#3FA1C9',
      restaurant: '#4FB7D4',
      retail: '#6AC1D2',
      point_of_interest: '#87CCCF',
      facility: '#AADACC',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeightBold: 600,
    overrides: {
      MuiMenu: {
        list: {
          fontSize: 14,
        },
      },
    },
  },
};
