export const styles = {
  root: (theme) => ({
    position: 'relative',
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.background.dark,
  }),
  header: {
    display: 'flex',
    height: '40px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: (theme) => theme.palette.text.primary,
    py: 1,
    px: 2,
    borderBottom: (theme) =>
      `1px solid ${theme.palette.background.contrast.dark}`,
  },
  title: {
    fontWeight: 'bold',
  },
  headerActions: {
    display: 'flex',
    gap: 1,
  },
  content: {
    color: (theme) => theme.palette.text.primary,
    padding: 2,
  },
  footer: {
    position: 'absolute',
    height: '40px',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 2,
    py: 0.25,
    borderTop: (theme) => `1px solid ${theme.palette.background.contrast.dark}`,
  },
};
