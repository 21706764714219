import PropTypes from 'prop-types';
import {
  AnimatedBarStack,
  AnimatedBarGroup,
  AnimatedBarSeries,
} from '@visx/xychart';

function BaseBar({
  data = {},
  type = 'grouped',
  colorAccessor,
  radius = 4,
  radiusAll = false,
  radiusTop = true,
  xAccessor = (d) => d?.x ?? 0,
  yAccessor = (d) => d?.y ?? 0,
}) {
  const keys = Object.keys(data);
  const isSingle = keys.length === 1;

  if (!isSingle && type === 'grouped') {
    return (
      <AnimatedBarGroup>
        {keys.map((key) => (
          <AnimatedBarSeries
            colorAccessor={colorAccessor}
            key={key}
            dataKey={key}
            data={data[key]}
            radius={radius}
            radiusTop
            xAccessor={xAccessor}
            yAccessor={yAccessor}
          />
        ))}
      </AnimatedBarGroup>
    );
  }
  if (!isSingle && type === 'stacked') {
    return (
      <AnimatedBarStack>
        {keys.map((key) => {
          const isLast = keys.indexOf(key) === keys.length - 1;
          return (
            <AnimatedBarSeries
              colorAccessor={colorAccessor}
              key={key}
              dataKey={key}
              data={data[key]}
              radius={isLast ? radius : 0}
              radiusTop={isLast}
              xAccessor={xAccessor}
              yAccessor={yAccessor}
            />
          );
        })}
      </AnimatedBarStack>
    );
  }
  return (
    <>
      {keys.map((key) => (
        <AnimatedBarSeries
          colorAccessor={colorAccessor}
          key={key}
          dataKey={key}
          data={data[key]}
          radius={radius}
          radiusAll={radiusAll}
          radiusTop={radiusTop}
          xAccessor={xAccessor}
          yAccessor={yAccessor}
        />
      ))}
    </>
  );
}

BaseBar.propTypes = {
  colorAccessor: PropTypes.func,
  type: PropTypes.oneOf(['stacked', 'series', 'grouped']),
  radius: PropTypes.number,
  radiusAll: PropTypes.bool,
  radiusTop: PropTypes.bool,
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
        y: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.instanceOf(Date),
        ]).isRequired,
      }),
    ).isRequired,
  ),
  xAccessor: PropTypes.func,
  yAccessor: PropTypes.func,
};

export default BaseBar;
