import {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {API_KEY_TAKEAWAY, API_KEY_TAKEAWAY_RADIUS} from '@/hooks/api/constants';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useTranslation} from '@/hooks/useTranslation';
import {useChangeCategory} from '@/hooks/charts/useChangeCategory';
import {useRouter} from '@/hooks/useRouter';
import {useChart} from '@/hooks/api/useChart';
import {useChangeTrend} from '@/hooks/charts/useChangeTrend';
import {useReportURL} from '@/hooks/report/useReportURL';

import {styles} from '@/components/analytics/sections/ChangeSection.styles.js';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import ThreatLandscapeSummary from '@/components/analytics/threatLandscape/ThreatLandscapeSummary';
import ChangeHeader from '@/components/analytics/threatLandscape/ChangeHeader';
import ReportChartWrapper from '@/components/report/ReportChartWrapper';
import ChangeOverview from '@/components/analytics/charts/ChangeOverview';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import AnalyticsWrapper from '@/components/analytics/AnalyticsWrapper';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import BaseTooltip from '@/components/common/BaseTooltip';
import ChangeTrend from '@/components/analytics/charts/ChangeTrend';
import {useChangeOptions} from '@/hooks/charts/useChangeOptions';

/**
 * Displays important information about the selected radius, district or city
 *
 * @param {string} granularity - The current selected tab
 * @returns {React.Component} - The component
 *
 * @example
 * <ChangeSection granularity="radius" id="threat-chart" />
 */
function ChangeSection({
  id,
  granularity,
  sx,
  isReport,
  latitude,
  longitude,
  radius,
  fromDate,
  toDate,
  settings,
  isGTM,
}) {
  const {locale, getI18N} = useTranslation();
  const {getFeatureAccess} = useFeatureAccess();
  const {track} = useSegmentAnalytics();
  const {isSavedRoute} = useRouter();
  const {reportURL} = useReportURL();

  const [aggregation, setAggregation] = useState('quarterly');
  const [period, setPeriod] = useState('year');
  const [normalized, setNormalized] = useState(false);

  const title = getI18N('threatAnalytics.takeAwaysTitle');
  const {change: changeLabel} = getI18N('chartSelection');
  const {changeMinimum} = getI18N('tooltips');
  const {emptyThreatLandscape, threatLandscape, threatChange} =
    getI18N('savedLocations');

  const hideChangeFeature = getFeatureAccess('threat-analytics-change', 'hide');

  const {
    data: changeData,
    errorMonthlyPeriod,
    errorMonthlyYear,
    errorQuarterlyPeriod,
    errorQuarterlyYear,
    isFetching: changeIsLoading,
    yDomain,
    overallChange,
    startDate,
    endDate,
    downloadImage,
    copyImage,
    downloadCSV,
  } = useChangeCategory({
    id,
    title,
    period,
    granularity,
    aggregation,
    normalized,
    disabled: hideChangeFeature,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
  });

  const {
    data: threatLandscapeData,
    error: threatLandscapeError,
    isFetching: threatLandscapeIsLoading,
  } = useChart({
    path: granularity === 'radius' ? API_KEY_TAKEAWAY_RADIUS : API_KEY_TAKEAWAY,
    granularity,
    latitude,
    longitude,
    radius,
    fromDate,
    toDate,
    disabled: hideChangeFeature,
  });

  // change trend
  const {
    data: changeTrendData,
    yDomain: changeTrendYDomain,
    errorMonthlyPeriod: changeTrendErrorMonthlyPeriod,
    errorMonthlyYear: changeTrendErrorMonthlyYear,
    errorQuarterlyPeriod: changeTrendErrorQuarterlyPeriod,
    errorQuarterlyYear: changeTrendErrorQuarterlyYear,
    isFetching: changeTrendIsLoading,
    downloadCSV: downloadCSVChangeTrend,
    copyCSV: copyCSVChangeTrend,
  } = useChangeTrend({
    id,
    title,
    period,
    granularity,
    aggregation,
    normalized,
    disabled: hideChangeFeature,
    ...reportURL?.customFilters,
  });

  const {aggregationOptions, periodOptions, allError} = useChangeOptions({
    locale,
    aggregation,
    period,
    errorMonthlyYear: errorMonthlyYear && changeTrendErrorMonthlyYear,
    errorMonthlyPeriod: errorMonthlyPeriod && changeTrendErrorMonthlyPeriod,
    errorQuarterlyYear: errorQuarterlyYear && changeTrendErrorQuarterlyYear,
    errorQuarterlyPeriod:
      errorQuarterlyPeriod && changeTrendErrorQuarterlyPeriod,
  });

  // Set aggregation to option that is not disabled
  useEffect(() => {
    const option = aggregationOptions.find((o) => o.value === aggregation);
    if (!option || option?.disabled) {
      const nextOption = aggregationOptions.find((o) => !o.disabled);
      setAggregation(nextOption?.value ?? 'monthly');
    }
  }, [aggregationOptions]);

  // Set period to option that is not disabled
  useEffect(() => {
    const option = periodOptions.find((o) => o.value === period);
    if (!option || option?.disabled) {
      const nextOption = periodOptions.find((o) => !o.disabled);
      setPeriod(nextOption?.value ?? 'periodOverPeriod');
    }
  }, [periodOptions]);

  const subtitleText = useMemo(() => {
    const periodLabel = periodOptions.find((p) => p.value === period)?.label;
    return `${periodLabel}`;
  }, [period, periodOptions]);

  const hideChangeOverview = useMemo(
    () => changeData?.length < 1 && !changeIsLoading,
    [changeData, changeIsLoading],
  );

  const hideChangeTrend = useMemo(
    () => !changeTrendData && !changeTrendIsLoading,
    [changeTrendData, changeTrendIsLoading],
  );

  const hideThreatLandscape = useMemo(
    () =>
      (!threatLandscapeIsLoading &&
        threatLandscapeData?.totalCount &&
        threatLandscapeData.totalCount === 0) ||
      threatLandscapeError,
    [threatLandscapeIsLoading, threatLandscapeData, threatLandscapeError],
  );

  const trackChangeEvent = () => {
    track('Filters Applied Change Overview', {
      period,
      aggregation,
      granularity,
    });
  };

  useEffect(() => {
    setAggregation(settings.period);
    setNormalized(settings.normalized);
    setPeriod(settings.metric);
  }, [settings]);

  if (isReport)
    return (
      <ReportChartWrapper
        isLoading={changeIsLoading && !allError}
        error={allError}>
        {id === 'chart-change-overview' && (
          <>
            <ChangeHeader
              overallChange={overallChange}
              period={period}
              aggregation={aggregation}
              startDate={startDate}
              endDate={endDate}
            />
            <ChangeOverview
              height={sx.height - 60}
              width={sx.width}
              isLoading={changeIsLoading}
              data={changeData}
              yDomain={yDomain}
            />
          </>
        )}
        {id === 'chart-change-trend' && (
          <ChangeTrend
            height={sx.height}
            width={sx.width}
            isLoading={changeTrendIsLoading}
            data={changeTrendData}
            yDomain={changeTrendYDomain}
            aggregation={aggregation}
            period={period}
            normalized={normalized}
          />
        )}
      </ReportChartWrapper>
    );

  if (hideChangeFeature) return null;

  if (isSavedRoute) {
    if (hideThreatLandscape && hideChangeOverview)
      return (
        <Box sx={styles.emptyMessageContainer}>
          <Typography variant="caption">
            <Box component="li" sx={styles.message}>
              {emptyThreatLandscape}
            </Box>
          </Typography>
        </Box>
      );
    return (
      <Grid container sx={styles.savedLocationsContainer}>
        <Grid item xs={12} md={4} sx={styles.threatChangeGrid}>
          <FeatureAccessWrapper feature="threat-analytics-change">
            <Typography variant="body2" sx={styles.threatChangeTitle}>
              {threatChange}
            </Typography>
            <ChangeOverview
              height={120}
              isLoading={changeIsLoading}
              data={changeData}
              yDomain={yDomain}
            />
          </FeatureAccessWrapper>
        </Grid>
        <Grid item xs={12} md={8} sx={styles.threatLandscapeGrid}>
          <Box sx={styles.threatLandscapeContainer}>
            <Typography variant="body2" sx={styles.threatLandscapeTitle}>
              {threatLandscape}
            </Typography>
            <Box sx={styles.threatLandscape}>
              <ThreatLandscapeSummary
                granularity={granularity}
                data={threatLandscapeData}
                isFetching={threatLandscapeIsLoading}
                isVisible={!hideThreatLandscape}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (allError || (hideChangeOverview && hideChangeTrend)) return null;

  return (
    <Box id={id} sx={sx}>
      <AnalyticsWrapper
        downloadable
        title={title}
        subtitle={subtitleText}
        granularity={granularity}
        downloadImage={downloadImage}
        copyImage={copyImage}
        copyCSV={copyCSVChangeTrend}
        downloadCSV={() => {
          downloadCSV();
          downloadCSVChangeTrend();
        }}
        isGTM={isGTM && aggregation === 'monthly'}
        chart={
          <Box sx={styles.panel}>
            {!hideChangeOverview && (
              <>
                <ChangeHeader
                  overallChange={overallChange}
                  period={period}
                  aggregation={aggregation}
                  startDate={startDate}
                  endDate={endDate}
                  isLoading={changeIsLoading}
                />
                <ChangeOverview
                  isLoading={changeIsLoading}
                  data={changeData}
                  yDomain={yDomain}
                />
              </>
            )}
            {!hideChangeTrend && (
              <ChangeTrend
                isLoading={changeTrendIsLoading}
                data={changeTrendData}
                yDomain={changeTrendYDomain}
                aggregation={aggregation}
                period={period}
                normalized={normalized}
              />
            )}
          </Box>
        }
        controls={
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="left"
            gap={1}>
            <Typography variant="body2" fontWeight="bold">
              {changeLabel}:
              <BaseTooltip
                title={changeMinimum}
                placement="top"
                iconSize={14}
              />
            </Typography>
            <BaseSelect
              variant="filled"
              size="small"
              value={aggregation}
              onChange={(e) => {
                trackChangeEvent();
                setAggregation(e.target.value);
              }}
              options={aggregationOptions}
              sx={styles.selector}
            />
            <BaseSelect
              variant="filled"
              size="small"
              value={period}
              onChange={(e) => {
                trackChangeEvent();
                setPeriod(e.target.value);
              }}
              options={periodOptions}
              sx={styles.selector}
            />
            <BaseSwitch
              checked={normalized}
              onChange={() => {
                trackChangeEvent();
                setNormalized(!normalized);
              }}
              labelPlacement="end"
              label="Normalized"
            />
          </Box>
        }
        settings={{id, aggregation, period, normalized}}
      />
    </Box>
  );
}

ChangeSection.propTypes = {
  id: PropTypes.string.isRequired,
  granularity: PropTypes.string.isRequired,
  sx: PropTypes.object,
  isReport: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  radius: PropTypes.number,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  settings: PropTypes.object,
  isGTM: PropTypes.bool,
};

export default ChangeSection;
