import {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {getFeedback} from '@sentry/react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';

import {
  House,
  Star,
  MapPinLine,
  Bell,
  Warning,
  ListStar,
} from '@phosphor-icons/react';

import {useRouter} from '@hooks/useRouter';
import {useNotifications} from '@novu/notification-center';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {setLocationAddress} from '@/store/modules/map/actions';
import {getUser} from '@/selectors';

import UserAvatar from '@/components/user/UserAvatar';
import MenuAccount from '@/components/navigation/menus/MenuAccount';
import MenuHelp from '@/components/navigation/menus/MenuHelp';
import NotificationTray from '@/components/navigation/menus/NotificationTray';
import SearchModal from '@/components/search/SearchModal';

import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/navigation/list/Navigation.styles';
import {useFeatureAccess} from '@/hooks/feature-access/useFeatureAccess';

function DrawerItem(props) {
  const {action, selected, icon, selectedIcon, text, divider, styles} = props;
  return (
    <>
      {action ? (
        <ListItem sx={styles.listItem}>
          <ListItemButton
            sx={styles.listButton}
            onClick={action}
            selected={selected}
            title={text}>
            <ListItemIcon sx={styles.listIcon}>
              {selected ? selectedIcon : icon}
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem sx={styles.listItemNoAction} title={text}>
          <ListItemIcon sx={styles.listIcon}>{icon}</ListItemIcon>
        </ListItem>
      )}
      <Divider variant="inset" style={{marginBlock: divider ? 10 : 0}} />
    </>
  );
}

function Navigation() {
  const dispatch = useDispatch();
  const {track} = useSegmentAnalytics();
  const {getI18N} = useTranslation();
  const {home, saved, map, myLocationsAnalysis} = getI18N('navigation');
  const {
    pushMapRoute,
    isMapRoute,
    isHomeRoute,
    pushHomeRoute,
    isSavedRoute,
    pushDashRoute,
    isMyLocationsAnalysisRoute,
    pushMyLocationsAnalysis,
    isUserRoute,
  } = useRouter();
  const notifications = useNotifications();
  const {getFeatureAccess} = useFeatureAccess();
  const hideMyLocationsAnalysis = getFeatureAccess(
    'my-locations-analysis',
    'hide',
  );

  const user = useSelector(getUser);

  const [signOutAnchorEl, setSignOutAnchorEl] = useState(false);
  const [helpAnchorEl, setHelpAnchorEl] = useState(false);
  const [notifAnchorEl, setNotifAnchorEl] = useState(false);
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState(false);

  const handleClose = () => {
    setSignOutAnchorEl(false);
    setHelpAnchorEl(false);
    setNotifAnchorEl(false);
    setFeedbackAnchorEl(false);
  };

  const handleSavedLocationRoute = () => {
    track('Navigate to Saved Locations');
    dispatch(setLocationAddress(null));
    pushDashRoute();
  };

  const handleMapRoute = () => {
    track('Navigate to Map');
    pushMapRoute();
  };

  const handleMyLocationsAnalysis = () => {
    dispatch(setLocationAddress(null));
    pushMyLocationsAnalysis();
  };

  const topList = [
    {
      id: 0,
      text: home,
      icon: <House size={20} />,
      selectedIcon: <House size={20} weight="fill" />,
      action: pushHomeRoute,
      selected: isHomeRoute,
    },
    {
      id: 1,
      text: myLocationsAnalysis,
      icon: <Star size={20} />,
      selectedIcon: <Star size={20} weight="fill" />,
      action: handleMyLocationsAnalysis,
      selected: isMyLocationsAnalysisRoute,
      hide: hideMyLocationsAnalysis,
    },
    {
      id: 2,
      text: saved,
      icon: <ListStar size={20} />,
      selectedIcon: <ListStar size={20} weight="fill" />,
      action: handleSavedLocationRoute,
      selected: isSavedRoute,
    },
    {
      id: 3,
      text: map,
      icon: <MapPinLine size={20} />,
      selectedIcon: <MapPinLine size={20} weight="fill" />,
      action: handleMapRoute,
      selected: isMapRoute,
    },
  ];

  const feedback = getFeedback();
  const formPromise = feedback?.createForm();
  let feedbackWidget = null;

  const showSentryFeedbackModal = async (e) => {
    feedbackWidget = await formPromise;
    if (feedbackWidget) {
      feedbackWidget.appendToDom();
      feedbackWidget.open();
      setFeedbackAnchorEl(e.currentTarget);
    }
  };

  window.addEventListener('sentryFeedbackFormClosed', () => {
    setFeedbackAnchorEl(false);
    if (feedbackWidget) {
      feedbackWidget.close();
      if (document.body.contains(feedbackWidget.element)) {
        feedbackWidget.removeFromDom();
      }
    }
  });

  const handleAlerts = (e) => {
    track('Open Notifications');
    setNotifAnchorEl(e.currentTarget);
  };

  const handleIssue = (e) => {
    track('Open Report an Issue');
    showSentryFeedbackModal(e);
  };

  const bottomList = [
    {
      id: 0,
      text: 'Alerts',
      icon: (
        <Badge
          badgeContent={notifications.unseenCount}
          invisible={Boolean(notifAnchorEl)}
          color="primary">
          <Bell size={20} />
        </Badge>
      ),
      selectedIcon: <Bell size={20} weight="fill" />,
      action: (e) => handleAlerts(e),
      selected: Boolean(notifAnchorEl),
    },
    {
      id: 1,
      text: 'Issue',
      icon: <Warning size={20} />,
      selectedIcon: <Warning size={20} weight="fill" />,
      action: (e) => handleIssue(e),
      selected: Boolean(feedbackAnchorEl),
    },
    {
      id: 2,
      icon: <UserAvatar size="2.5rem" user={user} />,
      selectedIcon: <UserAvatar size="2.5rem" user={user} />,
      action: (e) => setSignOutAnchorEl(e.currentTarget),
      selected: isUserRoute,
    },
  ];

  return (
    <Box sx={styles.root}>
      <Box>
        <Box sx={styles.logo} />
        <List>
          {topList
            .filter(({hide}) => !hide)
            .map((item) => (
              <DrawerItem key={item.id} {...{...item, styles}} />
            ))}
        </List>
      </Box>
      <List>
        {bottomList
          .filter(({hide}) => !hide)
          .map((item) => (
            <DrawerItem key={item.id} {...{...item, styles}} />
          ))}
      </List>
      <SearchModal />
      <NotificationTray anchorEl={notifAnchorEl} onClose={handleClose} />
      <MenuAccount anchorEl={signOutAnchorEl} onClose={handleClose} />
      <MenuHelp anchorEl={helpAnchorEl} onClose={handleClose} />
    </Box>
  );
}

DrawerItem.propTypes = {
  styles: PropTypes.object,
  action: PropTypes.func,
  selected: PropTypes.bool,
  icon: PropTypes.element,
  selectedIcon: PropTypes.element,
  text: PropTypes.string,
  divider: PropTypes.bool,
};

Navigation.propTypes = {};

export default Navigation;
