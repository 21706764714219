import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {styles} from '@/components/common/cards/BaseCard.style';
import BaseTooltip from '@/components/common/BaseTooltip';

function BaseCard({
  id,
  children,
  title,
  tooltip,
  headerActions,
  footer,
  sx = {root: {}, header: {}, content: {}, footer: {}},
  hideHeader,
}) {
  return (
    <Box
      id={id}
      key={id}
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx.root === 'function' ? sx.root(theme) : sx.root),
      })}>
      {(title || headerActions) && !hideHeader && (
        <Box
          sx={(theme) => ({
            ...styles.header,
            ...(typeof sx.header === 'function' ? sx.header(theme) : sx.header),
          })}>
          {title && (
            <Typography variant="body2" sx={styles.title}>
              {title}
              {tooltip && <BaseTooltip message={tooltip} iconSize={14} />}
            </Typography>
          )}
          {headerActions && (
            <Box sx={styles.headerActions}>{headerActions}</Box>
          )}
        </Box>
      )}
      <Box
        sx={(theme) => ({
          ...styles.content,
          ...(typeof sx.content === 'function'
            ? sx.content(theme)
            : sx.content),
        })}>
        {children}
      </Box>
      {footer && (
        <Box
          sx={(theme) => ({
            ...styles.footer,
            ...(typeof sx.footer === 'function' ? sx.footer(theme) : sx.footer),
          })}>
          {footer}
        </Box>
      )}
    </Box>
  );
}

BaseCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  headerActions: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  hideHeader: PropTypes.bool,
  sx: PropTypes.shape({
    root: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    header: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  }),
};

export default BaseCard;
