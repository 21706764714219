import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {useApi} from '@/hooks/api/useApi';
import {
  getClickedHoodId,
  getClickedLocation,
  getLocation,
  getDrawer,
  getSelectedDistrictIds,
} from '@/selectors';
import {useGranularity} from '@/hooks/charts/useGranularity';
import {API_THREAT_SCORE, API_THREAT_SCORE_RADIUS} from '@/hooks/api/constants';
import {DISTANCE_UNIT} from '@/utils/constants/distance';

export const useThreatScore = () => {
  const {id: locationId} = useSelector(getLocation);
  const clickedHoodId = useSelector(getClickedHoodId);
  const selectedDistricts = useSelector(getSelectedDistrictIds);
  const {analyticsOpen} = useSelector(getDrawer);

  const {
    latitude,
    longitude,
    radiusMeters: radius,
  } = useSelector(getClickedLocation);
  const {granularity} = useGranularity();
  const {useGetQuery} = useApi();

  const params = useMemo(() => {
    if (granularity === 'city') {
      return {
        locationId,
      };
    }

    if (granularity === 'district') {
      return {
        locationId,
        hoodIds: JSON.stringify(selectedDistricts ?? [clickedHoodId]),
      };
    }

    if (granularity === 'radius') {
      return {
        latitude,
        longitude,
        radius,
        units: DISTANCE_UNIT.meters,
      };
    }
  }, [
    granularity,
    locationId,
    clickedHoodId,
    selectedDistricts,
    latitude,
    longitude,
    radius,
  ]);

  const {data, isFetching} = useGetQuery({
    path: granularity === 'radius' ? API_THREAT_SCORE_RADIUS : API_THREAT_SCORE,
    params,
    config: {
      enabled: analyticsOpen && !isEmpty(params),
    },
  });

  return {
    data,
    isFetching,
  };
};
