export const styles = {
  innerBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  legend: {
    height: 64,
    padding: 0,
  },
  expandedLegend: {
    height: 'inherit',
  },
  content: {
    height: 360,
  },
};
