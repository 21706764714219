import {NumberSeven, UsersFour} from '@phosphor-icons/react';
import countriesPreview from '@/assets/images/countries.png';
import darkPreview from '@/assets/images/dark.png';
import districtsPreview from '@/assets/images/districts.png';
import eventsPreview from '@/assets/images/events.png';
import heatmapPreview from '@/assets/images/heatmap.png';
import lightPreview from '@/assets/images/light.png';
import satellitePreview from '@/assets/images/satellite.png';
import scoresPreview from '@/assets/images/scores.png';
import changePreview from '@/assets/images/change.png';
import savedLocationsPreview from '@/assets/images/saved-locations.png';
import citiesPreview from '@/assets/images/cities.png';
import nonePreview from '@/assets/images/none.png';

export const mapMarkers = [
  {
    id: 'savedLocations',
    preview: savedLocationsPreview,
  },
  {
    id: 'cities',
    preview: citiesPreview,
  },
];

export const mapStyles = [
  {
    id: 'dark',
    preview: darkPreview,
  },
  {
    id: 'map',
    preview: lightPreview,
  },
  {
    id: 'satellite',
    preview: satellitePreview,
  },
];

export const mapLayers = [
  {
    id: 'heatmap',
    preview: heatmapPreview,
  },
  {
    id: 'countries',
    preview: countriesPreview,
  },
  {
    id: 'districts',
    preview: districtsPreview,
  },
  {
    id: 'events',
    preview: eventsPreview,
  },
  {
    id: 'scores',
    preview: scoresPreview,
    icon: NumberSeven,
  },
  {
    id: 'population',
    preview: scoresPreview,
    icon: UsersFour,
  },
  {
    id: 'change',
    preview: changePreview,
  },
  {
    id: 'none',
    preview: nonePreview,
  },
];
