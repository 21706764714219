import {
  SET_SAVED_LOCATIONS,
  SET_SAVED_LOCATIONS_ANALYSIS_FILTERS,
  SET_SAVED_LOCATIONS_CONTROLS,
  SET_SAVED_LOCATIONS_FILTERS,
  SET_SAVED_LOCATIONS_TAB,
  SET_SAVED_LOCATIONS_TAGS,
} from '@/store/modules/saved-locations/actionTypes';
import {
  CHANGE_SORT_METRICS,
  OVERVIEW,
} from '@/components/saved-locations/constants';

export const initialState = {
  locations: [],
  filters: {
    addressTypes: [],
    tags: [],
    minCustomerRating: 1,
    maxCustomerRating: 5,
    includeUnrated: true,
    visibility: undefined,
  },
  analysisFilters: {
    addressTypes: [],
    tags: [],
    minCustomerRating: 1,
    maxCustomerRating: 5,
    includeUnrated: true,
    visibility: undefined,
    degreesOfChange: [],
    threatScores: [],
  },
  controls: {
    search: undefined,
    changeMetric: CHANGE_SORT_METRICS[1].value,
  },
  tab: OVERVIEW,
  tags: [],
};

const savedLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SAVED_LOCATIONS_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case SET_SAVED_LOCATIONS_ANALYSIS_FILTERS:
      return {
        ...state,
        analysisFilters: action.payload,
      };
    case SET_SAVED_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case SET_SAVED_LOCATIONS_CONTROLS:
      return {
        ...state,
        controls: {
          ...state.controls,
          ...action.payload,
        },
      };
    case SET_SAVED_LOCATIONS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case SET_SAVED_LOCATIONS_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    default:
      return state;
  }
};

export default savedLocationsReducer;
